import { createModel } from "@rematch/core";
import { RootModel } from "./models";
import api from "../api";
import {
  DeviceDetails,
  Dictionary,
  ProfileDetails,
  UIServiceState,
} from "../model";
import { createPollAndRefresh, tryCatch } from "../utils";
import groupBy from "lodash.groupby";
import { add, format } from "date-fns";
import i18n from "utils/i18n";

export interface ProfileState {
  profilesMap: Dictionary<ProfileDetails>;
  profileDevicesMap: Dictionary<DeviceDetails[]>;
  profileIds: string[];
  adapterProfileIds: Dictionary<string[]>;
}

const initialState: ProfileState = {
  profileIds: [],
  profilesMap: {},
  profileDevicesMap: {},
  adapterProfileIds: {},
};

export const profile = createModel<RootModel>()({
  state: initialState, // initial state
  reducers: {
    setProfileDetails(state, { profile }: { profile: ProfileDetails }) {
      const profileIds = [
        ...state.profileIds,
        ...(state.profileIds.indexOf(profile.id) === -1 ? [profile.id] : []),
      ];
      const profilesMap = { ...state.profilesMap, [profile.id]: profile };
      return { ...state, profileIds, profilesMap };
    },
    setProfileDevices(
      state,
      { devices, profileId }: { devices: DeviceDetails[]; profileId: string }
    ) {
      return {
        ...state,
        profileDevicesMap: { ...state.profileDevicesMap, [profileId]: devices },
      };
    },
    setAllProfileDevices(state, devices: Dictionary<DeviceDetails[]>) {
      return {
        ...state,
        profileDevicesMap: devices,
      };
    },
    setAdapterProfiles(
      state,
      { adapterId, profileIds }: { adapterId: string; profileIds: string[] }
    ) {
      return {
        ...state,
        adapterProfileIds: {
          ...state.adapterProfileIds,
          [adapterId]: profileIds,
        },
      };
    },
    clearAll(state) {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async deleteProfile(profileId: string) {
      const apiResult = await tryCatch(api.profile.deleteProfile(profileId));
      if (apiResult.error) {
        dispatch.ui.setToast({
          description: i18n.t("generic:errors.deleteProfileMessage"),
          type: "error",
        });
      } else {
        dispatch.ui.setToast({
          description: i18n.t("generic:success.deleteProfileMessage"),
          type: "success",
        });
        return true;
      }
    },
    async getAdapterProfiles(adapterId: string) {
      const apiResult = await tryCatch(
        api.profile.getProfileDetails(adapterId)
      );
      if (apiResult.error) {
        throw new Error(apiResult.error.message);
      } else {
        const profiles = apiResult.result.data;
        profiles.forEach((profile) =>
          dispatch.profile.setProfileDetails({ profile })
        );
        dispatch.profile.setAdapterProfiles({
          profileIds: profiles.map(({ id }) => id),
          adapterId,
        });
        const devices = await dispatch.device.getDevices(adapterId);
        const devicesMap = groupBy<DeviceDetails>(devices, "profile");
        dispatch.profile.setAllProfileDevices(devicesMap);
        return profiles;
      }
    },
    async getProfileDetails(profileId: string) {
      const apiResult = await tryCatch(
        api.profile.getProfileDetails(profileId)
      );
      if (apiResult.error) {
        throw apiResult.error;
      } else {
        const profiles = apiResult.result.data;
        profiles.forEach((profile) =>
          dispatch.profile.setProfileDetails({ profile })
        );
        const devices = await dispatch.device.getDevices(profileId);
        dispatch.profile.setProfileDevices({ devices, profileId });
        return profiles;
      }
    },
    // handle state changes with impure functions.
    // use async/await for async actions
    async delayBedTime({
      delay,
      profile,
      callback,
      updateUi,
    }: {
      delay: number;
      profile: ProfileDetails;
      callback?: (p: ProfileDetails) => any;
      updateUi: (state: UIServiceState) => any;
    }) {
      updateUi("Updating");
      const day = new global.Date().getDay();
      const { bedtime } = profile.settings;
      const isSchoolNight = bedtime["school-nights"][day];
      const currentDelay = profile.settings["bedtime-delay"];
      let bedtimeStart = isSchoolNight ? bedtime["school"] : bedtime["other"];

      const newDelay = delay === 0 ? delay : currentDelay + delay;

      const res = await tryCatch(
        api.profile.setBedTimeDelay(profile.id, newDelay)
      );
      if (res.error) {
        updateUi("Failed!");
        return dispatch.ui.setToast({
          description: i18n.t("generic:errors.setBedTimeDelayMessage"),
          type: "error",
        });
      }
      const getProfile = () =>
        // api.profile.getProfileDetails(profile.id).then(({ data }) => data[0]!);
        api.profile
          .getProfileDetails(profile.id)
          .then((res) => {
            // console.log("res", res);
            return res.data[0];
          })
          .catch((error) => undefined);

      const nowMinutes = global.Date.now() / 60000;
      const dayStart = new global.Date().setHours(0, 0, 0, 0);
      const dayStartMinutes = dayStart / 60000;
      const currentMinutes = nowMinutes - dayStartMinutes; // necessary for daylight saving

      const actualBedtimeStart = (() => {
        if (bedtimeStart >= 0) {
          // if (start < 360) start += 1440; // Bedtime before 6 AM, add 24 hours
          return newDelay + bedtimeStart;
        }
        return -1;
      })();
      const pastBedtime = currentMinutes >= bedtimeStart;

      const time = add(dayStart, {
        minutes: actualBedtimeStart,
      });
      function getMessage(delay: number) {
        if (delay === 0) {
          return i18n.t("generic:success.bedTimeRemoved");
        } else if (pastBedtime && delay > 0) {
          return `Bedtime delayed. Internet will be on until ${format(
            time,
            "KK:mm bb"
          )}`;
        } else {
          return `Bedtime delayed with ${delay} minutes successfully`;
        }
      }
      createPollAndRefresh<ProfileDetails | undefined>(
        getProfile,
        (profile) => {
          return profile?.settings["bedtime-delay"] === newDelay;
        },
        (state, data) => {
          if (state === "Done!") {
            updateUi(state);
            const message = getMessage(delay);
            dispatch.ui.setToast({
              description: message,
              type: "success",
            });
            if (callback) {
              callback(data!);
            }
            dispatch.profile.setProfileDetails({ profile: data! });
          } else if (state === "Failed!") {
            updateUi(state);
            dispatch.ui.setToast({
              description: i18n.t("generic:errors.setBedTimeDelayMessage"),
              type: "error",
            });
          }
        },
        10
      );
    },
  }),
});
