import React, { useEffect } from "react";
import http from "axios";
import { useHistory } from "react-router-dom";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import PageLayout from "../../layouts/PageLayout";
import timezones from "./timezones";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useSetState } from "ahooks";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import Select from "components/Select";
import { vestResolver } from "@hookform/resolvers/vest";
import { useForm } from "react-hook-form";
import vest, { enforce, test } from "vest";
import i18next from "i18next";
import ErrorMessage from "components/ErrorMessage";

interface Props {
  back?: () => void;
}
interface Inputs {
  timezone: string;
}

const validationSuite = vest.create((data: Inputs) => {
  test("timezone", i18next.t("settings:timezoneScreen:required"), () => {
    enforce(data.timezone).isNotEmpty();
  });
});

const timezoneOptions = timezones.map((timezone) => ({
  label: `UTC ${timezone.formatted.substring(0, 7)} - ${timezone.timeZoneName}`,
  value: timezone.timeZoneName,
}));
const TimezoneContainer: React.FC<Props> = () => {
  const { adapter } = useSelector((state: RootState) => ({
    adapter: state.adapter.adapter!,
  }));
  const [state, setState] = useSetState<{
    initialTimezone: string;
    networkError: string;
  }>();

  const history = useHistory();
  useEffect(() => {
    setState({ initialTimezone: adapter.timezone });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "timezone" });
  }, [adapter.id]);

  const { register, handleSubmit, watch } = useForm<Inputs>({
    mode: "all",
    resolver: vestResolver(validationSuite),
    defaultValues: { timezone: adapter.timezone },
  });

  const onSubmit = async ({ timezone }: Inputs) => {
    http
      .post("/v1/adapter/cfg/locale", {
        timezone,
        id: adapter.id,
      })
      .then(() => {
        history.goBack();
      })
      .catch(() => {});
  };
  const { t } = useTranslation("settings");
  const didSomethingChange = state.initialTimezone !== watch().timezone;

  return (
    <PageLayout
      back={history.goBack}
      save={handleSubmit(onSubmit)}
      didSomethingChange={didSomethingChange}
      testId="timezone"
    >
      <Title title={t("timezoneScreen.title")} className="mb-4" />
      <Select
        label={t("timezoneScreen.label")}
        inputProps={register("timezone")}
        id="day"
        options={timezoneOptions}
      />
      <ErrorMessage error={state.networkError} />
    </PageLayout>
  );
};

export default TimezoneContainer;
