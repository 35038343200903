import React, { useCallback, useEffect, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { validateEmail } from "../../utils";
import http from "axios";
import firebase from "firebase";
import { useHistory } from "react-router";
import ErrorMessage from "components/ErrorMessage";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import Input from "components/Input";
import Button from "components/Button";

type CheckEmailResponse = {
  first_name: string;
  last_name: string;
};
type Props = {};

const FindAccount: React.FC<Props> = () => {
  const { t } = useTranslation("admin");
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const history = useHistory();
  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "admin_account_details" });
  }, []);

  const submit = useCallback(
    (form) => {
      if (form) {
        form.preventDefault();
      }
      firebase.analytics().logEvent("admin_check_user", {});
      FirebaseAnalytics.logEvent("admin_check_user", {});

      http
        .get<CheckEmailResponse>(
          `/v1/admin/chkemail?email=${encodeURIComponent(email)}`
        )
        .then(() => {
          history.push(`/admin/account-details?email=${email}`);
        })
        .catch((e) => {
          setError(e);
        });
    },
    [email, history]
  );
  return (
    <PageLayout testId="find-account-screen">
      <Title title={t("findAccount.title")} />
      <form onSubmit={(e) => submit(e)}>
        <Input
          className="my-4"
          label={t("findAccount.emailLabel")}
          placeholder={t("findAccount.emailPlaceholder")}
          id="email"
          type="email"
          inputProps={{
            value: email,
            onChange: (e: any) => setEmail(e.target.value),
          }}
        />
        <ErrorMessage error={error} />
        <Button
          type="submit"
          disabled={!validateEmail(email)}
          data-testid="submit"
        >
          {t("findAccount.submitButton")}
        </Button>
      </form>
    </PageLayout>
  );
};

export default FindAccount;
