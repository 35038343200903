import React, { useEffect, useState } from "react";

import http from "axios";
import { ReactComponent as ChevronRight } from "../../../assets/images/svg-icons/chevron-right.svg";
import { ListRow } from "../../../components";
import { DeviceDetails } from "../../../model";
import { Link } from "react-router-dom";
import imagePlaceholder from "../../../assets/images/icons/placeholder_64.png";
import { useDeviceIcons } from "utils/hooks";
import EmptyMessage from "components/EmptyMessage";
import { useTranslation } from "react-i18next";
import NavigationLink from "components/NavigationLink";
import Title from "components/Title";

interface Props {
  profileId: string;
}

const DevicesTabComponent: React.FC<Props> = ({ profileId }) => {
  const { t } = useTranslation();
  const [devices, setDevices] = useState<DeviceDetails[]>([]);
  useEffect(() => {
    http
      .get<DeviceDetails[]>(`/v1/device/list?id=${profileId}`)
      .then(({ data }) => setDevices(data));
  }, [profileId]);

  const deviceTypes = useDeviceIcons();
  // NOTE: This will need to change once icons are added
  return (
    <div data-testid="devices-tab">
      <Title title={t("profileOverview:devices.title")} />
      {devices.length === 0 ? (
        <EmptyMessage
          message={t("profileOverview:devices.emptyMessage")}
          linkText={t("profileOverview:devices.manageDevices")}
          linkUrl={`/profile/${profileId}/manage`}
        />
      ) : (
        <div>
          <ul className="data-list module">
            {devices.map((device) => {
              const deviceImage = deviceTypes
                ? deviceTypes.find(({ name }) => name === device.type)?.icon
                : undefined;

              return (
                <Link to={`/device/${device.id}`} className="group">
                  <ListRow
                    image={deviceImage || imagePlaceholder}
                    text={device.name || "Unnamed device"}
                    className=" group-focus:ring group-focus:ring-orange rounded-sm"
                  >
                    <div className="w-3 h-3 fill-current">
                      <ChevronRight />
                    </div>
                  </ListRow>
                </Link>
              );
            })}
          </ul>
          <p>
            <NavigationLink
              to={`/profile/${profileId}/manage`}
              title={t("profileOverview:devices.manageDevices")}
            />
          </p>
        </div>
      )}
    </div>
  );
};

export default DevicesTabComponent;
