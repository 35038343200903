import React from "react";
import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";

import { AdapterListItem } from "../../model";
import DeviceList from "./Devices";
import DeviceDetails from "./Details";
import IdentifyDevice from "./Identify";

interface Props {
  adapter: AdapterListItem;
}

const Routes: React.FC<Props> = ({ adapter }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/list`} exact>
        <DeviceList />
      </Route>
      <Route path={`${path}/:deviceId`} exact>
        <DeviceDetails />
      </Route>
      <Route path={`${path}/identify/:deviceId`} exact>
        <IdentifyDevice />
      </Route>
      <Redirect to={`${path}/list`} />
    </Switch>
  );
};

export default Routes;
