import api from "api";
import { AxiosError } from "axios";
import { UserToken } from "model";
import i18n from "utils/i18n";

interface InterceptorData {
  isLoggedIn: boolean;
  logout: () => void;
  notify: (message: string) => void;
  mergeUserToken: (data: UserToken) => void;
}

export default function httpErrorInterceptor(
  error: AxiosError<any>,
  { isLoggedIn, logout, notify, mergeUserToken }: InterceptorData
) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  const status = error?.response?.status;
  // logdna.log(error);
  const url = error.response?.config?.url;
  if (status === 403 || status === 401) {
    if (
      url?.includes("helpscout.net") ||
      url?.includes("/auth/login") ||
      url?.includes("/auth/chgpwd") ||
      url?.includes("/auth/renew") ||
      !isLoggedIn
    ) {
      return Promise.reject(error);
    }
    // logout and redirect to login
    return api.auth
      .renewToken()
      .then(({ data }) => {
        // console.log("renewed token", url, data);
        mergeUserToken(data);
      })
      .catch((error) => {
        // console.log("user logged out", error);
        notify(i18n.t("generic:errors.sessionExpiredMessage"));
        logout();
      })
      .finally(() => {
        return Promise.reject({
          status: 400,
          message: i18n.t("generic:errors.sessionExpiredMessage"),
        });
      });
  }
  return Promise.reject(error);
}
