import React, { useEffect, useState, useMemo } from "react";
import { IonLoading, IonIcon } from "@ionic/react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { chevronDown } from "ionicons/icons";

import { Dispatch, RootState } from "../../store";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import { Subscription } from "model";
import PageLayout from "../../layouts/PageLayout";
import { useDispatch, useSelector } from "react-redux";
import Title from "components/Title";
import SettingsRow from "components/SettingsRow";
import NewModal from "components/NewModal";
import { Routers } from "../../../src/pages";

interface Props {
  subscription?: Subscription;
}

const SettingsContainer: React.FC<Props> = ({ subscription }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch>();

  const [
    resetAdapterModalWarningIsActive,
    setResetAdapterModalWarningIsActive,
  ] = useState<boolean>(false);
  const [adapterResetInProgress, setAdapterResetInProgress] = useState<boolean>(
    false
  );
  const [timezone, setTimezone] = useState<string>("");

  const { adapter, user } = useSelector((state: RootState) => ({
    adapter: state.adapter.adapter!,
    user: state.authentication.user!,
  }));
  const isAdapterOnline = adapter.services.online.state;

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "settings_container" });
    setTimezone(adapter ? adapter.timezone : "");
  }, [adapter]);

  useMemo(() => {
    if (isAdapterOnline) {
      setAdapterResetInProgress(false);
    }
  }, [isAdapterOnline]);

  const [
    settingsAdvancedSectionIsActive,
    setSettingsAdvancedSectionIsActive,
  ] = useState<boolean>(false);

  const advancedSectionClickHandler = () => {
    setSettingsAdvancedSectionIsActive(!settingsAdvancedSectionIsActive);
  };

  const onCancelAdapterResetModalHandler = () => {
    setResetAdapterModalWarningIsActive(false);
  };
  const onResetAdapterHandler = () => {
    setResetAdapterModalWarningIsActive(false);
    setAdapterResetInProgress(true);
    dispatch.adapter.resetAdapter(adapter.id);
  };

  const UpdateComponent = (
    <button
      className={classNames(
        "bg-white rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2",
        isAdapterOnline
          ? "text-orange hover:text-orange-600 focus:ring-orange"
          : "text-gray-400 focus:ring-gray cursor-default"
      )}
      onClick={() =>
        isAdapterOnline && setResetAdapterModalWarningIsActive(true)
      }
    >
      {t("settings:restartRouter")}
    </button>
  );

  return (
    <PageLayout testId="settings-screen">
      <Title title={t("settings:title")} className="mt-7" />
      <dl className="divide-y divide-gray-200">
        <SettingsRow
          title={t("settings:routerName")}
          updateText={t("settings:update")}
          value={adapter.name}
          updateLink="/settings/rename"
        />
      </dl>
      <Title title={t("settings:wifiSettings")} className="mt-7" />
      <dl className="divide-y divide-gray-200">
        <SettingsRow
          title={t("settings:primaryNetwork")}
          updateText={t("settings:update")}
          value={`${adapter.settings.wifi.ssid} & ${adapter.settings.wifi2.ssid}`}
          updateLink="/settings/primary-network"
        />
      </dl>
      <Title title={t("settings:accountSettings")} className="mt-7" />
      <dl className="divide-y divide-gray-200">
        <SettingsRow
          title={t("settings:email")}
          updateText={t("settings:update")}
          value={user.email}
          updateLink="/settings/edit"
        />
        <SettingsRow
          title={t("settings:timezone")}
          updateText={t("settings:update")}
          value={timezone}
          updateLink="/settings/timezone"
        />
      </dl>

      <>
        <div
          className="flex flex-row flex justify-between items-center w-full my-5 select-none cursor-pointer"
          onClick={advancedSectionClickHandler}
        >
          <h4 className="mt-2 leading-6 font-medium text-gray-900 capitalize text-lg">
            {t("settings:advancedSection")}
          </h4>
          <IonIcon
            icon={chevronDown}
            className={classNames(
              settingsAdvancedSectionIsActive ? "-rotate-180" : "rotate-0",
              "h-6 w-6 transform"
            )}
            aria-hidden="true"
          />
        </div>

        {settingsAdvancedSectionIsActive && (
          <>
            <Routers />
            <Title title={t("settings:routerStatus")} className="mt-7" />
            <SettingsRow
              title={t("settings:routerStatus")}
              value={
                isAdapterOnline
                  ? t("settings:routerOnlineStatus")
                  : t("settings:routerDownStatus")
              }
              updateComponent={UpdateComponent}
            />
          </>
        )}
      </>

      <NewModal
        type="warning"
        open={resetAdapterModalWarningIsActive}
        onClose={onCancelAdapterResetModalHandler}
        onOk={onResetAdapterHandler}
        title={t("settings:resetAdapterConfirmationModalTitle")}
        description={t("settings:resetAdapterConfirmationModalCopy")}
        okText="Yes"
        cancelText="Cancel"
        okTestId="reset-ok"
      />
      {adapterResetInProgress && isAdapterOnline && (
        <IonLoading isOpen message="Loading" />
      )}
    </PageLayout>
  );
};

export default SettingsContainer;
