
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "welcome": "Welcome Back",
  "invalidCredentials": "The password or username you entered is incorrect.",
  "email": {
    "label": "Email",
    "placeholder": "my@email.com"
  },
  "password": {
    "label": "Password",
    "placeholder": "********"
  },
  "pressable": {
    "login": "Login",
    "createAccount": "Create Account",
    "resetPassword": "Reset Password"
  }
}
