import React, { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../store";
import { useHistory } from "react-router-dom";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import { ListRow, Toggle } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import { UIServiceState } from "../../model";

import StreamingServiceItem from "../../components/StreamingServiceItem";
import { IonLoading } from "@ionic/react";
import WarningMessage from "../../components/WarningMessage";
import { useStreamingServicesWithConfig } from "../../utils";
import { useSetState } from "ahooks";
import _ from "lodash";
import Title from "components/Title";
import { useTranslation } from "react-i18next";
import InformationMessage from "components/InformationMessage";

const StreamingRelocationScreen: React.FC<{}> = () => {
  const dispatch = useDispatch<Dispatch>();
  const [uiStatus, setUiStatus] = useState<UIServiceState>();
  const history = useHistory();
  const [state, setState] = useSetState<{ loading: boolean; error: string }>({
    loading: true,
    error: "",
  });
  const { adapter } = useSelector((state: RootState) => ({
    adapter: state.adapter.adapter!,
  }));
  const adapterId = adapter.id;
  useEffect(() => {
    if (!adapter) {
      // setState({ loading: false, error: `Error loading adapter` });
      return;
    }
    setState({ loading: true });
    Promise.all([
      dispatch.streaming.getStreamingConfig(),
      dispatch.streaming.getStreamingServices(),
    ])
      .then(() => {
        setState({ loading: false });
      })
      .catch((error) => {
        console.log("err", error);
        setState({
          loading: false,
          error: `Error loading streaming services configuration`,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const services = useStreamingServicesWithConfig();
  const relocationActive = Boolean(
    adapter ? adapter.services.streamrelocation?.state : false
  );

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "configure_services" });
  }, [adapterId]);
  const updateRelocation = (state: boolean) => {
    dispatch.streaming.toggleRelocation({
      updateUi: setUiStatus,
      wantedState: state,
    });
  };

  const isAdapterOffline = useMemo<boolean>(
    () => !adapter?.services.online.state,
    [adapter]
  );
  const { portableServices, nonPortableServices } = useMemo(() => {
    const multiCountryServices = services.filter((service) => {
      const countriesMap = new Map<string, string>();
      service.countries.proxy.forEach((country) => {
        countriesMap.set(country, country);
      });
      service.countries["non-proxy"].forEach((country) => {
        countriesMap.set(country, country);
      });
      return Array.from(countriesMap).map(([code]) => code).length > 1;
    });

    return {
      portableServices: _.sortBy(
        multiCountryServices.filter(({ portable }) => portable),
        "priority"
      ),
      nonPortableServices: _.sortBy(
        multiCountryServices.filter(({ portable }) => !portable),
        "priority"
      ),
    };
  }, [services]);
  const { t } = useTranslation();
  return (
    <PageLayout>
      <Title title={t("streaming:title")} />
      {state.loading ? (
        <IonLoading isOpen message="Loading" />
      ) : state.error ? (
        <WarningMessage className="my-2" text={state.error} />
      ) : portableServices.length === 0 && nonPortableServices.length === 0 ? (
        <WarningMessage
          className="my-2"
          text={t("streaming:messages.noServices")}
        />
      ) : null}
      {!relocationActive ? (
        <WarningMessage
          className="my-2"
          text={t("streaming:messages.relocationOff")}
        />
      ) : null}
      <InformationMessage
        className="my-2"
        text="After changing country you may need to restart your streaming service."
        moreList={[
          "Smart TV: Turn off and turn on your TV",
          "IOS and Android Apps: Exit and reopen the app",
          "Web Browser: Close and reopen the browser",
          "Roku or Amazon FireTV: Restart the device or disconnect and reconnect power",
          "Other Streamers: Exit and reopen the app",
        ]}
      />
      <div className="">
        <ul className="data-list module">
          <ListRow
            text={t("streaming:relocationToggleLabel")}
            status={uiStatus}
          >
            <Toggle
              disabled={isAdapterOffline}
              id="relocation-toggle"
              checked={relocationActive}
              onChange={(v) => updateRelocation(v)}
            />
          </ListRow>
        </ul>
      </div>
      <div
        className="module-section"
        style={{
          paddingTop: 0,
          display: portableServices.length === 0 ? "none" : "block",
        }}
      >
        <div className="content-list-bundle">
          {/* <InformationMessage text={t("streaming:portableInfo")} /> */}
          <ul className="list-none pl-0">
            {portableServices.map((service) => (
              <StreamingServiceItem
                disabled={isAdapterOffline || !relocationActive}
                key={service.id}
                service={service}
                history={history}
              />
            ))}
          </ul>
        </div>
      </div>
      <div
        className="module-section"
        style={{
          paddingTop: 0,
          display: portableServices.length === 0 ? "none" : "block",
        }}
      >
        <div className="content-list-bundle">
          <InformationMessage text={t("streaming:nonPortableInfo")} />

          <ul className="list-none pl-0">
            {nonPortableServices.map((service) => (
              <StreamingServiceItem
                key={service.id}
                service={service}
                history={history}
                disabled={isAdapterOffline || !relocationActive}
              />
            ))}
          </ul>
        </div>
      </div>
    </PageLayout>
  );
};
export default StreamingRelocationScreen;
