import { createModel } from "@rematch/core";
import {
  AdapterListItem,
  StreamingConfig,
  StreamingServicesWithConfig,
  Subscription,
  WifiConfig,
} from "../model";
import { RootModel } from "./models";

import { Storage } from "@capacitor/storage";
import { createPollAndRefresh, handleError, tryCatch } from "../utils";
import api from "../api";
import _ from "lodash";
import i18n from "utils/i18n";
import { ClaimedAdapter } from "api/adapter";

type OnboardingStage = "before-claim" | "after-claim";

let adapterPollTimer: NodeJS.Timeout;

interface AdapterState {
  list: AdapterListItem[];
  adapter?: AdapterListItem;
  subscriptions: Subscription[];
  onboardingStage?: OnboardingStage;
  pollingTimer?: NodeJS.Timeout;
  streamingServices: StreamingServicesWithConfig[];
  claimedAdapter?: ClaimedAdapter;
}

const initialState: AdapterState = {
  list: [],
  subscriptions: [],
  streamingServices: [],
};

export const adapter = createModel<RootModel>()({
  state: initialState, // initial state
  reducers: {
    _setAdapter(state, adapter: AdapterListItem) {
      return {
        ...state,
        adapter,
      };
    },
    clearAdapter(state) {
      return { ...state, adapter: undefined };
    },
    setAdapterList(state, adapterList: AdapterListItem[]) {
      let adapter;
      if (state.adapter) {
        adapter = adapterList.find((a) => a.id === state.adapter?.id);
      }
      if (!adapter) {
        adapter = adapterList.find((a) => a.services.online.state);
      }
      if (!adapter && adapterList.length > 0) {
        adapter = adapterList[0];
      }
      return {
        ...state,
        list: adapterList,
        adapter,
      };
    },
    setSubscriptions(state, subscriptions: Subscription[]) {
      return { ...state, subscriptions: subscriptions || [] };
    },
    clearAll(state) {
      return { ...initialState };
    },
    setOnboardingStage(state, onboardingStage: OnboardingStage) {
      return { ...state, onboardingStage };
    },
    _setAdapterPollingTimer(state, pollingTimer: NodeJS.Timeout | undefined) {
      return { ...state, pollingTimer };
    },
    _setStreamingServices(
      state,
      streamingServices: StreamingServicesWithConfig[]
    ) {
      return { ...state, streamingServices };
    },
    setClaimedAdapter(state, claimedAdapter: ClaimedAdapter) {
      return { ...state, claimedAdapter };
    },
  },
  effects: (dispatch) => ({
    async getClaimedAdapter() {
      api.adapter
        .checkForClaimedAdapters()
        .then(({ data: { routers } }) => {
          const claimedAdapters = Object.keys(routers).map(
            (key) => routers[key]
          );
          if (claimedAdapters.length > 0) {
            dispatch.adapter.setClaimedAdapter(claimedAdapters[0]);
          }
        })
        .catch((error) => {
          console.error("error in check for claimed adapters", error);
        });
    },
    async configureWifi({
      wifiConfig,
      setLoading,
      onSuccess,
      onError: errorHandler,
    }: {
      wifiConfig: WifiConfig & { id: string };
      setLoading: (a: boolean) => void;
      onSuccess?: () => void;
      onError?: (error?: any) => void;
    }) {
      const result = await tryCatch(api.adapter.configureWifi(wifiConfig));
      const defaultErrorHandler = (error?: any) => {
        const fallback = i18n.t("generic:errors.wifiConfigError");
        dispatch.ui.setToast({
          type: "error",
          description: handleError({ error, fallback }) || fallback,
        });
        setLoading(false);
      };
      const onError = errorHandler || defaultErrorHandler;
      const loadAdapter = async () => {
        let adapterData: AdapterListItem | undefined;
        const getAdapterResponse = await tryCatch(
          api.adapter.getAdapter(wifiConfig.id)
        );
        if (getAdapterResponse.error) {
        } else {
          adapterData = getAdapterResponse.result.data[0]!;
        }
        return adapterData;
      };
      setLoading(true);
      if (result.error) {
        onError(result.error);
        setLoading(false);
      } else {
        createPollAndRefresh(
          loadAdapter,
          (ad) =>
            !ad
              ? false
              : _.gte(
                  ad.settings[wifiConfig.guest ? "guest" : "wifi"].since,
                  result.result.data.pending
                ),
          (state) => {
            if (state === "Done!") {
              dispatch.ui.setToast({
                description: i18n.t("generic:success.wifiConfigured"),
                type: "success",
              });
              setLoading(false);
              onSuccess?.();
            } else if (state === "Failed!") {
              onError();
              setLoading(false);
            }
          },
          10
        );
      }
    },
    async setAdapter(adapter: AdapterListItem, rootState) {
      dispatch.adapter._setAdapter(adapter);
      await Storage.set({
        key: "adapter",
        value: JSON.stringify(adapter),
      });
    },
    async getAdapters() {
      const adapterListResult = await tryCatch(api.adapter.getAdapterList());
      if (adapterListResult.error) {
        return adapterListResult.error;
      } else {
        dispatch.adapter.setAdapterList(adapterListResult.result.data);
      }
    },
    async getAdapter(adapterId: string) {
      const adapterListResult = await tryCatch(
        api.adapter.getAdapter(adapterId)
      );
      if (adapterListResult.error) {
        // return adapterListResult.error;
      } else {
        dispatch.adapter.setAdapter(adapterListResult.result.data[0]!);
        return adapterListResult.result.data[0]!;
      }
    },
    async resetAdapter(adapterId: string) {
      const adapterResetResult = await tryCatch(
        api.adapter.resetAdapter({ id: adapterId, reboot: true })
      );

      if (adapterResetResult.error) {
        console.error(adapterResetResult.error);
      } else {
        return adapterResetResult.result.data;
      }
    },
    /**
     * Get adapter data every 5 seconds and updates the state
     *
     * Adapter data is crucial to the app (adapter online/offline and different settings)
     * Note of caution: this needs to support switching adapters.
     * In order to do that, we need to clear polling timers before switching.
     * The best way to do that is to switch routers using a redux action to keep logic
     * in one place.
     *
     * @param adapterId ID of current adapter!
     */
    async pollForAdapter(adapterId: string) {
      if (adapterPollTimer) {
        clearInterval(adapterPollTimer);
      }
      adapterPollTimer = setInterval(
        () => dispatch.adapter.getAdapter(adapterId),
        5000
      );
      dispatch.adapter._setAdapterPollingTimer(adapterPollTimer);
    },
    stopPollingForAdapter(_, rootState) {
      const { pollingTimer } = rootState.adapter;
      if (pollingTimer) {
        clearInterval(pollingTimer);
      }
      dispatch.adapter._setAdapterPollingTimer(undefined);
    },
    async getStreamingServices(_, rootState) {
      if (!rootState.adapter.adapter) {
        console.warn("Cannot call streaming services when router is not set");
        return [];
      }
      const fallback = i18n.t("generic:errors.streamingConfigError");

      const cfgResult = await tryCatch(
        api.streaming.getStreamingConfig(rootState.adapter.adapter.id)
      );
      let config: StreamingConfig[] = [];
      if (cfgResult.error) {
        // how to handle error
        dispatch.ui.setToast({
          type: "error",
          description:
            handleError({ error: cfgResult.error, fallback }) || fallback,
        });
        return [];
      } else {
        config = cfgResult.result.data;
      }

      const servicesResult = await tryCatch(
        api.streaming.getStreamingServices(rootState.adapter.adapter.id)
      );
      if (servicesResult.error) {
        dispatch.ui.setToast({
          type: "error",
          description:
            handleError({ error: cfgResult.error, fallback }) || fallback,
        });
        // handle error
        return [];
      } else {
        const services = servicesResult.result.data;

        const servicesWithConfig = services.reduce<
          StreamingServicesWithConfig[]
        >((acc, service) => {
          const serviceConfig = config.find(({ id }) => id === service.id);
          acc.push({ ...service, configuration: serviceConfig || null });
          return acc;
        }, []);
        dispatch.adapter._setStreamingServices(servicesWithConfig);
      }
    },
  }),
});
