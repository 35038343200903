import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../store";
import http from "axios";

import PageLayout from "../../layouts/PageLayout";
import { Subscription } from "../../model";
import OnboardingSteps from "./components/OnboardingSteps";

interface SubscriptionResponse {
  subscriptions: Subscription[];
  unassigned_adapters: string[];
}

const subs: SubscriptionResponse = {
  subscriptions: [],
  unassigned_adapters: [],
};

const ActivateContainer: React.FC<{}> = () => {
  const [subscriptionId, setSubscriptionId] = useState<string>("");
  const [success] = useState<boolean>(true);
  const [setError] = useState<any>();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const setSubscriptions = dispatch.adapter.setSubscriptions;
  const { adapter } = useSelector((state: RootState) => ({
    adapter: state.adapter.adapter,
  }));
  useEffect(() => {
    // http
    // .get<SubscriptionResponse>("/v1/client/subscriptions")
    (async () => ({ data: subs }))()
      .then(({ data }) => {
        setSubscriptions(data.subscriptions);
        const availableSubscriptions = data.subscriptions.filter(
          (sub) => !sub.adapter_id
        );
        if (!availableSubscriptions.length) {
          // Open Chargebee session?
          try {
            http
              .post("/v1/client/chargebee", { operation: "start" })
              .then(() => {
                // console.log(chargebee.data);
              });
          } catch (error) {
            setError(error);
          }
        } else if (
          adapter &&
          availableSubscriptions.length < data.unassigned_adapters.length
        ) {
          // make sure the active adapter has a subscription
          if (
            data.unassigned_adapters.find((id) => id === adapter.id.valueOf())
          ) {
            http
              .post("/v1/adapter/cfg/subscription", {
                id: adapter.id,
                subscription: availableSubscriptions[0]!.subscription_id,
              })
              .then(() => history.push("/onboarding/create-primary-network"));
          }
        } else {
          const max = Math.max(
            data.unassigned_adapters.length,
            availableSubscriptions.length
          );
          for (let i = 0; i < max; i += 1) {
            http.post("/v1/adapter/cfg/subscription", {
              id: data.unassigned_adapters[i],
              subscription: availableSubscriptions[i]!.subscription_id,
            });
          }
          history.push("/onboarding/create-primary-network");
        }
      })
      .catch((fetchingSubscriptionsError) => {
        setError(fetchingSubscriptionsError);
      });
  }, []); //eslint-disable-line

  const handleInput = (e: any) => {
    setSubscriptionId(e.target.value);
  };

  const handleClick = async () => {
    // setFindingDevice(true);
    // http.post('/v1/adapter/claim', { id: subscriptionId }).then(() => {
    //   history.push('/onboarding/router-found');
    // }).catch(() => {
    //   setFindingDevice(false);
    //   history.push('/onboarding/router-not-found');
    // });
    history.push("/onboarding/create-primary-network");
  };

  return (
    <PageLayout>
      <OnboardingSteps stage="activateSubscription" />
      <h2>
        {success ? "Activate your subscription" : "Subscription not found"}
      </h2>
      <p>
        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
        eirmod tempor invidunt ut labore et dolore.
      </p>
      <form>
        <div className="field-group">
          <div className="field">
            <label htmlFor="activation-id">Activation Code</label>
            <input
              style={{ borderColor: !success ? "#F29B9B" : "#d1d1d1" }}
              id="activation-id"
              type="text"
              placeholder="XXX-XXXX-XX"
              value={subscriptionId}
              onChange={handleInput}
            />
            {!success && (
              <label
                style={{ color: "#F29B9B", marginTop: 10 }}
                htmlFor="activation-id"
              >
                Invalid code. No active subscription found.
              </label>
            )}
          </div>
        </div>
        <div className="button-group">
          <button
            type="button"
            className="button"
            disabled={!subscriptionId}
            onClick={handleClick}
            data-testid="activate-subscription"
          >
            Activate Subscription
          </button>
          {!success && (
            <Link className="button white" type="button" to="onboarding/help">
              Help
            </Link>
          )}
        </div>
      </form>
    </PageLayout>
  );
};

export default ActivateContainer;
