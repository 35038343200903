import React from "react";
import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";

import { AdapterListItem } from "model";
import GetConnected from "./GetConnected";
import Activate from "./Activate";
import RouterFound from "./RouterFound";
import RouterNotFound from "./RouterNotFound";
import ActivateSubscription from "./ActivateSubscription";
import AllSet from "./AllSet";
import Help from "./Help";
import OnboardingSetNetwork from "./OnboardingSetNetwork";

interface Props {
  adapter?: AdapterListItem;
}

const Routes: React.FC<Props> = ({ adapter }) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/create-primary-network`} exact>
        <OnboardingSetNetwork />
      </Route>
      <Route path={`${path}/get-connected`} exact>
        <GetConnected />
      </Route>
      <Route path={`${path}/activate`} exact>
        <Activate />
      </Route>
      <Route path={`${path}/router-found`} exact>
        <RouterFound />
      </Route>
      <Route path={`${path}/router-not-found`} exact>
        <RouterNotFound />
      </Route>
      <Route path={`${path}/activate-subscription`} exact>
        <ActivateSubscription />
      </Route>
      <Route path={`${path}/all-set`} exact>
        <AllSet />
      </Route>
      <Route path={`${path}/help`} exact>
        <Help />
      </Route>
      <Redirect to={`${path}/get-connected`} />
    </Switch>
  );
};

export default Routes;
