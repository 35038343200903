import React from "react";

import PageLayout from "../../layouts/PageLayout";
import FAQComponent from "../../components/FAQ";

const HelpContainer: React.FC = () => (
  <PageLayout>
    <div className="mt-14">
      <FAQComponent />
    </div>
  </PageLayout>
);
export default HelpContainer;
