import React, { useEffect, useMemo } from "react";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import PageLayout from "../../layouts/PageLayout";
import ProfileRow from "./components/Row";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../store";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import EmptyMessage from "components/EmptyMessage";
import NavigationLink from "components/NavigationLink";

interface Props {
  adapterId: string;
}

const ProfileListComponent: React.FC<Props> = ({ adapterId }) => {
  const { t } = useTranslation();
  const selector = (state: RootState) => {
    if (!state.profile.adapterProfileIds[adapterId]) {
      return [];
    }
    return state.profile.adapterProfileIds[adapterId]!.map(
      (id) => state.profile.profilesMap[id]!
    );
  };
  const profiles = useSelector(selector);
  const dispatch = useDispatch<Dispatch>();

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "profile_list" });
    dispatch.profile.getAdapterProfiles(adapterId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adapterId]);

  const { createdProfiles, defaultProfiles } = useMemo(
    () => ({
      createdProfiles: profiles.filter((profile) => !profile.system),
      defaultProfiles: profiles.filter((profile) => profile.system),
    }),
    [profiles]
  );

  return (
    <PageLayout testId="profiles">
      <Title title={t("profiles:title")} />
      {createdProfiles.length === 0 ? (
        <EmptyMessage
          message={t("home:noProfilesMessage")}
          linkUrl="/profile/create"
          linkText={t("home:pressable.createProfile")}
          className="mb-4"
        />
      ) : (
        <>
          <div className="module-section">
            <div className="data-list-header">
              <div className="item-main">
                <h6>{t("profiles:myProfiles")}</h6>
              </div>
              <div className="item bedtime">
                <h6>{t("profiles:bedtime")}</h6>
              </div>
              <div className="item net">
                <h6>{t("profiles:net")}</h6>
              </div>
              <div className="item net">
                <h6>{t("profiles:delete")}</h6>
              </div>
            </div>
            <ul className="data-list">
              {createdProfiles.map((profile) => (
                <ProfileRow key={profile.id} profile={profile} />
              ))}
            </ul>
            <p>
              <NavigationLink
                to="/profile/create"
                title={t("profiles:pressable.createNewProfile")}
              />
            </p>
          </div>
        </>
      )}

      <div className="module-large">
        <h6>{t("profiles:default")}</h6>
        <ul className="data-list">
          {defaultProfiles.map((profile) => (
            <ProfileRow key={profile.id} profile={profile} />
          ))}
        </ul>
      </div>
    </PageLayout>
  );
};

export default ProfileListComponent;
