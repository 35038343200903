import React, { useState, useEffect, useMemo } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import PageLayout from "../../layouts/PageLayout";
import DevicesTab from "./components/DevicesTab";
import SettingsTab from "./components/SettingsTab";
import ProfileOverview from "./components/Overview";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "store";
import UsageChart from "components/UsageChart";
import { classNames } from "utils";
import { useTranslation } from "react-i18next";

export type ProfileTab = "Usage" | "Settings" | "Devices";

const tabs: ProfileTab[] = ["Usage", "Settings", "Devices"];

const ProfileDetailComponent: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const { profileId } = useParams<{ profileId: string }>();
  const profile = useSelector(
    (state: RootState) => state.profile.profilesMap[profileId]
  );
  const search = useLocation().search;
  const query = useMemo(() => new URLSearchParams(search), [search]);

  const [tab, setTab] = useState<ProfileTab>("Settings");

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "profile_details", tab });
  }, [tab]);

  useEffect(() => {
    if (query.has("tab")) {
      const activeTab = query.get("tab") as ProfileTab;
      if (tabs.indexOf(activeTab) > -1) {
        setTab(activeTab);
      }
    }
  }, [query]);

  useEffect(() => {
    dispatch.profile.getProfileDetails(profileId).catch((error) => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);
  const { t } = useTranslation();
  return (
    <PageLayout>
      {profile && (
        <div className="mt-12">
          <ProfileOverview
            link={`/profile/${profileId}/edit`}
            profile={profile}
          />
          <div className="block mb-4">
            <nav
              className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
              aria-label="Tabs"
            >
              {tabs.map((name, tabIdx) => (
                <Link
                  key={name}
                  to={`?tab=${name}`}
                  onClick={(e) => {
                    setTab(name);
                  }}
                  className={classNames(
                    name === tab
                      ? "text-gray-900"
                      : "text-gray-500 hover:text-gray-700",
                    tabIdx === 0 ? "rounded-l-lg" : "",
                    tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                    "no-underline group relative min-w-0 flex-1 overflow-hidden",
                    "transition bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 focus:ring ring-orange"
                  )}
                  aria-current={name === tab ? "page" : undefined}
                >
                  <span className="capitalize">
                    {t(`profileOverview:tabs.${name.toLocaleLowerCase()}`)}
                  </span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      name === tab ? "bg-orange" : "bg-transparent",
                      "absolute inset-x-0 bottom-0 h-0.5"
                    )}
                  />
                </Link>
              ))}
            </nav>
          </div>
          <div className="my-6">
            {tab === "Usage" && <UsageChart id={profileId} type="profile" />}
            {tab === "Settings" && (
              <SettingsTab profileId={profileId} setTab={setTab} />
            )}
            {tab === "Devices" && <DevicesTab profileId={profileId} />}
          </div>
        </div>
      )}
    </PageLayout>
  );
};

export default ProfileDetailComponent;
