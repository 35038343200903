import React, { useEffect, useMemo, useState } from "react";
import http, { AxiosResponse } from "axios";
import { useParams, useHistory } from "react-router-dom";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import PageLayout from "../../layouts/PageLayout";
import { ProfileDetails } from "../../model";
import { IconSelector } from "../../components";
import DeleteProfile from "./components/DeleteProfileModal";
import { IonLoading } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../store";
import { useSetState } from "ahooks";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import Input from "components/Input";
import i18next from "i18next";
import { enforce, test } from "vest";
import vest from "vest";
import { vestResolver } from "@hookform/resolvers/vest";
import { useForm } from "react-hook-form";
import Button from "components/Button";
import EmptyMessage from "components/EmptyMessage";
import ErrorMessage from "components/ErrorMessage";

interface Props {
  adapterId: string;
}

interface Inputs {
  name: string;
}

const validationSuite = vest.create((data: Inputs) => {
  test("name", i18next.t("profileEditCreate:nameRequiredMessage"), () => {
    enforce(data.name).isNotEmpty();
  });
  test("name", i18next.t("profileEditCreate:nameTooLong"), () => {
    enforce(data.name.length).isBetween(1, 64);
  });
});

const CreateProfileComponent: React.FC<Props> = ({ adapterId }) => {
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    setDidMount(true);
  }, []);
  const dispatch = useDispatch<Dispatch>();
  const { profileId } = useParams<{ profileId: string }>();
  const [icon, setIcon] = useState<string | undefined>("");
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const [originalProfile, setOriginalProfile] = useState<ProfileDetails | null>(
    null
  );
  const [system, setSystem] = useState(true);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [error, setError] = useState<any>();
  const history = useHistory();
  const [initialState, setInitialState] = useSetState<{
    icon: string;
    name: string;
  }>({ icon: "", name: "" });

  const { register, formState, setValue, watch, trigger } = useForm<Inputs>({
    resolver: vestResolver(validationSuite),
    mode: "onChange",
    defaultValues: { name: "" },
  });

  const { isValid, errors } = formState;

  const didSomethingChange =
    initialState.name !== watch().name || initialState.icon !== icon;

  const profileDevices = useSelector(
    (state: RootState) => state.profile.profileDevicesMap[profileId] || []
  );
  const isDeletingDisabled = useMemo(() => profileDevices.length > 0, [
    profileDevices,
  ]);
  useEffect(() => {
    if (profileId) {
      FirebaseAnalytics.logEvent("init_page", { page: "create_profile" });
      setLoadingMessage("Loading");
      dispatch.profile
        .getProfileDetails(profileId)
        .then(async (data) => {
          if (data.length > 0) {
            const profile = data[0];
            setOriginalProfile(profile);
            setValue("name", profile.name);
            // this is a hack to make the form valid
            trigger("name");
            setIcon(profile.icon);
            setSystem(profile.system);
            setInitialState({ name: profile.name, icon: profile.icon });
            setLoadingMessage("");
          }
        })
        .catch((error) => {
          setError(error);
          setLoadingMessage("");
        });
    } else {
      // ERROR
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileId]);

  const save = () => {
    const name = watch().name;
    // the button is disabled when the profile isn't changed.
    // this will make sure the icon or the name have changed before making the api call.
    let update: Promise<AxiosResponse<ProfileDetails>>;
    const body: { name?: string; icon?: string } = {};
    if (icon && icon !== originalProfile?.icon) {
      body.icon = icon;
    }
    if (name && name !== originalProfile?.name) {
      body.name = name;
    }

    if (profileId) {
      setLoadingMessage("Updating Profile");
      update = http.post<ProfileDetails>("/v1/profile/cfg/name", {
        ...body,
        id: profileId,
      });
    } else {
      setLoadingMessage("Creating Profile");
      update = http.post<ProfileDetails>("/v1/profile/create", {
        ...body,
        id: adapterId,
      });
    }
    update
      .then(() => {
        setLoadingMessage("");
        history.goBack();
      })
      .catch((err) => {
        setError(err);
        setLoadingMessage("");
      });
  };

  const { t } = useTranslation("profileEditCreate");
  const isSubmitDisabled = !isValid || !icon;

  return (
    <PageLayout
      back={history.goBack}
      save={save}
      disabled={isSubmitDisabled}
      didSomethingChange={didSomethingChange}
    >
      {loadingMessage ? <IonLoading message={loadingMessage} isOpen /> : null}
      <Title
        title={profileId ? t("editTitle") : t("createTitle")}
        className="mb-4"
      />
      <Input
        type="text"
        id="name"
        placeholder={t("namePlaceholder")}
        label={t("nameLabel")}
        inputProps={{
          ...register("name"),
          readOnly: profileId && system ? true : undefined,
          minLength: 8,
        }}
        error={errors.name?.message}
      />
      <Title title={t("iconSelectorTitle")} className="mt-4" level={2} />
      {didMount ? <IconSelector select={setIcon} selected={icon} /> : null}
      {!system && (
        <>
          <Button
            type="button"
            variant="danger"
            data-testid="delete-profile"
            onClick={() => setConfirmDelete(true)}
            disabled={isDeletingDisabled}
          >
            {t("deleteButton")}
          </Button>
          {isDeletingDisabled ? (
            <EmptyMessage
              className="mt-2"
              linkText={t("manageDevices")}
              linkUrl={`/profile/${profileId}/manage`}
              message={t("cannotDeleteMessage")}
            />
          ) : null}
        </>
      )}
      <DeleteProfile
        open={confirmDelete}
        successCallback={() => {
          setLoadingMessage("");
          history.go(-2);
        }}
        preDelete={() => {
          setLoadingMessage("Deleting Profile");
        }}
        closeModal={() => setConfirmDelete(false)}
        profileId={profileId}
      />
      <ErrorMessage error={error} fallback={t("fallbackNetworkError")} />
    </PageLayout>
  );
};
export default CreateProfileComponent;
