/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useMemo } from "react";
import http from "axios";
import { Link } from "react-router-dom";
import { Dispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import imagePlaceholder from "../../assets/images/icons/placeholder_64.png";

import { DeviceDetails } from "../../model";
import PageLayout from "../../layouts/PageLayout";
import { ListRow } from "../../components";
import { DateTime, Duration } from "luxon";
import { IonLoading } from "@ionic/react";
import InternetPause from "../../components/InternetPause";
import { useDeviceIcons } from "utils/hooks";
import { useTranslation } from "react-i18next";
import ErrorMessage from "components/ErrorMessage";
import Title from "components/Title";

interface ProfileGroup {
  id: string;
  name: string;
  devices: DeviceDetails[];
  oldDevices: DeviceDetails[];
  system?: boolean;
}

const DeviceListComponent: React.FC<{}> = () => {
  const dispatch = useDispatch<Dispatch>();
  const { t } = useTranslation("devices");
  const { adapterId } = useSelector((state: RootState) => ({
    adapterId: state.adapter.adapter!.id,
  }));
  const [profileGroups, setGroups] = useState<ProfileGroup[]>([]);
  const [recent, setRecent] = useState<number>(0);
  const [error, setError] = useState();
  const [loading, setLoading] = useState<boolean>(true);
  const [
    shouldDisplayOldDevices,
    setShouldDisplayOldDevices,
  ] = useState<boolean>(false);

  useEffect(() => {
    initData();
    async function initData() {
      try {
        FirebaseAnalytics.logEvent("init_page", { page: "device_list" });
        const profiles = await dispatch.profile.getAdapterProfiles(adapterId);

        const profileMap: { [id: string]: ProfileGroup } = {};
        profiles.forEach((profile) => {
          profileMap[profile.id] = {
            id: profile.id,
            devices: [],
            oldDevices: [],
            name: profile.name,
            system: profile.system,
          };
        }, {});

        const devices = await http.get<DeviceDetails[]>(
          `/v1/device/list?id=${adapterId}`
        );
        let newlyDiscovered = 0;
        const nowTime = DateTime.local();

        const recentDuration = Duration.fromObject({ days: 1 });
        const sharedInactiveDuration = Duration.fromObject({ days: 7 });
        const otherInactiveDuration = Duration.fromObject({ days: 30 });

        devices.data.forEach((device) => {
          const discoveryTime = DateTime.fromSeconds(device.discovered);
          const lastSeenOnlineTime = DateTime.fromSeconds(device.online_since); //TODO: CONVERT TO LOCAL

          if (nowTime.diff(discoveryTime) < recentDuration) {
            newlyDiscovered += 1;
          }

          if (!profileMap[device.profile]) {
            profileMap[device.profile] = {
              id: device.profile,
              name: device.name,
              devices: [],
              oldDevices: [],
            };
          }
          const profile = profileMap[device.profile]!;
          const cutoffDuration = profile.system
            ? sharedInactiveDuration
            : otherInactiveDuration;

          const isOnlineOrNotOld =
            device.online || nowTime.diff(lastSeenOnlineTime) < cutoffDuration;

          if (isOnlineOrNotOld) {
            profile.devices.push(device);
          } else {
            profile.oldDevices.push(device);
          }
        });
        setRecent(newlyDiscovered);
        // Do we display profiles without devices?
        setGroups(
          Object.values(profileMap).filter(
            (profile) =>
              profile.oldDevices.length > 0 || profile.devices.length > 0
          )
        );
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adapterId]);

  // NOTE: This will need to change once icons are added
  const deviceTypes = useDeviceIcons();
  const getImage = (type: string) => {
    if (!deviceTypes) {
      return imagePlaceholder;
    } else {
      return (
        deviceTypes.find(({ name }) => name === type)?.icon || imagePlaceholder
      );
    }
  };
  const oldDevicesCount = useMemo(
    () =>
      profileGroups.reduce(
        (count, group) => count + group.oldDevices.length,
        0
      ),
    [profileGroups]
  );
  return (
    <PageLayout testId="device-list-screen">
      {loading ? <IonLoading message={t("loading")} isOpen /> : null}
      <Title
        title={t("title")}
        subtitle={recent > 0 ? `${recent} ${t("subtitle")}` : ""}
      />
      <ErrorMessage error={error} fallback="Error loading device list" />
      {oldDevicesCount > 0 ? (
        <span>
          {shouldDisplayOldDevices
            ? [
                t("oldDevicesShown"),
                " ",
                <a
                  key="a"
                  data-testid="hide-old-devices"
                  onClick={() => setShouldDisplayOldDevices(false)}
                >
                  {t("pressable.hideThem")}
                </a>,
              ]
            : [
                "Old device(s) currently hidden ",
                <a
                  key="b"
                  data-testid="display-old-devices"
                  onClick={() => setShouldDisplayOldDevices(true)}
                >
                  {t("pressable.showThem")}
                </a>,
              ]}
        </span>
      ) : null}
      {profileGroups.length === 0 && !loading ? (
        <span>{t("noDevices")}</span>
      ) : null}
      {profileGroups.map((group) => (
        <div key={group.id} className="module-section">
          <div>
            <h6 className="truncate">{group.name}</h6>
            <ul className="data-list">
              {group.devices.map((device) => (
                <ListRow
                  key={device.id}
                  image={getImage(device.type)}
                  text={
                    <Link
                      to={`/device/${device.id}`}
                      className="truncate hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 rounded-sm"
                    >
                      {device.name || t("unnamedDevice")}
                    </Link>
                  }
                >
                  <div className="actions">
                    <ul className="list-unstyled">
                      <li>
                        <InternetPause
                          type="device"
                          name={device.name || t("unnamedDevice")}
                          pauseState={device.cfg?.["internet-pause"]?.state}
                          id={device.id}
                        />
                      </li>
                    </ul>
                  </div>
                </ListRow>
              ))}
              {shouldDisplayOldDevices
                ? group.oldDevices.map((device) => (
                    <ListRow
                      key={device.id}
                      image={getImage(device.type)}
                      text={
                        <Link
                          data-testid="old-device"
                          className="old-device"
                          to={`/device/${device.id}`}
                        >
                          {`${
                            device.name || t("unnamedDevice")
                          } (last active ${DateTime.fromSeconds(
                            device.online_since
                          ).toRelativeCalendar()})`}
                        </Link>
                      }
                    >
                      <div className="actions">
                        <ul className="list-unstyled">
                          <li>
                            <InternetPause
                              type="device"
                              name={device.name || "Unnamed device"}
                              pauseState={device.cfg?.["internet-pause"]?.state}
                              id={device.id}
                            />
                          </li>
                        </ul>
                      </div>
                    </ListRow>
                  ))
                : null}
              {/* {group.devices.length === 0 && (
                <span>No recently used devices</span>
              )} */}
            </ul>
          </div>
        </div>
      ))}
    </PageLayout>
  );
};

export default DeviceListComponent;
