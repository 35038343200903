import { Switch } from "@headlessui/react";
import classNames from "classnames";
import React from "react";

interface ContainerProps {
  id: string;
  checked: boolean;
  onChange: (v: boolean) => void;
  disabled?: boolean;
  testId?: string;
  hint?: string;
}

const ToggleComponent: React.FC<ContainerProps> = ({
  id,
  onChange,
  checked,
  disabled,
  testId = id,
  hint,
}) => (
  <Switch
    id={id}
    checked={checked}
    onChange={onChange}
    disabled={disabled}
    data-testid={testId}
    className={classNames(
      checked ? "bg-orange" : "bg-gray-200",
      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer disabled:cursor-not-allowed transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
    )}
  >
    <span className="sr-only">{hint}</span>
    <span
      aria-hidden="true"
      className={classNames(
        checked ? "translate-x-5" : "translate-x-0",
        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
      )}
    />
  </Switch>
);

export default ToggleComponent;
