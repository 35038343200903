import http from "axios";
import { GET_STREAMING_CONFIG, GET_STREAMING_SERVICES } from "../constants";
import {
  RelocationServiceType,
  StreamingConfig,
  StreamingConfigQueued,
  StreamingServices,
} from "../model";
import { createMock } from "./utils";

export function getStreamingServices(
  adapterId: string,
  serviceType: RelocationServiceType = "All"
) {
  return http.get<StreamingServices[]>(
    `${GET_STREAMING_SERVICES}?id=${adapterId}&type=${serviceType}`
  );
}
export function mockGetStreamingServices(
  adapterId: string,
  serviceType: RelocationServiceType = "All"
) {
  return createMock<StreamingServices[], undefined>(
    `${GET_STREAMING_SERVICES}?id=${adapterId}&type=${serviceType}`,
    "GET"
  );
}

export function getStreamingConfig(adapterId: string) {
  return http.get<StreamingConfig[]>(`${GET_STREAMING_CONFIG}?id=${adapterId}`);
}
export function mockGetStreamingConfig(adapterId: string) {
  return createMock<StreamingConfig[], undefined>(
    `${GET_STREAMING_CONFIG}?id=${adapterId}`,
    "GET"
  );
}

export interface StreamingConfigBody {
  id: string;
  adapter: string;
  country: string;
}
export function configureStreamingService(config: StreamingConfigBody) {
  return http.post<StreamingConfigQueued>(GET_STREAMING_CONFIG, config);
}

export const mockConfigureStreamingService = createMock<
  StreamingConfigQueued,
  StreamingConfigBody
>(GET_STREAMING_CONFIG, "POST");
