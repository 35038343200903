import { StreamingServicesWithConfig, UIServiceState } from "model";
import CountryCodes from "../pages/streaming/CountryCodes";
import React, { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../store";
import { IonSpinner } from "@ionic/react";
import imagePlaceholder from "../assets/images/icons/placeholder_64.png";
import { getCountryName } from "utils";
import * as Flags from "../assets/images/flags";

const getFlagForCountry = (code: string) => {
  const country = CountryCodes[code];
  return country ? <country.flag /> : <></>;
};

const getFlagForCity = () => {
  return <Flags.City />;
};

const removeCodeFromCaption = (caption: string) => {
  return caption.substring(4);
};

const getCaptionFromCityCode = (code: string, captions: string[]) => {
  for (var caption_index in captions) {
    if (captions[caption_index].startsWith(code)) {
      return removeCodeFromCaption(captions[caption_index]);
    }
  }
  return code;
};

const isCity = (code: string) => {
  return code.length === 3;
};

type H = ReturnType<typeof useHistory>;

interface ItemProps {
  service: StreamingServicesWithConfig;
  history: H;
  disabled?: boolean;
}

export default function StreamingServiceItem({
  service,
  disabled = false,
}: ItemProps) {
  const dispatch = useDispatch<Dispatch>();
  const adapterId = useSelector(
    (state: RootState) => state.adapter.adapter!.id
  );
  const currentCountry = service.configuration?.country;
  const [currentCountryUpdating, setCurrentCountryUpdating] = useState<string>(
    ""
  );
  const [loadingState, setLoadingState] = useState<UIServiceState>();
  const countries = useMemo(() => {
    return _.uniq([
      ...service.countries["non-proxy"],
      ...service.countries.proxy,
    ]);
  }, [service.countries]);

  const selectCountry = useCallback(
    (country: string, isCityCode: boolean, locCaption: string) => {
      if (loadingState === "Updating") {
        return;
      }
      setCurrentCountryUpdating(country);
      dispatch.streaming.configureStreamingService({
        config: { country, adapter: adapterId, id: service.id },
        updateUi: setLoadingState,
        serviceName: service.name,
        isCityCode: isCityCode,
        locCaption: locCaption,
      });
    },
    [adapterId, dispatch.streaming, loadingState, service.id, service.name]
  );

  return (
    <div
      className={`py-3 my-2 rounded border-b-2 border-solid border-gray-100 ${
        disabled ? "disabled" : ""
      }`}
    >
      <div className="upper-row">
        <div className="left-column">
          <div className="service_icon-container">
            <img
              alt={`Streaming service icon for ${service.name}`}
              className="service-icon"
              src={service.icon || imagePlaceholder}
            />
            {currentCountry ? (
              <div className="current-country">
                {getFlagForCountry(currentCountry)}
              </div>
            ) : null}
          </div>
          <div className="sr-service-name">
            <span>{service.name}</span>
            {currentCountry ? (
              <button
                className="sr-disable focus:ring focus:ring-orange focus:outline-none hover:text-orange"
                onClick={() => selectCountry("", false, "")}
              >
                {currentCountryUpdating === "" &&
                loadingState === "Updating" ? (
                  <IonSpinner
                    name="lines"
                    style={{ width: 18, marginRight: 4 }}
                  />
                ) : null}
                Disable relocation
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="countries-list">
        {countries.map((country) =>
          isCity(country) ? (
            <button
              onClick={() =>
                selectCountry(
                  country,
                  true,
                  getCaptionFromCityCode(country, service.location_caption)
                )
              }
              onKeyPress={() =>
                selectCountry(
                  country,
                  true,
                  getCaptionFromCityCode(country, service.location_caption)
                )
              }
              className={`flex flex-wrap border-2 shadow-sm rounded-full ml-0 mx-1 my-1 space-y-1 sr-country bg-gray-50 focus:ring focus:outline-none focus:ring-orange hover:scale-105 items-center ${
                currentCountry === country
                  ? "ring-2 ring-orange-500 selected"
                  : ""
              } ${loadingState === "Updating" ? "updating" : ""} ${
                disabled
                  ? "hover:scale-100 pointer-events: none cursor: not-allowed"
                  : ""
              }`}
              key={country}
              title={getCaptionFromCityCode(country, service.location_caption)}
              disabled={disabled}
              style={
                disabled
                  ? {
                      fontWeight: 500,
                      cursor: "not-allowed",
                    }
                  : {}
              }
            >
              {CountryCodes[country]
                ? getFlagForCountry(country)
                : getFlagForCity()}
              {currentCountryUpdating === country &&
              loadingState === "Updating" ? (
                <IonSpinner
                  name="lines"
                  style={{ position: "absolute", left: 0 }}
                />
              ) : null}
              <div
                className=""
                style={{
                  margin: "0 10px",
                  float: "right",
                  display: "inline-block",
                  clear: "both",
                  fontWeight: 500,
                }}
              >
                {isCity(country)
                  ? getCaptionFromCityCode(country, service.location_caption)
                  : ""}
              </div>
            </button>
          ) : (
            <button
              onClick={() => selectCountry(country, false, "")}
              onKeyPress={() => selectCountry(country, false, "")}
              className={`flex flex-wrap first:ml-0 ml-1 mr-1 sr-country focus:ring focus:outline-none focus:ring-orange hover:scale-110 items-center ${
                currentCountry === country ? "selected" : ""
              } ${loadingState === "Updating" ? "updating" : ""} ${
                disabled
                  ? "hover:scale-100 pointer-events: none cursor: not-allowed"
                  : ""
              }`}
              key={country}
              title={getCountryName(country)}
              disabled={disabled}
              style={
                disabled
                  ? {
                      fontWeight: 500,
                      cursor: "not-allowed",
                    }
                  : {}
              }
            >
              {getFlagForCountry(country)}
              {currentCountryUpdating === country &&
              loadingState === "Updating" ? (
                <IonSpinner
                  name="lines"
                  style={{ position: "absolute", left: 0 }}
                />
              ) : null}
              <div
                style={{
                  float: "right",
                  display: "inline-block",
                  clear: "both",
                  fontWeight: 500,
                }}
              >
                {isCity(country)
                  ? getCaptionFromCityCode(country, service.location_caption)
                  : ""}
              </div>
            </button>
          )
        )}
      </div>
    </div>
  );
}
