import http from "axios";
import {
  STRIPE_CONFIG_ROUTE,
  LIST_SUBSCRIPTIONS_ROUTE,
  LIST_CODES_ROUTE,
  LIST_INVOICES_ROUTE,
  APPLY_REDEMPTION_CODE_ROUTE,
  CLAIM_CODE_ROUTE,
  CREATE_CHECKOUT_SESSION_SUBSCRIPTION_ROUTE,
  CREATE_CHECKOUT_SESSION_ONE_TIME_PAYMENT_ROUTE,
  CREATE_CHECKOUT_SESSION_PAYMENT_METHOD_UPDATE_ROUTE,
  CREATE_CUSTOMER_PORTAL_SESSION_ROUTE,
  CREATE_CUSTOMER_PORTAL_SESSION_PAYMENT_METHOD_UPDATE_ROUTE,
  CREATE_CUSTOMER_PORTAL_SESSION_SUBSCRIPTION_CANCEL_ROUTE,
  CREATE_CUSTOMER_PORTAL_SESSION_SUBSCRIPTION_UPDATE_ROUTE,
  CREATE_CUSTOMER_PORTAL_SESSION_SUBSCRIPTION_UPDATE_CONFIRM_ROUTE,
  SESSION_STATUS_ROUTE,
  CANCEL_SUBSCRIPTION_ROUTE,
  RENEW_SUBSCRIPTION_ROUTE,
  CHANGE_PLAN_ROUTE,
} from "../constants";
import { Code, Invoice, Subscription, UnclaimedCodes } from "../model";

interface ConfigResponse {
  publishable_key: string;
  prices: [any];
}

export function getConfig() {
  return http.get<ConfigResponse>(STRIPE_CONFIG_ROUTE);
}

interface ListSubscriptionsResponse {
  subscriptions: Subscription[];
}

export function getSubscriptionList() {
  return http.get<ListSubscriptionsResponse>(LIST_SUBSCRIPTIONS_ROUTE);
}

interface ListCodesResponse {
  codes: Code[];
  unclaimed_codes: UnclaimedCodes[];
}

export function getCodesList() {
  return http.get<ListCodesResponse>(LIST_CODES_ROUTE);
}

interface ListInvoicesResponse {
  invoices: Invoice[];
  balance: number;
}

export function getInvoiceList() {
  return http.get<ListInvoicesResponse>(LIST_INVOICES_ROUTE);
}

interface ApplyRedemptionCodeBody {
  adapter_id: string;
  redemption_code: string;
}

interface ApplyRedemptionCodeResponse {
  result: string;
}

export function applyRedemptionCode(
  adapter_id: string,
  redemption_code: string
) {
  const applyRedemptionCodeBody: ApplyRedemptionCodeBody = {
    adapter_id,
    redemption_code,
  };
  return http.post<ApplyRedemptionCodeResponse>(
    APPLY_REDEMPTION_CODE_ROUTE,
    applyRedemptionCodeBody
  );
}

interface ClaimCodeBody {
  adapter_id: string;
  code_type: string;
}

interface ClaimCodeResponse {
  result: string;
}

export function claimCode(adapter_id: string, code_type: string) {
  const claimCodeBody: ClaimCodeBody = {
    adapter_id,
    code_type,
  };
  return http.post<ClaimCodeResponse>(CLAIM_CODE_ROUTE, claimCodeBody);
}

interface CreateSubscriptionCheckoutSessionBody {
  adapter_id: string;
  price_id: string;
  promotion_code: string;
}

interface CreateOneTimePaymentCheckoutSessionBody {
  price_id: string;
  promotion_code: string;
}

interface CreatePaymentMethodUpdateCheckoutSessionBody {
  subscription_id: string;
}

interface CreateCheckoutSessionResponse {
  client_secret: string;
}

export function createSubscriptionCheckoutSession(
  adapter_id: string,
  price_id: string,
  promotion_code: string = ""
) {
  const createSubscriptionCheckoutSessionBody: CreateSubscriptionCheckoutSessionBody = {
    adapter_id,
    price_id,
    promotion_code,
  };
  return http.post<CreateCheckoutSessionResponse>(
    CREATE_CHECKOUT_SESSION_SUBSCRIPTION_ROUTE,
    createSubscriptionCheckoutSessionBody
  );
}
export function createOneTimePaymentCheckoutSession(
  price_id: string,
  promotion_code: string = ""
) {
  const createOneTimePaymentCheckoutSessionBody: CreateOneTimePaymentCheckoutSessionBody = {
    price_id,
    promotion_code,
  };
  return http.post<CreateCheckoutSessionResponse>(
    CREATE_CHECKOUT_SESSION_ONE_TIME_PAYMENT_ROUTE,
    createOneTimePaymentCheckoutSessionBody
  );
}

export function createPaymentMethodUpdateCheckoutSession(
  subscription_id: string
) {
  const createPaymentMethodUpdateCheckoutSessionBody: CreatePaymentMethodUpdateCheckoutSessionBody = {
    subscription_id,
  };
  return http.post<CreateCheckoutSessionResponse>(
    CREATE_CHECKOUT_SESSION_PAYMENT_METHOD_UPDATE_ROUTE,
    createPaymentMethodUpdateCheckoutSessionBody
  );
}

interface CreateCustomerPortalSessionSubscriptionCancelBody {
  subscription_id: string;
}

interface CreateCustomerPortalSessionSubscriptionUpdateBody {
  subscription_id: string;
}

interface CreateCustomerPortalSessionSubscriptionUpdateConfirmBody {
  subscription_id: string;
  price_id: string;
}

interface CreateCustomerPortalSessionResponse {
  url: string;
}

export function createCustomerPortalSession() {
  return http.post<CreateCustomerPortalSessionResponse>(
    CREATE_CUSTOMER_PORTAL_SESSION_ROUTE
  );
}

export function createCustomerPortalSession_PaymentMethodUpdate() {
  return http.post<CreateCustomerPortalSessionResponse>(
    CREATE_CUSTOMER_PORTAL_SESSION_PAYMENT_METHOD_UPDATE_ROUTE
  );
}

export function createCustomerPortalSession_SubscriptionCancel(
  subscription_id: string
) {
  const createCustomerPortalSessionSubscriptionCancelBody: CreateCustomerPortalSessionSubscriptionCancelBody = {
    subscription_id,
  };
  return http.post<CreateCustomerPortalSessionResponse>(
    CREATE_CUSTOMER_PORTAL_SESSION_SUBSCRIPTION_CANCEL_ROUTE,
    createCustomerPortalSessionSubscriptionCancelBody
  );
}

export function createCustomerPortalSession_SubscriptionUpdate(
  subscription_id: string
) {
  const createCustomerPortalSessionSubscriptionUpdateBody: CreateCustomerPortalSessionSubscriptionUpdateBody = {
    subscription_id,
  };
  return http.post<CreateCustomerPortalSessionResponse>(
    CREATE_CUSTOMER_PORTAL_SESSION_SUBSCRIPTION_UPDATE_ROUTE,
    createCustomerPortalSessionSubscriptionUpdateBody
  );
}

export function createCustomerPortalSession_SubscriptionUpdateConfirm(
  subscription_id: string,
  price_id: string
) {
  const createCustomerPortalSessionSubscriptionUpdateConfirmBody: CreateCustomerPortalSessionSubscriptionUpdateConfirmBody = {
    subscription_id,
    price_id,
  };
  return http.post<CreateCustomerPortalSessionResponse>(
    CREATE_CUSTOMER_PORTAL_SESSION_SUBSCRIPTION_UPDATE_CONFIRM_ROUTE,
    createCustomerPortalSessionSubscriptionUpdateConfirmBody
  );
}

interface SessionStatusBody {
  session_id: string;
}

interface SessionStatusResponse {
  status: string;
  mode: string;
  products: string[];
}

export function getSessionStatus(session_id: string) {
  const sessionStatusBody: SessionStatusBody = {
    session_id,
  };
  return http.post<SessionStatusResponse>(
    SESSION_STATUS_ROUTE,
    sessionStatusBody
  );
}

interface CancelSubscriptionBody {
  subscription_id: string;
  comment: string;
  reason: string;
  /* POSSIBLE REASONS:
  customer_service (Customer service was less than expected)
  low_quality (Quality was less than expected)
  missing_features (Some features are missing)
  other (Other reason)
  switched_service (I'm switching to a different service)
  too_complex (Ease of use was less than expected)
  too_expensive (It's too expensive)
  unused (I don't use the service enough)
  */
}

interface CancelSubscriptionResponse {
  result: string;
}

export function cancelSubscription(
  subscription_id: string,
  comment: string,
  reason: string
) {
  const cancelSubscriptionBody: CancelSubscriptionBody = {
    subscription_id,
    comment,
    reason,
  };
  return http.post<CancelSubscriptionResponse>(
    CANCEL_SUBSCRIPTION_ROUTE,
    cancelSubscriptionBody
  );
}

interface RenewSubscriptionBody {
  subscription_id: string;
}

interface RenewSubscriptionResponse {
  result: string;
}

export function renewSubscription(subscription_id: string) {
  const renewSubscriptionBody: RenewSubscriptionBody = {
    subscription_id,
  };
  return http.post<RenewSubscriptionResponse>(
    RENEW_SUBSCRIPTION_ROUTE,
    renewSubscriptionBody
  );
}

interface ChangePlanBody {
  subscription_id: string;
  price_id: string;
}

interface ChangePlanResponse {
  result: string;
}

export function changePlan(subscription_id: string, price_id: string) {
  const changePlanBody: ChangePlanBody = {
    subscription_id,
    price_id,
  };
  return http.post<ChangePlanResponse>(CHANGE_PLAN_ROUTE, changePlanBody);
}
