import { useCreation, useDebounceEffect } from "ahooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Fuse from "fuse.js";
import _ from "lodash";
import { useDeviceIcons } from "utils/hooks";
import classNames from "classnames";
import Input from "./Input";
import { useTranslation } from "react-i18next";

interface Props {
  select: (icon: string) => void;
  selected: string | undefined;
}
const DeviceIconSelector: React.FC<Props> = ({ select, selected }) => {
  const deviceTypes = useDeviceIcons();
  const types = useCreation(() => {
    if (!deviceTypes) {
      return [];
    }
    return deviceTypes.map((type) => ({
      ...type,
      displayName: type.name.replace(/_/g, " "),
      display: true,
    }));
  }, [deviceTypes]);
  const [filter, setFilter] = useState<string>("");
  const [filteredDeviceTypes, setFilteredDeviceTypes] = useState(types);
  const fuze = useMemo(
    () =>
      new Fuse(types, {
        keys: ["displayName"],
        findAllMatches: false,
        threshold: 0.5,
      }),
    [types]
  );
  const updateTypes = useCallback(() => {
    if (filter) {
      const itemsToDisplay = fuze.search(filter).map(({ item }) => item);
      const itemsToHide = _.differenceBy(
        types,
        itemsToDisplay,
        "name"
      ).map((item) => ({ ...item, display: false }));
      setFilteredDeviceTypes([...itemsToDisplay, ...itemsToHide]);
    } else {
      setFilteredDeviceTypes(types);
    }
  }, [filter, fuze, types]);
  useDebounceEffect(
    () => {
      updateTypes();
    },
    [filter, updateTypes],
    {
      wait: 400,
    }
  );
  const { t } = useTranslation("editDevice");
  useEffect(() => {
    updateTypes();
  }, [selected, updateTypes]);
  const renderIcon = useCallback(
    (item: typeof types[0]) => {
      const { icon, name, displayName, display } = item;
      const isSelected = selected === name;
      return (
        <li
          key={icon}
          className={classNames("relative", !display && "hidden")}
          title={displayName}
        >
          <div
            className={classNames(
              // : "focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-orange",
              "group w-full flex items-center justify-center rounded-full",
              { "cursor-pointer": !isSelected }
            )}
          >
            <img
              src={icon}
              alt={displayName}
              className={classNames(
                { "group-hover:opacity-75": !isSelected },
                "select-none rounded-full object-center pointer-events-none group-focus:outline-none",
                isSelected
                  ? "ring-4 ring-offset-2 ring-orange"
                  : "group-focus-within:ring-2 group-focus-within:ring-offset-4 group-focus-within:ring-offset-gray-100 group-focus-within:ring-orange"
              )}
            />
            <button
              type="button"
              className="absolute inset-0 focus:outline-none group-focus:outline-none outline-none w-full"
              onClick={() => {
                select(isSelected ? "" : name);
              }}
            >
              <span className="sr-only">Select icon {displayName}</span>
            </button>
          </div>
          <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
            {displayName}
          </p>
          {/* <p className="block text-sm font-medium text-gray-500 pointer-events-none">
        {file.size}
      </p> */}
        </li>
      );
    },
    [select, selected]
  );
  return (
    <div className="choose-avatar">
      <Input
        label={t("filterLabel")}
        type="search"
        id="selector-search"
        placeholder={t("filterPlaceholder")}
        inputProps={{
          value: filter,
          onChange: (event: any) => {
            setFilter(event.target.value);
          },
        }}
        className="mb-2 max-w-xs"
      />
      <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
        <h2 id="gallery-heading" className="sr-only">
          Device Icons
        </h2>
        <ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
          {types.length === 0 ? <span>Loading...</span> : null}
          {filteredDeviceTypes.map((item) => renderIcon(item))}
        </ul>
      </section>
    </div>
  );
};

export default DeviceIconSelector;
