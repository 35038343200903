import React, { useMemo, Fragment } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dispatch, RootState } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
//import { ReactComponent as SettingsIcon } from "../assets/images/svg-icons/settings.svg";
import { ReactComponent as HelpIcon } from "../assets/images/svg-icons/help.svg";
import { ReactComponent as AccountIcon } from "../assets/images/svg-icons/user_account.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/svg-icons/user_log_out.svg";
import { IonIcon } from "@ionic/react";
import { checkmark, chevronDown } from "ionicons/icons";
import { Listbox, Transition } from "@headlessui/react";

interface userMenuNavType {
  url: string;
  icon: any;
  title: string;
  id: string;
}

export const UserMenu: React.FC = () => {
  const dispatch = useDispatch<Dispatch>();
  const handleClick = (appPage: userMenuNavType) => {
    if (appPage.id === "logout") {
      dispatch.authentication.removeUserToken();
    }
  };
  const { t } = useTranslation();
  const { adapters, adapter: currentAdapter } = useSelector(
    (state: RootState) => ({
      adapters: state.adapter.list,
      adapter: state.adapter.adapter,
    })
  );
  const history = useHistory();
  const selectAdapter = (adapterId: string) => {
    if (adapterId === "ACTIVATE") {
      history.push("/onboarding/get-connected");
    } else {
      dispatch.adapter.stopPollingForAdapter();
      dispatch.adapter.setAdapter(adapters.find((a) => a.id === adapterId)!);
      dispatch.adapter.pollForAdapter(adapterId);
    }
  };

  const userMenuNav = useMemo<userMenuNavType[]>(
    () => [
      {
        title: t("generic:menu.help"),
        id: "help",
        url: "/onboarding/help",
        icon: <HelpIcon />,
      },
      {
        title: t("generic:menu.account"),
        id: "account",
        url: "/account",
        icon: <AccountIcon />,
      },
      {
        title: t("generic:menu.logout"),
        id: "logout",
        url: "/",
        icon: <LogoutIcon />,
      },
    ],
    [t]
  );

  return (
    <div className="flex items-center">
      <Listbox value={currentAdapter?.id} onChange={selectAdapter}>
        {({ open }) => (
          <>
            <Listbox.Label className="sr-only">Routers</Listbox.Label>
            <div className="relative">
              <div className="inline-flex shadow-sm rounded-md divide-x divide-gray-700">
                <div className="relative z-0 inline-flex shadow-sm rounded-md divide-x divide-gray-700">
                  <div className="relative inline-flex items-center bg-white text-black py-1 pl-2 pr-3 border border-transparent rounded-l-md shadow-sm">
                    <p className="ml-1.5 text-xs font-medium">
                      {currentAdapter?.name}
                    </p>
                  </div>
                  <Listbox.Button className="relative inline-flex items-center bg-white p-1 rounded-l-none rounded-r-md text-sm font-medium text-black hover:bg-orange hover:text-white focus:outline-none focus:z-10 focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-orange">
                    <span className="sr-only">Change selected router</span>
                    <IonIcon
                      icon={chevronDown}
                      className="h-4 w-4"
                      aria-hidden="true"
                    />
                  </Listbox.Button>
                </div>
              </div>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="origin-top-right absolute z-10 right-0 mt-2 w-72 rounded-md shadow-lg overflow-hidden bg-white divide-y divide-gray-200 ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  {adapters.map((adapter) => (
                    <Listbox.Option
                      key={adapter.id}
                      className={({ active }) =>
                        classNames(
                          active ? "text-white bg-orange" : "text-gray-900",
                          "cursor-default select-none relative p-4 text-sm"
                        )
                      }
                      value={adapter.id}
                    >
                      {({ selected, active }) => (
                        <div>
                          <div className="flex justify-between">
                            <p className="font-bold">{adapter.name}</p>
                            {selected ? (
                              <span
                                className={
                                  active ? "text-white" : "text-orange"
                                }
                              >
                                <IonIcon
                                  icon={checkmark}
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </div>
                          <span>
                            <span
                              className={classNames(
                                adapter.services.online.state
                                  ? "bg-green-100"
                                  : "bg-gray-100",
                                "h-4 w-4 rounded-full inline-flex items-center justify-center mr-1"
                              )}
                              aria-hidden="true"
                            >
                              <span
                                className={classNames(
                                  adapter.services.online.state
                                    ? "bg-green-400"
                                    : "bg-gray-400",
                                  "h-2 w-2 rounded-full"
                                )}
                              />
                            </span>
                            {adapter.services.online.state
                              ? "online"
                              : "offline"}
                          </span>{" "}
                          <span
                            className="hidden sm:inline sm:mx-1"
                            aria-hidden="true"
                          >
                            &middot;
                          </span>{" "}
                          <p
                            className={classNames(
                              active ? "text-white" : "text-gray-600",
                              "sm:inline mt-2"
                            )}
                          >
                            {adapter.hw.mac}
                          </p>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                  <Listbox.Option
                    key="ACTIVATE"
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-orange" : "text-gray-900",
                        "cursor-default select-none relative p-4 text-sm"
                      )
                    }
                    value="ACTIVATE"
                  >
                    {({ active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className="font-bold">
                            {t("routers:activateNewRouter")}
                          </p>
                        </div>
                      </div>
                    )}
                  </Listbox.Option>
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      {/*
        <Link
            key="settings"
            to="/settings"
            id="settings"
            className={classNames(
                "flex items-center ml-2 pr-1.5 last:pr-0 group border-r border-gray-300 last:border-none"
            )}
        >
            <span className="w-6 h-6 transition group-hover:scale-110 fill-current text-orange">
                  <SettingsIcon />
            </span>
        </Link>
        */}

      {userMenuNav.map((appPage) => (
        <Link
          key={appPage.title}
          to={appPage.url}
          onClick={() => handleClick(appPage)}
          id={appPage.id}
          className={classNames(
            "flex items-center ml-2 pr-1.5 last:pr-0 group border-r border-gray-300 last:border-none"
          )}
        >
          <span className="text-sm transition text-gray-500 group-hover:text-orange-500 group-hover:cursor-pointer">
            {appPage.title}
          </span>
          <span className="w-6 h-6 transition group-hover:scale-110">
            {appPage.icon}
          </span>
        </Link>
      ))}
    </div>
  );
};

export default UserMenu;
