import { createModel } from "@rematch/core";
import { RootModel } from "./models";
import api from "../api";
import { AdapterListItem, VPNProvider, UIServiceState } from "../model";
import { createPollAndRefresh } from "../utils";
import { VPNProviderConfigBody } from "../api/vpn";

export interface VPNState {
  adapterVPNProviderMap: VPNProvider[];
}

const initialState: VPNState = {
  adapterVPNProviderMap: [],
};

export const vpn = createModel<RootModel>()({
  state: initialState, // initial state
  reducers: {
    _setVPNProviders(state, { providers }: { providers: VPNProvider[] }) {
      vpn.state.adapterVPNProviderMap = providers;
      return {
        ...state,
        providers,
      };
    },
    clearAll(state) {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async getVPNProviders(_, rootState) {
      const res = await api.vpn.getVPNProviders().then((d) => d.data);

      dispatch.vpn._setVPNProviders({
        providers: res,
      });
      return res;
    },
    async configureVPNProvider({
      config,
      updateUi,
      display_name,
    }: {
      config: VPNProviderConfigBody;
      updateUi: (state: UIServiceState) => any;
      display_name: string;
    }) {
      updateUi("Updating");

      dispatch.ui.setToast({
        description: `Configuring ${display_name}...`,
        type: "info",
      });

      api.vpn
        .configureVPNProvider(config)
        .then((result) =>
          createPollAndRefresh<AdapterListItem | undefined>(
            () => dispatch.adapter.getAdapter(config.id),
            (adapter?) =>
              adapter?.services?.vpn?.state === true &&
              adapter?.status?.vpn !== 2 &&
              adapter?.status?.vpn_connections?.[adapter.status.active_server!]
                ?.provider === config.provider &&
              adapter?.status?.vpn_connections?.[adapter.status.active_server!]
                ?.up === true,
            (state) => {
              updateUi(state);

              if (state === "Failed!") {
                dispatch.ui.setToast({
                  notificationTitle: `Error connecting to ${display_name}`,
                  description: "Operation timed out",
                  type: "error",
                });
              } else if (state === "Done!") {
                dispatch.ui.setToast({
                  description: `Connected to ${display_name}`,
                  type: "success",
                });
              }
            },
            40,
            1500
          )
        )
        .catch((error) => {
          updateUi("Failed!");

          dispatch.ui.setToast({
            notificationTitle: `Error configuring ${display_name}`,
            description:
              error.response?.data.detail || "Message: " + error.message,
            type: "error",
          });
        });
    },
  }),
});
