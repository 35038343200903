import React from "react";
import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";

import { Subscription as SubscriptionType } from "model";
import Settings from "./SettingsContainer";
import TimezoneContainer from "./Timezone";
import RouterName from "./RouterName";
import GuestNetwork from "./GuestNetwork";
import PrimaryNetwork from "./PrimaryNetwork";
import EditNetwork from "./EditNetwork";

interface Props {
  subscription?: SubscriptionType;
}

const Routes: React.FC<Props> = ({ subscription }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/timezone`} exact>
        <TimezoneContainer />
      </Route>
      <Route path={`${path}/rename`} exact>
        <RouterName />
      </Route>

      <Route path={`${path}/edit-network`} exact>
        <EditNetwork screen="edit-network" />
      </Route>
      <Route path={`${path}/guest-network`} exact>
        <GuestNetwork />
      </Route>
      <Route path={`${path}/primary-network`} exact>
        <PrimaryNetwork />
      </Route>

      {!subscription && (
        <Route>
          <Settings subscription={subscription} />
        </Route>
      )}

      <Route path={path} exact>
        <Settings subscription={subscription} />
      </Route>
      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default Routes;
