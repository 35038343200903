import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { Dispatch } from "../../store";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { handleError } from "../../utils";
import http from "axios";
import { UserToken } from "../../model";
import { PageLayout } from "../../layouts";
import { isPlatform } from "@ionic/react";
import { useSetState } from "ahooks";
import Title from "components/Title";
import { useTranslation } from "react-i18next";
import { PasswordInput } from "components/Input";
import vest, { enforce, test } from "vest";
import i18next from "i18next";
import { vestResolver } from "@hookform/resolvers/vest";
import { useForm } from "react-hook-form";
import ErrorMessage from "components/ErrorMessage";
import Button from "components/Button";
import InformationMessage from "components/InformationMessage";

type Inputs = {
  password: string;
  confirmPassword: string;
};
const validationSuite = vest.create((data: Inputs) => {
  test("password", i18next.t("resetPassword:password.required"), () => {
    enforce(data.password).isNotEmpty();
  });
  test("password", i18next.t("resetPassword:password.min"), () => {
    enforce(data.password).longerThanOrEquals(8);
  });

  test(
    "confirmPassword",
    i18next.t("resetPassword:confirmPassword.required"),
    () => {
      enforce(data.confirmPassword).isNotEmpty();
    }
  );
  test(
    "confirmPassword",
    i18next.t("resetPassword:confirmPassword.match"),
    () => {
      enforce(data.confirmPassword).equals(data.password);
    }
  );
});

export const PasswordResetComponent: React.FC<{}> = () => {
  const { t } = useTranslation("resetPassword");
  const [state, setState] = useSetState<{
    password: string;
    confirm: string;
    error: string;
    loading: boolean;
  }>({ password: "", confirm: "", error: "", loading: false });
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const title = query.get("newCustomer")
    ? t("setPasswordTitle")
    : t("resetPasswordTitle");

  const { register, handleSubmit, formState } = useForm<Inputs>({
    mode: "all",
    resolver: vestResolver(validationSuite),
    defaultValues: {},
  });

  const { isValid, errors } = formState;
  useEffect(() => {
    // verify the token
    if (isPlatform("mobile")) {
      return;
    }
    http
      .post<UserToken>("/v1/auth/confirmcustomer", {
        verify: query.get("verify"),
        expchk: true,
        pwd: "wqe#C3#CcX..3",
      })
      .catch((e) => {
        setState({
          error:
            handleError({
              error: e,
              fallback: "Error during customer confirmation",
            }) || "Error during customer confirmation",
        });
        dispatch.ui.setError({
          error: `Your request to ${title} has expired. Please request a new one.`,
          helpRoute: "/",
          route: "/passwordRecovery",
        });
      });
    // We want to run this check once on render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = ({ password }: Inputs) => {
    setState({ loading: true });
    const body = { pwd: password };
    http
      .post<UserToken>("/v1/auth/confirmcustomer", {
        ...body,
        verify: query.get("verify"),
      })
      .then(({ data }) => {
        FirebaseAnalytics.logEvent("login", { type: "reset" });
        dispatch.authentication.updateUserToken(data); // No email or password saved

        dispatch.adapter.getAdapters().then((error) => {
          setState({ loading: false });
          history.push("/home");
        });
      })
      .catch((e) => {
        setState({
          loading: false,
          error: e,
        });
      });
  };

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "password_reset" });
  }, []);

  return (
    <PageLayout
      testId="password-reset"
      loading={state.loading}
      loadingMessage="Resetting password ..."
    >
      <Title title={title} className="mb-4" />
      <InformationMessage
        text={[
          "Please choose a password and confirm it below.",
          <br />,
          "Your password must contain at least 8 characters.",
        ]}
        className="mb-4"
      />
      <PasswordInput
        id="password"
        data-testid="password"
        label={t("password.label")}
        placeholder={t("password.placeholder")}
        inputProps={{ ...register("password"), autoComplete: "off" }}
        error={errors.password?.message}
      />
      <PasswordInput
        id="confirmPassword"
        data-testid="confirmPassword"
        label={t("confirmPassword.label")}
        placeholder={t("confirmPassword.placeholder")}
        inputProps={{ ...register("confirmPassword"), autoComplete: "off" }}
        error={errors.confirmPassword?.message}
        className="mt-2"
      />
      <ErrorMessage error={state.error} />

      <Button
        className="mt-4"
        data-testid="submit"
        disabled={!isValid}
        onClick={handleSubmit(submit)}
      >
        Submit
      </Button>
    </PageLayout>
  );
};

export default PasswordResetComponent;
