import React, { useState } from "react";
import { Dispatch } from "../store";
import { useDispatch } from "react-redux";

import { ReactComponent as Target } from "../assets/images/svg-icons/target.svg";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import NewModal from "components/NewModal";
import { IonIcon } from "@ionic/react";
import { chevronDown } from "ionicons/icons";

interface Props {
  email: string;
}

const ImpersonateHeaderComponent: React.FC<Props> = ({ email }) => {
  const { t } = useTranslation("admin");
  const dispatch = useDispatch<Dispatch>();
  const [active, setActive] = useState(false);
  const [endConfirmation, setEndConfirmation] = useState(false);

  const closeModal = (endSession?: boolean) => {
    setEndConfirmation(false);
    if (endSession) {
      dispatch.authentication.endImpersonation();
    }
  };

  return (
    <div data-testid="impersonate-header">
      <div className="app-container impersonate-banner">
        <div className="content">
          <div className="icon text-white fill-current">
            <Target />
          </div>
          <div className={`control ${active ? "is-active" : ""}`}>
            <button
              type="button"
              data-testid="impersonate-control"
              className="py-3"
              onClick={() => setActive(!active)}
            >
              <p>
                {email}
                <IonIcon icon={chevronDown} className="h-3 w-3 white" />
              </p>
            </button>
            {active && (
              <div className="end-session-control">
                <Button
                  variant="white"
                  type="button"
                  data-testid="end-session"
                  onClick={() => setEndConfirmation(true)}
                >
                  {t("header.endImpersonation")}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <NewModal
        type="warning"
        open={endConfirmation}
        onClose={() => closeModal(false)}
        onOk={() => closeModal(true)}
        title={t("header.modalTitle")}
        description={t("header.endDescription")}
        okText={t("header.ok")}
        cancelText={t("header.cancel")}
        okTestId="confirm-end-session"
      />
    </div>
  );
};

export default ImpersonateHeaderComponent;
