import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { vestResolver } from "@hookform/resolvers/vest";
import vest, { test, enforce } from "vest";

import PageLayout from "../../layouts/PageLayout";
import { Dispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import Input, { PasswordInput } from "components/Input";
import ErrorMessage from "components/ErrorMessage";
import Title from "components/Title";

type Inputs = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};
const validationSuite = vest.create((data: Inputs) => {
  test("oldPassword", i18next.t("editAccount:oldPassword.required"), () => {
    enforce(data.oldPassword).isNotEmpty();
  });
  test("oldPassword", i18next.t("editAccount:oldPassword.min"), () => {
    enforce(data.oldPassword).longerThanOrEquals(8);
  });

  test("newPassword", i18next.t("editAccount:newPassword.required"), () => {
    enforce(data.newPassword).isNotEmpty();
  });
  test("newPassword", i18next.t("editAccount:newPassword.min"), () => {
    enforce(data.newPassword).longerThanOrEquals(8);
  });
  test("newPassword", i18next.t("editAccount:newPassword.same"), () => {
    enforce(data.newPassword).notEquals(data.oldPassword);
  });
  test(
    "confirmPassword",
    i18next.t("editAccount:confirmPassword.match"),
    () => {
      enforce(data.confirmPassword).equals(data.newPassword);
    }
  );
});
export const EditAccountContainer: React.FC<{}> = () => {
  const history = useHistory();
  const { email, password } = useSelector((state: RootState) => ({
    email: state.authentication.user!.email,
    password: state.authentication.user!.password!,
  }));
  const dispatch = useDispatch<Dispatch>();

  const [networkError, setNetworkError] = useState<any>();
  const defaultValues = useMemo<Inputs>(
    () => ({
      oldPassword: password,
      newPassword: "",
      confirmPassword: "",
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const { register, handleSubmit, formState, watch } = useForm<Inputs>({
    resolver: vestResolver(validationSuite),
    mode: "all",
    defaultValues,
  });

  const { isValid, errors } = formState;

  const onSubmit = ({ newPassword, oldPassword }: Inputs) => {
    dispatch.authentication
      .changePassword({ oldPassword, newPassword })
      .then(() => {
        history.goBack();
      })
      .catch((error) => {
        setNetworkError(error);
      });
  };

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "edit_account" });
  }, []);
  const values = watch();
  const { t } = useTranslation();
  const disabled = !isValid;
  const didSomethingChange =
    defaultValues.confirmPassword !== values.confirmPassword ||
    defaultValues.oldPassword !== values.oldPassword ||
    defaultValues.newPassword !== values.newPassword;
  return (
    <PageLayout
      back={history.goBack}
      disabled={disabled}
      save={handleSubmit(onSubmit)}
      didSomethingChange={didSomethingChange}
    >
      <Title title={t("editAccount:title")} className="mb-2" />
      <form>
        <div className="mb-4">
          <PasswordInput
            id="oldPassword"
            data-testid="oldPassword"
            label={t("editAccount:oldPassword.label")}
            placeholder={t("editAccount:oldPassword.placeholder")}
            inputProps={{ ...register("oldPassword"), autoComplete: "off" }}
            error={errors.oldPassword?.message}
          />
        </div>

        <div className="mb-4">
          <PasswordInput
            id="newPassword"
            data-testid="newPassword"
            label={t("editAccount:newPassword.label")}
            placeholder={t("editAccount:newPassword.placeholder")}
            inputProps={{ ...register("newPassword"), autoComplete: "off" }}
            error={errors.newPassword?.message}
          />
        </div>
        <div className="">
          <PasswordInput
            id="confirmPassword"
            data-testid="confirmPassword"
            label={t("editAccount:confirmPassword.label")}
            placeholder={t("editAccount:confirmPassword.placeholder")}
            inputProps={{ ...register("confirmPassword"), autoComplete: "off" }}
            error={errors.confirmPassword?.message}
          />
        </div>
        <ErrorMessage error={networkError} fallback="Error editing account" />
      </form>
    </PageLayout>
  );
};

export default EditAccountContainer;
