
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Create Account",
  "resetPasswordTitle": "Reset Password",
  "successMessage": "We've sent you an email with a link to create a new password.",
  "resetPasswordSuccessMessage": "We’ve sent you an email with a link to reset your password.",
  "fallbackError": "Error creating account",
  "resetPasswordFallbackError": "Error requesting password reset",
  "thanks": "Thanks",
  "email": {
    "label": "Email",
    "placeholder": "my@email.com"
  },
  "password": {
    "label": "Password",
    "placeholder": "********"
  },
  "pressable": {
    "createAccount": "Create Account",
    "resetPassword": "Reset Password",
    "login": "Login"
  }
}
