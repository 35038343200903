import { Fragment, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { IonIcon } from "@ionic/react";
import { warningOutline } from "ionicons/icons";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { Dispatch } from "store";
interface Props {
  open: boolean;
  title: string;
  description: string;
  okText: string;
  cancelText: string;
  onOk: () => void;
  onCancel?: () => void;
  onClose: () => void;
  hideCancelButton?: boolean;
  type?: "warning" | "error";
  okTestId?: string;
}
export default function NewModal({
  open,
  title,
  description,
  okText,
  cancelText,
  onOk,
  onClose,
  onCancel = onClose,
  hideCancelButton = false,
  type = "error",
  okTestId,
}: Props) {
  const dispatch = useDispatch<Dispatch>();
  const cancelButtonRef = useRef(null);
  useEffect(() => {
    document.addEventListener("close-modal", (ev: any) => {
      onCancel();
    });
  }, [onCancel]);
  useEffect(() => {
    dispatch.ui.setIsModalOpen(open);
  }, [dispatch.ui, open]);
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={onClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div
                  className={classNames(
                    "mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full  sm:mx-0 sm:h-10 sm:w-10",
                    { "bg-red-100": type === "error" },
                    { "bg-orange-100": type === "warning" }
                  )}
                >
                  <IonIcon
                    icon={warningOutline}
                    className={classNames("h-6 w-6 ", {
                      "text-red-600": type === "error",
                      "text-orange": type === "warning",
                    })}
                    aria-hidden="true"
                  />
                </div>
                <div className="mt-3  sm:mt-0 sm:ml-4 text-left">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{description}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className={classNames(
                    "w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2  text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2  sm:ml-3 sm:w-auto sm:text-sm",
                    {
                      "bg-red-600 hover:bg-red-700 focus:ring-red-500":
                        type === "error",
                    },
                    {
                      "bg-orange hover:bg-orange-600 focus:ring-orange":
                        type === "warning",
                    }
                  )}
                  onClick={onOk}
                  data-testid={okTestId}
                >
                  {okText}
                </button>
                {!hideCancelButton ? (
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={onCancel}
                    ref={cancelButtonRef}
                  >
                    {cancelText}
                  </button>
                ) : null}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
