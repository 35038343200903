import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import PageLayout from "../../layouts/PageLayout";
import { Dispatch, RootState } from "../../store";
import { IonLoading } from "@ionic/react";
import { useSetState } from "ahooks";
import { useForm } from "react-hook-form";
import OnboardingSteps from "pages/onboarding/components/OnboardingSteps";
import { WifiConfig } from "model";
import { useTranslation } from "react-i18next";
import Input, { PasswordInput } from "components/Input";
import vest, { enforce, test } from "vest";
import i18n from "i18next";
import { vestResolver } from "@hookform/resolvers/vest";
import ErrorMessage from "components/ErrorMessage";
import Title from "components/Title";
import Button from "components/Button";
import InformationMessage from "components/InformationMessage";
import classNames from "classnames";
import { ssidRegex } from "../../utils/regexValidators";

type Inputs = {
  name: string;
  password: string;
  band: string;
  channel: number;
};

const validationSuite = vest.create((data: Inputs) => {
  test(
    "name",
    i18n.t("editNetwork:wifi.alphanum") +
      i18n.t("editNetwork:wifi.alphanum2") +
      i18n.t("editNetwork:wifi.alphanum3") +
      i18n.t("editNetwork:wifi.alphanum4") +
      i18n.t("editNetwork:wifi.alphanum5"),
    () => {
      enforce(data.name).matches(ssidRegex);
    }
  );
  test("name", i18n.t("editNetwork:wifi.required"), () => {
    enforce(data.name).isNotEmpty();
  });
  test("password", i18n.t("editNetwork:password.required"), () => {
    enforce(data.password).isNotEmpty();
  });
  test("password", i18n.t("editNetwork:password.alphanum"), () => {
    enforce(data.password).matches(new RegExp("^[ -~]{0,}$"));
  });
  test("password", i18n.t("editNetwork:password.min"), () => {
    enforce(63 >= data.password.length && data.password.length >= 8).isTruthy();
  });
});

type Props = {
  screen: "create-primary-network" | "edit-network";
};

interface State {
  loading: boolean;
  error: any;
}

interface ChannelState {
  channelStateValue: any;
}

const EditNetwork: React.FC<Props> = ({ screen }) => {
  const { adapter } = useSelector((state: RootState) => ({
    adapter: state.adapter.adapter!,
  }));
  const { t } = useTranslation();
  const isAdapterOffline = !adapter.services.online.state;
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const search = useLocation().search;
  const query = useMemo(() => new URLSearchParams(search), [search]);
  var network_query = query.get("network");
  const isGuest = network_query === "guest";
  //if network query is not null, set the band to its value
  var band = network_query && !isGuest ? network_query : "";
  var band_2 = band.length >= 0 && band === "2.4Ghz";

  const [channelstate, channelsetState] = useSetState<ChannelState>({
    channelStateValue: band_2
      ? typeof adapter.settings.wifi2.channel !== "undefined"
        ? adapter.settings.wifi2.channel
        : 6
      : typeof adapter.settings.wifi.channel !== "undefined"
      ? adapter.settings.wifi.channel
      : 36,
  });

  const { channelStateValue } = channelstate;
  var channelSelection = channelStateValue;

  const defaultValues = {
    name: adapter!.settings[isGuest ? "guest" : band_2 ? "wifi2" : "wifi"].ssid,
    password: adapter!.settings[isGuest ? "guest" : band_2 ? "wifi2" : "wifi"]
      .key,
    band: band,
    channel: adapter!.settings[isGuest ? "guest" : band_2 ? "wifi2" : "wifi"]
      .channel,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<Inputs>({
    mode: "all",
    resolver: vestResolver(validationSuite),
    defaultValues,
  });
  const values = watch();

  const [state, setState] = useSetState<State>({
    loading: false,
    error: undefined,
  });

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "edit_network" });
    if (query.has("span")) {
      const queryChartSpan = query.get("span");
      if (queryChartSpan) {
        const sp = +queryChartSpan;
        channelsetState({ channelStateValue: sp });
      }
    }
  }, [query, channelsetState]);
  const tabs_2Ghz = useMemo<
    {
      name: string;
      href: string;
      channelStateValue: ChannelState["channelStateValue"];
    }[]
  >(
    () => [
      {
        name: t("editNetwork:channel.low_2Ghz"),
        href: "#low2",
        channelStateValue: 1,
      },
      {
        name: t("editNetwork:channel.default_2Ghz"),
        href: "#medium",
        channelStateValue: 6,
      },
      {
        name: t("editNetwork:channel.high_2Ghz"),
        href: "#high2",
        channelStateValue: 11,
      },
    ],
    [t]
  );
  const tabs_5Ghz = useMemo<
    {
      name: string;
      href: string;
      channelStateValue: ChannelState["channelStateValue"];
    }[]
  >(
    () => [
      {
        name: t("editNetwork:channel.low_5Ghz"),
        href: "#low5",
        channelStateValue: 36,
      },
      {
        name: t("editNetwork:channel.default_5Ghz"),
        href: "#medium",
        channelStateValue: 52,
      },
      {
        name: t("editNetwork:channel.high_5Ghz"),
        href: "#high5",
        channelStateValue: 149,
      },
    ],
    [t]
  );

  const onSubmit = async ({ name, password }: Inputs) => {
    var wifiConfig: WifiConfig & { id: string } = {
      id: adapter!.id,
      enabled: true,
      ssid: name.trim(),
      key: password,
      guest: isGuest,
      channel: channelStateValue,
    };
    if (band.length !== 0) {
      wifiConfig.band = band;
    }
    dispatch.adapter.configureWifi({
      wifiConfig,
      setLoading: (loading: boolean) => setState({ loading }),
      onError: (error?: string) => setState({ error }),
    });
  };
  var form;
  if (band_2) {
    form = (
      <form>
        <div className="mb-4">
          <Input
            id="name"
            data-testid="name"
            type="text"
            label={t("editNetwork:wifi.label")}
            placeholder="" //{adapter.settings.wifi2.ssid}
            inputProps={register("name")}
            error={errors.name?.message}
          />
        </div>
        <div className="mb-4">
          <PasswordInput
            id="password"
            data-testid="password"
            label={t("editNetwork:password.label")}
            placeholder={t("editNetwork:password.placeholder")}
            inputProps={register("password")}
            error={errors.password?.message}
          />
        </div>
        <ErrorMessage
          error={state.error}
          fallback={t("editNetwork:defaultError")}
        />
        <div className="block">
          <div className="block text-sm font-medium text-gray-700 capitalize">
            {t("editNetwork:channel.label")}
          </div>
          <br></br>
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs_2Ghz.map((tab) => (
              <Link
                key={tab.name}
                to={`?network=2.4Ghz&span=${tab.channelStateValue}`}
                className={classNames(
                  tab.channelStateValue === channelStateValue
                    ? "bg-gray-200 text-gray-700 ring ring-orange"
                    : "text-gray-500 hover:text-gray-700",
                  "no-underline px-3 py-2 font-medium text-sm rounded-md",
                  "focus:ring focus:ring-orange"
                )}
                onClick={() => {
                  channelsetState({ channelStateValue: tab.channelStateValue });
                  channelSelection = channelStateValue;
                }}
                aria-current={
                  tab.channelStateValue === channelStateValue
                    ? "page"
                    : undefined
                }
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </form>
    );
  } else {
    form = (
      <form>
        <div className="mb-4">
          <Input
            id="name"
            data-testid="name"
            type="text"
            label={t("editNetwork:wifi.label")}
            placeholder="" //{adapter.settings.wifi.ssid}
            inputProps={register("name")}
            error={errors.name?.message}
          />
        </div>
        <div className="mb-4">
          <PasswordInput
            id="password"
            data-testid="password"
            label={t("editNetwork:password.label")}
            placeholder={t("editNetwork:password.placeholder")}
            inputProps={register("password")}
            error={errors.password?.message}
          />
        </div>
        <div className="block">
          <div className="block text-sm font-medium text-gray-700 capitalize">
            {t("editNetwork:channel.label")}
          </div>
          <br></br>
          <nav className="flex space-x-4" aria-label="Tabs">
            {tabs_5Ghz.map((tab) => (
              <Link
                key={tab.name}
                to={`?network=5Ghz&span=${tab.channelStateValue}`}
                className={classNames(
                  tab.channelStateValue === channelStateValue
                    ? "bg-gray-200 text-gray-700 ring ring-orange"
                    : "text-gray-500 hover:text-gray-700",
                  "no-underline px-3 py-2 font-medium text-sm rounded-md",
                  "focus:ring focus:ring-orange"
                )}
                onClick={() => {
                  channelsetState({ channelStateValue: tab.channelStateValue });
                  channelSelection = channelStateValue;
                }}
                aria-current={
                  tab.channelStateValue === channelStateValue
                    ? "page"
                    : undefined
                }
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
        <ErrorMessage
          error={state.error}
          fallback={t("editNetwork:defaultError")}
        />
      </form>
    );
  }
  const didSomethingChange =
    //!_.isEqual(values, defaultValues) ||
    (adapter.settings.wifi !== adapter.settings.wifi2 && band === "") ||
    adapter.settings[isGuest ? "guest" : band_2 ? "wifi2" : "wifi"].channel !==
      channelSelection ||
    adapter.settings[isGuest ? "guest" : band_2 ? "wifi2" : "wifi"].ssid !==
      values.name ||
    adapter.settings[isGuest ? "guest" : band_2 ? "wifi2" : "wifi"].key !==
      values.password;
  if (screen === "edit-network") {
    return (
      <PageLayout
        back={history.goBack}
        disabled={!isValid || isAdapterOffline}
        save={handleSubmit(onSubmit)}
        didSomethingChange={didSomethingChange}
      >
        {state.loading ? <IonLoading isOpen message="Loading" /> : null}
        <Title
          title={
            isGuest
              ? t("editNetwork:guestTitle")
              : t("editNetwork:title") +
                band.replace("Ghz", "") +
                t("editNetwork:titleP2")
          }
          className="mb-6"
        />
        {form}
      </PageLayout>
    );
  } else {
    const goToNextScreen = () => {
      history.push("/onboarding/all-set");
    };
    const submit = ({ name, password }: Inputs) => {
      dispatch.adapter.configureWifi({
        wifiConfig: {
          id: adapter!.id,
          enabled: true,
          ssid: name.trim(),
          key: password,
        },
        setLoading: (loading: boolean) => setState({ loading }),
        onError: (error?: any) => setState({ error }),
        onSuccess: () => {
          goToNextScreen();
        },
      });
    };
    return (
      <PageLayout>
        <OnboardingSteps stage="setupWifi" />
        {state.loading ? <IonLoading isOpen message="Loading" /> : null}
        <Title title={t("onboarding:createNetwork.title")} className="mb-2" />
        <InformationMessage
          text={[
            "Please choose the Wi-Fi name and password you'd like for your Privacy Hero Wi-Fi network:",
            <br />,
            "Wi-Fi password can only include letters and numbers",
          ]}
          className="mb-2"
        />
        {form}
        <Button
          data-testid="submit"
          type="button"
          disabled={!isValid}
          onClick={handleSubmit(submit)}
        >
          {t("onboarding:createNetwork.ok")}
        </Button>
      </PageLayout>
    );
  }
};

export default EditNetwork;
