import { IonIcon } from "@ionic/react";
import { useToggle } from "ahooks";
import { informationCircleSharp } from "ionicons/icons";

interface Props {
  className?: string;
  text: any;
  moreList?: string[];
  toggledText?: any;
}
export default function InformationMessage({
  className,
  text,
  moreList = [],
  toggledText,
}: Props) {
  const [toggled, { toggle }] = useToggle(false);

  return (
    <div className={`rounded-md bg-blue-50 p-4 ${className}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <IonIcon
            icon={informationCircleSharp}
            className="h-5 w-5 text-blue-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-blue-800">{text}</p>
          {toggledText && toggled ? (
            <p className="text-sm font-medium text-blue-800">{toggledText}</p>
          ) : null}
          <div className="mt-2 text-sm text-blue-700">
            <ul className="list-disc pl-5 space-y-1">
              {toggled
                ? moreList.map((item) => <li key={item}>{item}</li>)
                : null}
            </ul>
          </div>
        </div>
      </div>
      {moreList.length > 0 ? (
        <button
          type="button"
          className="mt-2 bg-blue-50 px-2 py-1.5 rounded-md text-sm font-medium text-blue-500 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-50 focus:ring-blue"
          onClick={() => toggle()}
        >
          {toggled ? "Show less" : "Show more"}
        </button>
      ) : null}
    </div>
  );
}
