import { createModel } from "@rematch/core";
import { AuthState, UserToken } from "../model";
import { RootModel } from "./models";
import http from "axios";
import appConfig from "../AppConfig";
import { Storage } from "@capacitor/storage";
import api from "api";
import { App as CapApp } from "@capacitor/app";
import { PluginListenerHandle } from "@capacitor/core";

const initialState: AuthState = {};

let stateChangeHandler: PluginListenerHandle;

export const authentication = createModel<RootModel>()({
  state: initialState, // initial state
  reducers: {
    _impersonateUser(state, user: UserToken) {
      return { ...state, user, originalImpersonatorUser: state.user };
    },
    _endImpersonation(state) {
      return {
        ...state,
        user: state.originalImpersonatorUser,
        originalImpersonatorUser: undefined,
      };
    },
    _updateUserToken(state, user: UserToken) {
      return { ...state, user };
    },
    _mergeUserToken(state, user: UserToken) {
      return { ...state, user: { ...state.user, ...user } };
    },
    _removeUserToken() {
      return {};
    },
    _setTokenPollingTimer(state, pollingTimer: NodeJS.Timeout | undefined) {
      return { ...state, pollingTimer };
    },
  },
  effects: (dispatch) => ({
    async renewToken() {
      try {
        const { data } = await api.auth.renewToken();
        dispatch.authentication.updateUserToken(data);
        return true;
      } catch (e) {
        return false;
      }
    },
    stopRenewingTokenContinuously(_, rootState) {
      const { pollingTimer } = rootState.authentication;
      if (pollingTimer) {
        clearInterval(pollingTimer);
      }
      dispatch.authentication._setTokenPollingTimer(undefined);
    },
    async renewTokenContinuously(_, rootState) {
      const { pollingTimer } = rootState.authentication;
      if (pollingTimer) {
        clearInterval(pollingTimer);
      }
      dispatch.authentication.renewToken();
      let authTimer = setInterval(async () => {
        dispatch.authentication.renewToken();
      }, 50 * 60 * 1000);

      dispatch.authentication._setTokenPollingTimer(authTimer);
    },
    impersonateUser(user: UserToken) {
      http.defaults.headers.common.Authorization = `Bearer ${user.bearer}`;
      dispatch.authentication._impersonateUser(user);
    },
    endImpersonation(_, rootState) {
      http.defaults.headers.common.Authorization = `Bearer ${
        rootState.authentication.originalImpersonatorUser!.bearer
      }`;
      dispatch.authentication._endImpersonation();
    },
    updateUserToken(user: UserToken) {
      Storage.set({
        key: "authentication",
        value: JSON.stringify(user),
      });
      http.defaults.headers.common.Authorization = `Bearer ${user.bearer}`;
      dispatch.authentication._updateUserToken(user);
    },
    mergeUserToken(user: UserToken) {
      Storage.set({
        key: "authentication",
        value: JSON.stringify(user),
      });
      http.defaults.headers.common.Authorization = `Bearer ${user.bearer}`;
      dispatch.authentication._mergeUserToken(user);
    },
    async followAppState(adapterId: string) {
      stateChangeHandler = await CapApp.addListener(
        "appStateChange",
        ({ isActive }) => {
          if (!isActive) {
            dispatch.adapter.stopPollingForAdapter();
            dispatch.authentication.stopRenewingTokenContinuously();
          } else {
            dispatch.authentication.renewTokenContinuously();
            dispatch.adapter.pollForAdapter(adapterId);
          }
        }
      );
    },
    async removeUserToken() {
      http.defaults.headers.common.Authorization = `Bearer ${appConfig.API_KEY}`;
      dispatch.adapter.stopPollingForAdapter();
      dispatch.authentication.stopRenewingTokenContinuously();
      dispatch.authentication._removeUserToken();
      dispatch.adapter.clearAll();
      dispatch.device.clearAll();
      dispatch.notifications.clearNotifications();
      dispatch.profile.clearAll();
      dispatch.streaming.clearAll();
      dispatch.ui.clearAll();
      dispatch.vpn.clearAll();
      Storage.remove({ key: "authentication" });
      Storage.remove({ key: "adapter" });
      await stateChangeHandler?.remove();
    },
    async changePassword(
      {
        newPassword,
        oldPassword,
      }: {
        oldPassword: string;
        newPassword: string;
      },
      rootState
    ) {
      const { data } = await api.auth.changePassword(oldPassword, newPassword);
      dispatch.authentication.updateUserToken({
        ...rootState.authentication.user!,
        ...data,
        password: newPassword,
      });
    },
  }),
});
