import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as PHLogoSvg } from "../assets/images/logos/privacy-hero-logo.svg";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { UserMenu } from "./UserMenu";

export const HeaderComponent: React.FC<{}> = () => {
  const { shouldHideMenu } = useSelector((state: RootState) => ({
    shouldHideMenu:
      state.ui.error?.route === "logout" || !state.authentication.user,
  }));
  return (
    <div className="w-full px-4 py-5 flex items-center bg-white relative max-w-5xl my-0 mx-auto flex-wrap">
      <Link to="/" id="home-link">
        <PHLogoSvg />
      </Link>
      {shouldHideMenu ? null : (
        <div className="nav-container p-2" data-testid="user-menu">
          <UserMenu />
        </div>
      )}
    </div>
  );
};

export default HeaderComponent;
