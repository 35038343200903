import React, { useState, useEffect, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import PageLayout from "../../layouts/PageLayout";
import { ToggleWithLabel } from "../../components/Checkbox";
import { BedtimeSetting, ProfileDetails, SchoolNights } from "../../model";
import {
  createPollAndRefresh,
  getLocalTimeFromUTCMinutesFromMidnight,
  toUTCTimestampFromMidnight,
} from "../../utils";
import api from "../../api";
import { IonLoading } from "@ionic/react";
import { useDispatch } from "react-redux";
import { Dispatch } from "../../store";
import { useSetState } from "ahooks";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import InformationMessage from "components/InformationMessage";
import ErrorMessage from "components/ErrorMessage";

interface Props {
  school?: boolean;
}
const hoursArray = new Array(12).fill(1).map((_, i) => i + 1);
const minutesArray = new Array(12).fill(1).map((_, i) => i * 5);

const BedtimeConfigComponent: React.FC<Props> = ({ school }) => {
  const [loadingSettingsMessage, setLoadingSettingsMessage] = useState("");
  const dispatch = useDispatch<Dispatch>();
  const { profileId } = useParams<{ profileId: string }>();
  const history = useHistory();
  const [bedtime, setBedtime] = useState<BedtimeSetting>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [bedtimeDelay, setBedtimeDelay] = useState<number>();
  const [hours, setHours] = useState<number>(12);
  const [minutes, setMinutes] = useState<number>(0);
  const [isPM, setIsPM] = useState<boolean>(true);
  const [schoolNights, setSchoolNights] = useState<SchoolNights>([
    true,
    true,
    true,
    true,
    true,
    false,
    false,
  ]);
  const [initialState, setInitialState] = useSetState<{
    hours: number;
    minutes: number;
    isPM: boolean;
    schoolNights: SchoolNights;
    delay: number;
  }>();

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "bedtime_config" });

    dispatch.profile
      .getProfileDetails(profileId)
      .then((data) => {
        const settings = data[0]!.settings.bedtime;
        setBedtime(settings);
        setBedtimeDelay(data[0]!.settings["bedtime-delay"]);
        const time = school ? settings.school : settings.other;
        // NOTE: local time for the adapter -- otherwise, use setUTCHours
        const minutes = time === -1 ? 60 : time;
        const timeValues = getLocalTimeFromUTCMinutesFromMidnight(
          minutes,
          true
        );
        const hours = timeValues.hours === 0 ? 12 : timeValues.hours;
        setHours(hours);
        setMinutes(timeValues.minutes);
        setIsPM(timeValues.isPM);
        setSchoolNights(settings["school-nights"]);
        setIsLoading(false);
        setInitialState({
          hours,
          minutes: timeValues.minutes,
          isPM: timeValues.isPM,
          schoolNights: settings["school-nights"],
          delay: data[0].settings["bedtime-delay"],
        });
      })
      .catch((error) => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateNights = (index: number, value: boolean) => {
    setSchoolNights((prev) => {
      const updated = [...prev];
      updated[index] = school === value;
      return updated as SchoolNights;
    });
  };

  const save = () => {
    if (bedtime) {
      // we should convert time to UTC before sending to the backend
      const bedtimeTime = toUTCTimestampFromMidnight(hours, minutes, isPM);
      // const bedtimeTime = hours * 60 + minutes + (isPM ? 720 : 0);
      const timeUpdate = school
        ? { school: bedtimeTime, other: bedtime["other"] }
        : { school: bedtime["school"], other: bedtimeTime };

      const update = {
        enabled: bedtime.enabled,
        ...timeUpdate,
        "school-nights": schoolNights,
      };

      api.profile
        .setBedTime(profileId, update)
        .then(() => {
          setLoadingSettingsMessage("Configuring bedtime ...");
          const getProfile = () =>
            dispatch.profile
              .getProfileDetails(profileId)
              .then((data) => data[0]!)
              .catch((error) => undefined);

          createPollAndRefresh<ProfileDetails | undefined>(
            getProfile,
            (profile) => {
              if (!profile) return false;
              const { bedtime } = profile.settings;
              return (
                bedtime.enabled === update.enabled &&
                bedtime.other === update.other &&
                bedtime.school === update.school &&
                bedtime["school-nights"].join("") ===
                  update["school-nights"].join("")
              );
            },
            async (state, profile) => {
              if (state === "Done!") {
                dispatch.profile.setProfileDetails({ profile: profile! });
                dispatch.ui.setToast({
                  type: "success",
                  description: t("generic:success.setBedTimeMessage"),
                });
                const day = new global.Date().getDay();
                const isSchoolNight = bedtime["school-nights"][day];
                if (
                  ((isSchoolNight && school) || (!isSchoolNight && !school)) &&
                  bedtimeDelay &&
                  bedtimeDelay > 0
                ) {
                  setLoadingSettingsMessage("removing bedtime delay");
                  // we should reset bedtime delay https://github.com/privacy-hero/issues/issues/2834
                  await dispatch.profile.delayBedTime({
                    delay: 0,
                    profile: profile!,
                    updateUi: (state) => {
                      if (state === "Done!") {
                        history.goBack();
                        setLoadingSettingsMessage("");
                      } else if (state === "Failed!") {
                        history.goBack();
                        dispatch.ui.setToast({
                          type: "warning",
                          description: t(
                            "generic:errors.setBedTimeDelayMessage"
                          ),
                        });
                        setLoadingSettingsMessage("");
                      }
                    },
                  });
                } else {
                  setLoadingSettingsMessage("");
                  history.goBack();
                }
              } else if (state === "Failed!") {
                dispatch.ui.setToast({
                  type: "error",
                  description: t("generic:errors.setBedTimeDelayMessage"),
                });
                setLoadingSettingsMessage("");
              }
            },
            10
          );
        })
        .catch(() => {
          dispatch.ui.setToast({
            type: "error",
            description: t("generic:errors.setBedTimeDelayMessage"),
          });
          setLoadingSettingsMessage("");
          setIsLoading(false);
        });
    }
  };

  const noSchoolNightsSelected = useMemo(() => !schoolNights.some((n) => n), [
    schoolNights,
  ]);
  const didSomethingChange =
    initialState.hours !== hours ||
    initialState.minutes !== minutes ||
    initialState.isPM !== isPM ||
    initialState.delay !== bedtimeDelay ||
    !_.isEqual(initialState.schoolNights, schoolNights);

  const { t } = useTranslation();
  if (isLoading) {
    return <IonLoading message="loading..." isOpen />;
  }
  return (
    <PageLayout
      back={history.goBack}
      save={save}
      disabled={noSchoolNightsSelected}
      didSomethingChange={didSomethingChange}
    >
      {loadingSettingsMessage ? (
        <IonLoading
          key={loadingSettingsMessage}
          message={loadingSettingsMessage || "Configuring bedtime ..."}
          isOpen
        />
      ) : null}
      <div className="module-large">
        <Title
          className="mb-4"
          title={
            school
              ? t("profileOverview:bedtime.schoolNightConfigTitle")
              : t("profileOverview:bedtime.weekendConfigTitle")
          }
        />
        <InformationMessage
          moreList={[]}
          text={
            school
              ? t("profileOverview:bedtime.schoolNightInfo")
              : t("profileOverview:bedtime.weekendInfo")
          }
        />
      </div>
      <form>
        <div className="field-group module-large">
          <label
            htmlFor="hours"
            className="block text-sm font-medium text-gray-700"
          >
            {t("profileOverview:bedtime.bedtimeStart")}
          </label>
          <div className="row flex">
            <div className="flex-auto">
              <div className="field">
                <div className="select-container">
                  <select
                    id="hours"
                    data-testid="hours-select"
                    value={hours}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-orange focus:border-orange sm:text-sm rounded-md"
                    onChange={(v) => setHours(+v.currentTarget.value)}
                  >
                    {hoursArray.map((hour) => (
                      <option
                        data-testid={`hour-${hour}`}
                        key={hour}
                        value={hour}
                      >
                        {hour}
                        {/* {hour === 12
                          ? isPM
                            ? " (midnight)"
                            : " (midday)"
                          : null} */}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="px-2 flex-auto">
              <div className="field">
                <div className="select-container">
                  <select
                    id="minutes"
                    data-testid="minutes-select"
                    value={minutes}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-orange focus:border-orange sm:text-sm rounded-md"
                    onChange={(v) => setMinutes(+v.currentTarget.value)}
                  >
                    {minutesArray.map((minute) => (
                      <option
                        data-testid={`minute-${minute}`}
                        key={minute}
                        value={minute}
                      >
                        {(minute < 10 ? "0" : "") + minute}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="flex-auto">
              <div className="field">
                <div className="select-container">
                  <select
                    value={isPM ? "PM" : "AM"}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-orange focus:border-orange sm:text-sm rounded-md"
                    onChange={(v) => setIsPM(v.currentTarget.value === "PM")}
                    data-testid="pm-select"
                  >
                    <option data-testid="am" key="AM" value="AM">
                      AM
                    </option>
                    <option data-testid="pm" key="PM" value="PM">
                      PM
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <InformationMessage
            className="mt-2"
            text={t("profileOverview:bedtime.autoOffNotice")}
          />
        </div>
        {school && (
          <div className="module-large">
            <div className="field-group">
              <Title
                title={t("profileOverview:bedtime.schoolNightsTitle")}
                subtitle={t("profileOverview:bedtime.schoolNightsSubtitle")}
                className="mb-2"
                level={2}
              />
              <div className="checkbox-group">
                <ToggleWithLabel
                  className="mb-2"
                  id="sn-sunday"
                  label="Sunday"
                  checked={school === schoolNights[0]}
                  onChange={(v) => updateNights(0, v)}
                />
                <ToggleWithLabel
                  className="mb-2"
                  id="sn-monday"
                  label="Monday"
                  checked={school === schoolNights[1]}
                  onChange={(v) => updateNights(1, v)}
                />
                <ToggleWithLabel
                  className="mb-2"
                  id="sn-tuesday"
                  label="Tuesday"
                  checked={school === schoolNights[2]}
                  onChange={(v) => updateNights(2, v)}
                />
                <ToggleWithLabel
                  className="mb-2"
                  id="sn-wednesday"
                  label="Wednesday"
                  checked={school === schoolNights[3]}
                  onChange={(v) => updateNights(3, v)}
                />
                <ToggleWithLabel
                  className="mb-2"
                  id="sn-thursday"
                  label="Thursday"
                  checked={school === schoolNights[4]}
                  onChange={(v) => updateNights(4, v)}
                />
                <ToggleWithLabel
                  className="mb-2"
                  id="sn-friday"
                  label="Friday"
                  checked={school === schoolNights[5]}
                  onChange={(v) => updateNights(5, v)}
                />
                <ToggleWithLabel
                  className="mb-2"
                  id="sn-saturday"
                  label="Saturday"
                  checked={school === schoolNights[6]}
                  onChange={(v) => updateNights(6, v)}
                />
              </div>
            </div>
          </div>
        )}
        {noSchoolNightsSelected ? (
          <ErrorMessage
            isText
            error={t("profileOverview:bedtime.schoolNightsRequired")}
          />
        ) : null}
      </form>
    </PageLayout>
  );
};

export default BedtimeConfigComponent;
