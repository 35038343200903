import http from "axios";
import { GET_VPN_PROVIDERS, SET_VPN_PROVIDER } from "../constants";
import { VPNProvider } from "../model";
import { createMock } from "./utils";

export function getVPNProviders() {
  return http.get<VPNProvider[]>(`${GET_VPN_PROVIDERS}`);
}
export function mockGetVPNProviders() {
  return createMock<VPNProvider[], undefined>(`${GET_VPN_PROVIDERS}`, "GET");
}

export interface VPNProviderRequirements {
  username?: string;
  password?: string;
  [key: string]: any;
}

export interface VPNProviderConfigBody {
  id: string;
  provider?: string;
  country?: string;
  creds?: VPNProviderRequirements;
}

export interface VPNError {
  message?: string;
  fields?: VPNProviderRequirements;
}

export interface VPNProviderConfigResponse {
  id: string;
  provider?: string;
  country?: string;
  creds?: VPNProviderRequirements;
  since?: number;
  pending?: number;
  error?: VPNError;
}

export function configureVPNProvider(config: VPNProviderConfigBody) {
  return http.post<VPNProviderConfigResponse>(SET_VPN_PROVIDER, config);
}
