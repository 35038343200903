import "@capacitor/core";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import http from "axios";
import AppConfig from "./AppConfig";
// import * as serviceWorker from "serviceWorker";
import { AdapterListItem, UserToken } from "./model";
import { createStore } from "./store";
import { BrowserRouter } from "react-router-dom";
import { IonApp, isPlatform } from "@ionic/react";
import { Storage } from "@capacitor/storage";
import AppWithErrorBoundary from "./AppWithErrorBoundary";
import "@ionic/react/css/core.css";
import "./index.css";
import "./assets/scss/style.scss";
import "./utils/i18n";
import { isMobile } from "utils";
import ErrorComponent from "components/ErrorComponent";
// import { WebviewVersionChecker } from "webview-version-checker";
// import { Dialog } from "@capacitor/dialog";
// import { AppLauncher } from "@capacitor/app-launcher";

const store = createStore();
// logdna.init(); //NOTE: LOGDNA WILL BUFFER MESSAGES IN LOCAL STORAGE UNTIL THE QUOTA IS REACHED

verifyEnvironmentVariables();
// mock intersection observer

function verifyEnvironmentVariables() {
  const emptyKeys: string[] = [];
  Object.keys(AppConfig).forEach((key) => {
    // @ts-ignore
    const item = AppConfig[key];
    if (item === "" || item === undefined) {
      emptyKeys.push(key);
    }
  });
  if (emptyKeys.length > 0) {
    const error = `Environment configuration missing: ${emptyKeys.join(", ")}`;
    ReactDOM.render(
      <BrowserRouter>
        <Provider store={store}>
          <ErrorComponent error={error} dismissError={() => {}} />
        </Provider>
      </BrowserRouter>,
      document.querySelector("#root")
    );
    throw new Error(error);
  }
}
const storageRequests = [
  Storage.get({ key: "authentication" }),
  Storage.get({ key: "adapter" }),
];

// if (isPlatform("android") && !isPlatform("mobileweb")) {
//   WebviewVersionChecker.getWebviewVersion().then(async ({ version, name }) => {
//     if (+version.split(".")[0] < 60) {
//       const res = await Dialog.confirm({
//         title: "Outdated Webview version",
//         message:
//           "You need to update the webview version of your system in order to use the app.",
//       });
//       if (res.value) {
//         const canOpen = await AppLauncher.canOpenUrl({ url: name });
//         if (canOpen) {
//           await AppLauncher.openUrl({ url: name });
//         }
//       }
//     }
//   });
// }
Promise.all(storageRequests).then(async ([authString, adapterJson]) => {
  http.defaults.headers.common.Authorization = `Bearer ${AppConfig.API_KEY}`;
  let adapter: AdapterListItem | undefined = undefined;
  if (adapterJson?.value) {
    try {
      adapter = JSON.parse(adapterJson.value) as AdapterListItem;
    } catch (error) {}
  }
  if (adapter) {
    // store.dispatch.adapter._setAdapter(adapter);
    store.dispatch.adapter.setAdapterList([adapter]);
  }
  if (authString?.value) {
    const token: UserToken = JSON.parse(authString.value);
    store.dispatch.authentication.updateUserToken(token);
    const renewed = await store.dispatch.authentication.renewToken();
    if (!renewed) {
      store.dispatch.authentication.removeUserToken();
    }
  }

  const RootApp = () => {
    return (
      <IonApp style={isMobile && isPlatform("ios") ? { marginTop: 18 } : {}}>
        <BrowserRouter>
          <AppWithErrorBoundary />
        </BrowserRouter>
      </IonApp>
    );
  };
  ReactDOM.render(
    <Provider store={store}>
      <RootApp />
    </Provider>,
    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  // serviceWorker.unregister();
});
