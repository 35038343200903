import React, { useEffect } from "react";

import { DataCap } from "model";
import { useDataUsage } from "utils/hooks";
import _ from "lodash";
import { IonSpinner } from "@ionic/react";
import prettyBytes from "pretty-bytes";

interface Props {
  id: string;
  total: number;
  type: "device" | "profile";
  reportTotal?: (usage: number) => void;
  chartSpan: 1 | 7 | 30;
  startFromBillingDate: boolean;
}

export const getBillingRange = (dataCap?: DataCap): [number, number] => {
  const date = new global.Date();
  if (dataCap) {
    if (date.getDate() > dataCap.day) {
      date.setMonth(date.getMonth() - 1);
    }
    date.setDate(dataCap.day);
    const start = Math.round(date.valueOf() / 1000);
    date.setMonth(date.getMonth() + 1);
    const end = Math.round(date.valueOf() / 1000);
    return [start, end];
  }
  const now = Math.round(Date.now() / 1000);
  return [now - 2592000, now];
};

const UsageBarComponent: React.FC<Props> = ({
  id,
  total,
  reportTotal,
  chartSpan,
  startFromBillingDate,
  type,
}) => {
  const usage = useDataUsage({
    chartSpan,
    id,
    type,
    startFromBillingDate,
    canStartFromBillingDate: true,
  });

  useEffect(() => {
    reportTotal && reportTotal(usage.currentPeriodTotalUsage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usage.currentPeriodTotalUsage]);

  const percentagValue =
    total && usage.currentPeriodTotalUsage
      ? (usage.currentPeriodTotalUsage / total) * 100
      : 0;
  const percentage = _.round(percentagValue, 2);

  if (usage.loading) {
    return <IonSpinner className="text-orange" />;
  }
  return (
    <div className="progress-container mt-1">
      <div className="progress-text">
        <p>
          {`${prettyBytes(usage.currentPeriodTotalUsage, {
            bits: false,
          })} (${percentage}%)`}
        </p>
      </div>
      <div className="progress-bar mt-1">
        <div className="inner" style={{ width: `${percentage}%` }} />
      </div>
    </div>
  );
};

export default UsageBarComponent;
