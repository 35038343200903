
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "filterPlaceholder": "",
  "filterLabel": "Search",
  "title": "Edit Device",
  "nameLabel": "Device Name",
  "namePlaceholder": "",
  "nameInvalidMessage": "Device name can only contain alphanumeric characters, spaces, - or _",
  "nameRequiredMessage": "Device name is required",
  "nameSizeLimitMessage": "Device name should have less than 64 characters",
  "deviceTypetitle": "Device Type"
}
