import config from "./AppConfig";
import React, { useEffect, useState } from "react";

import { useHistory } from "react-router";
import * as firebase from "firebase/app";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import http from "axios";
import { Dispatch } from "./store";
import { getAdapter, getAdapterList } from "./api/adapter";
import {
  PushNotifications,
  Token,
  PushNotificationSchema,
  ActionPerformed,
} from "@capacitor/push-notifications";
import { useDispatch } from "react-redux";
import { isMobile } from "utils";
const { addListener, requestPermissions, register } = PushNotifications;

type Props = { children: React.ReactNode };

interface NotificationData {
  location?: string;
  adapter?: string;
  device?: string;
  profile?: string;
  service?: string;
  message?: string;
}
const NotificationHandler: React.FC<Props> = ({ children }) => {
  const history = useHistory();
  const [safariRequest, setSafariRequest] = useState(false);

  const dispatch = useDispatch<Dispatch>();
  const addNotification = dispatch.notifications.addNotification;
  const setAdapter = dispatch.adapter.setAdapter;
  const handleNotification = (data: NotificationData) => {
    if (data && data.location) {
      firebase
        .analytics()
        .logEvent("notification_received", { location: data.location });
      FirebaseAnalytics.logEvent("notification_received", {
        location: data.location,
      });
      const setAdapterAndGo = (path: string, adapter?: string) => {
        if (data.adapter) {
          let getAdapterPromise;
          if (adapter) {
            getAdapterPromise = getAdapter(adapter);
          } else {
            getAdapterPromise = getAdapterList();
          }
          getAdapterPromise.then((adaperResponse) => {
            setAdapter(adaperResponse.data[0]!);
            if (history.location.pathname !== path) {
              history.push(path);
            }
          });
        } else if (history.location.pathname !== path) {
          history.push(path);
        }
      };

      switch (data.location.toUpperCase()) {
        case "ACCOUNTSETTINGS":
          setAdapterAndGo("/settings", data.adapter);
          break;
        case "ADAPTERSETTINGS":
          setAdapterAndGo("/security", data.adapter);
          break;
        case "CREATENETWORK":
          setAdapterAndGo("/onboarding/create-primary-network", data.adapter);
          break;
        case "DATACAP":
          setAdapterAndGo("/internet/datacap", data.adapter);
          break;
        case "DEVICELIST":
          setAdapterAndGo("/device/list", data.adapter);
          break;
        case "DEVICEDETAIL":
          if (data.device) {
            setAdapterAndGo(`/device/${data.device}`, data.adapter);
          }
          break;
        case "DEVICEUSAGE":
          if (data.device) {
            setAdapterAndGo(`/device/${data.device}?tab=usage`, data.adapter);
          }
          break;
        case "DEVICESETTINGS":
          if (data.device) {
            setAdapterAndGo(
              `/device/${data.device}?tab=settings`,
              data.adapter
            );
          }
          break;
        case "DEVICENERDSTUFF":
          if (data.device) {
            setAdapterAndGo(`/device/${data.device}?tab=nerd`, data.adapter);
          }
          break;
        case "DEVICEUPDATE":
          if (data.device) {
            setAdapterAndGo(`/device/identify/${data.device}`, data.adapter);
          }
          break;
        case "EDITACCOUNT":
          setAdapterAndGo("/settings/edit", data.adapter);
          break;
        case "GETCONNECTED":
          setAdapterAndGo("/onboarding/get-connected", data.adapter);
          break;
        case "HELP":
          setAdapterAndGo("/onboarding/help", data.adapter);
          break;
        case "HOME":
          setAdapterAndGo("/", data.adapter);
          break;
        case "IMPERSONATE":
          setAdapterAndGo("/settings/impersonate", data.adapter);
          break;
        case "INTERNET":
          setAdapterAndGo("/internet", data.adapter);
          break;
        case "PROFILECREATE":
          setAdapterAndGo("/profile/create", data.adapter);
          break;
        case "PROFILEDETAILS":
          if (data.profile) {
            setAdapterAndGo(`/profile/${data.profile}/details`, data.adapter);
          }
          break;
        case "PROFILEUSAGE":
          if (data.profile) {
            setAdapterAndGo(
              `/profile/${data.profile}/details?tab=usage`,
              data.adapter
            );
          }
          break;
        case "PROFILESETTINGS":
          if (data.profile) {
            setAdapterAndGo(
              `/profile/${data.profile}/details?tab=settings`,
              data.adapter
            );
          }
          break;
        case "PROFILEDEVICES":
          if (data.profile) {
            setAdapterAndGo(
              `/profile/${data.profile}/details?tab=devices`,
              data.adapter
            );
          }
          break;
        case "PROFILEDEVICEMANAGER":
          if (data.profile) {
            setAdapterAndGo(`/profile/${data.profile}/manage`, data.adapter);
          }
          break;
        case "PROFILEUPDATE":
          if (data.profile) {
            setAdapterAndGo(`/profile/${data.profile}/edit`, data.adapter);
          }
          break;
        case "PROFILESCHOOLNIGHTS":
          if (data.profile) {
            setAdapterAndGo(
              `/profile/${data.profile}/school-nights`,
              data.adapter
            );
          }
          break;
        case "PROFILEWEEKENDS":
          if (data.profile) {
            setAdapterAndGo(`/profile/${data.profile}/weekends`, data.adapter);
          }
          break;
        case "RENAMEROUTER":
          setAdapterAndGo("/settings/rename", data.adapter);
          break;
        case "ROUTERS":
          setAdapterAndGo("/routers", data.adapter);
          break;
        case "STREAMING":
          setAdapterAndGo("/streaming", data.adapter);
          break;
        case "STREAMINGSERVICE":
          if (data.service) {
            setAdapterAndGo(`/streaming/${data.service}`, data.adapter);
          }
          break;
        case "SUBSCRIPTION":
          setAdapterAndGo("/settings/subscription", data.adapter);
          break;
        case "TIMEZONE":
          setAdapterAndGo("/settings/timezone", data.adapter);
          break;
        default:
          break;
      }
    }
  };

  const requestMobilePush = () => {
    requestPermissions().then((result) => {
      if (result.receive === "granted") {
        FirebaseAnalytics.logEvent("notifications_accepted", {
          platform: "mobile",
        });

        // Register with Apple / Google to receive push via APNS/FCM
        register();
      }
    });

    addListener("registration", (token: Token) => {
      // console.log(token.value); // Used for testing purposes only
      http.post("/v1/client/notify", {
        token: token.value,
        service: "FIREBASE",
      });
    });

    // addListener("registrationError", () => {
    //   if (addNotification) {
    //     addNotification({
    //       message: "Error registering for notifications",
    //       level: "ERROR",
    //       canDismiss: true,
    //     });
    //   }
    // });

    addListener(
      "pushNotificationReceived",
      (message: PushNotificationSchema) => {
        if (addNotification && message.body) {
          addNotification({
            message: message.body,
            canDismiss: true,
            level: message.data?.type,
            onPress: () => handleNotification(message.data),
          });
        }
      }
    );

    addListener(
      "pushNotificationActionPerformed",
      ({ notification }: ActionPerformed) => {
        FirebaseAnalytics.logEvent("notification_action", {
          title: notification.title,
        });
        handleNotification(notification.data);
      }
    );
  };

  const requestWebPush = () => {
    const messaging = firebase.messaging();

    messaging.onTokenRefresh(() => {
      messaging
        .getToken()
        .then((currentToken) => {
          if (currentToken) {
            FirebaseAnalytics.logEvent("notifications_accepted", {
              platform: "web",
              refresh: true,
            });
            http.post("/v1/client/notify", {
              token: currentToken,
              service: "FIREBASE",
            });
          } else if (addNotification) {
            addNotification({
              message: "Unable to retrieve notifications from server",
              level: "ERROR",
              canDismiss: true,
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    });
    messaging.onMessage(({ data, notification }: any) => {
      if (addNotification && notification) {
        addNotification({
          message: notification.body,
          canDismiss: true,
          level: data?.type,
          onPress: () => handleNotification(data),
        });
      }
    });

    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        firebase
          .analytics()
          .logEvent("notifications_accepted", { platform: "web" });
        messaging
          .getToken()
          .then((currentToken) => {
            if (currentToken) {
              // console.log(currentToken); // Used for testing purposes only
              http.post("/v1/client/notify", {
                token: currentToken,
                service: "FIREBASE",
              });
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };

  const requestSafariPush = () => {
    // Needs to be triggered by user input
    const { safari } = window as any;
    if (safariRequest && safari && safari.pushNotification) {
      const checkRemotePermission = (permissionData: any) => {
        if (permissionData.permission === "default") {
          safari.pushNotification.requestPermission(
            `https://${config.API_URL}`,
            "web.com.privacyhero.privacyherodev",
            {},
            checkRemotePermission
          );
        } else if (permissionData.permission === "granted") {
          firebase
            .analytics()
            .logEvent("notifications_accepted", { platform: "safari" });
          // console.log(permissionData.deviceToken);
          http.post("/v1/client/notify", {
            token: permissionData.deviceToken,
            service: "firebase",
          });
        }
      };
      const permissionData = safari.pushNotification.permission(
        "web.com.privacyhero.privacyherodev"
      );
      checkRemotePermission(permissionData);
    }
  };

  useEffect(() => {
    if (isMobile) {
      requestMobilePush();
    } else if (firebase.messaging.isSupported()) {
      requestWebPush();
    } else {
      const { safari } = window as any;
      if (safari && safari.pushNotification) {
        const checkRemotePermission = (permissionData: any) => {
          if (permissionData.permission === "default") {
            setSafariRequest(true);
          } else if (permissionData.permission === "granted") {
            // console.log(permissionData.deviceToken);
            http.post("/v1/client/notify", {
              token: permissionData.deviceToken,
              service: "firebase",
            });
          }
        };
        const permissionData = safari.pushNotification.permission(
          "web.com.privacyhero.privacyherodev"
        );
        checkRemotePermission(permissionData);
      }
    }
  }, [addNotification]); // eslint-disable-line

  return (
    <div>
      {safariRequest ? (
        <div role="button" onClick={() => requestSafariPush()}>
          {children}
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default NotificationHandler;
