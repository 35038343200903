import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { isPlatform } from "@ionic/react";
import Login from "./Login";
import PasswordRecovery from "./PasswordRecovery";
import Welcome from "./Welcome";
import PasswordReset from "./PasswordReset";
import WelcomeConfirmation from "./WelcomeConfirmation";

// there is a different auth. workflow for mobile and web
// In mobile, if the token is invalid or none present
//    We redirect to Welcome screen (prompts email only)
//    And if present, we redirect to the home page

// In this component, we check for mobile app/web and redirect to welcome on / (login) route
interface Props {}
const Routes: React.FC<Props> = () => {
  if (isPlatform("mobileweb") || isPlatform("desktop")) {
    return (
      <Switch>
        <Route path="/" component={Login} exact />
        <Route path="/login" component={Login} exact />
        <Route path="/passwordRecovery" component={PasswordRecovery} exact />
        <Route path="/passwordReset" component={PasswordReset} exact />
        <Redirect to="/login" />
      </Switch>
    );
  } else {
    // mobile device
    return (
      <Switch>
        <Route path="/welcome" component={Welcome} exact />
        <Route
          path="/welcome-confirmation"
          component={WelcomeConfirmation}
          exact
        />
        <Redirect to="/welcome" />
      </Switch>
    );
  }
};

export default Routes;
