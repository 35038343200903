
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Upgrade",
  "upgrade": "Upgrade",
  "confirmChangeSub": "Changing Subscription Plan",
  "followingChange": "The following change will be made to your subscription plan",
  "confirmationMessage": "Changes to your plan rate and frequency will take effect on the next billing cycle",
  "confirmationMessageProrate": "The features provided by your new plan will be available to use immediately and your account will be billed the pro-rated difference"
}
