import { Models } from "@rematch/core";
import { adapter } from "./adapter";
import { authentication } from "./authentication";
import { notifications } from "./notification";
import { ui } from "./ui";
import { profile } from "./profile";
import { streaming } from "./streaming";
import { device } from "./device";
import { vpn } from "./vpn";

export interface RootModel extends Models<RootModel> {
  adapter: typeof adapter;
  authentication: typeof authentication;
  notifications: typeof notifications;
  ui: typeof ui;
  profile: typeof profile;
  streaming: typeof streaming;
  device: typeof device;
  vpn: typeof vpn;
}

export const models: RootModel = {
  adapter,
  authentication,
  notifications,
  ui,
  profile,
  streaming,
  device,
  vpn,
};
