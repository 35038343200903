
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Streaming Relocation",
  "relocationToggleLabel": "Relocation",
  "messages": {
    "noServices": "There are no services available at the moment. Please try again later.",
    "relocationOff": "Streaming relocation Off. Turn it on in order to configure streaming services."
  },
                                                                                                
  "nonPortableInfo": "Non-Portable: requires a separate account and active subscription in each country."
}
