
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "server": {
                       
    "401": {
      "en": "Invalid API Key, access denied."
    },
    "4004090": {
      "en": "There is a conflict between the current account and the adapter parameters."
    },
    "4004091": {
      "en": "Replacing the old adapter id failed because the callee did not claim the adapter."
    },
    "4004092": {
      "en": "Replacing the old adapter id failed because another account claimed the adapter."
    },
    "4004040": {
      "en": "The requested router/s ID does not exist."
    },
    "4014040": {
      "en": "The requested router/s could not be found."
    },
    "4024040": {
      "en": "The requested router/s ID does not exist."
    },
    "4029700": {
      "en": "The database version check has failed."
    },
    "4039700": {
      "en": "The database version check has failed."
    },
    "4039555": {
      "en": "Only a router which is Online at the time can have a speedtest requested."
    },
    "4054040": {
      "en": "The provided adapter ID is invalid."
    },
    "4059555": {
      "en": "Only a router which is online at the time can have be reset to factory defaults."
    },
    "4064040": {
      "en": "The adapter linked to this device is not eligible for this user to access."
    },
    "4069503": {
      "en": "The supplied parameters are incorrect."
    },
    "4069502": {
      "en": "The supplied parameters are incorrect."
    },
    "4069700": {
      "en": "The database version check has failed."
    },
    "4074040": {
      "en": "The provided adapter ID is invalid."
    },
    "4084040": {
      "en": "The provided adapter ID is invalid."
    },
    "4094040": {
      "en": "The provided adapter ID is invalid."
    },
    "4104040": {
      "en": "The provided adapter ID is invalid."
    },
    "4129600": {
      "en": "The service cannot be changed while it is disabled or while attempting to disable it."
    },
    "4124040": {
      "en": "The requested router/s could not be found."
    },
    "4129555": {
      "en": "Only a router which is Online at the time can have its service state changed."
    },
    "5005001": {
      "en": "The provided email address is invalid."
    },
    "5015002": {
      "en": "The provided password is invalid."
    },
    "5015003": {
      "en": "The provided password is invalid."
    },
    "5015014": {
      "en": "The provided password is invalid."
    },
    "5015007": {
      "en": "The account in the credentials does not exist."
    },
    "5025003": {
      "en": "The provided password is invalid."
    },
    "5025006": {
      "en": "Account creation failed because one with the same email already exists."
    },
    "5025001": {
      "en": "The provided email address is invalid."
    },
    "5025007": {
      "en": "The account requested does not exist."
    },
    "5025008": {
      "en": "A password is required in order to create an admin account."
    },
    "5025011": {
      "en": "The supplied password parameter and the credentials password requirement do not match."
    },
    "5025012": {
      "en": "Administrator accounts cannot be deleted through the api."
    },
    "5025013": {
      "en": "Deletion of the customer account failed."
    },
    "5025004": {
      "en": "The verify token has expired."
    },
    "5025009": {
      "en": "A password is required in order to do this operation."
    },
    "5025010": {
      "en": "No password is required in order to do this operation."
    },
    "5025005": {
      "en": "The supplied verification token is invalid."
    },
    "5035012": {
      "en": "Administrator accounts cannot be deleted through the api."
    },
    "5035014": {
      "en": "You have entered invalid username or password."
    },
    "5045015": {
      "en": "Administrator accounts cannot impersonate other admin accounts."
    },
    "5045009": {
      "en": "The password provided does not match the password requirement in the credentials."
    },
    "5045007": {
      "en": "The user account does not exist."
    },
    "5045014": {
      "en": "You have entered invalid username or password."
    },
    "5055008": {
      "en": "Administrator accounts require a password to login."
    },
    "5065016": {
      "en": "The API key supplied is invalid for the action specified."
    },
    "5065017": {
      "en": "The account token has expired and needs to be refreshed."
    },
    "5065007": {
      "en": "The admin account specified in the token does not exist."
    },
    "6009700": {
      "en": "The database version check has failed."
    },
    "6005007": {
      "en": "The account id specified in the user credentials is invalid."
    },
    "6016001": {
      "en": "The selected carousel page was not found."
    },
    "6019700": {
      "en": "The database version check has failed."
    },
    "6015007": {
      "en": "The account id specified in the user credentials is invalid."
    },
    "6026001": {
      "en": "The selected carousel page was not found."
    },
    "6025007": {
      "en": "The account id specified in the user credentials is invalid."
    },
    "6029700": {
      "en": "The database version check has failed."
    },
    "6039503": {
      "en": "The parameters supplied for notification configuration are invalid."
    },
    "6036002": {
      "en": "The notification could not be registered to the user."
    },
    "6039700": {
      "en": "The database version check has failed."
    },
    "6049700": {
      "en": "The database version check has failed."
    },
    "7007001": {
      "en": "The devices ID does not exist."
    },
    "7008001": {
      "en": "The profiles ID does not exist."
    },
    "7009555": {
      "en": "Only a router which is Online at the time the device is being moved can have its service state changed."
    },
    "7019800": {
      "en": "The supplied UUID is invalid and no devices could be retrieved from it."
    },
    "7019700": {
      "en": "The database version check has failed."
    },
    "7027001": {
      "en": "The requested devices ID does not exist."
    },
    "7027002": {
      "en": "There is a conflict between the proposed device name and another device with the same name."
    },
    "7029700": {
      "en": "The database version check has failed."
    },
    "7037001": {
      "en": "The requested device does not belong to the account in the credentials or it does not exist."
    },
    "7039700": {
      "en": "The database version check has failed."
    },
    "7039555": {
      "en": "Only a router which is Online at the time can have its service state changed."
    },
    "7049501": {
      "en": "This api call takes no parameters."
    },
    "7057001": {
      "en": "The supplied UUID is invalid and no devices could be retrieved from it."
    },
    "7054040": {
      "en": "The adapter linked to this device is not eligible for this user to access."
    },
    "7059503": {
      "en": "The supplied parameters are incorrect."
    },
    "7059502": {
      "en": "The supplied parameters are incorrect."
    },
    "7059700": {
      "en": "The database version check has failed."
    },
    "8004040": {
      "en": "The requested adapter ID does not exist."
    },
    "8008004": {
      "en": "Profile name already exists."
    },
    "8008005": {
      "en": "There is a conflict between the proposed profile name and another profile with the same name."
    },
    "8009700": {
      "en": "The database version check has failed."
    },
                                                                      
    "8018006": {
      "en": "The supplied profile is that of a system profile and cannot be deleted."
    },
    "8018001": {
      "en": "The supplied profile is that of a profile that does not exist on the users account and cannot be deleted."
    },
    "8018007": {
      "en": "The supplied profile has devices and cannot be deleted."
    },
    "8019700": {
      "en": "The database version check has failed."
    },
    "8038001": {
      "en": "The supplied profile UUID is invalid and no profiles could be retrieved from it."
    },
    "8034040": {
      "en": "The supplied adapter UUID is invalid and no profiles could be retrieved from it."
    },
    "8039700": {
      "en": "The database version check has failed."
    },
    "8048001": {
      "en": "The supplied UUID is invalid and no profiles could be retrieved from it."
    },
    "8044040": {
      "en": "The adapter linked to this profile is not eligible for this user to access."
    },
    "8049503": {
      "en": "The supplied parameters are incorrect."
    },
    "8049502": {
      "en": "The supplied parameters are incorrect."
    },
    "8049700": {
      "en": "The database version check has failed."
    },
    "8058001": {
      "en": "The profile ID supplied is not valid."
    },
    "8054040": {
      "en": "The adapter linked to this profile is not eligible for this user to access."
    },
    "8058008": {
      "en": "A bedtime that is not set cannot be delayed."
    },
    "8058009": {
      "en": "The bedtime delay cannot extend outside of the bedtime maximum bounds."
    },
    "8058010": {
      "en": "Bedtime must be enabled on a profile in order for it to delayed."
    },
    "8059700": {
      "en": "The database version check has failed."
    },
    "8059555": {
      "en": "Only a router which is Online at the time can have its service state changed."
    },
    "8068001": {
      "en": "The profile ID supplied is not valid."
    },
    "8069700": {
      "en": "The database version check has failed."
    },
    "8069555": {
      "en": "Only a router which is Online at the time can have its service state changed."
    },
    "8078001": {
      "en": "The requested profile ID does not exist."
    },
    "8078004": {
      "en": "Profile name already exists."
    },
    "8078003": {
      "en": "There is a conflict between the proposed profile name and another profile with the same name."
    },
    "8078005": {
      "en": "The creation of the profile has failed."
    },
    "8078011": {
      "en": "The profile provided cannot be renamed."
    },
    "8079700": {
      "en": "The database version check has failed."
    },
    "8088001": {
      "en": "The profile ID supplied is not valid."
    },
    "8089700": {
      "en": "The database version check has failed."
    },
    "8089555": {
      "en": "Only a router which is Online at the time can have its service state changed."
    },
    "9004040": {
      "en": "The provided adapter ID does not exist or is not claimed by the client."
    },
    "9009700": {
      "en": "The database version check has failed."
    },
    "9019001": {
      "en": "The selected streaming configuration could not be set because streaming is disabled."
    },
    "9019002": {
      "en": "The selected streaming configuration could not be set because it is invalid."
    },
    "9014040": {
      "en": "The provided adapter ID does not exist or is not claimed by the client."
    },
    "9019700": {
      "en": "The database version check has failed."
    },
    "9019555": {
      "en": "Only a router which is Online at the time can have its service state changed."
    },
    "9029003": {
      "en": "The service type provided is invalid. must be one of: all, summary, portable, non-portable."
    },
    "9024040": {
      "en": "The provided adapter ID does not exist or is not claimed by the client."
    },
    "9029700": {
      "en": "The database version check has failed."
    },
    "95009555": {
      "en": "Router offline, cannot change state."
    },
    "95009600": {
      "en": "Cannot change settings because service is disabled."
    }
  },
  "ui": {}
}