
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Devices",
  "loading": "Loading devices...",
  "subtitle": "devices recently connected",
  "oldDevicesShown": "Old device(s) currently shown.",
  "oldDevicesHidden": "Old device(s) currently hidden.",
  "noDevices": "No devices found",
  "unnamedDevice": "Unnamed device",
  "lastActive": "last active",
  "pressable": {
    "hideThem": "Hide them",
    "showThem": "Show them"
  }
}
