import Button from "components/Button";
import React from "react";

interface Props {
  back: () => void;
  disabled?: boolean;
  save?: () => void;
}
export const SaveFooterComponent: React.FC<Props> = ({
  back,
  disabled,
  save,
}) => {
  return (
    <footer className="max-w-2xl mx-auto">
      <div className="flex align-center justify-end py-3 px-8">
        <div className="back flex align-center mr-3">
          <Button variant="white" onClick={back}>
            Back
          </Button>
        </div>
        {save ? (
          <Button
            type="button"
            disabled={disabled}
            onClick={save}
            data-testid="save-button"
          >
            Save
          </Button>
        ) : null}
      </div>
    </footer>
  );
};

export default SaveFooterComponent;
