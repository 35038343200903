import React, { useEffect, useMemo } from "react";
import CountryCodes from "../../streaming/CountryCodes";
import { handleError, useStreamingServicesWithConfig } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "store";
import { useSetState } from "ahooks";
import { useTranslation } from "react-i18next";
import NavigationLink from "components/NavigationLink";

interface State {
  loading: boolean;
}

const StreamingRelocationCard: React.FC<{}> = () => {
  const { t } = useTranslation();
  const getFlag = (code: string) => {
    const country = CountryCodes[code];
    return country && country.flag ? <country.flag /> : <>{code}</>;
  };
  const { isRelocationOff } = useSelector((state: RootState) => {
    const { adapter } = state.adapter;
    return {
      isRelocationOff: !Boolean(
        adapter ? adapter.services.streamrelocation?.state : false
      ),
    };
  });
  const dispatch = useDispatch<Dispatch>();
  const [state, setState] = useSetState<State>({ loading: false });

  useEffect(() => {
    setState({ loading: true });
    Promise.all([
      dispatch.streaming.getStreamingConfig(),
      dispatch.streaming.getStreamingServices(),
    ])
      .then(() => {
        setState({ loading: false });
      })
      .catch((error) => {
        dispatch.ui.setToast({
          type: "error",
          description: error.response
            ? handleError(error)
            : "Error loading streaming services",
        });
        setState({
          loading: false,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const services = useStreamingServicesWithConfig();
  const multiCountryServices = useMemo(() => {
    const s = services
      .filter((service) => {
        const countriesMap = new Map<string, string>();
        service.countries.proxy.forEach((country) => {
          countriesMap.set(country, country);
        });
        service.countries["non-proxy"].forEach((country) => {
          countriesMap.set(country, country);
        });
        return Array.from(countriesMap).map(([code]) => code).length > 1;
      })
      .filter((service) => service.configuration)
      .map((service) => ({
        id: service.id,
        name: service.name,
        country: service.configuration
          ? CountryCodes[service.configuration.country]?.name
          : "",
        flag: getFlag(service.configuration?.country || ""),
      }));
    return s.splice(0, s.length > 1 ? 3 : 1);
  }, [services]);
  return (
    <div
      data-testid="streaming-relocation-card"
      className="bg-gray-50 p-8 flex-1 rounded-xl w-full mb-2"
    >
      <h1 className="font-bold text-xl mb-4 text-gray-700">
        Streaming Relocation
      </h1>
      {isRelocationOff ? (
        <p className="font-semibold text-gray-400 uppercase text-3xl">
          {t("home:relocationOff")}
        </p>
      ) : multiCountryServices.length === 0 ? (
        <span className="text-gray-400">{t("home:noRelocatedServices")}</span>
      ) : (
        <ul className="grid grid-cols-streaming-card tablet:grid-cols-streaming-card-tablet md:grid-cols-streaming-card-big gap-y-4 gap-1 items-center justify-center">
          {multiCountryServices.map((service) => (
            <React.Fragment key={service.id}>
              <span className="font-semibold text-lg text-gray-600 truncate ">
                {service.name}
              </span>
              <span className="tablet:mr-2 place-self-center justify-self-start ">
                {service.flag}
              </span>
              <span className="text-gray-500 font-light hidden tablet:block md:hidden  justify-self-end">
                {service.country}
              </span>
            </React.Fragment>
          ))}
        </ul>
      )}
      {state.loading ? (
        <span className="text-gray-400 text-xs font-light">loading...</span>
      ) : null}
      <NavigationLink
        title={t("home:pressable.streamingSettings")}
        to="streaming"
        className="py-0 mt-4"
      />
    </div>
  );
};

export default StreamingRelocationCard;
