import { Link } from "react-router-dom";

interface Props {
  title: string;
  value?: string;
  updateText?: string;
  updateLink?: string;
  updateComponent?: any;
}

export default function SettingsRow({
  title,
  value,
  updateText,
  updateLink,
  updateComponent,
}: Props) {
  return (
    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
      <dt className="text-sm font-medium text-gray-500">{title}</dt>
      <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <span className="flex-grow">{value}</span>
        <span className="ml-4 flex-shrink-0">
          {updateLink && (
            <Link
              to={updateLink}
              className="bg-white rounded-md font-medium text-orange hover:text-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange"
            >
              {updateText && updateText}
            </Link>
          )}
          {updateComponent && updateComponent}
        </span>
      </dd>
    </div>
  );
}
