import { IonIcon } from "@ionic/react";
import classNames from "classnames";
import { linkSharp } from "ionicons/icons";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  subtitle?: string;
  link?: string;
  className?: string;
  level?: number;
}

export default function Title({
  title,
  subtitle,
  link,
  className = "",
  level = 1,
  ...otherProps
}: Props) {
  const InnerTitle = (
    <div
      className={classNames(
        "border-b border-solid border-gray-200",
        link &&
          "transition group-focus:outline-none group-focus:ring-2 group-focus:ring-offset-2 group-focus:ring-orange-500 rounded-sm",
        { "pb-3": level === 1 },
        { "pb-1 border-none": level === 2 },
        className
      )}
      {...(link ? {} : otherProps)}
    >
      <div className="group -ml-2 mt-14 flex flex-wrap items-baseline">
        <h3
          className={classNames(
            "ml-2 mt-2 leading-6 font-medium text-gray-900 capitalize",
            { "text-lg": level === 1 },
            { "text-base": level === 2 }
          )}
        >
          {title}
        </h3>
        {subtitle ? (
          <span className="ml-2 mt-1 text-sm text-gray-500 truncate">
            {subtitle}
          </span>
        ) : null}
        {link ? (
          <IonIcon
            icon={linkSharp}
            className={classNames(
              "text-gray-500 relative ml-1 group-hover:text-orange-500",
              { "text-sm": level === 1 },
              { "text-xs": level === 2 }
            )}
            style={{ top: 3 }}
          />
        ) : null}
      </div>
    </div>
  );
  if (link) {
    return (
      <Link to={link} className="group no-underline" {...otherProps}>
        {InnerTitle}
      </Link>
    );
  }
  return InnerTitle;
}
