import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as IconCheckMarkCircle } from "./../../assets/images/svg-icons/checkmark-circle.svg";
import PageLayout from "./../../layouts/PageLayout";
import OnboardingSteps from "./components/OnboardingSteps";

const RouterFoundContainer: React.FC = () => {
  useEffect(() => {
    if (window.sessionStorage.getItem("reloadAfterFind") === "notReloaded") {
      window.sessionStorage.setItem("reloadAfterFind", "hasReloaded");
      setTimeout(() => window.location.reload(), 200);
    }
  }, []);
  return (
    <PageLayout>
      <OnboardingSteps stage="activate" />
      <h2>Router Found</h2>
      <div className="updating-icon large-general large-top">
        <div className="inner-updating">
          <IconCheckMarkCircle />
        </div>
      </div>
      <div style={{ fontWeight: 500, textAlign: "center" }}>
        <div>Success!</div>
        <br />
        <div>We found your Privacy Hero Router, let's continue.</div>
        <div className="button-group" style={{ marginTop: 50 }}>
          <Link
            className="button"
            type="button"
            to="/onboarding/create-primary-network"
          >
            Next
          </Link>
        </div>
      </div>
    </PageLayout>
  );
};
export default RouterFoundContainer;
