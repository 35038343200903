import classNames from "classnames";
import { ProfileIcon } from "model";
import React, { useCallback } from "react";
import { useProfileIcons } from "utils/hooks";

interface Props {
  select: (icon: string) => void;
  selected: string | undefined;
}
const IconSelectorComponent: React.FC<Props> = ({ select, selected }) => {
  const icons = useProfileIcons();
  const renderIcon = useCallback(
    (icon: ProfileIcon, index: string) => (
      <li key={icon.name} className="relative">
        <div
          className={classNames(
            "group w-full flex items-center justify-center rounded-full",
            { "cursor-pointer": icon.name !== selected }
          )}
        >
          <img
            src={icon.url}
            alt={icon.name}
            className={classNames(
              { "group-hover:opacity-75": selected !== icon.url },
              "select-none rounded-full object-center pointer-events-none group-focus:outline-none",
              selected === icon.url
                ? "ring-4 ring-offset-2 ring-orange"
                : "group-focus-within:ring-2 group-focus-within:ring-offset-4 group-focus-within:ring-offset-gray-100 group-focus-within:ring-orange"
            )}
          />
          <button
            type="button"
            data-testid={`${
              selected === icon.url ? "selected-" : ""
            }profile-icon-${index}`}
            className="absolute inset-0 focus:outline-none group-focus:outline-none outline-none w-full"
            onClick={() => {
              select(icon.url);
            }}
          >
            <span className="sr-only">Select icon {icon.name}</span>
          </button>
        </div>
        {/* <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
          {icon}
        </p> */}
        {/* <p className="block text-sm font-medium text-gray-500 pointer-events-none">
          {file.size}
        </p> */}
      </li>
    ),
    [select, selected]
  );
  return (
    <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
      <h2 id="gallery-heading" className="sr-only">
        Icons
      </h2>
      <ul className="grid grid-cols-4 gap-x-4 gap-y-8 sm:grid-cols-5 sm:gap-x-6 md:grid-cols-6">
        {(icons || []).map((icon, index) =>
          icon.name === selected
            ? renderIcon(icon, "selected")
            : renderIcon(icon, `${index}`)
        )}
      </ul>
    </section>
  );
};

export default IconSelectorComponent;
