
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "update": "Update",
  "title": "Settings",
  "wifiSettings": "Wifi Settings",
  "accountSettings": "Account Settings",
  "advancedSection": "Advanced Section",
  "primaryNetwork": "Primary Networks",
  "guestNetwork": "Guest Network",
  "disabled": "disabled",
  "routerName": "Name",
  "email": "Email",
  "password": "Password",
  "passwordPlaceholder": "********",
  "timezone": "Timezone",
  "routerStatus": "Status",
  "routerOnlineStatus": "Online",
  "routerDownStatus": "Offline",
  "restartRouter": "Restart",
  "resetAdapterConfirmationModalTitle": "Are you sure that you want to reboot the router?",
  "resetAdapterConfirmationModalCopy": "It may take up to 2 minutes for your router to get back online. All connected devices will loose internet connection for that period.",
  "routerNameScreen": {
    "title": "Edit Router Name",
    "label": "Router Name",
    "placeholder": "",
    "patternError": "Adapter name must consists of letters, numbers and spaces only",
    "requiredError": "Router name is required"
  },
  "guestNetworkScreen": {
    "title": "Guest Network",
    "toggleLabel": "Guest Network",
    "name": "Wireless Network Name",
    "password": "Password"
  },
  "timezoneScreen": {
    "title": "Timezone",
    "label": "Timezone"
  }
}
