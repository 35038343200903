
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "greetingsTitle": "Hello",
  "confirmationTitle": "Email Verification",
  "confirmationMessage": "We’ve sent you an email with a link to verify your identity. If the link didn't redirect you to the app, you can enter the code provided here",
  "setPasswordTitle": "Create Account / Set Password",
  "resetPasswordTitle": "Reset Password",
  "password": {
    "label": "Password",
    "placeholder": "",
    "required": "Password is required",
    "min": "Password should contain at least 8 characters"
  },
  "confirmPassword": {
    "label": "Password Confirmation",
    "placeholder": "",
    "match": "Passwords don't match",
    "required": "Password is required"
  },
  "welcome": {
    "title": "Welcome",
    "emailLabel": "Email",
    "emailPlaceholder": "",
    "message": "To get started, all we need is your email",
    "button": "Create Account",
    "explanationMessage": "We’ve sent you an email with a link to verify your identity. If the link didn't redirect you to the app, you can enter the code provided here",
    "submitCode": "Submit Code",
    "goBack": "Go Back",
    "sendAgain": "Resend",
    "confirmationCodeLabel": "Code",
    "confirmationCodePlaceholder": ""
  }
}