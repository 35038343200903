import { IonIcon } from "@ionic/react";
import { checkmarkCircle, closeCircle, reloadCircle } from "ionicons/icons";
import { ReactComponent as Minus } from "../assets/images/svg-icons/minus-30.svg";
import { ReactComponent as Add } from "../assets/images/svg-icons/add-30.svg";
import { ReactComponent as Moon } from "../assets/images/svg-icons/moon.svg";
import { UIServiceState } from "../model";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../store";
import { useFirstRender } from "../utils";

interface Props {
  profileId: string;
  displayMoon?: boolean;
  large?: boolean;
}
const DelayBedtime: React.FC<Props> = ({
  profileId,
  displayMoon = false,
  large = false,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const [bedTimeState, setBedTimeState] = useState<UIServiceState>();
  const [ongoingDelay, setOngoingDelay] = useState<number>();
  const { profile, isAdapterOnline } = useSelector((state: RootState) => ({
    profile: state.profile.profilesMap[profileId],
    isAdapterOnline: state.adapter.adapter?.services.online.state,
  }));

  const now = Date.now() / 60000;
  const dayStart = new global.Date().setHours(0, 0, 0, 0) / 60000;
  const currentMinutes = now - dayStart; // necessary for daylight savings

  const bedtimeStart = useMemo(() => {
    if (profile?.settings.bedtime.enabled) {
      const { bedtime } = profile.settings;
      const day = new global.Date().getDay();
      const isSchoolNight = bedtime["school-nights"][day];
      const delay = profile.settings["bedtime-delay"];
      let start = isSchoolNight ? bedtime["school"] : bedtime["other"];
      if (start >= 0) {
        if (start < 360) start += 1440; // Bedtime before 6 AM, add 24 hours
        return delay + start;
      }
    }
    return -1;
  }, [profile]);
  const pastBedtime = currentMinutes >= bedtimeStart;
  const { disabled, disabledReason, minusDisabled } = useMemo(() => {
    if (!isAdapterOnline) {
      return {
        disabled: true,
        disabledReason: "disabled because router offline",
        minusDisabled: false,
      };
    } else if (bedTimeState === "Updating" && ongoingDelay !== -30) {
      return {
        disabled: true,
        disabledReason: "Updating",
        minusDisabled: false,
      };
    } else if (pastBedtime) {
      return {
        disabled: false,
        minusDisabled: true,
        disabledReason: "disabled because it's already past bedtime",
      };
    } else {
      return { disabled: false, disabledReason: "", minusDisabled: false };
    }
  }, [bedTimeState, isAdapterOnline, ongoingDelay, pastBedtime]);
  const pastBedtimeDiff = currentMinutes - bedtimeStart;
  const bedtimeDelay = useCallback(
    (delay: number) => {
      if (!profile) {
        throw new Error("no profile in delay");
      }
      setOngoingDelay(delay);
      let delayToApply = delay;
      if (delay === 30 && pastBedtime) {
        delayToApply = Math.round(pastBedtimeDiff + delay);
      }
      dispatch.profile.delayBedTime({
        delay: delayToApply,
        profile: profile!,
        updateUi: setBedTimeState,
      });
    },
    [profile, pastBedtime, dispatch.profile, pastBedtimeDiff]
  );
  // if before 6 AM, display previous day / convert SUN-SAT to MON-SUN

  const firstRender = useFirstRender();

  const [
    shouldDisplayNotification,
    setShouldDisplayNotification,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (firstRender) {
      return;
    }
    const newStatusState = bedTimeState;
    if (bedTimeState === "Done!" || bedTimeState === "Failed!") {
      setShouldDisplayNotification(true);
      setTimeout(() => {
        if (bedTimeState === newStatusState) {
          setShouldDisplayNotification(false);
          setBedTimeState(undefined);
        }
      }, 2000);
    }
  }, [bedTimeState, firstRender]);
  const renderDelayIcon = useCallback(
    (delay) => {
      const fontSize = large ? 26 : 23;

      if (shouldDisplayNotification && ongoingDelay === delay) {
        if (bedTimeState === "Done!") {
          return (
            <IonIcon
              icon={checkmarkCircle}
              style={{ color: "green", fontSize, marginTop: -2, marginLeft: 2 }}
              title="internet-pause-success"
            />
          );
        } else if (bedTimeState === "Failed!") {
          return (
            <IonIcon
              icon={closeCircle}
              style={{ color: "red", fontSize, marginTop: -2 }}
              title="internet-pause-failure"
            />
          );
        }
      }
      if (bedTimeState === "Updating" && ongoingDelay === delay) {
        return (
          <IonIcon
            title="delay-updating"
            style={{ fontSize, marginTop: -2 }}
            className="spin"
            icon={reloadCircle}
          />
        );
      }

      let classes = "";
      if (
        disabled ||
        (bedTimeState === "Updating" && ongoingDelay !== delay) ||
        bedTimeState !== undefined ||
        (delay < 0 && pastBedtime)
      ) {
        classes += " gray-icon";
      }
      if (large) {
        classes += " large";
      }
      if (delay === 30) {
        return (
          <Add className={`has-additive text-orange fill-current ${classes}`} />
        );
      } else if (delay === -30) {
        return (
          <Minus
            className={`has-additive text-orange fill-current ${classes}`}
          />
        );
      }
    },
    [
      large,
      shouldDisplayNotification,
      ongoingDelay,
      bedTimeState,
      disabled,
      pastBedtime,
    ]
  );
  return (
    <>
      <li key="minus-time">
        <button
          disabled={disabled || minusDisabled}
          className="unstyled"
          data-testid="delay-bedtime-minus-30min"
          title={
            disabled || minusDisabled ? disabledReason : "delay bedtime - 30min"
          }
          type="button"
          onClick={() => bedtimeDelay(-30)}
        >
          {renderDelayIcon(-30)}
        </button>
      </li>
      {displayMoon ? (
        <li>
          <button type="button" className="unstyled no-action">
            <Moon className="text-orange fill-current" />
          </button>
        </li>
      ) : null}
      <li key="add-time">
        <button
          disabled={disabled}
          type="button"
          className="unstyled"
          data-testid="delay-bedtime-plus-30min"
          onClick={() => bedtimeDelay(30)}
          title={disabled ? disabledReason : "delay bedtime + 30min"}
        >
          {renderDelayIcon(30)}
        </button>
      </li>
    </>
  );
};

export default DelayBedtime;
