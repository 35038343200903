import React, { useState } from "react";
import { IonPage, IonContent, IonLoading, IonHeader } from "@ionic/react";
import * as firebase from "firebase/app";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import Footer from "./Footer";
import Header from "./Header";
import ImpersonateHeader from "./ImpersonateHeader";
import SaveFooter from "./SaveFooter";
import Status from "../components/NavigationMenu";
import { RootState } from "store";
import { useSelector } from "react-redux";
import NewModal from "components/NewModal";
import { useTranslation } from "react-i18next";

interface Props {
  back?: () => void;
  children: any;
  disabled?: boolean;
  save?: () => void;
  handleShowModal?: () => {};
  testId?: string;
  didSomethingChange?: boolean;
  loading?: boolean;
  loadingMessage?: string;
}

const PageLayoutComponent: React.FC<Props> = ({
  back,
  children,
  disabled,
  save,
  testId,
  didSomethingChange = true,
  loading,
  loadingMessage,
}) => {
  const [showModal, setShowModal] = useState(false);
  const { emailImpersonated, isAuthenticated } = useSelector(
    ({ authentication }: RootState) => ({
      isAuthenticated: Boolean(authentication?.user),
      emailImpersonated: authentication.originalImpersonatorUser
        ? authentication.user?.email
        : "",
    })
  );
  const handleCloseModal = (leave?: boolean) => {
    setShowModal(false);
    if (leave) {
      firebase.analytics().logEvent("back_no_save", {});
      FirebaseAnalytics.logEvent("back_no_save", {});
      back!();
    }
  };
  const backButtonPress = () =>
    save && didSomethingChange ? setShowModal(true) : back!();
  const { t } = useTranslation();
  return (
    <IonPage id="main" className={emailImpersonated ? "is-impersonating" : ""}>
      {emailImpersonated ? (
        <ImpersonateHeader email={emailImpersonated} />
      ) : null}
      <IonContent className="mx-auto my-0">
        <IonHeader className="sticky top-0 left-0 right-0 after:hidden z-20">
          <Header />
        </IonHeader>
        {isAuthenticated && <Status />}
        {loading ? <IonLoading isOpen message={loadingMessage} /> : null}
        <main
          data-testid={testId}
          className="max-w-5xl mx-auto my-0 min-h-full bg-white flex flex-col justify-between items-center"
        >
          <div className="w-full h-full min-h-full max-w-3xl mb-8">
            <div className="my-0 px-8 md:px-0 lg:px-0">
              <div className="max-w-3xl">{children}</div>
            </div>
            <NewModal
              open={showModal && didSomethingChange}
              onClose={() => setShowModal(false)}
              onOk={handleCloseModal}
              title={t("generic:unsavedModal.title")}
              description={t("generic:unsavedModal.description")}
              okText={t("generic:unsavedModal.ok")}
              cancelText={t("generic:unsavedModal.cancel")}
            />
          </div>
          <div className="w-full">
            {save ? (
              <SaveFooter
                back={() => backButtonPress()}
                disabled={disabled || !didSomethingChange}
                save={save}
              />
            ) : null}
            <Footer />
          </div>
        </main>
      </IonContent>
    </IonPage>
  );
};

export default PageLayoutComponent;
