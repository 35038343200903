import {
  DataCap,
  Dictionary,
  StreamingConfig,
  StreamingServicesWithConfig,
  UIServiceState,
  UserToken,
  ValidationError,
} from "../model";
import React, { useRef, useEffect, useMemo } from "react";
import { Store } from "redux";
import { getName } from "country-list";
import CountryCodes from "../pages/streaming/CountryCodes";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import _ from "lodash";
import errorsMap from "./errorsMap.jsonc";
import { emailRegex } from "./regexValidators";
// import AppConfig, {LOGDNA_INGESTION_KEY} from "../AppConfig";
// import Logdna from "@logdna/browser";
import { isPlatform } from "@ionic/react";
export type ErrorText = { en: string };
export type ErrorMap = {
  server: Dictionary<ErrorText>;
  ui: Dictionary<ErrorText>;
};

interface handleErrorProps {
  error: any;
  valueMap?: Record<string, string>;
  errorsMapObject?: ErrorMap;
  fallback?: string;
  asObject?: boolean;
}

export const isMobile = !isPlatform("mobileweb") && !isPlatform("desktop");

export function handleError(props: handleErrorProps) {
  const result = _handleError(props);
  // logdna.log(result);
  return result;
}
export function _handleError({
  error,
  fallback,
  valueMap,
  errorsMapObject = errorsMap, // only used for testing,
  asObject = false,
}: handleErrorProps) {
  if (error?.response) {
    // axios error
    const { status, data } = error.response;
    if (status === 422 && !data.unique_id) {
      const parsedErrors = parseError(data.detail, valueMap);
      if (asObject) {
        return { code: 422, message: `${parsedErrors[0]}`, fallback } as any;
      }
      return undefined;
      // return parsedErrors[0] as string;
    } else {
      if (data.unique_id) {
        if (asObject) {
          return {
            code: data.unique_id,
            message: errorsMapObject.server[data.unique_id]?.en,
            fallback: fallback,
          } as any;
        }
        return `Error ${data.unique_id}: ${
          errorsMapObject.server[data.unique_id]?.en || fallback
        }`;
      }
      return (data.detail || data.error || data.message) as string;
    }
  }
}

export function parseError(
  errors: ValidationError[],
  valueMap?: Record<string, string>
) {
  // error handling has different use cases:
  // - notification message: one line message to show the user
  // - single form message: password reset, enable internet pause, login
  // - complex form: multiple inputs -> display an error below each input
  // To handle all those cases, we should return an array of messages
  // But we don't have any complex forms for now.
  // We will instead return a single string
  return errors.map(({ loc, msg, type }) => {
    const field = loc[loc.length - 1];
    let fieldName = "";
    if (field && valueMap && valueMap[field]) {
      fieldName = valueMap[field]! + " ";
    }
    return `${fieldName}Error: ${msg}`;
  });
}
export const getFlag = (code: string) => {
  const country = CountryCodes[code];
  return country ? <country.flag /> : <></>;
};

/* eslint-disable import/prefer-default-export */
const containsADigit = /(?=.*[0-9])/;
const containsUpperCase = /(?=.*[A-Z])/;
const containsLowerCase = /(?=.*[a-z])/;
const containsSymbol = /(?=.*[^A-Za-z0-9])/;

export const validateEmail = (email: string): boolean =>
  emailRegex.test(email.toLowerCase());

export function verifyPassword(password: string) {
  const result = {
    isBigEnough: password.length >= 8,
    containsDigit: containsADigit.test(password),
    containsUpperCase: containsUpperCase.test(password),
    containsLowerCase: containsLowerCase.test(password),
    containsSymbol: containsSymbol.test(password),
  };

  const isValid =
    result.isBigEnough &&
    result.containsDigit &&
    result.containsUpperCase &&
    result.containsLowerCase &&
    result.containsSymbol;

  return {
    isValid,
    ...result,
  };
}

export const getSearchWithoutDecoding = (search: string, key: string) => {
  const keyValue = search
    .substring(1)
    .split("&")
    .map((entry) => entry.split("="))
    .filter((entries) => entries[0] === key)
    .map((a) => a[1]);
  if (keyValue.length) {
    return keyValue[0];
  } else {
    return "";
  }
};

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;
/**
 * Utility to avoid try catch hell.
 * @param promise
 *
 * @example
 * const c = await tryCatch(fetch("/all"));
 * if(c.error){
 *   setError(c.error)
 * }else{
 *   const result = c.result;
 * }
 * @description The type signature doesn't allow accessing the result without first handling
 * the error. Yes, it's annoying but a good practice to always handle errors !
 * Destructiring doesn't work because type signatures are evaluated at that point and not updated, Typescript
 * errors.
 */
export async function tryCatch<T>(
  promise: Promise<T>
): Promise<
  | { error: Error & { response: any } }
  | { result: ThenArg<typeof promise>; error: undefined }
> {
  try {
    const result = await promise;
    return { result, error: undefined };
  } catch (error: any) {
    return { error };
  }
}

interface FakeUserOptions {
  admin?: boolean;
}
export function _fakeUser(store: Store, options?: FakeUserOptions) {
  const user: UserToken = {
    bearer: "FAKE TEST TOKEN",
    exp: 343222,
    renew: 2000,
    email: "mail@gmail.com",
  };
  if (options?.admin) {
    user.admin = true;
  }
  store.dispatch.authentication.updateUserToken(user);
}

/**
 * A function that checks if a setting was set to a given state.
 * The only way to know this state is by polling the API. And this is a function that eases performing this check
 * and update ui accordingly
 *
 * @param callApi: Promise The api method that get the setting we want to check.
 * @param expectCallback Function to execute on the result of the promise to check if the setting was applied or not
 * @param updateUIIndicator a function that's called with UIServiceState depending on the expected result (Done! or Failed!)
 * @param retryCount the number of times we should call the API to check for the seeing
 * @param pollInterval the amount of time in ms to wait between 2 api calls
 */
export function createPollAndRefresh<T>(
  callApi: () => Promise<T>,
  expectCallback: (arg0: T) => boolean,
  updateUIIndicator: (arg0: UIServiceState, arg1?: T) => any,
  retryCount: number,
  pollInterval: number = 1500
) {
  updateUIIndicator("Updating");
  return callApi().then((result) => {
    const resultExpected = expectCallback(result);
    if (resultExpected) {
      updateUIIndicator("Done!", result);
    } else {
      if (retryCount > 0) {
        setTimeout(
          () =>
            createPollAndRefresh(
              callApi,
              expectCallback,
              updateUIIndicator,
              retryCount - 1,
              pollInterval
            ),
          pollInterval
        );
      } else {
        updateUIIndicator("Failed!", result);
      }
    }
  });
}

export function useFirstRender() {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
}

export function toUTCTimestampFromMidnight(
  hours: number,
  minutes: number,
  isPM: boolean
) {
  // offset = UTC - Local Time
  if (!isPM && (hours < 6 || hours === 12)) {
    if (hours < 6) {
      const utcMinutesToReturn = minutes + (hours + 24) * 60;
      return utcMinutesToReturn;
    } else {
      const utcMinutesToReturn = minutes + (hours + 12) * 60;
      return utcMinutesToReturn;
    }
  } else {
    const utcMinutesToReturn =
      (hours % 12) * 60 + minutes + (isPM ? 12 * 60 : 0);
    return utcMinutesToReturn;
  }
}
/**
 * retrieve the current time in hours, minutes and AM/PM from a number of minutes from midnight.
 * @param timestamp unix timestamp
 * @param offset timezone offset in minutes
 * @param shouldRound whether or not to round minutes to multiples of 5
 */
export function getLocalTimeFromUTCMinutesFromMidnight(
  minutes: number,
  shouldRound: boolean = false
) {
  if (minutes >= 60) {
    const hours = Math.round(minutes / 60);
    const hoursInMinutes = hours * 60;
    const minutesToReturn = Math.round(minutes - hoursInMinutes);
    return {
      hours: Math.round(hours % 12),
      minutes: minutesToReturn,
      isPM: hours > 12 && minutes < 1440,
    };
  } else {
    return {
      hours: 0, //Math.round(hours % 12),
      minutes: Math.round(minutes),
      isPM: false,
    };
  }
}

/**
 * Get profile icon and display an avatar if it's not available
 * TODO: remove it after adding all icons
 * @param image
 */
export function getIcon(image?: string) {
  if (!image || !image.startsWith("http")) {
    return "/assets/profiles/placeholder_64.png";
  } else {
    return image;
  }
}

// export function getStreamingServiceUrl(service: StreamingServices): string {
//   const servicesMap: Record<string, string> = {
//     Netflix: "https://netflix.com",
//     AcornTV: "https://acorn.tv",
//     DAZN: "https://dazn.com",
//     Dplay: "https://dplay.com",
//     DisneyPlus: "https://disneyplus.com",
//     Amazon: "https://primevideo.com",
//     Zattoo: "https://zattoo.com",
//   };

//   return servicesMap[service.name] || "#";
// }

export function useStreamingServicesWithConfig() {
  const { servicesWithoutConfig, config } = useSelector((state: RootState) => {
    const { adapter } = state.adapter;
    return {
      servicesWithoutConfig:
        state.streaming.adapterServicesMap[adapter!.id] || [],
      config: state.streaming.adapterConfigMap[adapter!.id],
    };
  });

  const services = useMemo(() => {
    let actualConfig: StreamingConfig[] = [];
    if (!servicesWithoutConfig) return [];
    if (config) {
      actualConfig = config;
    }
    return servicesWithoutConfig.reduce<StreamingServicesWithConfig[]>(
      (acc, service) => {
        const serviceConfig = actualConfig.find(({ id }) => id === service.id);
        acc.push({ ...service, configuration: serviceConfig || null });
        return acc;
      },
      []
    );
  }, [config, servicesWithoutConfig]);
  return services;
}

export function classNames(...classes: Array<string | boolean>) {
  return classes.filter(Boolean).join(" ");
}

export function daysInMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}

export type ArgumentTypes<F extends Function> = F extends (
  ...args: infer A
) => any
  ? A
  : never;

export function insertUrlParam(key: string, value: string) {
  let searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);
  let newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname +
    "?" +
    searchParams.toString();
  window.history.pushState({ path: newurl }, "", newurl);
}
export const unitsMap = {
  GB: 1024,
};

export const getDataCapDisplayValues: (
  dataCap?: DataCap
) => {
  unit: "GB";
  cap: number;
  day: number;
  capOriginal: number;
} = (dataCap) => {
  const day = dataCap?.day || 0;
  let cap = dataCap?.cap || 0;
  cap = cap / unitsMap.GB;
  return { unit: "GB", capOriginal: cap, cap: _.round(cap, 1), day };
};

export function getCountryName(countryCode: string) {
  if (countryCode === "GB") {
    return "United Kingdom";
  }
  return getName(countryCode);
}

export function getReasonString(reason: string | undefined): string {
  switch (reason) {
    case "VPN":
      return "subscribe:vpnReason";
    case "PHERO_VPN":
      return "subscribe:pheroVpnReason";
    case "STREAMING_RELOCATION":
      return "subscribe:streamingReason";
    case "PROFILES":
      return "subscribe:profilesReason";
    case "DATA":
      return "subscribe:dataReason";
    case "DEVICES":
      return "subscribe:deviceReason";
    default:
      return "subscribe:noReason";
  }
}

// export const logdna = {
//   init() {
//     if (AppConfig.env === "prod") {
//       Logdna.init(LOGDNA_INGESTION_KEY!);
//     }
//   },
//   addContext(...props: Parameters<typeof Logdna.addContext>) {
//     if (AppConfig.env === "prod") {
//       Logdna.addContext(...props);
//     } else {
//       console.log(props);
//     }
//   },
//   log(...props: Parameters<typeof Logdna.log>) {
//     if (AppConfig.env === "prod") {
//       Logdna.log(props);
//     } else {
//       console.log(props);
//     }
//   },
// };
