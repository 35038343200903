import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { IonToast } from "@ionic/react";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { Subscription } from "model";
import PageLayout from "../../layouts/PageLayout";
import { useSelector } from "react-redux";
import Title from "components/Title";
import SettingsRow from "components/SettingsRow";
import Routers from "./Routers";
import Codes from "./Codes";
import Invoices from "./Invoices";
import api from "../../api";
import { tryCatch } from "../../utils";

interface Props {
  subscription?: Subscription;
}

const AccountContainer: React.FC<Props> = ({ subscription }) => {
  const { search } = useLocation();
  const { t } = useTranslation();
  const [toastMessage, setToastMessage] = useState<string>("");

  const { user } = useSelector((state: RootState) => ({
    user: state.authentication.user!,
  }));

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "account_container" });
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(search);
    const reason = urlParams.get("reason");
    const session_id = urlParams.get("session_id");

    if (reason) {
      if (reason === "code_applied") {
        setToastMessage("Code applied!");
      } else if (reason === "code_claimed") {
        setToastMessage("Code claimed!");
      } else if (reason === "plan_updated") {
        setToastMessage("Plan updated!");
      } else if (reason === "plan_updated_no_payment_method") {
        setToastMessage(
          "Plan updated. Please add a payment method to ensure uninterrupted service!"
        );
      } else if (reason === "subscription_canceled") {
        setToastMessage("Subscription canceled!");
      } else if (reason === "subscription_renewed") {
        setToastMessage("Subscription renewed!");
      }
    } else if (session_id) {
      handleSessionStatus(session_id);
    }
  }, [search]);

  const handleSessionStatus = async (session_id: string) => {
    const sessionStatus = await tryCatch(
      api.subscription.getSessionStatus(session_id)
    );
    if (sessionStatus.error) {
      console.warn(sessionStatus.error.message);
    } else {
      if (sessionStatus.result.data.status === "complete") {
        const nordVPNSpecialDealProduct = process.env
          .REACT_APP_NORDVPN_SPECIAL_DEAL_PRODUCT_ID!;
        if (sessionStatus.result.data.mode === "subscription") {
          if (
            sessionStatus.result.data.products.includes(
              nordVPNSpecialDealProduct
            )
          ) {
            setToastMessage(
              "Subscription created and NordVPN code purchased - you will receive an email containing your code shortly!"
            );
          } else {
            setToastMessage("Subscription created!");
          }
        } else if (sessionStatus.result.data.mode === "payment") {
          if (
            sessionStatus.result.data.products.includes(
              nordVPNSpecialDealProduct
            )
          ) {
            setToastMessage(
              "NordVPN code purchased - you will receive an email containing your code shortly!"
            );
          }
        } else if (sessionStatus.result.data.mode === "setup") {
          setToastMessage("Payment method updated!");
        }
      } else if (sessionStatus.result.data.status === "expired") {
        setToastMessage("Checkout session expired!");
      }
    }
  };

  return (
    <PageLayout testId="account-screen">
      <Title title={t("settings:accountSettings")} className="mt-7" />
      <dl className="divide-y divide-gray-200">
        <SettingsRow
          title={t("account:password")}
          updateText={t("account:update")}
          value={t("account:passwordPlaceholder")}
          updateLink="/account/edit"
        />
      </dl>
      <Routers />
      <Codes />
      <Invoices />
      <IonToast
        color="success"
        isOpen={!!toastMessage.length}
        onDidDismiss={() => setToastMessage("")}
        message={toastMessage}
        duration={5000}
      />
    </PageLayout>
  );
};

export default AccountContainer;
