
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Editing the ",
  "titleP2": " GHz network",
  "guestTitle": "Edit Guest Network settings",
                                                                                    
                                    
  "defaultError": "Error configuring network settings",
  "wifi": {
    "label": "Wireless Network name",
    "placeholder": "",
    "alphanum": "WiFi name has the following requirements:\n",
    "alphanum2": "-  Must be between 2 and 32 characters long\n",
    "alphanum3": "-  Cannot start with a space, !, #, or ;\n",
    "alphanum4": "-  Cannot end with a space\n",
    "alphanum5": "-  Cannot contain any of the following ? \" $ \\ ] [ +\n",
    "required": "WiFi name is required"
  },
  "password": {
    "label": "Password",
    "placeholder": "",
    "required": "Password is required",
    "alphanum": "Password must consist of letters, numbers, and/or any of the symbols:\n  ! @ # $ % ^ & * - = ~ ` _ + ( ) [ ] { } < > ; : ' \" | , . \\ / ?",
    "min": "Password must be at least 8 characters and less than 63"
  },
  "onboarding": {
    "title": "Create your primary wireless network",
    "paragraph": "lorem ipsum",
    "okButton": "Go"
  },
  "channel": {
    "label": "Wireless Channel",
    "low_2Ghz": "Low 1",
    "high_2Ghz": "High 11",
    "default_2Ghz": "Medium (Default) 6",
    "low_5Ghz": "Low (Default) 36",
    "high_5Ghz": "High 149",
    "default_5Ghz": "Medium  52",
  }
}