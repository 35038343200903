import React, { useState, useEffect, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import PageLayout from "../../layouts/PageLayout";
import { useTranslation } from "react-i18next";
import Title from "components/Title";

import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";

import api from "../../api";
import { tryCatch } from "../../utils";

interface Props {}

export const PaymentMethodContainer: React.FC<Props> = () => {
  const { subscriptionId } = useParams<{ subscriptionId: string }>();
  const history = useHistory();
  const [stripeKey, setStripeKey] = useState<any>(null);
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [clientSecret, setClientSecret] = useState<string>("");
  const { t } = useTranslation("paymentMethod");

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "payment_method" });

    const getConfiguration = async () => {
      const configRes = await tryCatch(api.subscription.getConfig());
      if (configRes.error) {
        console.warn(configRes.error.message);
      } else {
        if (configRes.result.data.publishable_key) {
          setStripeKey(configRes.result.data.publishable_key);
        }
      }
    };
    getConfiguration();
  }, []);

  useEffect(() => {
    const getCheckoutSession = async () => {
      setClientSecret("");

      const apiResult = await tryCatch(
        api.subscription.createPaymentMethodUpdateCheckoutSession(
          subscriptionId
        )
      );
      if (apiResult.error) {
        console.warn(apiResult.error.message);
      } else {
        setClientSecret(apiResult.result.data.client_secret);
      }
    };
    getCheckoutSession();
  }, [subscriptionId]);

  useEffect(() => {
    if (stripeKey) {
      let newStripePromise = loadStripe(stripeKey);
      setStripePromise(newStripePromise);
    }
  }, [stripeKey]);

  return (
    <PageLayout back={history.goBack}>
      <Title title={t("title")} className="mb-2" />
      {stripePromise && clientSecret && (
        <div style={styles.formContainer}>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </div>
      )}
    </PageLayout>
  );
};

const styles = {
  formContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    gap: "10px",
  },
};

export default PaymentMethodContainer;
