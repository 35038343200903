import { DeviceDetails, ProfileDetails, UIServiceState } from "../model";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  playCircle,
  pauseCircle,
  reloadCircle,
  checkmarkCircle,
  closeCircle,
} from "ionicons/icons";
import { IonIcon, IonToast } from "@ionic/react";
import { createPollAndRefresh, tryCatch, useFirstRender } from "../utils";
import api from "../api";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../store";
import NewModal from "./NewModal";

interface Props {
  id: string;
  type: "device" | "profile";
  pauseState: number | undefined;
  onSuccess?: () => any;
  onFailure?: () => any;
  pauseDuration?: number;
  name: string;
}
const fontSize = 26;

const InternetPause: React.FC<Props> = ({
  type,
  pauseState: initialPauseState,
  pauseDuration = -1,
  id,
  name,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { isAdapterOnline } = useSelector((state: RootState) => ({
    isAdapterOnline: state.adapter.adapter?.services.online.state,
  }));
  const disabled = useMemo(() => !isAdapterOnline, [isAdapterOnline]);
  const [uiState, setUIState] = useState<UIServiceState>();
  const [pauseState, setPauseState] = useState<Props["pauseState"]>(
    initialPauseState
  );
  const [notification, setNotification] = useState<
    | { isOpen: true; message: string; type: "danger" | "success" }
    | { isOpen: false }
  >({ isOpen: false });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const onInternetPauseClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);
  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);
  const toggleInternetPause = useCallback(async () => {
    setIsModalOpen(false);
    if (uiState === "Updating") {
      return;
    } else {
      setUIState("Updating");
      const wantedState = pauseState !== 0 ? 0 : pauseDuration;

      const setUIStateCallback = (newUiState: UIServiceState) => {
        setUIState(newUiState);
        const message =
          wantedState !== 0
            ? `Enabling Internet Pause`
            : `Disabling Internet Pause`;
        if (newUiState === "Failed!") {
          setNotification({
            isOpen: true,
            message: `${message} failed!`,
            type: "danger",
          });
        } else if (newUiState === "Done!") {
          setPauseState(wantedState);
          setNotification({
            isOpen: true,
            message: `${message} was successful!`,
            type: "success",
          });
        }
      };
      if (type === "device") {
        // pause internet
        const setDeviceSettingResult = await tryCatch(
          api.device.setDeviceSettings({
            id,
            service: "internet-pause",
            setting: wantedState,
          })
        );

        if (setDeviceSettingResult.error) {
          setUIStateCallback("Failed!");
        } else {
          const getDevice = () =>
            dispatch.device.getDevice(id).then((res) => res[0]!);

          const expectCallback = (device: DeviceDetails) =>
            device.cfg?.["internet-pause"]?.state === wantedState;

          createPollAndRefresh<DeviceDetails>(
            getDevice,
            expectCallback,
            setUIStateCallback,
            5
          );
        }
      } else {
        const setDeviceSettingResult = await tryCatch(
          api.profile.setProfileSetting(id, {
            "internet-pause": wantedState,
          })
        );

        if (setDeviceSettingResult.error) {
          setUIStateCallback("Failed!");
        } else {
          const getProfile = () =>
            dispatch.profile
              .getProfileDetails(id)
              .then((profiles) => profiles[0]!)
              .catch((error) => undefined);

          const expectCallback = (profile: ProfileDetails | undefined) =>
            profile?.settings["internet-pause"] === wantedState;
          createPollAndRefresh<ProfileDetails | undefined>(
            getProfile,
            expectCallback,
            setUIStateCallback,
            5
          );
        }
      }
    }
  }, [
    dispatch.device,
    dispatch.profile,
    id,
    pauseDuration,
    pauseState,
    type,
    uiState,
  ]);

  const firstRender = useFirstRender();
  const [
    shouldDisplayNotification,
    setShouldDisplayNotification,
  ] = useState<boolean>(false);
  useEffect(() => {
    if (firstRender) {
      return;
    }
    const newStatusState = uiState;
    if (uiState === "Done!" || uiState === "Failed!") {
      setShouldDisplayNotification(true);
      setTimeout(() => {
        if (uiState === newStatusState) {
          setShouldDisplayNotification(false);
        }
      }, 2000);
    }
  }, [uiState, firstRender]);

  const renderIcon = useCallback(() => {
    if (shouldDisplayNotification) {
      if (uiState === "Done!") {
        return (
          <IonIcon
            icon={checkmarkCircle}
            style={{ color: "green", fontSize }}
          />
        );
      } else if (uiState === "Failed!") {
        return (
          <IonIcon icon={closeCircle} style={{ color: "red", fontSize }} />
        );
      }
    }
    if (uiState === "Updating") {
      return (
        <IonIcon
          className="spin"
          style={{ fontSize }}
          icon={reloadCircle}
          data-testid="internet-pause-updating"
          title="internet-pause-updating"
        />
      );
    }
    if (pauseState === 0 || pauseState === undefined) {
      return (
        <IonIcon
          style={{ fontSize }}
          title="pause-internet"
          icon={pauseCircle}
        />
      );
    } else {
      return (
        <IonIcon
          style={{ fontSize }}
          icon={playCircle}
          data-testid="resume-internet"
        />
      );
    }
  }, [pauseState, shouldDisplayNotification, uiState]);
  return (
    <>
      <button
        type="button"
        className={`transition
      focus:outline-none focus:ring-2 focus:ring-offset-2 text-orange focus:ring-orange-500  unstyled status text-gr
    disabled:text-gray-400 disabled:cursor-not-allowed
      hover:scale-125
      `}
        onClick={onInternetPauseClick}
        title={
          disabled ? "disabled because router offline" : "internet-pause-button"
        }
        data-testid="internet-pause-button"
        disabled={disabled}
      >
        {renderIcon()}
        {notification.isOpen ? (
          <IonToast
            isOpen
            onDidDismiss={() => setNotification({ isOpen: false })}
            message={notification.message}
            color={notification.type}
            duration={2000}
          />
        ) : null}
      </button>
      <NewModal
        type="warning"
        open={isModalOpen}
        onClose={closeModal}
        onOk={toggleInternetPause}
        title="Internet Pause"
        description={`Are you sure you would like to ${
          pauseState === 0 ? "pause" : "re-enable"
        } internet connection for this ${type}?`}
        okText="Yes"
        cancelText="Cancel"
        okTestId="ip-ok"
      />
    </>
  );
};

export default InternetPause;
