import React, { useState, useEffect } from "react";
import http from "axios";
import config from "../AppConfig";
import { useTranslation } from "react-i18next";
import NavigationLink from "./NavigationLink";
import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import { chevronDown } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import AppConfig from "../AppConfig";

interface HSResponse {
  articles: {
    page: number;
    pages: number;
    count: number;
    items: HSArticle[];
  };
}

interface HSArticle {
  id: string;
  siteId: string;
  collectionId: string;
  categoryIds: string[];
  slug: string;
  name: string;
  preview: string;
  url: string;
  docsUrl: string;
  number: number;
  status: string;
  visibility: string;
  updatedAt: string; // ZonedDateTime,
  hasDraft: boolean;
  lastPublishedAt: string; // ZonedDateTime
}

interface Props {
  filter?: string;
}

interface ArticlesWithCategories {
  gettingStarted: {
    title: string;
    articles: HSArticle[];
  };
  vpnProtection: {
    title: string;
    articles: HSArticle[];
  };
  network: {
    title: string;
    articles: HSArticle[];
  };
  streaming: {
    title: string;
    articles: HSArticle[];
  };
}

const FAQComponent: React.FC<Props> = ({ filter = "*" }) => {
  const [articles, setArticles] = useState<HSArticle[]>([]);
  const { t } = useTranslation();
  useEffect(() => {
    const url = `${config.HS_URL}?page=1&query=${filter}&collectionId=${config.HS_COL}`;
    const headers = { Authorization: config.HS_AUTH };
    http
      .get<HSResponse>(url, { headers })
      .then((response) => setArticles(response?.data?.articles?.items || []));
  }, [filter]);

  const articlesWithCategories: ArticlesWithCategories = {
    gettingStarted: {
      title: "Getting Started",
      articles: [],
    },
    vpnProtection: {
      title: "VPN Protection",
      articles: [],
    },
    network: {
      title: "Network",
      articles: [],
    },
    streaming: {
      title: "Streaming",
      articles: [],
    },
  };

  const CATEGORIES_IDS = {
    GETTING_STARTED: ["5afad13b2c7d3a640ed6d8b9", "5af851ed2c7d3a3f981f881c"],
    VPN_PROTECTION: ["5afd9a8a2c7d3a2f90119979"],
    NETWORK: ["5afd9ac00428635ba8b2689b"],
    STREAMING: ["5afd9ad80428635ba8b2689d"],
  };

  articles.forEach((article) => {
    if (CATEGORIES_IDS.GETTING_STARTED.includes(article.categoryIds[0])) {
      articlesWithCategories.gettingStarted.articles.push(article);
    }
    if (article.categoryIds[0] === CATEGORIES_IDS.VPN_PROTECTION[0]) {
      articlesWithCategories.vpnProtection.articles.push(article);
    }
    if (article.categoryIds[0] === CATEGORIES_IDS.NETWORK[0]) {
      articlesWithCategories.network.articles.push(article);
    }
    if (article.categoryIds[0] === CATEGORIES_IDS.STREAMING[0]) {
      articlesWithCategories.streaming.articles.push(article);
    }
  });

  return (
    <div className="max-w-7xl mx-auto my-16">
      <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
        <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">
          Frequently asked questions
        </h2>

        {articles.length > 0 &&
          Object.values(articlesWithCategories).map((category) => (
            <>
              <h3 className="mt-16 text-2xl text-gray-900 font-bold">
                {category.title}
              </h3>
              <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                {category.articles.map((article: HSArticle) => (
                  <Disclosure as="div" key={article.id} className="pt-6">
                    {({ open }) => (
                      <>
                        <dt className="text-lg">
                          <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                            <span className="font-medium text-gray-900">
                              {article.name}
                            </span>
                            <span className="ml-6 h-7 flex items-center">
                              <IonIcon
                                icon={chevronDown}
                                className={classNames(
                                  open ? "-rotate-180" : "rotate-0",
                                  "h-6 w-6 transform"
                                )}
                                aria-hidden="true"
                              />
                            </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <p className="text-base text-gray-500">
                            {article.preview}
                          </p>
                          <NavigationLink
                            isOuter
                            className="small has-icon"
                            title="Read more"
                            to={article.url}
                          />
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </dl>
            </>
          ))}

        {articles.length > 0 && (
          <NavigationLink
            isOuter
            className="mt-16 small has-icon"
            title={t("securitySettings:faq.visitFullBase")}
            to={AppConfig.SUPPORT_URL!}
          />
        )}
      </div>
    </div>
  );
};
export default FAQComponent;
