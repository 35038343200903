import { createModel } from "@rematch/core";
import { RootModel } from "./models";
import api from "../api";
import { handleError, tryCatch } from "../utils";
import { DeviceType } from "../api/device";
import { DeviceDetails, Dictionary } from "model";
import _ from "lodash";
import i18n from "utils/i18n";

export interface DeviceState {
  types: DeviceType[];
  deviceIds: string[];
  devices: Dictionary<DeviceDetails>;
}

const initialState: DeviceState = {
  types: [],
  deviceIds: [],
  devices: {},
};

export const device = createModel<RootModel>()({
  state: initialState, // initial state
  reducers: {
    _setDeviceTypes(state, types: DeviceType[]) {
      return { ...state, types };
    },
    _setDevice(state, device: DeviceDetails) {
      return {
        ...state,
        deviceIds: _.uniq([...state.deviceIds, device.id]),
        devices: {
          ...state.devices,
          [device.id]: device,
        },
      };
    },
    clearAll(state) {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async getDeviceTypes() {
      const res = await tryCatch(api.device.getDeviceTypes());
      if (res.error) {
        const fallback = i18n.t("generic:errors.loadingDeviceImagesMessage");
        dispatch.ui.setToast({
          type: "error",
          description: handleError({ error: res.error, fallback }) || fallback,
        });
      } else {
        const types = res.result.data;
        dispatch.device._setDeviceTypes(types);
      }
    },
    async getDevice(deviceId: string) {
      try {
        const devices = await api.device.getDevices(deviceId);
        if (devices.data.length > 0) {
          const device = devices.data[0];
          dispatch.device._setDevice(device);
          return [device];
        }
        return [];
      } catch (error) {
        console.log(`error getting device ${deviceId}`, error);
        return [];
      }
    },
    async getDevices(adapterOrProfileId: string) {
      try {
        const devices = await api.device.getDevices(adapterOrProfileId);
        devices.data.forEach((device) => dispatch.device._setDevice(device));
        return devices.data;
      } catch (error) {
        console.log(`error getting devices for ${adapterOrProfileId}`, error);
        return [];
      }
    },
  }),
});
