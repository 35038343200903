import React, { useEffect, useMemo, useState } from "react";
import { RootState } from "../../../store";
import { Link } from "react-router-dom";
import imagePlaceholder from "../../../assets/images/icons/placeholder_64.png";

import { UsageBar } from "../../../components";
import http from "axios";
import { DeviceDetails, Dictionary } from "../../../model";
import { useDeviceIcons } from "utils/hooks";
import { useSelector } from "react-redux";
import { useSetState } from "ahooks";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import NavigationLink from "components/NavigationLink";
import Loading from "components/Loading";

interface Props {}
interface State {
  devicesUsage: Dictionary<number>;
  loading: boolean;
}
const DevicesDisplay: React.FC<Props> = () => {
  const { t } = useTranslation();
  const [deviceData, setDeviceData] = useState<DeviceDetails[]>([]);

  const [state, setState] = useSetState<State>({
    devicesUsage: {},
    loading: true,
  });
  const { adapterId, dataCap } = useSelector(({ adapter }: RootState) => ({
    adapterId: adapter.adapter!.id,
    dataCap: adapter.adapter!.settings.datacap,
  }));
  useEffect(() => {
    if (adapterId) {
      http.get(`/v1/device/list?id=${adapterId}`).then(({ data }) => {
        setDeviceData(data);
      });
    }
  }, [adapterId, dataCap]); // eslint-disable-line

  // NOTE: This will need to change once icons are added
  const deviceTypes = useDeviceIcons();
  const getImage = (type: string) => {
    if (!deviceTypes) {
      return imagePlaceholder;
    } else {
      return (
        deviceTypes.find(({ name }) => name === type)?.icon || imagePlaceholder
      );
    }
  };

  const sortedDeviceData = useMemo(() => {
    return _.sortBy(deviceData, (device) => {
      return -1 * state.devicesUsage[device.id] || 0;
    });
  }, [deviceData, state.devicesUsage]);

  const total = useMemo(() => {
    return deviceData.reduce((acc, device) => {
      const newValue = state.devicesUsage[device.id] || 0;
      return acc + newValue;
    }, 0);
  }, [deviceData, state.devicesUsage]);
  return (
    <div className="devices module-large">
      <Title
        title={t("home:devicesTitle")}
        subtitle={t("home:devicesSubtitle")}
        link="/device/list"
      />
      <Loading loading={state.loading} />
      <ul data-testid="device-list" className="device-list list-unstyled">
        {sortedDeviceData.map((device) => (
          <Link
            key={device.id}
            to={`/device/${device.id}?tab=usage`}
            className="group flex items-center transition pt-5 pb-5 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 rounded-sm"
          >
            <div className="icon">
              <div className="image-container">
                <img
                  className="rounded-full"
                  src={getImage(device.type)}
                  alt=""
                />
              </div>
            </div>
            <div className="data group-hover:text-orange truncate">
              {device.name || "Unnamed device"}
              <UsageBar
                id={device.id}
                type="device"
                chartSpan={30}
                startFromBillingDate
                total={total}
                reportTotal={(deviceUsage: number) =>
                  setState({
                    devicesUsage: {
                      ...state.devicesUsage,
                      [device.id]: deviceUsage,
                    },
                    loading: false,
                  })
                }
              />
            </div>
          </Link>
        ))}
      </ul>
    </div>
  );
};

export default DevicesDisplay;
