import React, { useState, useEffect, useCallback } from "react";

import appConfig from "../../AppConfig";
import http from "axios";
import { PageLayout } from "../../layouts";
import { useHistory, useLocation } from "react-router";
import api from "api";
import { useDispatch } from "react-redux";
import { Dispatch } from "store";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import Input from "components/Input";
import Button from "components/Button";
import InformationMessage from "components/InformationMessage";
import NavigationLink from "components/NavigationLink";
import ErrorMessage from "components/ErrorMessage";
import { reload } from "ionicons/icons";

type WelcomeState =
  | {
      email?: string;
    }
  | undefined;

const WelcomeConfirmation: React.FC = () => {
  const { t } = useTranslation("resetPassword");
  const [email, setEmail] = useState<string>("");
  const [codeError, setCodeError] = useState<any>();
  const [code, setCode] = useState<string>("");
  const location = useLocation<WelcomeState>();
  const onCodeChange = useCallback((event) => {
    setCode(event.target.value);
  }, []);

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const email = search.get("email");
    if (email) {
      setEmail(email);
    }
  }, [location]);

  const submit = (form?: React.FormEvent<HTMLFormElement>) => {
    if (form) {
      form.preventDefault();
    }

    http
      .post<{ msg: string }>("/v1/auth/newcustomer", {
        email,
        callback: `${appConfig.DEEPLINK_HOST}/passwordReset?newCustomer=true`,
      })
      .catch((e) => {
        setCodeError(e);
      });
  };
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const onCodeSubmit = useCallback(async () => {
    try {
      const result = await api.auth.confirmCustomer(code);
      dispatch.authentication.updateUserToken(result.data);
      // fresh: reload everything in App.tsx
      history.push(`/home?fresh=good`);
    } catch (error) {
      setCodeError(error);
    }
  }, [code, dispatch.authentication, history]);
  return (
    <PageLayout>
      <div className="h-full">
        <div className="module-large">
          <Title title={t("confirmationTitle")} className="mb-2" />
          <InformationMessage text={t("confirmationMessage")} />
        </div>
        <div className="flex flex-row items-center">
          <Input
            type="text"
            id="token-code"
            className="mb-2"
            label={t("welcome.confirmationCodeLabel")}
            placeholder={t("welcome.confirmationCodePlaceholder")}
            inputProps={{
              value: code,
              onChange: onCodeChange,
            }}
            onButtonClick={() => submit()}
            buttonText={t("welcome.sendAgain")}
            buttonIcon={reload}
            buttonProps={{ "data-testid": "send-again", disabled: !email }}
          />
        </div>
        <ErrorMessage
          error={codeError}
          fallback="The code is either invalid or expired"
        />
        <Button
          data-testid="token-code-submit"
          onClick={onCodeSubmit}
          type="button"
          disabled={!code}
        >
          {t("welcome.submitCode")}
        </Button>
        <NavigationLink to="/" title={t("welcome.goBack")} />
      </div>
    </PageLayout>
  );
};

export default WelcomeConfirmation;
