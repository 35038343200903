import React, { useState } from "react";

import {
  AdapterDetails,
  DeviceDetails,
  DeviceServiceUpdate,
} from "../../../model";
import { ListRow, Toggle } from "../../../components";
import { ServiceStatus } from "../Details";
import WarningMessage from "../../../components/WarningMessage";
import { useTranslation } from "react-i18next";
import NewModal from "components/NewModal";

type ServiceKey = "vpn" | "adblocking" | "device-protection" | "internet-pause";

interface Props {
  device: DeviceDetails;
  adapterStatus: AdapterDetails["services"];
  status: ServiceStatus;
  update: (
    key: ServiceKey,
    value: {
      service: DeviceServiceUpdate;
      setting: boolean;
    }
  ) => void;
  isAdapterOnline: boolean;
  isVPNAdapterConnected: boolean;
}

interface SettingUpdate {
  service: ServiceKey;
  setting: boolean;
}

/**
 * /**
 * General
 * We want to give the user the possibility of bypassing settings at a device
 *    level (VPN, Ad Blocking).
 * So there are three states a setting can be in
 * S1. OFF/ON and Impossible to change: in that case we should display information why.
 * S2. ON and Possible to change
 * S3. OFF and possible to change
 * VPN And Ad Blocking
 * ===
 * Off in the router -> S3
 * On  in the router -> S1 | S2
 *
 * Device protection is not linked with a global setting
 *
 * For the async setting, we want to:
 * - display a spinner while the setting is not set in the adapter
 * - send a notification when the setting is set
 * - display the new state in the toggle
 ** **/
const SettingsComponent: React.FC<Props> = ({
  device,
  status,
  update,
  isAdapterOnline,
  adapterStatus,
  isVPNAdapterConnected,
}) => {
  const toggleSetting = (config: SettingUpdate) => {
    update(config.service, {
      service: config.service,
      setting: config.setting,
    });
  };
  const { t } = useTranslation("deviceOverview");
  const vpn = device.cfg?.vpn.state;
  const malware = device.cfg?.["device-protection"].state;
  const adBlock = device.cfg?.adblocking.state;

  const isPaused = device.cfg?.["internet-pause"]
    ? device.cfg["internet-pause"].state < 0 ||
      device.cfg["internet-pause"].state > Date.now() / 1000
    : false;

  const isVPNDown = adapterStatus.vpn.state === true && !isVPNAdapterConnected;
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      {isPaused ? (
        <WarningMessage
          text="Device internet is paused, so its settings can't be changed"
          className="mb-5"
        />
      ) : null}
      {!malware ? (
        <WarningMessage
          text="Device Protection is off, so all privacy hero features won't be applied to this device"
          className="mb-5"
        />
      ) : null}

      <div className="module-large">
        <NewModal
          type="warning"
          open={isOpen}
          onClose={() => setIsOpen(false)}
          onOk={() => {
            toggleSetting({ service: "device-protection", setting: false });
            setIsOpen(false);
          }}
          title="Disable device protection"
          description={`Are you sure you want to disable device protection for this device? all privacy hero features won't be applied after that!`}
          okText="Yes"
          cancelText="Cancel"
        />
        <ul className="data-list">
          <ListRow
            text={t("settings.malware")}
            disabled={isPaused || !isAdapterOnline}
            status={status["device-protection"]}
          >
            <Toggle
              id="malware"
              checked={Boolean(malware)}
              onChange={(value) => {
                if (!value) {
                  setIsOpen(true);
                } else {
                  toggleSetting({
                    service: "device-protection",
                    setting: true,
                  });
                }
              }}
              disabled={isPaused || !isAdapterOnline}
            />
          </ListRow>

          <ListRow
            text={t("settings.vpn")}
            subInfo={
              isVPNDown
                ? t("settings.vpnDown")
                : !isVPNAdapterConnected
                ? t("settings.adapterVPNOff")
                : vpn
                ? device.ext_ip.ip4
                : ""
            }
            disabled={isPaused || !isAdapterOnline || !malware}
            status={status.vpn}
          >
            <Toggle
              id="vpn"
              checked={Boolean(vpn)}
              onChange={(value) =>
                toggleSetting({ service: "vpn", setting: value })
              }
              disabled={isPaused || !isAdapterOnline || !malware}
            />
          </ListRow>
          {/* subInfo={malware ? '-3,240 attacks blocked' : ''} */}

          <ListRow
            text={t("settings.adBlocking")}
            subInfo={
              !adapterStatus.adblocking.state
                ? t("settings.adapterAdBlockingOff")
                : null
            }
            disabled={
              isPaused ||
              !isAdapterOnline ||
              !adapterStatus.adblocking.state ||
              !malware
            }
            status={status["ad-blocking"]}
          >
            <Toggle
              id="ad-block"
              checked={Boolean(adBlock)}
              onChange={(value) =>
                toggleSetting({ service: "adblocking", setting: value })
              }
              disabled={
                isPaused ||
                !isAdapterOnline ||
                !adapterStatus.adblocking.state ||
                !malware
              }
            />
          </ListRow>
        </ul>
      </div>
    </div>
  );
};

export default SettingsComponent;
