import React, { useCallback, useState } from "react";

import { ProfileDetails, UIServiceState } from "../../../model";
import { ListRow, Time, Toggle } from "../../../components";
import { Link } from "react-router-dom";
import { createPollAndRefresh, tryCatch } from "../../../utils";
import api from "../../../api";
import { SettingUpdate } from "../../../api/profile";
import { Dispatch, RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { ProfileTab } from "../Details";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import { IonIcon } from "@ionic/react";
import { chevronForward } from "ionicons/icons";

interface Props {
  profileId: string;
  setTab?: (tab: ProfileTab) => any;
}

type ProfileConfigServices = keyof ProfileDetails["settings"];

type ConfigurableSettings = ProfileConfigServices &
  ("adult-block" | "youtube-restricted" | "safesearch");

export const SettingsComponent: React.FC<Props> = ({ profileId }) => {
  const dispatch = useDispatch<Dispatch>();
  const { profile, isAdapterOffline } = useSelector((state: RootState) => ({
    profile: state.profile.profilesMap[profileId],
    isAdapterOffline: !state.adapter.adapter?.services.online.state,
  }));
  const { bedtime } = profile.settings;
  const [adultBlockStatus, setAdultBlockStatus] = useState<UIServiceState>();
  const [
    youtubeRestrictedStatus,
    setYoutubeRestrictedStatus,
  ] = useState<UIServiceState>();
  const [safeSearchStatus, setSafeSearchStatus] = useState<UIServiceState>();
  const [bedtimeStatus, setBedtimeStatus] = useState<UIServiceState>();

  const setStatus = useCallback(
    (setting: ConfigurableSettings, state: UIServiceState) => {
      if (setting === "adult-block") {
        setAdultBlockStatus(state);
      } else if (setting === "youtube-restricted") {
        setYoutubeRestrictedStatus(state);
      } else if (setting === "safesearch") {
        setSafeSearchStatus(state);
      }
    },
    []
  );
  const toggleBedtime = async () => {
    setBedtimeStatus("Updating");
    // toggle bedtime
    await _toggleBedtime({});
  };
  const _toggleBedtime = async ({ callback }: { callback?: () => void }) => {
    const bedTimeWantedState = !bedtime.enabled;

    const setBedTimeResult = await tryCatch(
      api.profile.setBedTime(profile.id, {
        ...bedtime,
        enabled: bedTimeWantedState,
      })
    );
    if (setBedTimeResult.error) {
      setBedtimeStatus("Failed!");
    } else {
      const getProfileDetails = () =>
        dispatch.profile
          .getProfileDetails(profile.id)
          .then((data) => data[0]!)
          .catch((error) => undefined);

      const expectCallback = (profile: ProfileDetails | undefined) =>
        profile?.settings.bedtime.enabled === bedTimeWantedState;

      createPollAndRefresh<ProfileDetails | undefined>(
        getProfileDetails,
        expectCallback,
        async (state) => {
          if (state !== "Updating") {
            setBedtimeStatus(state);
            if (callback) {
              callback();
            }
          }
        },
        10
      );
    }
  };

  const toggleSetting = async (setting: SettingUpdate) => {
    const settingKey = Object.keys(setting)[0] as ConfigurableSettings;
    setStatus(settingKey, "Updating");
    const wantedSettingState = setting?.[settingKey];
    const settingResult = await tryCatch(
      api.profile.setProfileSetting(profile.id, setting)
    );
    if (settingResult.error) {
      setStatus(settingKey, "Failed!");
    } else {
      const getProfileDetails = () =>
        dispatch.profile.getProfileDetails(profile.id).then((data) => data[0]!);

      const expectCallback = (profile: ProfileDetails) =>
        profile.settings[settingKey] === wantedSettingState;

      createPollAndRefresh<ProfileDetails>(
        getProfileDetails,
        expectCallback,
        (state) => setStatus(settingKey, state),
        10
      );
    }
  };

  const getDayDisplay = (schoolDay: boolean, t: (v: string) => void) => {
    const days = bedtime["school-nights"];
    const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    // Groups of consecutive days
    const groupings: string[][] = [];
    const lastGrouping = dayNames.reduce((group: string[], day, index) => {
      if (days[index] === schoolDay) {
        group.push(day);
        return group;
      }
      groupings.push(group);
      return [];
    }, []);
    // Combine last group with first group if both match school days
    if (days[0] === days[6] && days[0] === schoolDay && groupings.length > 0) {
      groupings[0]!.unshift(...lastGrouping);
    } else {
      groupings.push(lastGrouping);
    }
    const result = groupings
      .filter((group) => group.length)
      .map((group) => {
        if (group.length === 1) return group[0];
        if (group.length === 2) return group.join(", ");
        return `${group.shift()}-${group.pop()}`;
      })
      .join(", ");
    return result || "No Days";
  };

  const schoolInfo =
    bedtime.enabled && bedtime["school"] >= 0 ? "Ends at 6:00 AM" : undefined;
  const weekendInfo =
    bedtime.enabled && bedtime["other"] >= 0 ? "Ends at 6:00 AM" : undefined;
  const { t } = useTranslation();
  return (
    <div data-testid="profile-settings-tab">
      <div className="module-large">
        <Title
          className="px-4 mt-4"
          title={t("profileOverview:settings.bedtimeTitle")}
        />
        <ul className="data-list ">
          <ListRow
            text={t("profileOverview:settings.bedtime")}
            status={bedtimeStatus}
            disabled={isAdapterOffline}
          >
            <Toggle
              id="bedtime-toggle"
              checked={bedtime.enabled}
              onChange={() => toggleBedtime()}
              disabled={isAdapterOffline}
            />
          </ListRow>
          <ListRow
            className="focus-within:ring focus-within:ring-orange rounded-sm"
            disabled={!bedtime.enabled || isAdapterOffline}
            text={`${t(
              "profileOverview:settings.schoolNights"
            )} (${getDayDisplay(true, t)})`}
            subInfo={
              bedtime.enabled
                ? schoolInfo
                : t("profileOverview:settings.schoolNightsOffDescription")
            }
          >
            <Link
              to={`/profile/${profile.id}/school-nights`}
              data-testid="bedtime-school-link"
              tabIndex={!bedtime.enabled || isAdapterOffline ? -1 : undefined}
            >
              <span className="text-sm font-extralight text-gray-600 flex flex-row items-center justify-between">
                <span>
                  {bedtime["school"] >= 0 ? (
                    <Time
                      value={bedtime["school"] * 60}
                      timeOnly
                      testId="bedtime-time"
                    />
                  ) : (
                    t("profileOverview:settings.noBedtime")
                  )}
                </span>
                <IonIcon
                  icon={chevronForward}
                  className=" fill-current w-4 h-4"
                />
              </span>
            </Link>
          </ListRow>
          <ListRow
            className="focus-within:ring focus-within:ring-orange rounded-sm"
            disabled={!bedtime.enabled || isAdapterOffline}
            text={`${t("profileOverview:settings.weekends")} (${getDayDisplay(
              false,
              t
            )})`}
            subInfo={
              bedtime.enabled
                ? weekendInfo
                : t("profileOverview:settings.weekendsOffDescription")
            }
          >
            <Link
              to={`/profile/${profile.id}/weekends`}
              data-testid="bedtime-weekend-link"
              tabIndex={!bedtime.enabled || isAdapterOffline ? -1 : undefined}
            >
              <span className="text-sm font-extralight text-gray-600 flex flex-row items-center justify-between">
                <span>
                  {bedtime["other"] >= 0 ? (
                    <Time value={bedtime["other"] * 60} timeOnly />
                  ) : (
                    t("profileOverview:settings.noBedtime")
                  )}
                </span>
                <IonIcon
                  icon={chevronForward}
                  className=" fill-current w-4 h-4"
                />
              </span>
            </Link>
          </ListRow>
        </ul>
      </div>
      <div className="mt-8">
        <Title
          className="px-4"
          title={t("profileOverview:settings.safeSurf")}
        />
        <ul className="data-list">
          <ListRow
            text={t("profileOverview:settings.safeSearch")}
            status={safeSearchStatus}
            disabled={isAdapterOffline}
          >
            <Toggle
              id="internet"
              checked={profile.settings.safesearch}
              disabled={isAdapterOffline}
              onChange={(value) => toggleSetting({ safesearch: value })}
            />
          </ListRow>
          <ListRow
            text={t("profileOverview:settings.youtubeSafeSearch")}
            status={youtubeRestrictedStatus}
            disabled={isAdapterOffline}
          >
            <Toggle
              id="youtube"
              checked={profile.settings["youtube-restricted"]}
              disabled={isAdapterOffline}
              onChange={(value) =>
                toggleSetting({ "youtube-restricted": value })
              }
            />
          </ListRow>
          <ListRow
            text={t("profileOverview:settings.adultBlock")}
            status={adultBlockStatus}
            disabled={isAdapterOffline}
          >
            <Toggle
              disabled={isAdapterOffline}
              id="adult-content"
              checked={profile.settings["adult-block"]}
              onChange={(value) => toggleSetting({ "adult-block": value })}
            />
          </ListRow>
        </ul>
      </div>
    </div>
  );
};

export default SettingsComponent;
