import React, { useEffect, useState, useMemo } from "react";
import { useHistory, Link } from "react-router-dom";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { IonLoading } from "@ionic/react";
import classNames from "classnames";

import { ListRow, Toggle } from "../../components"; //Toggle
import PageLayout from "../../layouts/PageLayout";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../store";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import NewModal from "components/NewModal";

import { WIFI_BANDS } from "../../constants";

const PrimaryNetworkComponent: React.FC<{}> = () => {
  const history = useHistory();
  const { adapter } = useSelector((state: RootState) => ({
    adapter: state.adapter.adapter!,
  }));
  const [wifiStatuses, setWifiStatuses] = useState<any>("");
  const [wiFiStatusUpdating, setWiFiStatusUpdating] = useState<boolean>(false);
  const [
    wiFiModalWarningIsActive,
    setWiFiModalWarningIsActive,
  ] = useState<boolean>(false);
  const [
    wiFiModalWarningTargetBand,
    setWiFiModalWarningTargetBand,
  ] = useState<string>("");
  // const [state, setState] = useSetState<{ loading: boolean; error: string }>({
  //   loading: false,
  //   error: "",
  // });

  const formatWiFiSectionTitle = (title: string) => {
    return (
      <span>
        {title}
        <svg
          className="w-6 h-6 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
          ></path>
        </svg>
      </span>
    );
  };

  const formatWiFiMetaSection = (
    networkName: any,
    networkPassword: any,
    status: boolean
  ) => {
    return (
      <div className="mt-2 mb-2">
        <p>
          {t("primaryNetwork:advancedSsidRow")}: <b>{networkName}</b>
        </p>
        <p>
          {t("primaryNetwork:advancedPasswordRow")}: <b>{networkPassword}</b>
        </p>
        <p>
          {t("primaryNetwork:advancedStatusRow")}:
          <b
            className={classNames("font-semibold ml-1", {
              "text-rose-500": !status,
              "text-green-600": status,
            })}
          >
            {status ? "On" : "Off"}
          </b>
        </p>
      </div>
    );
  };

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "primary_network" });
  }, []);
  const { t } = useTranslation();
  const isAdapterOffline = !adapter.services.online.state;
  const dispatch = useDispatch<Dispatch>();

  const toggleWiFiNetwork = (isEnabled: boolean, band: string) => {
    dispatch.adapter
      .configureWifi({
        wifiConfig: {
          id: adapter.id,
          enabled: isEnabled,
          band,
        },
        setLoading: (loading: boolean) => {},
      })
      .then(() => {
        setWiFiStatusUpdating(true);
        setWifiStatuses(
          `5G-${adapter.settings.wifi.enabled}, 2.4G-${adapter.settings.wifi2.enabled}`
        );
      });
  };

  const onCancelWiFiDisableModalHandler = () => {
    setWiFiModalWarningIsActive(false);
  };
  const onWiFiDisableHandler = () => {
    setWiFiModalWarningIsActive(false);
    toggleWiFiNetwork(false, wiFiModalWarningTargetBand);
  };

  useMemo(() => {
    const newWiFiStatuses = `5G-${adapter.settings.wifi.enabled}, 2.4G-${adapter.settings.wifi2.enabled}`;
    if (newWiFiStatuses !== wifiStatuses) {
      setWiFiStatusUpdating(false);
      setWifiStatuses(newWiFiStatuses);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adapter.settings]);

  return (
    <PageLayout back={history.goBack}>
      {wiFiStatusUpdating ? <IonLoading isOpen message="Loading" /> : null}
      <div className="module">
        <div className="-mx-2 px-2">
          <Title title={t("primaryNetwork:sectionTitle")} />
          <ul className="data-list">
            <Link to="/settings/edit-network?network=5Ghz">
              <ListRow
                text={formatWiFiSectionTitle(
                  t("primaryNetwork:advanced5GnameRow", {
                    networkName: adapter.settings.wifi.ssid,
                  })
                )}
                subInfo={formatWiFiMetaSection(
                  adapter.settings.wifi.ssid,
                  adapter.settings.wifi.key,
                  adapter.settings.wifi.enabled
                )}
              >
                <Toggle
                  id="disable-data-cap"
                  disabled={isAdapterOffline}
                  checked={adapter.settings.wifi.enabled}
                  onChange={(isEnabled) => {
                    if (isEnabled) {
                      toggleWiFiNetwork(isEnabled, WIFI_BANDS["WIFI_5G"]);
                    } else {
                      setWiFiModalWarningIsActive(true);
                      setWiFiModalWarningTargetBand(WIFI_BANDS["WIFI_5G"]);
                    }
                  }}
                />
              </ListRow>
            </Link>
          </ul>
          <ul className="data-list">
            <Link to="/settings/edit-network?network=2.4Ghz">
              <ListRow
                text={formatWiFiSectionTitle(
                  t("primaryNetwork:advanced2GnameRow", {
                    networkName: adapter.settings.wifi2.ssid,
                  })
                )}
                subInfo={formatWiFiMetaSection(
                  adapter.settings.wifi2.ssid,
                  adapter.settings.wifi2.key,
                  adapter.settings.wifi2.enabled
                )}
              >
                <Toggle
                  id="disable-data-cap"
                  disabled={isAdapterOffline}
                  checked={adapter.settings.wifi2.enabled}
                  onChange={(isEnabled) => {
                    if (isEnabled) {
                      toggleWiFiNetwork(isEnabled, WIFI_BANDS["WIFI_2.4G"]);
                    } else {
                      setWiFiModalWarningIsActive(true);
                      setWiFiModalWarningTargetBand(WIFI_BANDS["WIFI_2.4G"]);
                    }
                  }}
                />
              </ListRow>
            </Link>
          </ul>
        </div>
      </div>
      <NewModal
        type="warning"
        open={wiFiModalWarningIsActive}
        onClose={onCancelWiFiDisableModalHandler}
        onOk={onWiFiDisableHandler}
        title={t("primaryNetwork:advancedDisableWiFiNotificationTitle")}
        description={t("primaryNetwork:advancedDisableWiFiNotificationCopy", {
          networkBand: wiFiModalWarningTargetBand,
        })}
        okText="Yes"
        cancelText="Cancel"
        okTestId="ip-ok"
      />
    </PageLayout>
  );
};

export default PrimaryNetworkComponent;
