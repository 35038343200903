
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Profiles",
  "myProfiles": "Created By Me",
  "bedtime": "Bedtime",
  "net": "Net",
  "delete": "Delete",
  "systemProfiles": "Default",
  "pressable": {
    "createNewProfile": "Create New Profile"
  },
  "default": "System Profiles"
}
