
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Code Redemption",
  "text": "Note: Code will be applied to your currently selected Privacy Hero device",
  "alreadySubscribed": "Please wait until your subscription has less than 350 days remaining before applying a code",
  "redemptionCode": "Redemption Code",
}
