
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "findAccount": {
    "title": "Find Account",
    "emailLabel": "Email",
    "emailPlaceholder": "Email",
    "submitButton": "Submit"
  },
  "accountDetails": {
    "title": "Account Details",
    "impersonateExplanation": "You are about to begin viewing this user's account as if you were them.",
    "passwordLabel": "Admin Password",
    "passwordPlaceholder": "Admin Password",
    "loginAs": "Login as",
    "emailLabel": "User Email",
    "emailPlaceholder": "Email",
    "modalTitle": "Login as",
    "ok": "OK",
    "cancel": "Cancel"
  },
  "header": {
    "endDescription": "You are about to end your session viewing the account of this user. Any unsaved changes will be lost.",
    "modalTitle": "End impersonation",
    "ok": "OK",
    "cancel": "Cancel",
    "endImpersonation": "End Impersonation"
  }
}
