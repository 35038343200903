import React, { useMemo } from "react";

import { Time } from "../../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";

interface Props {
  profileId: string;
}

const BedtimeDisplayComponent: React.FC<Props> = ({ profileId }) => {
  const profile = useSelector(
    (state: RootState) => state.profile.profilesMap[profileId]
  );
  const now = Date.now() / 60000;
  const dayStart = new global.Date().setHours(0, 0, 0, 0) / 60000;
  const currentSeconds = now - dayStart; // necessary for daylight savings

  const bedtimeStart = useMemo(() => {
    if (profile?.settings.bedtime.enabled) {
      const { bedtime } = profile.settings;
      const day = new global.Date().getDay();
      const isSchoolNight = bedtime["school-nights"][day];
      const delay = profile.settings["bedtime-delay"];
      let start = isSchoolNight ? bedtime["school"] : bedtime["other"];
      if (start >= 0) {
        if (start < 360) start += 1440; // Bedtime before 6 AM, add 24 hours
        return delay + start;
      }
    }
    return -1;
  }, [profile]);
  if (!profile) {
    return null;
  }

  if (bedtimeStart !== -1) {
    if (currentSeconds < bedtimeStart) {
      return (
        <span>
          Starts at&nbsp;
          <Time testId="bedtime-starts-at" value={bedtimeStart * 60} timeOnly />
        </span>
      );
    }
    return <span>Paused until 6:00 AM</span>;
  }
  return <></>;
};

export default BedtimeDisplayComponent;
