const timezones = [
  {
    timeZoneName: "Pacific/Niue",
    formatted: "-11:00 Niue Time - Niue",
    group: ["Pacific/Niue"],
  },
  {
    timeZoneName: "Pacific/Midway",
    formatted: "-11:00 Samoa Time - Midway",
    group: ["Pacific/Midway"],
  },
  {
    timeZoneName: "Pacific/Pago_Pago",
    formatted: "-11:00 Samoa Time - Pago Pago",
    group: ["Pacific/Pago_Pago"],
  },
  {
    timeZoneName: "Pacific/Rarotonga",
    formatted: "-10:00 Cook Islands Time - Rarotonga",
    group: ["Pacific/Rarotonga"],
  },
  {
    timeZoneName: "America/Adak",
    formatted: "-10:00 Hawaii-Aleutian Time - Adak",
    group: ["America/Adak"],
  },
  {
    timeZoneName: "Pacific/Honolulu",
    formatted: "-10:00 Hawaii-Aleutian Time - Honolulu",
    group: ["Pacific/Honolulu"],
  },
  {
    timeZoneName: "Pacific/Tahiti",
    formatted: "-10:00 Tahiti Time - Tahiti",
    group: ["Pacific/Tahiti"],
  },
  {
    timeZoneName: "Pacific/Marquesas",
    formatted: "-09:30 Marquesas Time - Marquesas",
    group: ["Pacific/Marquesas"],
  },
  {
    timeZoneName: "America/Anchorage",
    formatted: "-09:00 Alaska Time - Anchorage, Juneau",
    group: [
      "America/Anchorage",
      "America/Juneau",
      "America/Metlakatla",
      "America/Nome",
      "America/Sitka",
      "America/Yakutat",
    ],
  },
  {
    timeZoneName: "Pacific/Gambier",
    formatted: "-09:00 Gambier Time - Gambier",
    group: ["Pacific/Gambier"],
  },
  {
    timeZoneName: "America/Los_Angeles",
    formatted: "-08:00 Pacific Time - Los Angeles",
    group: ["America/Los_Angeles"],
  },
  {
    timeZoneName: "America/Tijuana",
    formatted: "-08:00 Pacific Time - Tijuana",
    group: ["America/Tijuana"],
  },
  {
    timeZoneName: "America/Vancouver",
    formatted: "-08:00 Pacific Time - Vancouver",
    group: ["America/Vancouver"],
  },
  {
    timeZoneName: "America/Whitehorse",
    formatted: "-08:00 Pacific Time - Whitehorse, Dawson",
    group: ["America/Dawson", "America/Whitehorse"],
  },
  {
    timeZoneName: "Pacific/Pitcairn",
    formatted: "-08:00 Pitcairn Time - Pitcairn",
    group: ["Pacific/Pitcairn"],
  },
  {
    timeZoneName: "America/Chihuahua",
    formatted: "-07:00 Mexican Pacific Time - Chihuahua, Mazatlan",
    group: ["America/Chihuahua", "America/Mazatlan", "America/Ojinaga"],
  },
  {
    timeZoneName: "America/Hermosillo",
    formatted: "-07:00 Mexican Pacific Time - Hermosillo",
    group: ["America/Hermosillo"],
  },
  {
    timeZoneName: "America/Creston",
    formatted: "-07:00 Mountain Time - Creston, Dawson Creek",
    group: ["America/Creston", "America/Dawson_Creek", "America/Fort_Nelson"],
  },
  {
    timeZoneName: "America/Denver",
    formatted: "-07:00 Mountain Time - Denver, Boise",
    group: ["America/Boise", "America/Denver"],
  },
  {
    timeZoneName: "America/Edmonton",
    formatted: "-07:00 Mountain Time - Edmonton, Yellowknife",
    group: [
      "America/Cambridge_Bay",
      "America/Edmonton",
      "America/Inuvik",
      "America/Yellowknife",
    ],
  },
  {
    timeZoneName: "America/Phoenix",
    formatted: "-07:00 Mountain Time - Phoenix",
    group: ["America/Phoenix"],
  },
  {
    timeZoneName: "America/Belize",
    formatted: "-06:00 Central Time - Belize",
    group: ["America/Belize"],
  },
  {
    timeZoneName: "America/Chicago",
    formatted: "-06:00 Central Time - Chicago, Center",
    group: [
      "America/Chicago",
      "America/Indiana/Knox",
      "America/Indiana/Tell_City",
      "America/Menominee",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
    ],
  },
  {
    timeZoneName: "America/Costa_Rica",
    formatted: "-06:00 Central Time - Costa Rica",
    group: ["America/Costa_Rica"],
  },
  {
    timeZoneName: "America/El_Salvador",
    formatted: "-06:00 Central Time - El Salvador",
    group: ["America/El_Salvador"],
  },
  {
    timeZoneName: "America/Guatemala",
    formatted: "-06:00 Central Time - Guatemala",
    group: ["America/Guatemala"],
  },
  {
    timeZoneName: "America/Managua",
    formatted: "-06:00 Central Time - Managua",
    group: ["America/Managua"],
  },
  {
    timeZoneName: "America/Mexico_City",
    formatted: "-06:00 Central Time - Mexico City, Monterrey",
    group: [
      "America/Bahia_Banderas",
      "America/Matamoros",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey",
    ],
  },
  {
    timeZoneName: "America/Regina",
    formatted: "-06:00 Central Time - Regina, Swift Current",
    group: ["America/Regina", "America/Swift_Current"],
  },
  {
    timeZoneName: "America/Tegucigalpa",
    formatted: "-06:00 Central Time - Tegucigalpa",
    group: ["America/Tegucigalpa"],
  },
  {
    timeZoneName: "America/Winnipeg",
    formatted: "-06:00 Central Time - Winnipeg, Rainy River",
    group: [
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Resolute",
      "America/Winnipeg",
    ],
  },
  {
    timeZoneName: "Pacific/Galapagos",
    formatted: "-06:00 Galapagos Time - Galapagos",
    group: ["Pacific/Galapagos"],
  },
  {
    timeZoneName: "America/Rio_Branco",
    formatted: "-05:00 Acre Time - Rio Branco, Eirunepe",
    group: ["America/Eirunepe", "America/Rio_Branco"],
  },
  {
    timeZoneName: "America/Bogota",
    formatted: "-05:00 Colombia Time - Bogota",
    group: ["America/Bogota"],
  },
  {
    timeZoneName: "America/Havana",
    formatted: "-05:00 Cuba Time - Havana",
    group: ["America/Havana"],
  },
  {
    timeZoneName: "Pacific/Easter",
    formatted: "-05:00 Easter Island Summer Time - Easter",
    group: ["Pacific/Easter"],
  },
  {
    timeZoneName: "America/Atikokan",
    formatted: "-05:00 Eastern Time - Atikokan",
    group: ["America/Atikokan"],
  },
  {
    timeZoneName: "America/Cancun",
    formatted: "-05:00 Eastern Time - Cancun",
    group: ["America/Cancun"],
  },
  {
    timeZoneName: "America/Cayman",
    formatted: "-05:00 Eastern Time - Cayman",
    group: ["America/Cayman"],
  },
  {
    timeZoneName: "America/Grand_Turk",
    formatted: "-05:00 Eastern Time - Grand Turk",
    group: ["America/Grand_Turk"],
  },
  {
    timeZoneName: "America/Jamaica",
    formatted: "-05:00 Eastern Time - Jamaica",
    group: ["America/Jamaica"],
  },
  {
    timeZoneName: "America/Nassau",
    formatted: "-05:00 Eastern Time - Nassau",
    group: ["America/Nassau"],
  },
  {
    timeZoneName: "America/New_York",
    formatted: "-05:00 Eastern Time - New York, Indianapolis",
    group: [
      "America/Detroit",
      "America/Indiana/Indianapolis",
      "America/Indiana/Marengo",
      "America/Indiana/Petersburg",
      "America/Indiana/Vevay",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Kentucky/Louisville",
      "America/Kentucky/Monticello",
      "America/New_York",
    ],
  },
  {
    timeZoneName: "America/Panama",
    formatted: "-05:00 Eastern Time - Panama",
    group: ["America/Panama"],
  },
  {
    timeZoneName: "America/Port-au-Prince",
    formatted: "-05:00 Eastern Time - Port-au-Prince",
    group: ["America/Port-au-Prince"],
  },
  {
    timeZoneName: "America/Toronto",
    formatted: "-05:00 Eastern Time - Toronto, Thunder Bay",
    group: [
      "America/Iqaluit",
      "America/Nipigon",
      "America/Pangnirtung",
      "America/Thunder_Bay",
      "America/Toronto",
    ],
  },
  {
    timeZoneName: "America/Guayaquil",
    formatted: "-05:00 Ecuador Time - Guayaquil",
    group: ["America/Guayaquil"],
  },
  {
    timeZoneName: "America/Lima",
    formatted: "-05:00 Peru Time - Lima",
    group: ["America/Lima"],
  },
  {
    timeZoneName: "America/Manaus",
    formatted: "-04:00 Amazon Time - Manaus, Campo Grande",
    group: [
      "America/Boa_Vista",
      "America/Campo_Grande",
      "America/Cuiaba",
      "America/Manaus",
      "America/Porto_Velho",
    ],
  },
  {
    timeZoneName: "America/Anguilla",
    formatted: "-04:00 Atlantic Time - Anguilla",
    group: ["America/Anguilla"],
  },
  {
    timeZoneName: "America/Antigua",
    formatted: "-04:00 Atlantic Time - Antigua",
    group: ["America/Antigua"],
  },
  {
    timeZoneName: "America/Aruba",
    formatted: "-04:00 Atlantic Time - Aruba",
    group: ["America/Aruba"],
  },
  {
    timeZoneName: "America/Barbados",
    formatted: "-04:00 Atlantic Time - Barbados",
    group: ["America/Barbados"],
  },
  {
    timeZoneName: "Atlantic/Bermuda",
    formatted: "-04:00 Atlantic Time - Bermuda",
    group: ["Atlantic/Bermuda"],
  },
  {
    timeZoneName: "America/Blanc-Sablon",
    formatted: "-04:00 Atlantic Time - Blanc-Sablon",
    group: ["America/Blanc-Sablon"],
  },
  {
    timeZoneName: "America/Curacao",
    formatted: "-04:00 Atlantic Time - Curacao",
    group: ["America/Curacao"],
  },
  {
    timeZoneName: "America/Dominica",
    formatted: "-04:00 Atlantic Time - Dominica",
    group: ["America/Dominica"],
  },
  {
    timeZoneName: "America/Grenada",
    formatted: "-04:00 Atlantic Time - Grenada",
    group: ["America/Grenada"],
  },
  {
    timeZoneName: "America/Guadeloupe",
    formatted: "-04:00 Atlantic Time - Guadeloupe",
    group: ["America/Guadeloupe"],
  },
  {
    timeZoneName: "America/Halifax",
    formatted: "-04:00 Atlantic Time - Halifax, Moncton",
    group: [
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Moncton",
    ],
  },
  {
    timeZoneName: "America/Kralendijk",
    formatted: "-04:00 Atlantic Time - Kralendijk",
    group: ["America/Kralendijk"],
  },
  {
    timeZoneName: "America/Lower_Princes",
    formatted: "-04:00 Atlantic Time - Lower Princes",
    group: ["America/Lower_Princes"],
  },
  {
    timeZoneName: "America/Marigot",
    formatted: "-04:00 Atlantic Time - Marigot",
    group: ["America/Marigot"],
  },
  {
    timeZoneName: "America/Martinique",
    formatted: "-04:00 Atlantic Time - Martinique",
    group: ["America/Martinique"],
  },
  {
    timeZoneName: "America/Montserrat",
    formatted: "-04:00 Atlantic Time - Montserrat",
    group: ["America/Montserrat"],
  },
  {
    timeZoneName: "America/Port_of_Spain",
    formatted: "-04:00 Atlantic Time - Port of Spain",
    group: ["America/Port_of_Spain"],
  },
  {
    timeZoneName: "America/Puerto_Rico",
    formatted: "-04:00 Atlantic Time - Puerto Rico",
    group: ["America/Puerto_Rico"],
  },
  {
    timeZoneName: "America/Santo_Domingo",
    formatted: "-04:00 Atlantic Time - Santo Domingo",
    group: ["America/Santo_Domingo"],
  },
  {
    timeZoneName: "America/St_Barthelemy",
    formatted: "-04:00 Atlantic Time - St Barthelemy",
    group: ["America/St_Barthelemy"],
  },
  {
    timeZoneName: "America/St_Kitts",
    formatted: "-04:00 Atlantic Time - St Kitts",
    group: ["America/St_Kitts"],
  },
  {
    timeZoneName: "America/St_Lucia",
    formatted: "-04:00 Atlantic Time - St Lucia",
    group: ["America/St_Lucia"],
  },
  {
    timeZoneName: "America/St_Thomas",
    formatted: "-04:00 Atlantic Time - St Thomas",
    group: ["America/St_Thomas"],
  },
  {
    timeZoneName: "America/St_Vincent",
    formatted: "-04:00 Atlantic Time - St Vincent",
    group: ["America/St_Vincent"],
  },
  {
    timeZoneName: "America/Thule",
    formatted: "-04:00 Atlantic Time - Thule",
    group: ["America/Thule"],
  },
  {
    timeZoneName: "America/Tortola",
    formatted: "-04:00 Atlantic Time - Tortola",
    group: ["America/Tortola"],
  },
  {
    timeZoneName: "America/La_Paz",
    formatted: "-04:00 Bolivia Time - La Paz",
    group: ["America/La_Paz"],
  },
  {
    timeZoneName: "America/Guyana",
    formatted: "-04:00 Guyana Time - Guyana",
    group: ["America/Guyana"],
  },
  {
    timeZoneName: "America/Caracas",
    formatted: "-04:00 Venezuela Time - Caracas",
    group: ["America/Caracas"],
  },
  {
    timeZoneName: "America/St_Johns",
    formatted: "-03:30 Newfoundland Time - St Johns",
    group: ["America/St_Johns"],
  },
  {
    timeZoneName: "America/Argentina/Buenos_Aires",
    formatted: "-03:00 Argentina Time - Buenos Aires, Cordoba",
    group: [
      "America/Argentina/Buenos_Aires",
      "America/Argentina/Catamarca",
      "America/Argentina/Cordoba",
      "America/Argentina/Jujuy",
      "America/Argentina/La_Rioja",
      "America/Argentina/Mendoza",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia",
    ],
  },
  {
    timeZoneName: "America/Sao_Paulo",
    formatted: "-03:00 Brasilia Time - Sao Paulo, Bahia",
    group: [
      "America/Araguaina",
      "America/Bahia",
      "America/Belem",
      "America/Fortaleza",
      "America/Maceio",
      "America/Recife",
      "America/Santarem",
      "America/Sao_Paulo",
    ],
  },
  {
    timeZoneName: "America/Santiago",
    formatted: "-03:00 Chile Summer Time - Santiago",
    group: ["America/Santiago"],
  },
  {
    timeZoneName: "Atlantic/Stanley",
    formatted: "-03:00 Falkland Islands Time - Stanley",
    group: ["Atlantic/Stanley"],
  },
  {
    timeZoneName: "America/Cayenne",
    formatted: "-03:00 French Guiana Time - Cayenne",
    group: ["America/Cayenne"],
  },
  {
    timeZoneName: "Antarctica/Palmer",
    formatted: "-03:00 GMT-03:00 - Palmer, Rothera",
    group: ["Antarctica/Palmer", "Antarctica/Rothera"],
  },
  {
    timeZoneName: "America/Punta_Arenas",
    formatted: "-03:00 GMT-03:00 - Punta Arenas",
    group: ["America/Punta_Arenas"],
  },
  {
    timeZoneName: "America/Asuncion",
    formatted: "-03:00 Paraguay Summer Time - Asuncion",
    group: ["America/Asuncion"],
  },
  {
    timeZoneName: "America/Miquelon",
    formatted: "-03:00 St. Pierre & Miquelon Time - Miquelon",
    group: ["America/Miquelon"],
  },
  {
    timeZoneName: "America/Paramaribo",
    formatted: "-03:00 Suriname Time - Paramaribo",
    group: ["America/Paramaribo"],
  },
  {
    timeZoneName: "America/Montevideo",
    formatted: "-03:00 Uruguay Time - Montevideo",
    group: ["America/Montevideo"],
  },
  {
    timeZoneName: "America/Godthab",
    formatted: "-03:00 West Greenland Time - Godthab",
    group: ["America/Godthab"],
  },
  {
    timeZoneName: "America/Noronha",
    formatted: "-02:00 Fernando de Noronha Time - Noronha",
    group: ["America/Noronha"],
  },
  {
    timeZoneName: "Atlantic/South_Georgia",
    formatted: "-02:00 South Georgia Time - South Georgia",
    group: ["Atlantic/South_Georgia"],
  },
  {
    timeZoneName: "Atlantic/Azores",
    formatted: "-01:00 Azores Time - Azores",
    group: ["Atlantic/Azores"],
  },
  {
    timeZoneName: "Atlantic/Cape_Verde",
    formatted: "-01:00 Cape Verde Time - Cape Verde",
    group: ["Atlantic/Cape_Verde"],
  },
  {
    timeZoneName: "America/Scoresbysund",
    formatted: "-01:00 East Greenland Time - Scoresbysund",
    group: ["America/Scoresbysund"],
  },
  {
    timeZoneName: "Africa/Abidjan",
    formatted: "+00:00 Greenwich Mean Time - Abidjan",
    group: ["Africa/Abidjan"],
  },
  {
    timeZoneName: "Africa/Accra",
    formatted: "+00:00 Greenwich Mean Time - Accra",
    group: ["Africa/Accra"],
  },
  {
    timeZoneName: "Africa/Bamako",
    formatted: "+00:00 Greenwich Mean Time - Bamako",
    group: ["Africa/Bamako"],
  },
  {
    timeZoneName: "Africa/Banjul",
    formatted: "+00:00 Greenwich Mean Time - Banjul",
    group: ["Africa/Banjul"],
  },
  {
    timeZoneName: "Africa/Bissau",
    formatted: "+00:00 Greenwich Mean Time - Bissau",
    group: ["Africa/Bissau"],
  },
  {
    timeZoneName: "Africa/Conakry",
    formatted: "+00:00 Greenwich Mean Time - Conakry",
    group: ["Africa/Conakry"],
  },
  {
    timeZoneName: "Africa/Dakar",
    formatted: "+00:00 Greenwich Mean Time - Dakar",
    group: ["Africa/Dakar"],
  },
  {
    timeZoneName: "America/Danmarkshavn",
    formatted: "+00:00 Greenwich Mean Time - Danmarkshavn",
    group: ["America/Danmarkshavn"],
  },
  {
    timeZoneName: "Europe/Dublin",
    formatted: "+00:00 Greenwich Mean Time - Dublin",
    group: ["Europe/Dublin"],
  },
  {
    timeZoneName: "Africa/Freetown",
    formatted: "+00:00 Greenwich Mean Time - Freetown",
    group: ["Africa/Freetown"],
  },
  {
    timeZoneName: "Europe/Guernsey",
    formatted: "+00:00 Greenwich Mean Time - Guernsey",
    group: ["Europe/Guernsey"],
  },
  {
    timeZoneName: "Europe/Isle_of_Man",
    formatted: "+00:00 Greenwich Mean Time - Isle of Man",
    group: ["Europe/Isle_of_Man"],
  },
  {
    timeZoneName: "Europe/Jersey",
    formatted: "+00:00 Greenwich Mean Time - Jersey",
    group: ["Europe/Jersey"],
  },
  {
    timeZoneName: "Africa/Lome",
    formatted: "+00:00 Greenwich Mean Time - Lome",
    group: ["Africa/Lome"],
  },
  {
    timeZoneName: "Europe/London",
    formatted: "+00:00 Greenwich Mean Time - London",
    group: ["Europe/London"],
  },
  {
    timeZoneName: "Africa/Monrovia",
    formatted: "+00:00 Greenwich Mean Time - Monrovia",
    group: ["Africa/Monrovia"],
  },
  {
    timeZoneName: "Africa/Nouakchott",
    formatted: "+00:00 Greenwich Mean Time - Nouakchott",
    group: ["Africa/Nouakchott"],
  },
  {
    timeZoneName: "Africa/Ouagadougou",
    formatted: "+00:00 Greenwich Mean Time - Ouagadougou",
    group: ["Africa/Ouagadougou"],
  },
  {
    timeZoneName: "Atlantic/Reykjavik",
    formatted: "+00:00 Greenwich Mean Time - Reykjavik",
    group: ["Atlantic/Reykjavik"],
  },
  {
    timeZoneName: "Africa/Sao_Tome",
    formatted: "+00:00 Greenwich Mean Time - Sao Tome",
    group: ["Africa/Sao_Tome"],
  },
  {
    timeZoneName: "Atlantic/St_Helena",
    formatted: "+00:00 Greenwich Mean Time - St Helena",
    group: ["Atlantic/St_Helena"],
  },
  {
    timeZoneName: "Antarctica/Troll",
    formatted: "+00:00 Greenwich Mean Time - Troll",
    group: ["Antarctica/Troll"],
  },
  {
    timeZoneName: "Atlantic/Canary",
    formatted: "+00:00 Western European Time - Canary",
    group: ["Atlantic/Canary"],
  },
  {
    timeZoneName: "Atlantic/Faroe",
    formatted: "+00:00 Western European Time - Faroe",
    group: ["Atlantic/Faroe"],
  },
  {
    timeZoneName: "Europe/Lisbon",
    formatted: "+00:00 Western European Time - Lisbon, Madeira",
    group: ["Atlantic/Madeira", "Europe/Lisbon"],
  },
  {
    timeZoneName: "Africa/Algiers",
    formatted: "+01:00 Central European Time - Algiers",
    group: ["Africa/Algiers"],
  },
  {
    timeZoneName: "Europe/Amsterdam",
    formatted: "+01:00 Central European Time - Amsterdam",
    group: ["Europe/Amsterdam"],
  },
  {
    timeZoneName: "Europe/Andorra",
    formatted: "+01:00 Central European Time - Andorra",
    group: ["Europe/Andorra"],
  },
  {
    timeZoneName: "Europe/Belgrade",
    formatted: "+01:00 Central European Time - Belgrade",
    group: ["Europe/Belgrade"],
  },
  {
    timeZoneName: "Europe/Berlin",
    formatted: "+01:00 Central European Time - Berlin, Busingen",
    group: ["Europe/Berlin", "Europe/Busingen"],
  },
  {
    timeZoneName: "Europe/Bratislava",
    formatted: "+01:00 Central European Time - Bratislava",
    group: ["Europe/Bratislava"],
  },
  {
    timeZoneName: "Europe/Brussels",
    formatted: "+01:00 Central European Time - Brussels",
    group: ["Europe/Brussels"],
  },
  {
    timeZoneName: "Europe/Budapest",
    formatted: "+01:00 Central European Time - Budapest",
    group: ["Europe/Budapest"],
  },
  {
    timeZoneName: "Europe/Copenhagen",
    formatted: "+01:00 Central European Time - Copenhagen",
    group: ["Europe/Copenhagen"],
  },
  {
    timeZoneName: "Europe/Gibraltar",
    formatted: "+01:00 Central European Time - Gibraltar",
    group: ["Europe/Gibraltar"],
  },
  {
    timeZoneName: "Europe/Ljubljana",
    formatted: "+01:00 Central European Time - Ljubljana",
    group: ["Europe/Ljubljana"],
  },
  {
    timeZoneName: "Arctic/Longyearbyen",
    formatted: "+01:00 Central European Time - Longyearbyen",
    group: ["Arctic/Longyearbyen"],
  },
  {
    timeZoneName: "Europe/Luxembourg",
    formatted: "+01:00 Central European Time - Luxembourg",
    group: ["Europe/Luxembourg"],
  },
  {
    timeZoneName: "Europe/Madrid",
    formatted: "+01:00 Central European Time - Madrid, Ceuta",
    group: ["Africa/Ceuta", "Europe/Madrid"],
  },
  {
    timeZoneName: "Europe/Malta",
    formatted: "+01:00 Central European Time - Malta",
    group: ["Europe/Malta"],
  },
  {
    timeZoneName: "Europe/Monaco",
    formatted: "+01:00 Central European Time - Monaco",
    group: ["Europe/Monaco"],
  },
  {
    timeZoneName: "Europe/Oslo",
    formatted: "+01:00 Central European Time - Oslo",
    group: ["Europe/Oslo"],
  },
  {
    timeZoneName: "Europe/Paris",
    formatted: "+01:00 Central European Time - Paris",
    group: ["Europe/Paris"],
  },
  {
    timeZoneName: "Europe/Podgorica",
    formatted: "+01:00 Central European Time - Podgorica",
    group: ["Europe/Podgorica"],
  },
  {
    timeZoneName: "Europe/Prague",
    formatted: "+01:00 Central European Time - Prague",
    group: ["Europe/Prague"],
  },
  {
    timeZoneName: "Europe/Rome",
    formatted: "+01:00 Central European Time - Rome",
    group: ["Europe/Rome"],
  },
  {
    timeZoneName: "Europe/San_Marino",
    formatted: "+01:00 Central European Time - San Marino",
    group: ["Europe/San_Marino"],
  },
  {
    timeZoneName: "Europe/Sarajevo",
    formatted: "+01:00 Central European Time - Sarajevo",
    group: ["Europe/Sarajevo"],
  },
  {
    timeZoneName: "Europe/Skopje",
    formatted: "+01:00 Central European Time - Skopje",
    group: ["Europe/Skopje"],
  },
  {
    timeZoneName: "Europe/Stockholm",
    formatted: "+01:00 Central European Time - Stockholm",
    group: ["Europe/Stockholm"],
  },
  {
    timeZoneName: "Europe/Tirane",
    formatted: "+01:00 Central European Time - Tirane",
    group: ["Europe/Tirane"],
  },
  {
    timeZoneName: "Africa/Tunis",
    formatted: "+01:00 Central European Time - Tunis",
    group: ["Africa/Tunis"],
  },
  {
    timeZoneName: "Europe/Vaduz",
    formatted: "+01:00 Central European Time - Vaduz",
    group: ["Europe/Vaduz"],
  },
  {
    timeZoneName: "Europe/Vatican",
    formatted: "+01:00 Central European Time - Vatican",
    group: ["Europe/Vatican"],
  },
  {
    timeZoneName: "Europe/Vienna",
    formatted: "+01:00 Central European Time - Vienna",
    group: ["Europe/Vienna"],
  },
  {
    timeZoneName: "Europe/Warsaw",
    formatted: "+01:00 Central European Time - Warsaw",
    group: ["Europe/Warsaw"],
  },
  {
    timeZoneName: "Europe/Zagreb",
    formatted: "+01:00 Central European Time - Zagreb",
    group: ["Europe/Zagreb"],
  },
  {
    timeZoneName: "Europe/Zurich",
    formatted: "+01:00 Central European Time - Zurich",
    group: ["Europe/Zurich"],
  },
  {
    timeZoneName: "Africa/Casablanca",
    formatted: "+01:00 GMT+01:00 - Casablanca",
    group: ["Africa/Casablanca"],
  },
  {
    timeZoneName: "Africa/El_Aaiun",
    formatted: "+01:00 GMT+01:00 - El Aaiun",
    group: ["Africa/El_Aaiun"],
  },
  {
    timeZoneName: "Africa/Bangui",
    formatted: "+01:00 West Africa Time - Bangui",
    group: ["Africa/Bangui"],
  },
  {
    timeZoneName: "Africa/Brazzaville",
    formatted: "+01:00 West Africa Time - Brazzaville",
    group: ["Africa/Brazzaville"],
  },
  {
    timeZoneName: "Africa/Douala",
    formatted: "+01:00 West Africa Time - Douala",
    group: ["Africa/Douala"],
  },
  {
    timeZoneName: "Africa/Kinshasa",
    formatted: "+01:00 West Africa Time - Kinshasa",
    group: ["Africa/Kinshasa"],
  },
  {
    timeZoneName: "Africa/Lagos",
    formatted: "+01:00 West Africa Time - Lagos",
    group: ["Africa/Lagos"],
  },
  {
    timeZoneName: "Africa/Libreville",
    formatted: "+01:00 West Africa Time - Libreville",
    group: ["Africa/Libreville"],
  },
  {
    timeZoneName: "Africa/Luanda",
    formatted: "+01:00 West Africa Time - Luanda",
    group: ["Africa/Luanda"],
  },
  {
    timeZoneName: "Africa/Malabo",
    formatted: "+01:00 West Africa Time - Malabo",
    group: ["Africa/Malabo"],
  },
  {
    timeZoneName: "Africa/Ndjamena",
    formatted: "+01:00 West Africa Time - Ndjamena",
    group: ["Africa/Ndjamena"],
  },
  {
    timeZoneName: "Africa/Niamey",
    formatted: "+01:00 West Africa Time - Niamey",
    group: ["Africa/Niamey"],
  },
  {
    timeZoneName: "Africa/Porto-Novo",
    formatted: "+01:00 West Africa Time - Porto-Novo",
    group: ["Africa/Porto-Novo"],
  },
  {
    timeZoneName: "Africa/Blantyre",
    formatted: "+02:00 Central Africa Time - Blantyre",
    group: ["Africa/Blantyre"],
  },
  {
    timeZoneName: "Africa/Bujumbura",
    formatted: "+02:00 Central Africa Time - Bujumbura",
    group: ["Africa/Bujumbura"],
  },
  {
    timeZoneName: "Africa/Gaborone",
    formatted: "+02:00 Central Africa Time - Gaborone",
    group: ["Africa/Gaborone"],
  },
  {
    timeZoneName: "Africa/Harare",
    formatted: "+02:00 Central Africa Time - Harare",
    group: ["Africa/Harare"],
  },
  {
    timeZoneName: "Africa/Khartoum",
    formatted: "+02:00 Central Africa Time - Khartoum",
    group: ["Africa/Khartoum"],
  },
  {
    timeZoneName: "Africa/Kigali",
    formatted: "+02:00 Central Africa Time - Kigali",
    group: ["Africa/Kigali"],
  },
  {
    timeZoneName: "Africa/Lubumbashi",
    formatted: "+02:00 Central Africa Time - Lubumbashi",
    group: ["Africa/Lubumbashi"],
  },
  {
    timeZoneName: "Africa/Lusaka",
    formatted: "+02:00 Central Africa Time - Lusaka",
    group: ["Africa/Lusaka"],
  },
  {
    timeZoneName: "Africa/Maputo",
    formatted: "+02:00 Central Africa Time - Maputo",
    group: ["Africa/Maputo"],
  },
  {
    timeZoneName: "Africa/Windhoek",
    formatted: "+02:00 Central Africa Time - Windhoek",
    group: ["Africa/Windhoek"],
  },
  {
    timeZoneName: "Asia/Amman",
    formatted: "+02:00 Eastern European Time - Amman",
    group: ["Asia/Amman"],
  },
  {
    timeZoneName: "Europe/Athens",
    formatted: "+02:00 Eastern European Time - Athens",
    group: ["Europe/Athens"],
  },
  {
    timeZoneName: "Asia/Beirut",
    formatted: "+02:00 Eastern European Time - Beirut",
    group: ["Asia/Beirut"],
  },
  {
    timeZoneName: "Europe/Bucharest",
    formatted: "+02:00 Eastern European Time - Bucharest",
    group: ["Europe/Bucharest"],
  },
  {
    timeZoneName: "Africa/Cairo",
    formatted: "+02:00 Eastern European Time - Cairo",
    group: ["Africa/Cairo"],
  },
  {
    timeZoneName: "Europe/Chisinau",
    formatted: "+02:00 Eastern European Time - Chisinau",
    group: ["Europe/Chisinau"],
  },
  {
    timeZoneName: "Asia/Damascus",
    formatted: "+02:00 Eastern European Time - Damascus",
    group: ["Asia/Damascus"],
  },
  {
    timeZoneName: "Asia/Gaza",
    formatted: "+02:00 Eastern European Time - Gaza, Hebron",
    group: ["Asia/Gaza", "Asia/Hebron"],
  },
  {
    timeZoneName: "Europe/Helsinki",
    formatted: "+02:00 Eastern European Time - Helsinki",
    group: ["Europe/Helsinki"],
  },
  {
    timeZoneName: "Europe/Kaliningrad",
    formatted: "+02:00 Eastern European Time - Kaliningrad",
    group: ["Europe/Kaliningrad"],
  },
  {
    timeZoneName: "Europe/Kiev",
    formatted: "+02:00 Eastern European Time - Kiev, Uzhgorod",
    group: ["Europe/Kiev", "Europe/Uzhgorod", "Europe/Zaporozhye"],
  },
  {
    timeZoneName: "Europe/Mariehamn",
    formatted: "+02:00 Eastern European Time - Mariehamn",
    group: ["Europe/Mariehamn"],
  },
  {
    timeZoneName: "Asia/Nicosia",
    formatted: "+02:00 Eastern European Time - Nicosia, Famagusta",
    group: ["Asia/Famagusta", "Asia/Nicosia"],
  },
  {
    timeZoneName: "Europe/Riga",
    formatted: "+02:00 Eastern European Time - Riga",
    group: ["Europe/Riga"],
  },
  {
    timeZoneName: "Europe/Sofia",
    formatted: "+02:00 Eastern European Time - Sofia",
    group: ["Europe/Sofia"],
  },
  {
    timeZoneName: "Europe/Tallinn",
    formatted: "+02:00 Eastern European Time - Tallinn",
    group: ["Europe/Tallinn"],
  },
  {
    timeZoneName: "Africa/Tripoli",
    formatted: "+02:00 Eastern European Time - Tripoli",
    group: ["Africa/Tripoli"],
  },
  {
    timeZoneName: "Europe/Vilnius",
    formatted: "+02:00 Eastern European Time - Vilnius",
    group: ["Europe/Vilnius"],
  },
  {
    timeZoneName: "Asia/Jerusalem",
    formatted: "+02:00 Israel Time - Jerusalem",
    group: ["Asia/Jerusalem"],
  },
  {
    timeZoneName: "Africa/Johannesburg",
    formatted: "+02:00 South Africa Time - Johannesburg",
    group: ["Africa/Johannesburg"],
  },
  {
    timeZoneName: "Africa/Maseru",
    formatted: "+02:00 South Africa Time - Maseru",
    group: ["Africa/Maseru"],
  },
  {
    timeZoneName: "Africa/Mbabane",
    formatted: "+02:00 South Africa Time - Mbabane",
    group: ["Africa/Mbabane"],
  },
  {
    timeZoneName: "Asia/Aden",
    formatted: "+03:00 Arabian Time - Aden",
    group: ["Asia/Aden"],
  },
  {
    timeZoneName: "Asia/Baghdad",
    formatted: "+03:00 Arabian Time - Baghdad",
    group: ["Asia/Baghdad"],
  },
  {
    timeZoneName: "Asia/Bahrain",
    formatted: "+03:00 Arabian Time - Bahrain",
    group: ["Asia/Bahrain"],
  },
  {
    timeZoneName: "Asia/Kuwait",
    formatted: "+03:00 Arabian Time - Kuwait",
    group: ["Asia/Kuwait"],
  },
  {
    timeZoneName: "Asia/Qatar",
    formatted: "+03:00 Arabian Time - Qatar",
    group: ["Asia/Qatar"],
  },
  {
    timeZoneName: "Asia/Riyadh",
    formatted: "+03:00 Arabian Time - Riyadh",
    group: ["Asia/Riyadh"],
  },
  {
    timeZoneName: "Africa/Addis_Ababa",
    formatted: "+03:00 East Africa Time - Addis Ababa",
    group: ["Africa/Addis_Ababa"],
  },
  {
    timeZoneName: "Indian/Antananarivo",
    formatted: "+03:00 East Africa Time - Antananarivo",
    group: ["Indian/Antananarivo"],
  },
  {
    timeZoneName: "Africa/Asmara",
    formatted: "+03:00 East Africa Time - Asmara",
    group: ["Africa/Asmara"],
  },
  {
    timeZoneName: "Indian/Comoro",
    formatted: "+03:00 East Africa Time - Comoro",
    group: ["Indian/Comoro"],
  },
  {
    timeZoneName: "Africa/Dar_es_Salaam",
    formatted: "+03:00 East Africa Time - Dar es Salaam",
    group: ["Africa/Dar_es_Salaam"],
  },
  {
    timeZoneName: "Africa/Djibouti",
    formatted: "+03:00 East Africa Time - Djibouti",
    group: ["Africa/Djibouti"],
  },
  {
    timeZoneName: "Africa/Juba",
    formatted: "+03:00 East Africa Time - Juba",
    group: ["Africa/Juba"],
  },
  {
    timeZoneName: "Africa/Kampala",
    formatted: "+03:00 East Africa Time - Kampala",
    group: ["Africa/Kampala"],
  },
  {
    timeZoneName: "Indian/Mayotte",
    formatted: "+03:00 East Africa Time - Mayotte",
    group: ["Indian/Mayotte"],
  },
  {
    timeZoneName: "Africa/Mogadishu",
    formatted: "+03:00 East Africa Time - Mogadishu",
    group: ["Africa/Mogadishu"],
  },
  {
    timeZoneName: "Africa/Nairobi",
    formatted: "+03:00 East Africa Time - Nairobi",
    group: ["Africa/Nairobi"],
  },
  {
    timeZoneName: "Europe/Istanbul",
    formatted: "+03:00 GMT+03:00 - Istanbul",
    group: ["Europe/Istanbul"],
  },
  {
    timeZoneName: "Europe/Minsk",
    formatted: "+03:00 Moscow Time - Minsk",
    group: ["Europe/Minsk"],
  },
  {
    timeZoneName: "Europe/Moscow",
    formatted: "+03:00 Moscow Time - Moscow, Kirov",
    group: ["Europe/Kirov", "Europe/Moscow", "Europe/Simferopol"],
  },
  {
    timeZoneName: "Antarctica/Syowa",
    formatted: "+03:00 Syowa Time - Syowa",
    group: ["Antarctica/Syowa"],
  },
  {
    timeZoneName: "Asia/Tehran",
    formatted: "+03:30 Iran Time - Tehran",
    group: ["Asia/Tehran"],
  },
  {
    timeZoneName: "Asia/Yerevan",
    formatted: "+04:00 Armenia Time - Yerevan",
    group: ["Asia/Yerevan"],
  },
  {
    timeZoneName: "Asia/Baku",
    formatted: "+04:00 Azerbaijan Time - Baku",
    group: ["Asia/Baku"],
  },
  {
    timeZoneName: "Asia/Tbilisi",
    formatted: "+04:00 Georgia Time - Tbilisi",
    group: ["Asia/Tbilisi"],
  },
  {
    timeZoneName: "Asia/Dubai",
    formatted: "+04:00 Gulf Time - Dubai",
    group: ["Asia/Dubai"],
  },
  {
    timeZoneName: "Asia/Muscat",
    formatted: "+04:00 Gulf Time - Muscat",
    group: ["Asia/Muscat"],
  },
  {
    timeZoneName: "Indian/Mauritius",
    formatted: "+04:00 Mauritius Time - Mauritius",
    group: ["Indian/Mauritius"],
  },
  {
    timeZoneName: "Indian/Reunion",
    formatted: "+04:00 Réunion Time - Reunion",
    group: ["Indian/Reunion"],
  },
  {
    timeZoneName: "Europe/Samara",
    formatted: "+04:00 Samara Time - Samara, Volgograd",
    group: [
      "Europe/Astrakhan",
      "Europe/Samara",
      "Europe/Saratov",
      "Europe/Ulyanovsk",
      "Europe/Volgograd",
    ],
  },
  {
    timeZoneName: "Indian/Mahe",
    formatted: "+04:00 Seychelles Time - Mahe",
    group: ["Indian/Mahe"],
  },
  {
    timeZoneName: "Asia/Kabul",
    formatted: "+04:30 Afghanistan Time - Kabul",
    group: ["Asia/Kabul"],
  },
  {
    timeZoneName: "Indian/Kerguelen",
    formatted: "+05:00 French Southern & Antarctic Time - Kerguelen",
    group: ["Indian/Kerguelen"],
  },
  {
    timeZoneName: "Indian/Maldives",
    formatted: "+05:00 Maldives Time - Maldives",
    group: ["Indian/Maldives"],
  },
  {
    timeZoneName: "Antarctica/Mawson",
    formatted: "+05:00 Mawson Time - Mawson",
    group: ["Antarctica/Mawson"],
  },
  {
    timeZoneName: "Asia/Karachi",
    formatted: "+05:00 Pakistan Time - Karachi",
    group: ["Asia/Karachi"],
  },
  {
    timeZoneName: "Asia/Dushanbe",
    formatted: "+05:00 Tajikistan Time - Dushanbe",
    group: ["Asia/Dushanbe"],
  },
  {
    timeZoneName: "Asia/Ashgabat",
    formatted: "+05:00 Turkmenistan Time - Ashgabat",
    group: ["Asia/Ashgabat"],
  },
  {
    timeZoneName: "Asia/Tashkent",
    formatted: "+05:00 Uzbekistan Time - Tashkent, Samarkand",
    group: ["Asia/Samarkand", "Asia/Tashkent"],
  },
  {
    timeZoneName: "Asia/Qyzylorda",
    formatted: "+05:00 West Kazakhstan Time - Qyzylorda, Aqtobe",
    group: [
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Atyrau",
      "Asia/Oral",
      "Asia/Qyzylorda",
    ],
  },
  {
    timeZoneName: "Asia/Yekaterinburg",
    formatted: "+05:00 Yekaterinburg Time - Yekaterinburg",
    group: ["Asia/Yekaterinburg"],
  },
  {
    timeZoneName: "Asia/Colombo",
    formatted: "+05:30 India Time - Colombo",
    group: ["Asia/Colombo"],
  },
  {
    timeZoneName: "Asia/Kolkata",
    formatted: "+05:30 India Time - Kolkata",
    group: ["Asia/Kolkata"],
  },
  {
    timeZoneName: "Asia/Kathmandu",
    formatted: "+05:45 Nepal Time - Kathmandu",
    group: ["Asia/Kathmandu"],
  },
  {
    timeZoneName: "Asia/Dhaka",
    formatted: "+06:00 Bangladesh Time - Dhaka",
    group: ["Asia/Dhaka"],
  },
  {
    timeZoneName: "Asia/Thimphu",
    formatted: "+06:00 Bhutan Time - Thimphu",
    group: ["Asia/Thimphu"],
  },
  {
    timeZoneName: "Asia/Almaty",
    formatted: "+06:00 East Kazakhstan Time - Almaty, Qostanay",
    group: ["Asia/Almaty", "Asia/Qostanay"],
  },
  {
    timeZoneName: "Asia/Urumqi",
    formatted: "+06:00 GMT+06:00 - Urumqi",
    group: ["Asia/Urumqi"],
  },
  {
    timeZoneName: "Indian/Chagos",
    formatted: "+06:00 Indian Ocean Time - Chagos",
    group: ["Indian/Chagos"],
  },
  {
    timeZoneName: "Asia/Bishkek",
    formatted: "+06:00 Kyrgyzstan Time - Bishkek",
    group: ["Asia/Bishkek"],
  },
  {
    timeZoneName: "Asia/Omsk",
    formatted: "+06:00 Omsk Time - Omsk",
    group: ["Asia/Omsk"],
  },
  {
    timeZoneName: "Antarctica/Vostok",
    formatted: "+06:00 Vostok Time - Vostok",
    group: ["Antarctica/Vostok"],
  },
  {
    timeZoneName: "Indian/Cocos",
    formatted: "+06:30 Cocos Islands Time - Cocos",
    group: ["Indian/Cocos"],
  },
  {
    timeZoneName: "Asia/Yangon",
    formatted: "+06:30 Myanmar Time - Yangon",
    group: ["Asia/Yangon"],
  },
  {
    timeZoneName: "Indian/Christmas",
    formatted: "+07:00 Christmas Island Time - Christmas",
    group: ["Indian/Christmas"],
  },
  {
    timeZoneName: "Antarctica/Davis",
    formatted: "+07:00 Davis Time - Davis",
    group: ["Antarctica/Davis"],
  },
  {
    timeZoneName: "Asia/Hovd",
    formatted: "+07:00 Hovd Time - Hovd",
    group: ["Asia/Hovd"],
  },
  {
    timeZoneName: "Asia/Bangkok",
    formatted: "+07:00 Indochina Time - Bangkok",
    group: ["Asia/Bangkok"],
  },
  {
    timeZoneName: "Asia/Ho_Chi_Minh",
    formatted: "+07:00 Indochina Time - Ho Chi Minh",
    group: ["Asia/Ho_Chi_Minh"],
  },
  {
    timeZoneName: "Asia/Phnom_Penh",
    formatted: "+07:00 Indochina Time - Phnom Penh",
    group: ["Asia/Phnom_Penh"],
  },
  {
    timeZoneName: "Asia/Vientiane",
    formatted: "+07:00 Indochina Time - Vientiane",
    group: ["Asia/Vientiane"],
  },
  {
    timeZoneName: "Asia/Novosibirsk",
    formatted: "+07:00 Novosibirsk Time - Novosibirsk, Krasnoyarsk",
    group: [
      "Asia/Barnaul",
      "Asia/Krasnoyarsk",
      "Asia/Novokuznetsk",
      "Asia/Novosibirsk",
      "Asia/Tomsk",
    ],
  },
  {
    timeZoneName: "Asia/Jakarta",
    formatted: "+07:00 Western Indonesia Time - Jakarta, Pontianak",
    group: ["Asia/Jakarta", "Asia/Pontianak"],
  },
  {
    timeZoneName: "Antarctica/Casey",
    formatted: "+08:00 Australian Western Time - Casey",
    group: ["Antarctica/Casey"],
  },
  {
    timeZoneName: "Australia/Perth",
    formatted: "+08:00 Australian Western Time - Perth",
    group: ["Australia/Perth"],
  },
  {
    timeZoneName: "Asia/Brunei",
    formatted: "+08:00 Brunei Darussalam Time - Brunei",
    group: ["Asia/Brunei"],
  },
  {
    timeZoneName: "Asia/Makassar",
    formatted: "+08:00 Central Indonesia Time - Makassar",
    group: ["Asia/Makassar"],
  },
  {
    timeZoneName: "Asia/Macau",
    formatted: "+08:00 China Time - Macau",
    group: ["Asia/Macau"],
  },
  {
    timeZoneName: "Asia/Shanghai",
    formatted: "+08:00 China Time - Shanghai",
    group: ["Asia/Shanghai"],
  },
  {
    timeZoneName: "Asia/Hong_Kong",
    formatted: "+08:00 Hong Kong Time - Hong Kong",
    group: ["Asia/Hong_Kong"],
  },
  {
    timeZoneName: "Asia/Irkutsk",
    formatted: "+08:00 Irkutsk Time - Irkutsk",
    group: ["Asia/Irkutsk"],
  },
  {
    timeZoneName: "Asia/Kuala_Lumpur",
    formatted: "+08:00 Malaysia Time - Kuala Lumpur, Kuching",
    group: ["Asia/Kuala_Lumpur", "Asia/Kuching"],
  },
  {
    timeZoneName: "Asia/Manila",
    formatted: "+08:00 Philippine Time - Manila",
    group: ["Asia/Manila"],
  },
  {
    timeZoneName: "Asia/Singapore",
    formatted: "+08:00 Singapore Time - Singapore",
    group: ["Asia/Singapore"],
  },
  {
    timeZoneName: "Asia/Taipei",
    formatted: "+08:00 Taipei Time - Taipei",
    group: ["Asia/Taipei"],
  },
  {
    timeZoneName: "Asia/Ulaanbaatar",
    formatted: "+08:00 Ulaanbaatar Time - Ulaanbaatar, Choibalsan",
    group: ["Asia/Choibalsan", "Asia/Ulaanbaatar"],
  },
  {
    timeZoneName: "Australia/Eucla",
    formatted: "+08:45 Australian Central Western Time - Eucla",
    group: ["Australia/Eucla"],
  },
  {
    timeZoneName: "Asia/Dili",
    formatted: "+09:00 East Timor Time - Dili",
    group: ["Asia/Dili"],
  },
  {
    timeZoneName: "Asia/Jayapura",
    formatted: "+09:00 Eastern Indonesia Time - Jayapura",
    group: ["Asia/Jayapura"],
  },
  {
    timeZoneName: "Asia/Tokyo",
    formatted: "+09:00 Japan Time - Tokyo",
    group: ["Asia/Tokyo"],
  },
  {
    timeZoneName: "Asia/Pyongyang",
    formatted: "+09:00 Korean Time - Pyongyang",
    group: ["Asia/Pyongyang"],
  },
  {
    timeZoneName: "Asia/Seoul",
    formatted: "+09:00 Korean Time - Seoul",
    group: ["Asia/Seoul"],
  },
  {
    timeZoneName: "Pacific/Palau",
    formatted: "+09:00 Palau Time - Palau",
    group: ["Pacific/Palau"],
  },
  {
    timeZoneName: "Asia/Chita",
    formatted: "+09:00 Yakutsk Time - Chita, Yakutsk",
    group: ["Asia/Chita", "Asia/Khandyga", "Asia/Yakutsk"],
  },
  {
    timeZoneName: "Australia/Darwin",
    formatted: "+09:30 Australian Central Time - Darwin",
    group: ["Australia/Darwin"],
  },
  {
    timeZoneName: "Australia/Brisbane",
    formatted: "+10:00 Australian Eastern Time - Brisbane, Lindeman",
    group: ["Australia/Brisbane", "Australia/Lindeman"],
  },
  {
    timeZoneName: "Pacific/Guam",
    formatted: "+10:00 Chamorro Time - Guam",
    group: ["Pacific/Guam"],
  },
  {
    timeZoneName: "Pacific/Saipan",
    formatted: "+10:00 Chamorro Time - Saipan",
    group: ["Pacific/Saipan"],
  },
  {
    timeZoneName: "Pacific/Chuuk",
    formatted: "+10:00 Chuuk Time - Chuuk",
    group: ["Pacific/Chuuk"],
  },
  {
    timeZoneName: "Antarctica/DumontDUrville",
    formatted: "+10:00 Dumont-d’Urville Time - DumontDUrville",
    group: ["Antarctica/DumontDUrville"],
  },
  {
    timeZoneName: "Pacific/Port_Moresby",
    formatted: "+10:00 Papua New Guinea Time - Port Moresby",
    group: ["Pacific/Port_Moresby"],
  },
  {
    timeZoneName: "Asia/Vladivostok",
    formatted: "+10:00 Vladivostok Time - Vladivostok, Ust-Nera",
    group: ["Asia/Ust-Nera", "Asia/Vladivostok"],
  },
  {
    timeZoneName: "Australia/Adelaide",
    formatted: "+10:30 Australian Central Time - Adelaide, Broken Hill",
    group: ["Australia/Adelaide", "Australia/Broken_Hill"],
  },
  {
    timeZoneName: "Australia/Sydney",
    formatted: "+11:00 Australian Eastern Time - Sydney, Melbourne",
    group: [
      "Australia/Currie",
      "Australia/Hobart",
      "Australia/Melbourne",
      "Australia/Sydney",
    ],
  },
  {
    timeZoneName: "Pacific/Bougainville",
    formatted: "+11:00 GMT+11:00 - Bougainville",
    group: ["Pacific/Bougainville"],
  },
  {
    timeZoneName: "Pacific/Kosrae",
    formatted: "+11:00 Kosrae Time - Kosrae, Pohnpei",
    group: ["Pacific/Kosrae", "Pacific/Pohnpei"],
  },
  {
    timeZoneName: "Australia/Lord_Howe",
    formatted: "+11:00 Lord Howe Time - Lord Howe",
    group: ["Australia/Lord_Howe"],
  },
  {
    timeZoneName: "Antarctica/Macquarie",
    formatted: "+11:00 Macquarie Island Time - Macquarie",
    group: ["Antarctica/Macquarie"],
  },
  {
    timeZoneName: "Asia/Magadan",
    formatted: "+11:00 Magadan Time - Magadan, Sakhalin",
    group: ["Asia/Magadan", "Asia/Sakhalin", "Asia/Srednekolymsk"],
  },
  {
    timeZoneName: "Pacific/Noumea",
    formatted: "+11:00 New Caledonia Time - Noumea",
    group: ["Pacific/Noumea"],
  },
  {
    timeZoneName: "Pacific/Guadalcanal",
    formatted: "+11:00 Solomon Islands Time - Guadalcanal",
    group: ["Pacific/Guadalcanal"],
  },
  {
    timeZoneName: "Pacific/Efate",
    formatted: "+11:00 Vanuatu Time - Efate",
    group: ["Pacific/Efate"],
  },
  {
    timeZoneName: "Asia/Anadyr",
    formatted: "+12:00 Anadyr Time - Anadyr, Kamchatka",
    group: ["Asia/Anadyr", "Asia/Kamchatka"],
  },
  {
    timeZoneName: "Pacific/Tarawa",
    formatted: "+12:00 Gilbert Islands Time - Tarawa",
    group: ["Pacific/Tarawa"],
  },
  {
    timeZoneName: "Pacific/Majuro",
    formatted: "+12:00 Marshall Islands Time - Majuro, Kwajalein",
    group: ["Pacific/Kwajalein", "Pacific/Majuro"],
  },
  {
    timeZoneName: "Pacific/Nauru",
    formatted: "+12:00 Nauru Time - Nauru",
    group: ["Pacific/Nauru"],
  },
  {
    timeZoneName: "Pacific/Norfolk",
    formatted: "+12:00 Norfolk Island Time - Norfolk",
    group: ["Pacific/Norfolk"],
  },
  {
    timeZoneName: "Pacific/Funafuti",
    formatted: "+12:00 Tuvalu Time - Funafuti",
    group: ["Pacific/Funafuti"],
  },
  {
    timeZoneName: "Pacific/Wake",
    formatted: "+12:00 Wake Island Time - Wake",
    group: ["Pacific/Wake"],
  },
  {
    timeZoneName: "Pacific/Wallis",
    formatted: "+12:00 Wallis & Futuna Time - Wallis",
    group: ["Pacific/Wallis"],
  },
  {
    timeZoneName: "Pacific/Fiji",
    formatted: "+13:00 Fiji Summer Time - Fiji",
    group: ["Pacific/Fiji"],
  },
  {
    timeZoneName: "Pacific/Auckland",
    formatted: "+13:00 New Zealand Time - Auckland",
    group: ["Pacific/Auckland"],
  },
  {
    timeZoneName: "Pacific/Enderbury",
    formatted: "+13:00 Phoenix Islands Time - Enderbury",
    group: ["Pacific/Enderbury"],
  },
  {
    timeZoneName: "Pacific/Fakaofo",
    formatted: "+13:00 Tokelau Time - Fakaofo",
    group: ["Pacific/Fakaofo"],
  },
  {
    timeZoneName: "Pacific/Tongatapu",
    formatted: "+13:00 Tonga Time - Tongatapu",
    group: ["Pacific/Tongatapu"],
  },
  {
    timeZoneName: "Pacific/Chatham",
    formatted: "+13:45 Chatham Time - Chatham",
    group: ["Pacific/Chatham"],
  },
  {
    timeZoneName: "Pacific/Apia",
    formatted: "+14:00 Apia Time - Apia",
    group: ["Pacific/Apia"],
  },
  {
    timeZoneName: "Pacific/Kiritimati",
    formatted: "+14:00 Line Islands Time - Kiritimati",
    group: ["Pacific/Kiritimati"],
  },
];

export default timezones;
