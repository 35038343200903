
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Guest Network",
  "info": "We will one guest network separate from the main network",
  "info5Ghz": "5Ghz band provides faster speeds.",
  "info2Ghz": "2.4Ghz band provides better coverage at slower speeds.",
  "sectionTitle": "Configure Guest Network",
  "nameRow": "Wireless Network Name",
  "passwordRow": "Password",
  "passwordPlaceholder": "•••••••••••••••"
}