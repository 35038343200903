import { IonIcon } from "@ionic/react";
import classNames from "classnames";
import { linkSharp } from "ionicons/icons";
import { UIStatus } from "model";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as LockIcon } from "../assets/images/svg-icons/lock-outline.svg";

interface Props {
  to: string;
  className?: string;
  title: string;
  isOuter?: boolean;
}
export default function NavigationLink({
  to,
  title,
  className = "",
  isOuter = false,
  ...otherProps
}: Props) {
  const LinkComponent = useMemo(() => {
    if (isOuter) {
      return (
        <a
          href={to}
          className="capitalize group transition text-gray-500 hover:text-gray-900  border-b border-solid border-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 rounded-sm"
        >
          {`${title} `}
          <IonIcon
            icon={linkSharp}
            className="text-gray-500 text-sm relative ml-1 group-hover:text-orange-500"
            style={{ top: 3 }}
          />
        </a>
      );
    }
    return (
      <Link
        to={to}
        className="capitalize group transition text-gray-500 hover:text-gray-900  border-b border-solid border-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 rounded-sm"
      >
        {`${title} `}
        <IonIcon
          icon={linkSharp}
          className="text-gray-500 text-sm relative ml-1 group-hover:text-orange-500"
          style={{ top: 3 }}
        />
      </Link>
    );
  }, [isOuter, title, to]);
  return (
    <span
      className={`block py-4 text-sm border-t border-gray-200 ${className}`}
      {...otherProps}
    >
      {LinkComponent}
    </span>
  );
}

type StatusLinkProps = {
  title: string;
  icon: any;
  to: string;
  status?: UIStatus | undefined;
  id?: string;
  locked?: boolean;
  disabled?: boolean;
};
const getStatus = (status?: UIStatus) => {
  if (status === 1) {
    return "after:block after:bg-orange";
  } else if (status === 2) {
    return "after:block after:bg-red-500";
  }
  return "";
};
export function StatusLink({
  title,
  icon,
  to,
  status,
  id,
  locked,
  disabled,
}: StatusLinkProps) {
  const disabledNavLinkClickHandler = (event: React.MouseEvent) => {
    if (disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  return (
    <li
      id={id}
      className={classNames(
        "focus-within:outline-none focus-within:ring-offset-2 rounded-sm"
      )}
    >
      <Link
        to={to}
        className={classNames(
          "flex items-center justify-center flex-col border-none",
          {
            "group transition text-gray-500 hover:text-gray-900  border-b border-solid border-gray-600  rounded-sm no-underline": !disabled,
            "text-gray-300 hover:text-gray-300 hover:cursor-not-allowed": disabled,
          }
        )}
        onClick={disabledNavLinkClickHandler}
        data-testid={disabled ? `disabled-${id}` : id}
      >
        <div
          className={classNames(
            "relative w-16 h-16 rounded-full fill-current bg-gray-50 flex justify-center items-center mb-2 transition group-hover:ring-orange-500 group-hover:ring-2 group-hover:ring-offset-2 group-hover:scale-105",
            "after:z-10 after:absolute after:top-0 after:right-0 after:w-3 after:h-3 after:rounded-full hover:border-2",
            {
              "ring-2 ring-offset-2 ring-orange-500 scale-100":
                to === window.location.pathname,
              "text-orange": !disabled,
              "text-gray-300": disabled,
            },
            getStatus(status)
          )}
          style={{ position: "relative" }}
        >
          {icon}
          {locked ? (
            <div>
              <div
                style={{
                  backgroundColor: "rgb(250,250,250)",
                  borderRadius: "1000px",
                  height: "20px",
                  width: "20px",
                  position: "absolute",
                  right: "3px",
                  bottom: "3px",
                }}
              ></div>
              <LockIcon
                style={{
                  color: "rgba(0.3,0.3,0.3,0.3)",
                  height: "20px",
                  width: "20px",
                  position: "absolute",
                  right: "3px",
                  bottom: "3px",
                }}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <span className="text-md font-semibold group-hover:text-orange-500 group-focus:text-orange-500">
          {title}
        </span>
      </Link>
    </li>
  );
}
