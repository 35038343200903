
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "infoGuest": "The guest network provides a safe place for guests to connect to.",
  "sectionTitle": "Configure Primary Network",
  "nameRow": "Wireless Network Name",
  "advancedRow": "Advanced",
  "advanced5GnameRow": "WiFi Network: {{networkName}} (5 GHz)",
  "advanced2GnameRow": "WiFi Network: {{networkName}} (2.4 GHz)",
  "advancedDisableWiFiNotificationTitle": "Disable WiFi",
  "advancedDisableWiFiNotificationCopy": "Are you sure you would like to turn off {{networkBand}} WiFi band? All connected devices will get disconnected!",
  "advancedSsidRow": "SSID",
  "advancedPasswordRow": "Password",
  "advancedStatusRow": "Status",
  "passwordRow": "Password",
  "passwordPlaceholder": "•••••••••••••••"
}
