
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Edit Data Cap",
  "disableDataCap": "Disable Data Cap",
  "capLabel": "Monthly Data Cap",
  "startDate": "Monthly Bill Date",
  "required": "Data Cap is required",
  "integer": "Data Cap must be an integer",
  "dataCap": "Data Cap"
}
