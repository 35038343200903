import { IonIcon } from "@ionic/react";
import { warning } from "ionicons/icons";
import React, { CSSProperties } from "react";

interface Props {
  text: string;
  style?: CSSProperties;
  className?: string;
}
const WarningMessage: React.FC<Props> = ({ text, className }: Props) => {
  return (
    <div className={`rounded-md bg-yellow-50 p-4 ${className}`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <IonIcon
            icon={warning}
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-yellow-800">Warning</h3>
          <div className="mt-2 text-sm text-yellow-700">
            <p>{text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarningMessage;
