import React from "react";
import { IonIcon } from "@ionic/react";
import { arrowUp, arrowDown } from "ionicons/icons";
import { classNames } from "utils";
import prettyBytes from "pretty-bytes";
import _ from "lodash";
import { useTranslation } from "react-i18next";

export interface StatType {
  name: string;
  stat: string;
  previousStat: string;
  change: string;
  changeType: "increase" | "decrease";
}

export function getStat({
  name,
  stat,
  previousStat,
}: {
  name: string;
  stat: number;
  previousStat: number;
}) {
  const number = (stat - previousStat) / previousStat;
  const changeNumber = _.round(number, 2);
  const change = _.isFinite(changeNumber)
    ? `${Math.round(changeNumber * 100)}%`
    : "";

  return {
    name,
    stat: prettyBytes(stat, { bits: false }),
    previousStat: prettyBytes(previousStat, { bits: false }),
    change,
    changeType: changeNumber > 0 ? "increase" : "decrease",
  } as StatType;
}

export default function Stat({
  item,
  chartSpan,
  startFromBillingDate,
  className,
}: {
  item: StatType;
  chartSpan: 1 | 7 | 30;
  startFromBillingDate: boolean;
  className?: string;
}) {
  const { t } = useTranslation();
  return (
    <div key={item.name} className={`px-4 py-5 sm:p-6 ${className}`}>
      <dt className="text-base font-normal text-gray-900">{item.name}</dt>
      <dd className="mt-1 flex justify-between items-baseline md:block">
        <div className="flex items-baseline text-2xl font-semibold text-orange mb-1">
          {item.stat}
          <span className="ml-2 text-xs font-medium text-gray-500">
            {`${t("profileOverview:usage.vs")} `}
            <span className="text-gray-600 text-sm">
              {item.previousStat}
            </span>{" "}
            {chartSpan === 1
              ? t("profileOverview:usage.yesterday")
              : chartSpan === 30
              ? `${
                  startFromBillingDate
                    ? t("profileOverview:usage.lastBillingCycle")
                    : t("profileOverview:usage.previous30Days")
                }`
              : t("profileOverview:usage.previous7Days")}
          </span>
        </div>
        <div
          className={classNames(
            item.changeType === "increase"
              ? "bg-red-100 text-red-800"
              : "bg-green-100 text-green-800",
            "inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0"
          )}
        >
          {item.changeType === "increase" ? (
            <IonIcon
              icon={arrowUp}
              className={classNames(
                "-ml-1 text-xs flex-shrink-0 self-center h-5 w-5 text-red-500",
                item.change && "mr-0.5"
              )}
              aria-hidden="true"
            />
          ) : (
            <IonIcon
              icon={arrowDown}
              className={classNames(
                "-ml-1 text-xs flex-shrink-0 self-center h-5 w-5 text-green-500",
                item.change && "mr-0.5"
              )}
              aria-hidden="true"
            />
          )}

          <span className="sr-only">
            {item.changeType === "increase"
              ? t("profileOverview:usage.increased")
              : t("profileOverview:usage.decreased")}{" "}
          </span>
          {item.change}
        </div>
      </dd>
    </div>
  );
}
