import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import PageLayout from "../../layouts/PageLayout";
import { Dispatch, RootState } from "../../store";
import { IonLoading } from "@ionic/react";
import { useSetState } from "ahooks";
import { useForm } from "react-hook-form";
import OnboardingSteps from "pages/onboarding/components/OnboardingSteps";
import { useTranslation } from "react-i18next";
import Input, { PasswordInput } from "components/Input";
import vest, { enforce, test } from "vest";
import i18n from "i18next";
import { vestResolver } from "@hookform/resolvers/vest";
import ErrorMessage from "components/ErrorMessage";
import Title from "components/Title";
import Button from "components/Button";
import InformationMessage from "components/InformationMessage";
import { ssidRegex } from "../../utils/regexValidators";

type Inputs = {
  name: string;
  password: string;
};

const validationSuite = vest.create((data: Inputs) => {
  test(
    "name",
    i18n.t("editNetwork:wifi.alphanum") +
      i18n.t("editNetwork:wifi.alphanum2") +
      i18n.t("editNetwork:wifi.alphanum3") +
      i18n.t("editNetwork:wifi.alphanum4") +
      i18n.t("editNetwork:wifi.alphanum5"),
    () => {
      enforce(data.name).matches(ssidRegex);
    }
  );
  test("name", i18n.t("editNetwork:wifi.required"), () => {
    enforce(data.name).isNotEmpty();
  });
  test("password", i18n.t("editNetwork:password.required"), () => {
    enforce(data.password).isNotEmpty();
  });
  test("password", i18n.t("editNetwork:password.alphanum"), () => {
    enforce(data.password).matches(new RegExp("^[ -~]{0,}$"));
  });
  test("password", i18n.t("editNetwork:password.min"), () => {
    enforce(63 >= data.password.length && data.password.length >= 8).isTruthy();
  });
});
interface Props {}
interface State {
  loading: boolean;
  error: any;
}
const OnboardingSetNetwork: React.FC<Props> = () => {
  const { adapter } = useSelector((state: RootState) => ({
    adapter: state.adapter.adapter!,
  }));
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const search = useLocation().search;
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const isGuest = query.get("network") === "guest";
  const defaultValues = {
    name: adapter!.settings[isGuest ? "guest" : "wifi"].ssid,
    password: "", // adapter!.settings[isGuest ? "guest" : "wifi"].key,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    mode: "all",
    resolver: vestResolver(validationSuite),
    defaultValues,
  });
  const [state, setState] = useSetState<State>({
    loading: false,
    error: undefined,
  });

  const goToNextScreen = () => {
    history.push("/onboarding/all-set");
  };
  const submit = ({ name, password }: Inputs) => {
    dispatch.adapter.configureWifi({
      wifiConfig: {
        id: adapter!.id,
        enabled: true,
        ssid: name.trim(),
        key: password,
      },
      setLoading: (loading: boolean) => setState({ loading }),
      onError: (error?: any) => setState({ error }),
      onSuccess: () => {
        goToNextScreen();
      },
    });
  };
  return (
    <PageLayout>
      <OnboardingSteps stage="setupWifi" />
      {state.loading ? <IonLoading isOpen message="Loading" /> : null}
      <Title title={t("onboarding:createNetwork.title")} className="mb-2" />
      <InformationMessage
        text={[
          "You can set the network name to the same name and password as your existing network to avoid needing to re-connect every device.",
          <br />,
          <strong>It is very important</strong>,
          " to disable your ISP router's Wi-Fi signal if you decide to go with this option. ",
          <a href="https://support.privacyhero.com/article/136-how-to-disable-isp-router-wifi">
            <strong>(Learn how to)</strong>
          </a>,
          <br />,
          "OR",
          <br />,
          "You can choose a new network name (like PrivacyHero) and move all of your devices to the new network.",
        ]}
        toggledText={[
          <br />,
          "Regardless of the above:",
          <br />,
          "We will create two separate Wi-Fi networks.",
        ]}
        moreList={[
          "NetworkName - 5Ghz band provides less coverage but transmits data at faster speeds.",
          "NetworkName2 - 2.4Ghz band provides coverage at a longer range but transmits data at slower speeds.",
        ]}
        className="mb-2"
      />
      <form>
        <div className="mb-4">
          <Input
            id="name"
            data-testid="name"
            type="text"
            label={t("editNetwork:wifi.label")}
            placeholder={t("editNetwork:wifi.placeholder")}
            inputProps={register("name")}
            error={errors.name?.message}
          />
        </div>
        <div className="mb-4">
          <PasswordInput
            id="password"
            data-testid="password"
            label={t("editNetwork:password.label")}
            placeholder={""} //{t("editNetwork:password.placeholder")}
            inputProps={register("password")}
            error={errors.password?.message}
          />
        </div>
        <ErrorMessage
          error={state.error}
          fallback={t("editNetwork:defaultError")}
        />
      </form>
      <Button
        data-testid="submit"
        type="button"
        disabled={!isValid}
        onClick={handleSubmit(submit)}
      >
        {t("onboarding:createNetwork.next")}
      </Button>
    </PageLayout>
  );
};

export default OnboardingSetNetwork;
