
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "NordVPN Codes",
  "subtitle": "Codes purchased",
  "purchaseCode": "Purchase a NordVPN code",
  "code": "Code",
  "type": "Type",
  "purchasedOn": "Purchased On"
}
