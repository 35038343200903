import React, { useState } from "react";
import { Link } from "react-router-dom";
import { trash } from "ionicons/icons";
import { ProfileDetails } from "../../../model";
import { ListRow } from "../../../components";
import BedtimeDisplay from "./BedtimeDisplay";
import InternetPause from "../../../components/InternetPause";
import { getIcon } from "../../../utils";
import DelayBedtime from "../../../components/DelayBedtime";
import { IonIcon } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "store";
import DeleteProfileModal from "./DeleteProfileModal";

interface Props {
  profile: ProfileDetails;
}

const ProfileRowComponent: React.FC<Props> = ({ profile }) => {
  const { bedtime } = profile.settings;
  const [confirmDelete, setConfirmDelete] = useState(false);
  // if before 6 AM, display previous day / convert SUN-SAT to MON-SUN
  const day = new global.Date().getDay();
  const isSchoolNight = bedtime["school-nights"][day];
  const startTime = isSchoolNight ? bedtime["school"] : bedtime["other"];
  const dispatch = useDispatch<Dispatch>();
  const { adapterId } = useSelector((state: RootState) => ({
    adapterId: state.adapter.adapter!.id,
  }));
  return (
    <>
      <DeleteProfileModal
        open={confirmDelete}
        profileId={profile.id}
        successCallback={() => dispatch.profile.getAdapterProfiles(adapterId)}
        closeModal={() => setConfirmDelete(false)}
      />
      <ListRow
        image={getIcon(profile.icon)}
        text={
          <Link
            className="truncate hover:text-orange-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 rounded-sm"
            to={`/profile/${profile.id}/details`}
          >
            {profile.name}
          </Link>
        }
        subInfo={<BedtimeDisplay profileId={profile.id} />}
      >
        <div className="actions" data-testid={`profile-row-${profile.id}`}>
          <ul className="list-unstyled center">
            {profile.settings.bedtime.enabled && startTime >= 0 && (
              <DelayBedtime profileId={profile.id} />
            )}
            <li
              key="internet-pause"
              className="has-left-divider has-right-divider"
            >
              <InternetPause
                name={profile.name}
                type="profile"
                pauseState={profile.settings["internet-pause"]}
                id={profile.id}
              />
            </li>
            <li key="delete-profile" className="">
              <button
                type="button"
                style={
                  profile.system
                    ? { visibility: "hidden", cursor: "unset" }
                    : {}
                }
                className="hover:text-red-500 hover:scale-125 px-1.5 py-1 transition unstyled status text-gr text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 text-orange focus:ring-orange-500"
                onClick={() => setConfirmDelete(true)}
                title="delete profile"
                data-testid={`delete-profile-${profile.id}`}
              >
                <IonIcon style={{ fontSize: 16 }} icon={trash} />
              </button>
            </li>
          </ul>
        </div>
      </ListRow>
    </>
  );
};

export default ProfileRowComponent;
