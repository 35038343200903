import React from "react";

import { Switch } from "@headlessui/react";
import classNames from "classnames";

interface ContainerProps {
  id: string;
  checked: boolean;
  change: (v: boolean) => void;
  label: string;
  className?: string;
  disabled?: boolean;
}

const CheckboxComponent: React.FC<ContainerProps> = ({
  id,
  change,
  checked,
  label,
  className = "",
  disabled,
}) => (
  <div className={`checkbox-custom ${className}`}>
    <input
      type="checkbox"
      disabled={disabled}
      id={id}
      alt={id}
      data-testid={id}
      checked={checked}
      onChange={(event) => change(event.target.checked)}
    />
    <label htmlFor={id}>
      <span className="label-container">{label}</span>
    </label>
  </div>
);
export default CheckboxComponent;

interface ToggleWithLabelProps {
  id?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  className?: string;
  labelSubtitle?: string;
  disabled?: boolean;
}
export function ToggleWithLabel({
  id,
  className,
  checked,
  onChange,
  label,
  disabled = false,
  labelSubtitle,
}: ToggleWithLabelProps) {
  return (
    <Switch.Group id={id} as="div" className={`flex items-center ${className}`}>
      <Switch
        data-testid={id}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
        className={classNames(
          checked ? "bg-orange" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer disabled:cursor-not-allowed transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            checked ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
      <Switch.Label as="span" className="ml-3">
        <span className="text-sm font-medium text-gray-900">{`${label}`}</span>
        {labelSubtitle ? (
          <span className="text-sm text-gray-500">({labelSubtitle})</span>
        ) : null}
      </Switch.Label>
    </Switch.Group>
  );
}
