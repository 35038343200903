import * as Flag from "../../assets/images/flags";

interface Country {
  name: string;
  code: string;
  flag?: any;
}

export default {
  AF: { name: "Afghanistan", code: "AF", flag: Flag.Afghanistan },
  AL: { name: "Albania", code: "AL", flag: Flag.Albania },
  DZ: { name: "Algeria", code: "DZ", flag: Flag.Algeria },
  AS: { name: "American Samoa", code: "AS", flag: Flag.AmericanSamoa },
  AD: { name: "Andorra", code: "AD", flag: Flag.Andorra },
  AO: { name: "Angola", code: "AO", flag: Flag.Angola },
  // AI: { name: 'Anguilla', code: 'AI', flag: Flag.Anguilla },
  // AQ: { name: 'Antarctica', code: 'AQ', flag: Flag.Antartica },
  AG: { name: "Antigua and Barbuda", code: "AG", flag: Flag.AntiguaAndBarbuda },
  AR: { name: "Argentina", code: "AR", flag: Flag.Argentina },
  AM: { name: "Armenia", code: "AM", flag: Flag.Armenia },
  AW: { name: "Aruba", code: "AW", flag: Flag.Aruba },
  AU: { name: "Australia", code: "AU", flag: Flag.Australia },
  AT: { name: "Austria", code: "AT", flag: Flag.Austria },
  AZ: { name: "Azerbaijan", code: "AZ", flag: Flag.Azerbaijan },
  BS: { name: "Bahamas", code: "BS", flag: Flag.Bahamas },
  BH: { name: "Bahrain", code: "BH", flag: Flag.Bahrain },
  BD: { name: "Bangladesh", code: "BD", flag: Flag.Bangladesh },
  BB: { name: "Barbados", code: "BB", flag: Flag.Barbados },
  BY: { name: "Belarus", code: "BY", flag: Flag.Belarus },
  BE: { name: "Belgium", code: "BE", flag: Flag.Belgium },
  BZ: { name: "Belize", code: "BZ", flag: Flag.Belize },
  BJ: { name: "Benin", code: "BJ", flag: Flag.Benin },
  // BM: { name: 'Bermuda', code: 'BM', flag: Flag.Bermuda },
  BT: { name: "Bhutan", code: "BT", flag: Flag.Bhutan },
  BO: { name: "Bolivia", code: "BO", flag: Flag.Boliva },
  // BQ: { name: 'Bonaire, Sint Eustatius and Saba', code: 'BQ', flag: Flag. },
  BA: {
    name: "Bosnia and Herzegovina",
    code: "BA",
    flag: Flag.BosniaAndHerzegovina,
  },
  BW: { name: "Botswana", code: "BW", flag: Flag.Botswana },
  // BV: { name: 'Bouvet Island', code: 'BV', flag: Flag.Bouvet },
  BR: { name: "Brazil", code: "BR", flag: Flag.Brazil },
  IO: {
    name: "British Indian Ocean Territory",
    code: "IO",
    flag: Flag.UnitedKingdom,
  },
  BN: { name: "Brunei Darussalam", code: "BN", flag: Flag.Brunei },
  BG: { name: "Bulgaria", code: "BG", flag: Flag.Bulgaria },
  BF: { name: "Burkina Faso", code: "BF", flag: Flag.BurkinaFaso },
  BI: { name: "Burundi", code: "BI", flag: Flag.Burundi },
  CV: { name: "Cabo Verde", code: "CV", flag: Flag.CaboVerde },
  KH: { name: "Cambodia", code: "KH", flag: Flag.Cambodia },
  CM: { name: "Cameroon", code: "CM", flag: Flag.Cameroon },
  CA: { name: "Canada", code: "CA", flag: Flag.Canada },
  // KY: { name: 'Cayman Islands', code: 'KY', flag: Flag.CaymanIslands },
  CF: {
    name: "Central African Republic",
    code: "CF",
    flag: Flag.CentralAfricanRepublic,
  },
  TD: { name: "Chad", code: "TD", flag: Flag.Chad },
  CL: { name: "Chile", code: "CL", flag: Flag.Chile },
  CN: { name: "China", code: "CN", flag: Flag.China },
  // CX: { name: 'Christmas Island', code: 'CX', flag: Flag.ChristmasIsland },
  // CC: { name: 'Cocos Islands', code: 'CC', flag: Flag.Cocos },
  CO: { name: "Colombia", code: "CO", flag: Flag.Colombia },
  KM: { name: "Comoros", code: "KM", flag: Flag.Comoros },
  CD: {
    name: "Democratic Republic of the Congo",
    code: "CD",
    flag: Flag.DPRofCongo,
  },
  CG: { name: "Congo", code: "CG", flag: Flag.Congo },
  // CK: { name: 'Cook Islands', code: 'CK', flag: Flag.CookIslands },
  CR: { name: "Costa Rica", code: "CR", flag: Flag.CostaRica },
  HR: { name: "Croatia", code: "HR", flag: Flag.Croatia },
  CU: { name: "Cuba", code: "CU", flag: Flag.Cuba },
  // CW: { name: 'Curaçao', code: 'CW', flag: Flag.Curacao },
  CY: { name: "Cyprus", code: "CY", flag: Flag.Cyprus },
  CZ: { name: "Czechia", code: "CZ", flag: Flag.Czechia },
  CI: { name: "Côte d'Ivoire", code: "CI", flag: Flag.CoteDIvoire },
  DK: { name: "Denmark", code: "DK", flag: Flag.Denmark },
  DJ: { name: "Djibouti", code: "DJ", flag: Flag.Djibouti },
  DM: { name: "Dominica", code: "DM", flag: Flag.Dominica },
  DO: { name: "Dominican Republic", code: "DO", flag: Flag.DominicanRepublic },
  EC: { name: "Ecuador", code: "EC", flag: Flag.Ecuador },
  EG: { name: "Egypt", code: "EG", flag: Flag.Egypt },
  SV: { name: "El Salvador", code: "SV", flag: Flag.ElSalvador },
  GQ: { name: "Equatorial Guinea", code: "GQ", flag: Flag.EquatorialGuinea },
  ER: { name: "Eritrea", code: "ER", flag: Flag.Eritrea },
  EE: { name: "Estonia", code: "EE", flag: Flag.Estonia },
  SZ: { name: "Eswatini", code: "SZ", flag: Flag.Eswatini },
  ET: { name: "Ethiopia", code: "ET", flag: Flag.Ethiopia },
  // FK: { name: 'Falkland Islands (Malvinas)', code: 'FK', flag: Flag. },
  // FO: { name: 'Faroe Islands', code: 'FO', flag: Flag.FaroeIslands },
  FJ: { name: "Fiji", code: "FJ", flag: Flag.Fiji },
  FI: { name: "Finland", code: "FI", flag: Flag.Finland },
  FR: { name: "France", code: "FR", flag: Flag.France },
  GF: { name: "French Guiana", code: "GF", flag: Flag.France },
  PF: { name: "French Polynesia", code: "PF", flag: Flag.France },
  TF: { name: "French Southern Territories", code: "TF", flag: Flag.France },
  GA: { name: "Gabon", code: "GA", flag: Flag.Gabon },
  GM: { name: "Gambia", code: "GM", flag: Flag.Gambia },
  GE: { name: "Georgia", code: "GE", flag: Flag.Georgia },
  DE: { name: "Germany", code: "DE", flag: Flag.Germany },
  GH: { name: "Ghana", code: "GH", flag: Flag.Ghana },
  // GI: { name: 'Gibraltar', code: 'GI', flag: Flag.Gibraltar },
  GR: { name: "Greece", code: "GR", flag: Flag.Greece },
  // GL: { name: 'Greenland', code: 'GL', flag: Flag.Greenland },
  GD: { name: "Grenada", code: "GD", flag: Flag.Grenada },
  // GP: { name: 'Guadeloupe', code: 'GP', flag: Flag.Guadeloupe },
  GU: { name: "Guam", code: "GU", flag: Flag.UnitedStates },
  GT: { name: "Guatemala", code: "GT", flag: Flag.Guatamala },
  // GG: { name: 'Guernsey', code: 'GG', flag: Flag.Guernsey },
  GN: { name: "Guinea", code: "GN", flag: Flag.Guinea },
  GW: { name: "Guinea - Bissau", code: "GW", flag: Flag.GuineaBissau },
  GY: { name: "Guyana", code: "GY", flag: Flag.Guyana },
  HT: { name: "Haiti", code: "HT", flag: Flag.Haiti },
  // HM: { name: 'Heard Island and McDonald Islands', code: 'HM', flag: Flag. },
  VA: { name: "Holy See", code: "VA", flag: Flag.HolySee },
  HN: { name: "Honduras", code: "HN", flag: Flag.Honduras },
  HK: { name: "Hong Kong", code: "HK", flag: Flag.China },
  HU: { name: "Hungary", code: "HU", flag: Flag.Hungary },
  IS: { name: "Iceland", code: "IS", flag: Flag.Iceland },
  IN: { name: "India", code: "IN", flag: Flag.India },
  ID: { name: "Indonesia", code: "ID", flag: Flag.Indonesia },
  IR: { name: "Iran", code: "IR", flag: Flag.Iran },
  IQ: { name: "Iraq", code: "IQ", flag: Flag.Iraq },
  IE: { name: "Ireland", code: "IE", flag: Flag.Ireland },
  // IM: { name: 'Isle of Man', code: 'IM', flag: Flag.IsleOfMan },
  IL: { name: "Israel", code: "IL", flag: Flag.Israel },
  IT: { name: "Italy", code: "IT", flag: Flag.Italy },
  JM: { name: "Jamaica", code: "JM", flag: Flag.Jamaica },
  JP: { name: "Japan", code: "JP", flag: Flag.Japan },
  // JE: { name: 'Jersey', code: 'JE', flag: Flag.Jersey },
  JO: { name: "Jordan", code: "JO", flag: Flag.Jordan },
  KZ: { name: "Kazakhstan", code: "KZ", flag: Flag.Kazakhstan },
  KE: { name: "Kenya", code: "KE", flag: Flag.Kenya },
  KI: { name: "Kiribati", code: "KI", flag: Flag.Kiribati },
  KP: { name: "Korea (KP)", code: "KP", flag: Flag.DPRofKorea },
  KR: { name: "Korea (KR)", code: "KR", flag: Flag.SouthKorea },
  KW: { name: "Kuwait", code: "KW", flag: Flag.Kuwait },
  KG: { name: "Kyrgyzstan", code: "KG", flag: Flag.Kyrgyzstan },
  LA: {
    name: "Lao People's Democratic Republic ",
    code: "LA",
    flag: Flag.Laos,
  },
  LV: { name: "Latvia", code: "LV", flag: Flag.Latvia },
  LB: { name: "Lebanon", code: "LB", flag: Flag.Lebanon },
  LS: { name: "Lesotho", code: "LS", flag: Flag.Lesotho },
  LR: { name: "Liberia", code: "LR", flag: Flag.Liberia },
  LY: { name: "Libya", code: "LY", flag: Flag.Libya },
  LI: { name: "Liechtenstein", code: "LI", flag: Flag.Liechtensten },
  LT: { name: "Lithuania", code: "LT", flag: Flag.Lithuania },
  LU: { name: "Luxembourg", code: "LU", flag: Flag.Luxembourg },
  // MO: { name: 'Macao', code: 'MO', flag: Flag.Macao },
  MG: { name: "Madagascar", code: "MG", flag: Flag.Madagascar },
  MW: { name: "Malawi", code: "MW", flag: Flag.Malawi },
  MY: { name: "Malaysia", code: "MY", flag: Flag.Malaysia },
  MV: { name: "Maldives", code: "MV", flag: Flag.Maldives },
  ML: { name: "Mali", code: "ML", flag: Flag.Malawi },
  MT: { name: "Malta", code: "MT", flag: Flag.Malta },
  MH: { name: "Marshall Islands", code: "MH", flag: Flag.MarshallIslands },
  // MQ: { name: 'Martinique', code: 'MQ', flag: Flag.Martinique },
  MR: { name: "Mauritania", code: "MR", flag: Flag.Mauritania },
  MU: { name: "Mauritius", code: "MU", flag: Flag.Mauritius },
  // YT: { name: 'Mayotte', code: 'YT', flag: Flag.Mayotte },
  MX: { name: "Mexico", code: "MX", flag: Flag.Mexico },
  FM: { name: "Micronesia", code: "FM", flag: Flag.Micronesia },
  MD: { name: "Moldova", code: "MD", flag: Flag.Moldova },
  MC: { name: "Monaco", code: "MC", flag: Flag.Monaco },
  MN: { name: "Mongolia", code: "MN", flag: Flag.Mongolia },
  ME: { name: "Montenegro", code: "ME", flag: Flag.Montenegro },
  // MS: { name: 'Montserrat', code: 'MS', flag: Flag.Monsterrat },
  MA: { name: "Morocco", code: "MA", flag: Flag.Morocco },
  MZ: { name: "Mozambique", code: "MZ", flag: Flag.Mozambique },
  MM: { name: "Myanmar", code: "MM", flag: Flag.Myanmar },
  NA: { name: "Namibia", code: "NA", flag: Flag.Namibia },
  NR: { name: "Nauru", code: "NR", flag: Flag.Nauru },
  NP: { name: "Nepal", code: "NP", flag: Flag.Nepal },
  NL: { name: "Netherlands", code: "NL", flag: Flag.Netherlands },
  // NC: { name: 'New Caledonia', code: 'NC', flag: Flag.NewCaledonia },
  NZ: { name: "New Zealand", code: "NZ", flag: Flag.NewZealand },
  NI: { name: "Nicaragua", code: "NI", flag: Flag.Nicaragua },
  NE: { name: "Niger", code: "NE", flag: Flag.Niger },
  NG: { name: "Nigeria", code: "NG", flag: Flag.Nigeria },
  // NU: { name: 'Niue', code: 'NU', flag: Flag.Niue },
  // NF: { name: 'Norfolk Island', code: 'NF', flag: Flag.NorfolkIsland },
  // MP: { name: 'Northern Mariana Islands', code: 'MP', flag: Flag. },
  NO: { name: "Norway", code: "NO", flag: Flag.Norway },
  OM: { name: "Oman", code: "OM", flag: Flag.Oman },
  PK: { name: "Pakistan", code: "PK", flag: Flag.Pakistan },
  PW: { name: "Palau", code: "PW", flag: Flag.Palau },
  PS: { name: "Palestine", code: "PS", flag: Flag.Palestine },
  PA: { name: "Panama", code: "PA", flag: Flag.Panama },
  PG: { name: "Papua New Guinea", code: "PG", flag: Flag.PapuaNewGuinea },
  PY: { name: "Paraguay", code: "PY", flag: Flag.Paraguay },
  PE: { name: "Peru", code: "PE", flag: Flag.Peru },
  PH: { name: "Philippines", code: "PH", flag: Flag.Philipines },
  // PN: { name: 'Pitcairn', code: 'PN', flag: Flag.Pitcairn },
  PL: { name: "Poland", code: "PL", flag: Flag.Poland },
  PT: { name: "Portugal", code: "PT", flag: Flag.Portugal },
  PR: { name: "Puerto Rico", code: "PR", flag: Flag.UnitedStates },
  QA: { name: "Qatar", code: "QA", flag: Flag.Qatar },
  MK: { name: "North Macedonia", code: "MK", flag: Flag.NorthMacedonia },
  RO: { name: "Romania", code: "RO", flag: Flag.Romania },
  RU: { name: "Russia", code: "RU", flag: Flag.Russia },
  RW: { name: "Rwanda", code: "RW", flag: Flag.Rwanda },
  // RE: { name: 'Réunion', code: 'RE', flag: Flag.Reunion },
  // BL: { name: 'Saint Barthélemy', code: 'BL', flag: Flag. },
  // SH: { name: 'Saint Helena', code: 'SH', flag: Flag.Saint },
  KN: {
    name: "Saint Kitts and Nevis",
    code: "KN",
    flag: Flag.SaintKittsAndNevis,
  },
  LC: { name: "Saint Lucia", code: "LC", flag: Flag.SaintLucia },
  // MF: { name: 'Saint Martin', code: 'MF', flag: Flag.Saint },
  // PM: { name: 'Saint Pierre and Miquelon', code: 'PM', flag: Flag. },
  VC: { name: "Saint Vincent", code: "VC", flag: Flag.SaintVincentGrenadines },
  WS: { name: "Samoa", code: "WS", flag: Flag.Samoa },
  SM: { name: "San Marino", code: "SM", flag: Flag.SanMarino },
  ST: {
    name: "Sao Tome and Principe",
    code: "ST",
    flag: Flag.SaoTomeAndPrincipe,
  },
  SA: { name: "Saudi Arabia", code: "SA", flag: Flag.SaudiArabia },
  SN: { name: "Senegal", code: "SN", flag: Flag.Senegal },
  RS: { name: "Serbia", code: "RS", flag: Flag.Serbia },
  SC: { name: "Seychelles", code: "SC", flag: Flag.Seychelles },
  SL: { name: "Sierra Leone", code: "SL", flag: Flag.SierraLeone },
  SG: { name: "Singapore", code: "SG", flag: Flag.Singapore },
  // SX: { name: 'Sint Maarten', code: 'SX', flag: Flag. },
  SK: { name: "Slovakia", code: "SK", flag: Flag.Slovakia },
  SI: { name: "Slovenia", code: "SI", flag: Flag.Slovenia },
  SB: { name: "Solomon Islands", code: "SB", flag: Flag.SolomonIslands },
  SO: { name: "Somalia", code: "SO", flag: Flag.Somalia },
  ZA: { name: "South Africa", code: "ZA", flag: Flag.SouthAfrica },
  // GS: { name: 'South Georgia', code: 'GS', flag: Flag. },
  SS: { name: "South Sudan", code: "SS", flag: Flag.SouthSudan },
  ES: { name: "Spain", code: "ES", flag: Flag.Spain },
  LK: { name: "Sri Lanka", code: "LK", flag: Flag.SriLanka },
  SD: { name: "Sudan", code: "SD", flag: Flag.Sudan },
  SR: { name: "Suriname", code: "SR", flag: Flag.Suriname },
  // SJ: { name: 'Svalbard and Jan Mayen', code: 'SJ', flag: Flag.Sval },
  SE: { name: "Sweden", code: "SE", flag: Flag.Sweden },
  CH: { name: "Switzerland", code: "CH", flag: Flag.Switzerland },
  SY: { name: "Syria", code: "SY", flag: Flag.Syria },
  // TW: { name: 'Taiwan', code: 'TW', flag: Flag.Taiwan },
  TJ: { name: "Tajikistan", code: "TJ", flag: Flag.Tajikistan },
  TZ: { name: "Tanzania", code: "TZ", flag: Flag.Tanzania },
  TH: { name: "Thailand", code: "TH", flag: Flag.Thailand },
  TL: { name: "Timor - Leste", code: "TL", flag: Flag.TimorLeste },
  TG: { name: "Togo", code: "TG", flag: Flag.Togo },
  // TK: { name: 'Tokelau', code: 'TK', flag: Flag.Tokelau },
  TO: { name: "Tonga", code: "TO", flag: Flag.Tonga },
  TT: { name: "Trinidad and Tobago", code: "TT", flag: Flag.TrinidadAndTabago },
  TN: { name: "Tunisia", code: "TN", flag: Flag.Tunisia },
  TR: { name: "Turkey", code: "TR", flag: Flag.Turkey },
  // TM: { name: 'Turkmenistan', code: 'TM', flag: Flag.Turkmenistan },
  // TC: { name: 'Turks and Caicos Islands', code: 'TC', flag: Flag. },
  TV: { name: "Tuvalu", code: "TV", flag: Flag.Tuvalu },
  UG: { name: "Uganda", code: "UG", flag: Flag.Uganda },
  UA: { name: "Ukraine", code: "UA", flag: Flag.Ukraine },
  AE: {
    name: "United Arab Emirates",
    code: "AE",
    flag: Flag.UnitedArabEmirates,
  },
  GB: { name: "United Kingdom", code: "GB", flag: Flag.UnitedKingdom },
  UM: { name: "United States (UM)", code: "UM", flag: Flag.UnitedStates },
  US: { name: "United States", code: "US", flag: Flag.UnitedStates },
  UY: { name: "Uruguay", code: "UY", flag: Flag.Uruguay },
  UZ: { name: "Uzbekistan", code: "UZ", flag: Flag.Uzbekistan },
  VU: { name: "Vanuatu", code: "VU", flag: Flag.Vanuatu },
  VE: { name: "Venezuela", code: "VE", flag: Flag.Venezuela },
  VN: { name: "Viet Nam", code: "VN", flag: Flag.Vietnam },
  VG: {
    name: "Virgin Islands (British)",
    code: "VG",
    flag: Flag.UnitedKingdom,
  },
  VI: { name: "Virgin Islands (US)", code: "VI", flag: Flag.UnitedStates },
  // WF: { name: 'Wallis and Futuna', code: 'WF', flag: Flag. },
  // EH: { name: 'Western Sahara', code: 'EH', flag: Flag. },
  YE: { name: "Yemen", code: "YE", flag: Flag.Yemen },
  ZM: { name: "Zambia", code: "ZM", flag: Flag.Zambia },
  ZW: { name: "Zimbabwe", code: "ZW", flag: Flag.Zimbabwe },
  // AX: { name: 'Åland Islands', code: 'AX', flag: Flag. },

  YYC: { name: "", code: "YYC", flag: Flag.Canada },
  YYJ: { name: "", code: "YYJ", flag: Flag.Canada },
  YYZ: { name: "", code: "YYZ", flag: Flag.Canada },
  YOW: { name: "", code: "YOW", flag: Flag.Canada },

  TPA: { name: "", code: "TPA", flag: Flag.UnitedStates },
  SLC: { name: "", code: "SLC", flag: Flag.UnitedStates },
} as { [code: string]: Country };
