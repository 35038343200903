import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import PageLayout from "../../layouts/PageLayout";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "store";
import { useSetState } from "ahooks";
import { IonLoading } from "@ionic/react";
import Title from "components/Title";
import { useTranslation } from "react-i18next";
import SettingsRow from "components/SettingsRow";
import { ListRow, Toggle } from "components";

const GuestNetworkComponent: React.FC<{}> = () => {
  const history = useHistory();
  const { adapter } = useSelector((state: RootState) => ({
    adapter: state.adapter.adapter!,
  }));
  const isAdapterOffline = !adapter.services.online.state;
  const [state, setState] = useSetState<{ loading: boolean; error: string }>({
    loading: false,
    error: "",
  });
  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "guest_network" });
  }, []);
  const dispatch = useDispatch<Dispatch>();

  const disableGuestNetwork = (wantedState: boolean) => {
    dispatch.adapter.configureWifi({
      wifiConfig: {
        ssid: adapter.settings.guest.ssid,
        key: adapter.settings.guest.key,
        id: adapter.id,
        enabled: wantedState,
        guest: true,
      },
      setLoading: (loading: boolean) => setState({ loading }),
      onError: (err?: string) =>
        setState({ error: err || "Error configuring guest network settings" }),
    });
  };
  const { t } = useTranslation();
  return (
    <PageLayout back={history.goBack}>
      {state.loading ? (
        <IonLoading isOpen message="Saving Guest network configuration" />
      ) : null}
      <Title title={t("settings:guestNetworkScreen.title")} />
      <div>
        <ul className="data-list">
          <ListRow
            text={t("settings:guestNetworkScreen.toggleLabel")}
            className="mb-2"
          >
            <Toggle
              id="disable-data-cap"
              disabled={isAdapterOffline}
              checked={adapter.settings.guest.enabled}
              onChange={(isDisabled) => {
                disableGuestNetwork(isDisabled);
              }}
            />
          </ListRow>
        </ul>

        {adapter.settings.guest.enabled ? (
          <>
            <SettingsRow
              title={t("settings:guestNetworkScreen.name")}
              updateText={t("settings:update")}
              value={adapter?.settings?.guest?.ssid || ""}
              updateLink="/settings/edit-network?network=guest"
            />
            <SettingsRow
              title={t("settings:guestNetworkScreen.password")}
              updateText={t("settings:update")}
              value={adapter?.settings?.guest?.key || ""}
              updateLink="/settings/edit-network?network=guest"
            />
          </>
        ) : null}
      </div>
    </PageLayout>
  );
};

export default GuestNetworkComponent;
