import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { chevronDown } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import PageLayout from "../layouts/PageLayout";
import { ListRow, Toggle } from "../components";
import {
  AdapterListItem,
  UIServiceState,
  VPNProvider,
  ClientInfoField,
} from "../model";
import { createPollAndRefresh } from "../utils";
import api from "../api";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "store";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import Input, { PasswordInput } from "components/Input";
import RadioSelector from "components/RadioSelector";
import vest, { enforce, test } from "vest";
import Button from "components/Button";
import { useHistory } from "react-router-dom";

///////////////////////////////////////////////////////////////
//   MAIN COMPONENT
///////////////////////////////////////////////////////////////
interface SettingsProps {
  adapterId: string;
  pheroVpn: boolean;
}

const Settings: React.FC<SettingsProps> = ({ adapterId, pheroVpn }) => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  const { t } = useTranslation("securitySettings");

  const { adapterDetails } = useSelector((state: RootState) => ({
    adapterDetails: state.adapter.adapter!,
  }));

  const providerData = useSelector(
    (state: RootState) => state.vpn.adapterVPNProviderMap
  );

  type ConfigServices = "adblocking" | "malware" | "vpn" | "vpn-killswitch";
  type ServiceStatus = {
    [key in ConfigServices]: UIServiceState | undefined;
  };
  const [serviceStatus, setServiceStatus] = useState<ServiceStatus>({
    adblocking: undefined,
    malware: undefined,
    vpn: undefined,
    "vpn-killswitch": undefined,
  });
  const [
    providerSelectionVisible,
    setProviderSelectionVisible,
  ] = useState<boolean>(false);
  const [providerGroups, setProviderGroups] = useState<string[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<string>("");
  const [groupRadioOptions, setGroupRadioOptions] = useState<any[]>([]);
  const [selectedVPNProvider, setSelectedVPNProvider] = useState<VPNProvider>();
  const [currentFieldValues, setCurrentFieldValues] = useState<{
    [field: string]: string;
  }>({});
  const [fieldErrorMessages, setFieldErrorMessages] = useState<{
    [field: string]: string;
  }>({});
  const [optionalSectionVisible, setOptionalSectionVisible] = useState<boolean>(
    false
  );
  const [
    optionalSectionExpanded,
    setOptionalSectionExpanded,
  ] = useState<boolean>(false);
  const [submitEnabled, setSubmitEnabled] = useState<boolean>(false);

  const validateField = vest.create((data: any) => {
    test(data.field.field, t("validationError"), () => {
      enforce(data.value).matches(data.field.options.validation);
    });
  });

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "security_settings" });

    Promise.resolve(dispatch.vpn.getVPNProviders())
      .then((vpn_providers) => {
        let groupNames: string[] = [];
        vpn_providers.forEach((provider) => {
          if (
            isProviderSupported(provider) &&
            groupNames.indexOf(provider.group_name) === -1
          ) {
            groupNames.push(provider.group_name);
          }
        });
        setProviderGroups(groupNames);
      })
      .catch((error) => {
        console.log("Error getting VPN providers: ", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateStateFromAdapter();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerGroups]);

  useEffect(() => {
    let options: any[] = [];
    providerData.forEach((provider) => {
      if (
        isProviderSupported(provider) &&
        provider.group_name === selectedGroup
      ) {
        options.push({
          label: provider.display_name,
          value: provider.provider,
        });
      }
    });
    setGroupRadioOptions(options);

    if (
      adapterDetails.settings.vpn !== undefined &&
      adapterDetails.settings.vpn.length > 0
    ) {
      const vpnProvider = providerData.find(
        (vpnProvider) =>
          vpnProvider.provider === adapterDetails.settings.vpn![0].provider
      );
      if (
        vpnProvider !== undefined &&
        vpnProvider.group_name === selectedGroup
      ) {
        setSelectedVPNProvider(vpnProvider);
      } else {
        const vpnProvider = providerData.find(
          (vpnProvider) =>
            isProviderSupported(vpnProvider) &&
            vpnProvider.group_name === selectedGroup
        );
        setSelectedVPNProvider(vpnProvider);
      }
    } else {
      const vpnProvider = providerData.find(
        (vpnProvider) =>
          isProviderSupported(vpnProvider) &&
          vpnProvider.group_name === selectedGroup
      );
      setSelectedVPNProvider(vpnProvider);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedVPNProvider !== undefined) {
      setOptionalSectionVisible(
        selectedVPNProvider.creds.filter(
          (field) => field.optional && !field.options.hidden
        ).length > 0
      );

      setSelectedGroup(selectedVPNProvider.group_name);

      const credentials = adapterDetails.credentials.find(
        (credentials) => credentials.provider === selectedVPNProvider.provider
      );
      let newFieldValues: { [field: string]: string } = {};
      let newOptionalSectionExpanded = false;
      selectedVPNProvider.creds.forEach((field) => {
        if (
          credentials !== undefined &&
          field.field in credentials &&
          credentials[field.field as keyof typeof credentials]
        ) {
          newFieldValues[field.field] = credentials[
            field.field as keyof typeof credentials
          ] as string;

          if (field.optional) {
            newOptionalSectionExpanded = true;
          }
        } else {
          newFieldValues[field.field] =
            field.options.selections.length > 0
              ? field.options.selections[0]
              : "";
        }
      });
      setCurrentFieldValues(newFieldValues);
      setOptionalSectionExpanded(newOptionalSectionExpanded);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVPNProvider]);

  useEffect(() => {
    if (selectedVPNProvider !== undefined) {
      let fieldErrorMessages: { [field: string]: string } = {};
      let allFieldsReady = true;

      selectedVPNProvider.creds.forEach((field) => {
        if (currentFieldValues[field.field] !== "") {
          if (field.options.validation) {
            const validationState = validateField({
              field: field,
              value: currentFieldValues[field.field],
            });

            fieldErrorMessages[field.field] =
              validationState.getErrors(field.field).shift() || "";

            if (validationState.hasErrors()) {
              allFieldsReady = false;
            }
          } else {
            fieldErrorMessages[field.field] = "";
          }
        } else {
          fieldErrorMessages[field.field] = "";

          if (!field.optional) {
            allFieldsReady = false;
          }
        }
      });

      setFieldErrorMessages(fieldErrorMessages);
      setSubmitEnabled(allFieldsReady);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFieldValues]);

  useEffect(() => {
    if (!optionalSectionExpanded && selectedVPNProvider !== undefined) {
      let newFieldValues = {
        ...currentFieldValues,
      };
      selectedVPNProvider.creds.forEach((field) => {
        if (field.optional) {
          newFieldValues[field.field] =
            field.options.selections.length > 0
              ? field.options.selections[0]
              : "";
        }
      });
      setCurrentFieldValues(newFieldValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionalSectionExpanded]);

  const isProviderSupported = (provider: VPNProvider) => {
    const appVersion = adapterDetails.fw_status.fw.os.version
      .split("-")[0]
      .substring(1);
    return (
      appVersion.localeCompare(provider.supported_version, undefined, {
        numeric: true,
      }) >= 0
    );
  };

  const updateStateFromAdapter = () => {
    if (
      adapterDetails.settings.vpn !== undefined &&
      adapterDetails.settings.vpn.length > 0
    ) {
      const vpnProvider = providerData.find(
        (vpnProvider) =>
          vpnProvider.provider === adapterDetails.settings.vpn![0].provider
      );
      if (vpnProvider !== undefined) {
        setSelectedVPNProvider(vpnProvider);
      } else {
        setSelectedGroup(providerGroups[0]);
      }
    } else {
      setSelectedGroup(providerGroups[0]);
    }
  };

  const onSave = () => {
    if (selectedVPNProvider !== undefined) {
      setProviderSelectionVisible(false);
      let vpnConfig = {
        id: adapterId,
        provider: selectedVPNProvider.provider,
        country: undefined,
        creds: currentFieldValues,
      };
      dispatch.vpn
        .configureVPNProvider({
          config: vpnConfig,
          updateUi: (state) => {
            setServiceStatus({ ...serviceStatus, vpn: state });

            if (state === "Failed!") {
              updateStateFromAdapter();
            }
          },
          display_name: selectedVPNProvider.group_name,
        })
        .catch(() => {
          setServiceStatus({ ...serviceStatus, vpn: "Failed!" });
        });
    }
  };

  const onCancel = () => {
    setProviderSelectionVisible(false);
    updateStateFromAdapter();
  };

  const onFieldChange = (field: string, value: string) => {
    setCurrentFieldValues({ ...currentFieldValues, [field]: value });
  };

  const onServiceToggled = (service: ConfigServices, state: boolean) => {
    setServiceStatus({ ...serviceStatus, [service]: "Updating" });
    api.adapter
      .changeConfigurationService({
        id: adapterId,
        service,
        state,
      })
      .then(() =>
        createPollAndRefresh<AdapterListItem | undefined>(
          () => dispatch.adapter.getAdapter(adapterId),
          (adapter?) => adapter?.services[service]?.state === state,
          (state) => setServiceStatus({ ...serviceStatus, [service]: state }),
          10,
          1500
        )
      )
      .catch(() =>
        setServiceStatus({ ...serviceStatus, [service]: "Failed!" })
      );
  };

  return (
    <PageLayout testId="security-settings">
      <div className="module-large">
        <Title title={t("title")} />
        <ul className="data-list">
          <ListRow
            key="vpn_selection"
            text={t("vpn")}
            status={serviceStatus.vpn}
            disabled={!adapterDetails.services.online.state}
            className="self-center flex flex-col justify-between w-full"
          >
            <div className="flex flex-col justify-between w-full">
              <div className="flex flex-row justify-end w-full relative">
                {/* VPN Selection Goes Here */}
                {providerSelectionVisible && (
                  <div
                    id="provider_selection"
                    className="flex flex-col justify-around items-start x-40 bg-gray-100 rounded-xl shadow-2xl drop-shadow-lg absolute z-20 px-5 py-4 position-abs-center-horizontally"
                  >
                    <h2 className="mt-2 mb-5 text-left text-xl font-semibold whitespace-nowrap">
                      Change VPN service provider
                    </h2>

                    <div className="flex flex-row justify-start items-center w-full gap-3">
                      <label
                        htmlFor="Provider Selection"
                        className="text-md text-center font-medium my-3 whitespace-nowrap bold text-gray-500"
                      >
                        {t("vpnProviderSelect")}
                      </label>
                      <div className="select-container w-full">
                        <select
                          id="provider"
                          data-testid="provider-select"
                          value={selectedGroup}
                          className="w-full mt-1 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-orange focus:border-orange sm:text-sm rounded-md"
                          onChange={(e) => {
                            setSelectedGroup(e.currentTarget.value);
                          }}
                        >
                          {providerGroups.map((groupName) => (
                            <option
                              data-testid={`vpngroup-${groupName}`}
                              key={groupName}
                              value={groupName}
                            >
                              {groupName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {groupRadioOptions.length > 1 && (
                      <div
                        className="flex flex-row"
                        style={{ paddingTop: "10px" }}
                      >
                        <RadioSelector
                          onSelect={(value) => {
                            const vpnProvider = providerData.find(
                              (vpnProvider) => vpnProvider.provider === value
                            );
                            setSelectedVPNProvider(vpnProvider);
                          }}
                          options={groupRadioOptions}
                          value={selectedVPNProvider?.provider!}
                          title={""}
                        />
                      </div>
                    )}

                    <div className="flex flex-col justify-start items-center w-full mt-5 gap-3">
                      {selectedVPNProvider?.creds.map(
                        (credsData) =>
                          !credsData.optional && (
                            <CredsField
                              credsData={credsData}
                              fieldValue={currentFieldValues[credsData.field]}
                              fieldErrorMessage={
                                fieldErrorMessages[credsData.field]
                              }
                              onChange={onFieldChange}
                            />
                          )
                      )}
                    </div>

                    {optionalSectionVisible && (
                      <>
                        <div
                          className="flex flex-row justify-between items-center w-full mt-5 select-none cursor-pointer"
                          onClick={() => {
                            setOptionalSectionExpanded(
                              !optionalSectionExpanded
                            );
                          }}
                        >
                          <h4 className="m-0 p-0">
                            Advanced options:
                            <span
                              className={classNames("ml-1 font-semibold", {
                                "is-active text-rose-500": !optionalSectionExpanded,
                                "text-green-600": optionalSectionExpanded,
                              })}
                            >
                              {optionalSectionExpanded ? "On" : "Off"}
                            </span>
                          </h4>
                          <IonIcon
                            icon={chevronDown}
                            className={classNames(
                              optionalSectionExpanded
                                ? "-rotate-180"
                                : "rotate-0",
                              "h-6 w-6 transform"
                            )}
                            aria-hidden="true"
                          />
                        </div>

                        {optionalSectionExpanded && (
                          <div className="flex flex-col justify-start items-center w-full mt-2 gap-3">
                            {selectedVPNProvider?.creds.map(
                              (credsData) =>
                                credsData.optional && (
                                  <CredsField
                                    credsData={credsData}
                                    fieldValue={
                                      currentFieldValues[credsData.field]
                                    }
                                    fieldErrorMessage={
                                      fieldErrorMessages[credsData.field]
                                    }
                                    onChange={onFieldChange}
                                  />
                                )
                            )}
                          </div>
                        )}
                      </>
                    )}

                    {!pheroVpn && selectedVPNProvider?.provider === "phero" && (
                      <div className="flex flex-row justify-center items-center mt-5">
                        <p style={{ whiteSpace: "pre-line", width: "80%" }}>
                          {t("pheroVpnMessage")}
                        </p>
                      </div>
                    )}

                    <div className="flex flex-row justify-end items-center w-full mt-5">
                      {(pheroVpn ||
                        selectedVPNProvider?.provider !== "phero") && (
                        <Button
                          data-testid="submit"
                          className="text-center mr-3"
                          size="small"
                          type="button"
                          disabled={!submitEnabled}
                          onClick={onSave}
                        >
                          Save
                        </Button>
                      )}
                      {!pheroVpn && selectedVPNProvider?.provider === "phero" && (
                        <Button
                          data-testid="submit"
                          className="text-center mr-3"
                          size="small"
                          type="button"
                          disabled={false}
                          onClick={() =>
                            history.push(
                              `/account/upgrade/${adapterDetails.id}`
                            )
                          }
                        >
                          Upgrade
                        </Button>
                      )}
                      <Button
                        data-testid="back_select_provider"
                        className="text-center"
                        size="small"
                        type="button"
                        variant="white"
                        disabled={false}
                        onClick={onCancel}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                )}
                <div
                  id="provider_status"
                  className="flex flex-col items-center grow flex-1 justify-center gap-3"
                >
                  <div className="flex flex-row items-center justify-center">
                    <label
                      htmlFor="Provider Selection Name"
                      className="text-md mx-5 py-3 text-center font-medium bold text-gray-500"
                    >
                      {selectedVPNProvider?.group_name}
                    </label>
                    <Button
                      data-testid="select_provider"
                      className="text-center"
                      type="button"
                      size="small"
                      disabled={!adapterDetails.services.online.state}
                      onClick={() => setProviderSelectionVisible(true)}
                    >
                      Change
                    </Button>
                  </div>
                  <div>
                    <label
                      htmlFor="Connected To"
                      className="text-md mx-5 py-3 text-center font-medium bold text-gray-500"
                    >
                      {adapterDetails.services?.vpn?.state === true &&
                      adapterDetails.status?.vpn !== 2 &&
                      adapterDetails.status?.vpn_connections?.[
                        adapterDetails.status.active_server!
                      ]?.provider === selectedVPNProvider?.provider &&
                      adapterDetails.status?.vpn_connections?.[
                        adapterDetails.status.active_server!
                      ]?.up === true
                        ? "Connected to " +
                          adapterDetails.status?.vpn_connections?.[
                            adapterDetails.status.active_server!
                          ]?.connected_to
                        : "Not connected"}
                      {adapterDetails.status?.vpn_connections?.[
                        adapterDetails.status.active_server!
                      ]?.error !== undefined &&
                      adapterDetails.status?.vpn_connections?.[
                        adapterDetails.status.active_server!
                      ]?.error !== null &&
                      adapterDetails.status?.vpn_connections?.[
                        adapterDetails.status.active_server!
                      ]?.error !== ""
                        ? "(" +
                          adapterDetails.status?.vpn_connections?.[
                            adapterDetails.status.active_server!
                          ]?.error +
                          ")"
                        : null}
                    </label>
                  </div>
                </div>
                <div className="self-center">
                  <Toggle
                    onChange={(state) => onServiceToggled("vpn", state)}
                    checked={adapterDetails.services.vpn.state}
                    id="vpn-active"
                    disabled={!adapterDetails.services.online.state}
                  />
                </div>
              </div>
              {/* VPN Selection Goes Here */}
            </div>
          </ListRow>
          <ListRow
            text={t("vpn_killswitch")}
            status={serviceStatus["vpn-killswitch"]}
            disabled={!adapterDetails.services.online.state}
          >
            <Toggle
              onChange={(state) => onServiceToggled("vpn-killswitch", state)}
              checked={adapterDetails.services["vpn-killswitch"].state}
              id="vpn-killswitch"
              disabled={!adapterDetails.services.online.state}
            />
          </ListRow>
          <ListRow
            key="phishing"
            text={t("malware")}
            status={serviceStatus.malware}
            disabled={!adapterDetails.services.online.state}
          >
            <Toggle
              onChange={(state) => onServiceToggled("malware", state)}
              checked={adapterDetails.services.malware.state}
              id="malware-phishing"
              disabled={!adapterDetails.services.online.state}
            />
          </ListRow>
          <ListRow
            text={t("adBlocking")}
            status={serviceStatus.adblocking}
            disabled={!adapterDetails.services.online.state}
          >
            <Toggle
              onChange={(state) => onServiceToggled("adblocking", state)}
              checked={adapterDetails.services.adblocking.state}
              id="adblocking"
              disabled={!adapterDetails.services.online.state}
            />
          </ListRow>
        </ul>
      </div>
    </PageLayout>
  );
};

export default Settings;

///////////////////////////////////////////////////////////////
//   CREDS FIELD COMPONENT
///////////////////////////////////////////////////////////////
interface CredsFieldProps {
  credsData: ClientInfoField;
  fieldValue: string;
  fieldErrorMessage: string;
  onChange: any;
}

const CredsField: React.FC<CredsFieldProps> = ({
  credsData,
  fieldValue,
  fieldErrorMessage,
  onChange,
}) => {
  return (
    <>
      {!credsData.options.hidden && (
        <div className="basis-full w-full">
          {!credsData.options.password &&
            credsData.options.selections.length === 0 && (
              <Input
                id={credsData.field}
                data-testid={credsData.field}
                label={credsData.prompt}
                inputProps={{
                  value: fieldValue,
                  onChange: (e: any) => {
                    onChange(credsData.field, e.target.value);
                  },
                }}
                error={fieldErrorMessage}
                type="text"
                placeholder=""
                useTextArea={credsData.options.multiline}
              />
            )}
          {credsData.options.password && (
            <PasswordInput
              inputTagClassname="pr-8"
              id={credsData.field}
              data-testid={credsData.field}
              label={credsData.prompt}
              inputProps={{
                autoComplete: "new-password",
                value: fieldValue,
                onChange: (e: any) => {
                  onChange(credsData.field, e.target.value);
                },
              }}
              error={fieldErrorMessage}
              placeholder=""
            />
          )}
          {credsData.options.selections.length > 0 && (
            <div className="flex flex-col">
              <label className="pb-1 block text-sm whitespace-nowrap font-medium text-gray-700">
                {credsData.prompt}
              </label>
              <select
                id={credsData.field}
                data-testid={credsData.field}
                className="block w-full pl-3 pr-10 px-3 py-3 text-base border-gray-300 focus:outline-none focus:ring-orange focus:border-orange sm:text-sm rounded-md"
                value={fieldValue}
                onChange={(e) => {
                  onChange(credsData.field, e.currentTarget.value);
                }}
              >
                {credsData.options.selections.map((selection) => (
                  <option
                    data-testid={`${credsData.field}-${selection}`}
                    key={`${credsData.field}-${selection}`}
                    value={selection}
                  >
                    {selection}
                  </option>
                ))}
              </select>
            </div>
          )}
          {credsData.options.html_extra && (
            <div
              className="pb-0 mb-0 mt-2"
              dangerouslySetInnerHTML={{
                __html: credsData.options.html_extra,
              }}
            />
          )}
        </div>
      )}
    </>
  );
};
