
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "wifi": "Wi-Fi",
  "devices": "Devices",
  "vpn": "VPN",
  "data": "Data",
  "home": "Home",
  "profiles": "Profiles",
  "profilesSubtitle": "Created By Me",
  "streaming": "Streaming", 
  "settings": "Router Settings", 
  "account": "Account",
  "noProfilesMessage": "You haven’t created any Profiles yet. Create custom Profiles for quick access.",
  "currentPing": "Your current ping",
  "devicesTitle": "Devices",
  "devicesSubtitle": "Usage this month",
  "allProfiles": "See All Profiles",
  "relocationOff": "Off",
  "pressable": {
    "streamingSettings": "Settings",
    "createProfile": "Create New Profile",
    "newSpeedTest": "New Speed Test",
    "seeNetworkUsage": "See all network usage",
    "allProfiles": "See all profiles"
  },
  "runningSpeedTest": "Running Speed Test",
  "noSpeedTestData": "Speed test data unavailable",
  "noRelocatedServices": "No services relocated. Click on settings to relocate services",
  "speedTestTypeVPN": "VPN",
  "speedTestTypeVPNDescription": "Speed test through VPN",
  "speedTestTypeISP": "ISP",
  "speedTestTypeISPDescription": "Speed test via your Internet service provider",
  "speedTestDateLabel": "Last run"
}
