import http from "axios";
import { GET_DEVICES_ROUTE, DEVICE_SETTING_URL } from "../constants";
import {
  DeviceDetails,
  DeviceServiceUpdate,
  DeviceUsage,
  InternetStateStatus,
  StateStatus,
} from "../model";
import { createMock } from "./utils";

export function getDevices(profileOrAdapterOrDeviceId?: string) {
  let route = GET_DEVICES_ROUTE;
  if (profileOrAdapterOrDeviceId) {
    route += `?id=${profileOrAdapterOrDeviceId}`;
  }
  return http.get<DeviceDetails[]>(route);
}

export const mockGetAllDevices = createMock<DeviceDetails[], undefined>(
  GET_DEVICES_ROUTE,
  "GET"
);
export const mockGetDevicesById = (profileOrAdapterOrDeviceId: string) =>
  createMock<DeviceDetails[], undefined>(
    `${GET_DEVICES_ROUTE}?id=${profileOrAdapterOrDeviceId}`,
    "GET"
  );

interface DeviceSettingUpdate {
  id: string;
  service: DeviceServiceUpdate;
  setting: number | boolean;
}
export function setDeviceSettings(update: DeviceSettingUpdate) {
  return http.post<StateStatus | InternetStateStatus>(
    DEVICE_SETTING_URL,
    update
  );
}
export const mockSetDeviceSettings = createMock<
  StateStatus | InternetStateStatus,
  DeviceSettingUpdate
>(DEVICE_SETTING_URL, "POST");

export interface DeviceType {
  name: string;
  icon: string;
  group: string;
}
export const GET_DEVICE_TYPES = "/v1/device/types";
export function getDeviceTypes() {
  return http.get<DeviceType[]>(GET_DEVICE_TYPES);
}
export const mockGetDeviceTypes = createMock<DeviceType[], undefined>(
  GET_DEVICE_TYPES,
  "GET"
);

export interface DataUsageParams {
  id: string;
  resolution: "Hourly" | "Daily";
  start: number;
  end?: number;
}
export const GET_DEVICE_USAGE = "/v1/device/usage";
export function getDeviceUsage(params: DataUsageParams) {
  return http.get<DeviceUsage[]>(GET_DEVICE_USAGE, { params });
}
export const mockGetDeviceUsage = createMock<DeviceUsage[], undefined>(
  GET_DEVICE_USAGE,
  "GET"
);
