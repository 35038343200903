import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { ToggleWithLabel } from "./Checkbox";
import UsageBar from "./UsageBar";
import prettyBytes from "pretty-bytes";

// Charts
import { Line, LineConfig, Column, ColumnConfig } from "@ant-design/charts";

import { useSetState } from "ahooks";
import { RootState } from "store";
import { arrowUp, arrowDown } from "ionicons/icons";
import { getDataCapDisplayValues, insertUrlParam } from "utils";
import { IonIcon, IonLoading } from "@ionic/react";
import { DataUsagePoint, useDataUsage } from "utils/hooks";
import { Link, useLocation } from "react-router-dom";
import Stat from "./Stat";
import {
  format,
  getDaysInMonth,
  isWithinInterval,
  sub,
  subWeeks,
} from "date-fns";
import { getPreviousDateByDay } from "utils/time";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
interface Props {
  id: string;
  type: "device" | "adapter" | "profile";
  chartType?: "line" | "bar";
}

interface Options {
  span: State["chartSpan"];
  today: Date;
  startDateDay: number;
}
export function getBillingPeriodPosition({
  span,
  today,
  startDateDay,
}: Options) {
  let position: number | undefined;
  const todaysDay = today.getDate();
  if (span === 30 || (span === 7 && todaysDay - startDateDay <= 7)) {
    return today.setDate(startDateDay);
  }
  return position;
}

interface State {
  chartSpan: 7 | 1 | 30;
  data: Array<{
    time: number;
    value: number;
    category: "Current usage" | "Previous usage";
    download: number;
    upload: number;
  }>;
  startFromBillingDate: boolean;
}

const UsageChart: React.FC<Props> = ({ id, type, chartType = "line" }) => {
  const { t } = useTranslation();
  const tabs = useMemo<
    {
      name: string;
      href: string;
      chartSpan: State["chartSpan"];
    }[]
  >(
    () => [
      {
        name: t("profileOverview:usageTabs.today"),
        href: "#today",
        chartSpan: 1,
      },
      {
        name: t("profileOverview:usageTabs.sevenDays"),
        href: "#7-days",
        chartSpan: 7,
      },
      {
        name: t("profileOverview:usageTabs.thirtyDays"),
        href: "#30-days",
        chartSpan: 30,
      },
    ],
    [t]
  );

  const [
    includePreviousPeriodStats,
    setIncludePreviousPeriodStats,
  ] = useState<boolean>(false);

  //TODO refactor, use native useState hook, get rid of useSetState
  const [state, setState] = useSetState<State>({
    chartSpan: 7,
    data: [],
    startFromBillingDate: true,
  });
  const today = useMemo(() => new Date(), []);
  const { dataCapOrigin } = useSelector((state: RootState) => ({
    dataCapOrigin: state.adapter.adapter!.settings.datacap,
  }));
  const search = useLocation().search;
  const query = useMemo(() => new URLSearchParams(search), [search]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataCapDay = useMemo(() => dataCapOrigin.day, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const dataCap = useMemo(() => dataCapOrigin.cap * 1000 * 1000, []);

  const { chartSpan } = state;
  useEffect(() => {
    if (query.has("span")) {
      const queryChartSpan = query.get("span");
      if (queryChartSpan) {
        const sp = +queryChartSpan;
        if (sp === 1 || sp === 7 || sp === 30) {
          setState({ chartSpan: sp });
        }
      }
    }
    if (query.has("show_previous")) {
      const showPrevious = query.get("show_previous");
      if (showPrevious && showPrevious === "true") {
        setIncludePreviousPeriodStats(true);
      }
    }
    if (query.has("start_from_billing")) {
      const startFromBillingDate = query.get("start_from_billing");
      if (startFromBillingDate) {
        if (startFromBillingDate === "true") {
          setState({ startFromBillingDate: true });
        }
      }
    }
  }, [query, chartSpan, setState]);
  const canStartFromBillingDate = useMemo(() => {
    return (
      type === "adapter" &&
      (chartSpan === 30 ||
        (chartSpan === 7 &&
          isWithinInterval(getPreviousDateByDay(dataCapDay, today), {
            start: subWeeks(today, 1),
            end: today,
          })))
    );
  }, [chartSpan, dataCapDay, today, type]);
  const { data, loading, error, stats, currentPeriodTotalUsage } = useDataUsage(
    {
      chartSpan,
      id,
      type,
      today,
      startFromBillingDate: state.startFromBillingDate,
      canStartFromBillingDate,
    }
  );

  const billingPeriodDate = useMemo<number | undefined>(
    () =>
      getBillingPeriodPosition({
        span: state.chartSpan,
        today: new Date(new Date(today).setHours(0)),
        startDateDay: dataCapDay,
      }),
    [dataCapDay, state.chartSpan, today]
  );
  const dataToDisplay = useMemo(() => {
    if (includePreviousPeriodStats) {
      return data;
    } else {
      return data.filter(({ category }) => category === "Current usage");
    }
  }, [includePreviousPeriodStats, data]);
  const noData = useMemo(
    () => dataToDisplay.every((item) => item.value === 0),
    [dataToDisplay]
  );

  // @ts-ignore
  const annotations: LineConfig["annotations"] = useMemo(() => {
    // const cap = getDataCapDisplayValues(dataCapOrigin);
    const daysInMonth = getDaysInMonth(new Date());
    const dailyDataCap = dataCap ? dataCap / daysInMonth : 0;
    const spanForDataCap =
      chartSpan === 30 ? daysInMonth : chartSpan === 7 ? 9 : chartSpan;

    const spanCap = getDataCapDisplayValues({
      cap:
        ((chartSpan === 30 ? daysInMonth : chartSpan) * dailyDataCap) /
        1000 /
        1000,
      day: 1,
      since: 1,
    });

    const todaysDay = today.getDate();
    let dataCapPositionStart = [billingPeriodDate, dailyDataCap];
    let dataCapPositionEnd = ["max", spanForDataCap * dailyDataCap];
    let lastMonthBillingPeriodDate;
    if (
      billingPeriodDate &&
      todaysDay <= new Date(billingPeriodDate).getDate()
    ) {
      lastMonthBillingPeriodDate = sub(billingPeriodDate, {
        months: 1,
      }).getTime();
      dataCapPositionStart = [lastMonthBillingPeriodDate, dailyDataCap];
    }
    return [
      dataCap && billingPeriodDate && canStartFromBillingDate && !noData
        ? {
            type: "line",
            start: [lastMonthBillingPeriodDate || billingPeriodDate, "min"],
            end: [lastMonthBillingPeriodDate || billingPeriodDate, "max"],
            text: {
              content: "Billing date",
              position: "left",
              offsetX: 18,
              style: { textAlign: "right" },
            },
            style: {
              lineDash: [4, 4],
            },
          }
        : undefined,
      dataCap &&
        type === "adapter" &&
        !noData && {
          type: "line",
          start: dataCapPositionStart,
          end: dataCapPositionEnd,
          text: {
            content: `${
              chartSpan === 7 ? "Weekly" : chartSpan === 1 ? "Daily" : ""
            } Data Cap (${_.round(spanCap.cap, 2)} ${spanCap.unit})`,
            position: "start",
          },
          style: {
            lineDash: [4, 4],
          },
        },
      // dataCap &&
      //   type === "adapter" && {
      //     type: "regionFilter",
      //     start: ["min", dataCap],
      //     end: ["max", "max"],
      //     color: "red",
      //   },
    ].filter(Boolean);
  }, [
    dataCap,
    chartSpan,
    today,
    billingPeriodDate,
    canStartFromBillingDate,
    noData,
    type,
  ]);

  // TODO split line and bar chart into separate components
  const lineChartConfig = useMemo<LineConfig>(
    () => ({
      data: dataToDisplay,
      height: 400,
      xField: "time",
      yField: "value",
      smooth: true,
      animation: false,
      seriesField: "category",
      tooltip: {
        enterable: true,
        // @ts-ignore
        customContent: (
          title,
          data: Array<{
            color: string;
            name: string;
            title: string;
            value: string;
            data: DataUsagePoint;
          }>
        ) => {
          return (
            <div className="p-2">
              <span className="text-sm">{title}</span>
              <div className="mt-2">
                {data.map((item) => {
                  return (
                    <div
                      key={`${item.name}-${item.value}`}
                      className="mb-1 flex flex-row justify-between"
                    >
                      <div className="flex flex-row items-center">
                        <div
                          className="rounded-full w-2 h-2 mr-2"
                          style={{ backgroundColor: item.color }}
                        ></div>
                        <span className="text-xs">
                          {item.name}
                          <span
                            className={
                              item.data.category === "Current usage"
                                ? "ml-2"
                                : ""
                            }
                          >
                            :
                          </span>
                          <IonIcon
                            icon={arrowDown}
                            className="text-green-500 -mr-0.5 ml-2"
                            aria-hidden="true"
                          />{" "}
                          {prettyBytes(item.data.download, { bits: false })}
                          <IonIcon
                            icon={arrowUp}
                            className="text-purple-500 -mr-0.5 ml-2"
                            aria-hidden="true"
                          />{" "}
                          {prettyBytes(item.data.upload, { bits: false })}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        },
      },
      point: {
        size: 5,
        shape: "diamond",
      },
      color: ["#FDA23F", "black"],
      annotations,
      meta: {
        value: {
          formatter: (value: number) => prettyBytes(value, { bits: false }),
          nice: true,
        },
        time: {
          type: "time",
          mask: chartSpan === 7 ? "ddd" : chartSpan === 30 ? "DD MMM" : "ha",
        },
      },
    }),
    [dataToDisplay, annotations, chartSpan]
  );

  const dataToDisplayFormattedForBarChart = dataToDisplay.reduce(
    (acc: any, cur: any) => {
      const currentDayTrafficStats = includePreviousPeriodStats
        ? [
            {
              type: cur.category,
              category: cur.category,
              value: cur.value,
              upload: cur.upload,
              download: cur.download,
              time: cur.time,
              timestamp: cur.timestamp,
            },
          ]
        : [
            {
              type: "Upload",
              category: cur.category,
              value: cur.upload,
              summedUpUploadAndDownload: cur.value,
              time: cur.time,
              timestamp: cur.timestamp,
            },
            {
              type: "Download",
              category: cur.category,
              value: cur.download,
              summedUpUploadAndDownload: cur.value,
              time: cur.time,
              timestamp: cur.timestamp,
            },
          ];
      return [...acc, ...currentDayTrafficStats];
    },
    []
  );

  const barChartBarsColors = includePreviousPeriodStats
    ? ["#FDA23F", "#b9a8d4"]
    : ["#a3d2cc", "#FDA23F"];

  // TODO split line and bar chart into separate components
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const barChartConfig = useMemo<ColumnConfig>(
    () => ({
      data: dataToDisplayFormattedForBarChart,
      isStack: true,
      height: 400,
      autoFit: true,
      animation: false,
      xField: "time",
      yField: "value",
      seriesField: "type",
      interactions: [
        {
          type: "active-region",
          enable: false,
        },
      ],
      connectedArea: {
        style: (oldStyle: any, element: any) => {
          return {
            fill: "rgba(0,0,0,0.25)",
            stroke: oldStyle.fill,
            lineWidth: 0.5,
          };
        },
      },
      smooth: true,
      tooltip: {
        enterable: true,
        // @ts-ignore
        customContent: (
          title,
          data: Array<{
            color: string;
            name: string;
            title: string;
            value: string;
            data: DataUsagePoint;
          }>
        ) => {
          return (
            <div className="p-2">
              <span className="text-sm">{title}</span>
              <div className="mt-2">
                {includePreviousPeriodStats ? (
                  data.map((item) => {
                    return (
                      <div
                        key={`${item.name}-${item.value}`}
                        className="mb-1 flex flex-row justify-between"
                      >
                        <div className="flex flex-row items-center">
                          <div
                            className="rounded-full w-2 h-2 mr-2"
                            style={{ backgroundColor: item.color }}
                          ></div>
                          <span className="text-xs">
                            {item.name}
                            <span
                              className={
                                item.data.category === "Current usage"
                                  ? "ml-2"
                                  : ""
                              }
                            >
                              :
                            </span>
                            <IonIcon
                              icon={arrowDown}
                              className="text-green-500 ml-2 mr-1"
                              aria-hidden="true"
                            />
                            {prettyBytes(item.data.download, { bits: false })}
                            <IonIcon
                              icon={arrowUp}
                              className="text-purple-500 ml-2 mr-1"
                              aria-hidden="true"
                            />
                            {prettyBytes(item.data.upload, { bits: false })}
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div
                    key={`${data[0]?.title}-${data[0]?.value}`}
                    className="mb-1 flex flex-row justify-between"
                  >
                    <div className="flex flex-row items-center">
                      <span className="text-xs">
                        Usage
                        <span
                          className={
                            data[0]?.data.category === "Current usage"
                              ? "ml-2"
                              : ""
                          }
                        >
                          :
                        </span>
                        <IonIcon
                          icon={arrowDown}
                          className="text-green-500 ml-2 mr-1"
                          aria-hidden="true"
                        />
                        {prettyBytes(data[1]?.data?.value || 0, {
                          bits: false,
                        })}
                        <IonIcon
                          icon={arrowUp}
                          className="text-purple-500 ml-2 mr-1"
                          aria-hidden="true"
                        />
                        {prettyBytes(data[0]?.data?.value || 0, {
                          bits: false,
                        })}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        },
      },
      point: {
        size: 5,
        shape: "diamond",
      },
      color: barChartBarsColors,
      options: {},
      annotations,
      legend: {
        layout: "horizontal",
        position: "top",
      },
      meta: {
        value: {
          formatter: (value: number) => prettyBytes(value, { bits: false }),
          nice: true,
        },
        time: {
          type: "time",
          mask: chartSpan === 7 ? "ddd" : chartSpan === 30 ? "DD MMM" : "ha",
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataToDisplayFormattedForBarChart, annotations, chartSpan]
  );

  if (error) {
    return (
      <span className="text-red-500">
        Error loading data. Please try again later or contact support
      </span>
    );
  }
  if (loading && data.length === 0) {
    return <IonLoading message="Loading data..." isOpen></IonLoading>;
  }

  return (
    <div className="chart-container">
      <div className="block">
        <nav className="flex space-x-4" aria-label="Tabs">
          {tabs.map((tab) => (
            <Link
              key={tab.name}
              to={`?tab=usage&span=${tab.chartSpan}`}
              className={classNames(
                tab.chartSpan === chartSpan
                  ? "bg-gray-100 text-gray-700"
                  : "text-gray-500 hover:text-gray-700",
                "no-underline px-3 py-2 font-medium text-sm rounded-md",
                "focus:ring focus:ring-orange"
              )}
              onClick={() => {
                setState({ chartSpan: tab.chartSpan });
              }}
              aria-current={tab.chartSpan === chartSpan ? "page" : undefined}
            >
              {tab.name}
            </Link>
          ))}
        </nav>
      </div>
      <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-2 md:divide-y-0 md:divide-x">
        {stats.map((item, index) => (
          <Stat
            className={classNames({
              "border-solid border-b border-gray-200 md:border-b-0 md:border-r":
                index === 0,
            })}
            key={`${item.stat}-${item.name}`}
            item={item}
            chartSpan={chartSpan}
            startFromBillingDate={state.startFromBillingDate}
          />
        ))}
      </dl>
      {type !== "adapter" && (
        <div className="my-4">
          <UsageBar
            chartSpan={chartSpan}
            startFromBillingDate={state.startFromBillingDate}
            id={id}
            type={type}
            total={currentPeriodTotalUsage}
          />
        </div>
      )}
      <div className="module-large">
        {chartType === "bar" && <Column {...barChartConfig} />}
        {chartType === "line" && <Line {...lineChartConfig} />}
        <ToggleWithLabel
          id="previous"
          checked={includePreviousPeriodStats}
          onChange={(includePreviousPeriodStats) => {
            setIncludePreviousPeriodStats(includePreviousPeriodStats);
            if (includePreviousPeriodStats) {
              insertUrlParam("show_previous", "true");
            } else {
              insertUrlParam("show_previous", "false");
            }
          }}
          label={`${t("profileOverview:usage.showPrevious")} ${
            chartSpan === 1 ? "day" : chartSpan === 7 ? "week" : "month"
          }`}
          className="mt-4"
        />
        {canStartFromBillingDate ? (
          // eslint-disable-next-line react/jsx-no-undef
          <ToggleWithLabel
            id="start-from-billing-date"
            checked={state.startFromBillingDate}
            onChange={(startFromBillingDate) => {
              setState({ startFromBillingDate });
              if (startFromBillingDate) {
                insertUrlParam("start_from_billing", "true");
              } else {
                insertUrlParam("start_from_billing", "false");
              }
            }}
            label={`${t(
              "profileOverview:usage.startFromBillingDate"
            )} (${format(getPreviousDateByDay(dataCapDay, today), "d MMM")})`}
            className="mt-1"
          />
        ) : null}
      </div>
    </div>
  );
};

export default UsageChart;
