
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "editTitle": "Edit Profile",
  "createTitle": "Create Profile",
  "namePlaceholder": "",
  "nameLabel": "Profile Name",
  "nameRequiredMessage": "Profile Name is required.",
  "nameSizeLimitMessage": "Profile name should have less than 64 characters.",
  "iconSelectorTitle": "Profile Icon",
  "deleteButton": "Delete Profile",
  "cannotDeleteMessage": "Cannot delete a profile that has devices. You need to move them to another profile.",
  "manageDevices": "Manage Devices",
  "fallbackNetworkError": "Profile name already exists.",
  "nameTooLong": "Ensure this value has at most 64 characters."
}
