
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "NordVPN Special Deals",
  "purchase": "Purchase",
  "purchaseLocked": "Deals are limited to customers who have an active subscription with payment method on file"
}
