
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "speedTestTitle": "Internet Speed",
  "usageTitle": "Internet Usage",
  "usageSubtitle": "Over time",
  "dataCapLink": "Data Cap",
  "dataCapLinkEdit": "Edit"
}
