import React, { useEffect, useState } from "react";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import Title from "components/Title";
import NavigationLink from "components/NavigationLink";
import Button from "components/Button";
import { Invoice } from "model";
import api from "../../api";
import { tryCatch } from "../../utils";

const InvoicesContainer: React.FC<{}> = () => {
  const { t } = useTranslation("invoices");
  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [showAll, setShowAll] = useState<Boolean>(false);
  const [balance, setBalance] = useState<Number>(0);

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "invoices" });
    getNewCodeList();
  }, []);

  const getNewCodeList = async () => {
    let invoiceListRes = await tryCatch(api.subscription.getInvoiceList());
    if (invoiceListRes.error) {
      console.log("Error fetching invoices: ", invoiceListRes.error);
    } else {
      setInvoices(invoiceListRes.result.data.invoices);
      setBalance(invoiceListRes.result.data.balance);
    }
  };

  return (
    <div className="py-0 px-8 mx-[-32px]">
      <Title
        title={t("title")}
        subtitle={`${invoices.length} ${t("subtitle")}${
          invoices.length === 1 ? "" : "s"
        } Available - ${
          showAll || invoices.length < 5 ? t("showingAll") : t("showingRecent")
        }`}
        className="mt-7"
      />
      {balance < 0 && (
        <div
          className="py-4"
          style={{ border: "1px solid d8d8d8" }}
        >{`You have a credit balance of $${Number(-balance / 100).toFixed(
          2
        )}. This amount will be applied to your next payment automatically.`}</div>
      )}
      {invoices.length > 0 && (
        <ul className="list-none pl-0">
          <div
            style={{
              display: "flex",
              padding: "20px 0px",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  width: "25%",
                  display: "inline-block",
                  alignItems: "center",
                }}
              >
                {t("created")}
              </div>
              <div
                style={{
                  width: "25%",
                  display: "inline-block",
                  alignItems: "center",
                }}
              >
                {t("status")}
              </div>
              <div
                style={{
                  width: "25%",
                  display: "inline-block",
                  alignItems: "center",
                }}
              >
                {t("total")}
              </div>
              <div
                style={{
                  width: "25%",
                  display: "inline-block",
                  alignItems: "center",
                }}
              >
                {t("download")}
              </div>
            </div>
            {invoices.map((invoice, i) => {
              if (showAll || i < 5) {
                return (
                  <InvoiceRow
                    invoice={invoice}
                    key={`${invoice.invoice_id}_${i}`}
                  />
                );
              } else {
                return null;
              }
            })}
          </div>
        </ul>
      )}
      {invoices.length > 5 && (
        <button
          className={classNames(
            "hover:cursor-allowed group transition text-gray-500 hover:text-gray-900  border-b border-solid border-gray-600  rounded-sm no-underline"
          )}
          onClick={() => {
            setShowAll(!showAll);
          }}
        >
          {!showAll ? t("showAll") : t("hideAll")}
        </button>
      )}
    </div>
  );
};

interface InvoiceRowProps {
  invoice: Invoice;
}

const InvoiceRow: React.FC<InvoiceRowProps> = ({ invoice }) => {
  const { t } = useTranslation("invoices");

  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className={"text-gray-500"}
      key={`${invoice.invoice_id}`}
    >
      <div
        style={{
          width: "25%",
          display: "inline-block",
          alignItems: "center",
          wordWrap: "break-word",
        }}
      >
        <p className="sm:inline">
          {new Date(invoice.created * 1000).toLocaleDateString()}
        </p>
      </div>
      <div
        style={{
          width: "25%",
          display: "inline-block",
          alignItems: "center",
          wordWrap: "break-word",
        }}
      >
        <p className="sm:inline">{invoice.status}</p>
      </div>
      <div
        style={{
          width: "25%",
          display: "inline-block",
          alignItems: "center",
          wordWrap: "break-word",
        }}
      >
        <p className="sm:inline">{`$ ${Number(invoice.total / 100).toFixed(
          2
        )}`}</p>
      </div>
      <div
        style={{
          width: "25%",
          display: "inline-block",
          alignItems: "center",
          wordWrap: "break-word",
        }}
      >
        <Button
          data-testid="updateSubscription"
          size="small"
          onClick={() => {
            window.open(invoice.invoice_pdf, "_blank");
          }}
        >
          {t("download")}
        </Button>
      </div>
    </div>
  );
};

export default InvoicesContainer;
