
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Subscribe",
  "subscribe": "Subscribe",
  "vpnReason": "Your account level does not include VPN service.\n\n If you'd like to upgrade your account to include this feature and more, please choose one of the upgrade options below:",
  "pheroVpnReason": "Your account level does not include Privacy Hero VPN service.\n\n If you'd like to upgrade your account to include this feature and more, please choose one of the upgrade options below:",
  "streamingReason": "Your account level does not include Streaming Relocation services.\n\n If you'd like to upgrade your account to include this feature and more, please choose one of the upgrade options below:",
  "profilesReason": "Your account level does not include Saved Profile services.\n\n If you'd like to upgrade your account to include this feature and more, please choose one of the upgrade options below:",
  "dataReason": "Your account level does not include Data services.\n\n If you'd like to upgrade your account to include this feature and more, please choose one of the upgrade options below:",
  "deviceReason": "Your account level does not include Device services.\n\n If you'd like to upgrade your account to include this feature and more, please choose one of the upgrade options below:",
  "noReason": ""
}
