import React, { useState, useEffect, useMemo } from "react";
import http from "axios";
import { useParams } from "react-router-dom";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import imagePlaceholder from "../../assets/images/icons/placeholder_64.png";

import { DeviceDetails } from "../../model";
import PageLayout from "../../layouts/PageLayout";
import { ListRow } from "../../components";
import { IonLoading } from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "store";
import { useDeviceIcons } from "utils/hooks";
import { useTranslation } from "react-i18next";
import Title from "components/Title";

interface Props {
  adapterId: string;
}

const ManageDevicesComponent: React.FC<Props> = ({ adapterId }) => {
  const { profileId } = useParams<{ profileId: string }>();
  const { profiles } = useSelector((state: RootState) => ({
    profiles: state.profile.profileIds.map((id) => ({
      id,
      name: state.profile.profilesMap[id].name,
      system: state.profile.profilesMap[id].system,
      devices: state.profile.profileDevicesMap[id] || [],
    })),
  }));
  const [loading, setLoading] = useState<boolean>(true);
  const activeProfile = profiles.find((profile) => profile.id === profileId);
  const otherProfiles = profiles.filter((profile) => profile.id !== profileId);
  const dispatch = useDispatch<Dispatch>();
  const groupByProfiles = () => {
    setLoading(true);
    dispatch.profile
      .getAdapterProfiles(adapterId)
      // .then((result) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "manage_profile" });
    groupByProfiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adapterId]);

  // const updateProfiles = (profile: ProfileDetails) => setProfiles((prev) => {
  //   const update = [...prev];
  //   const index = update.findIndex((p) => p.id === profile.id);
  //   update[index] = profile;
  //   return update;
  // });
  const removeDevice = (device: DeviceDetails) => {
    const systemProfile = otherProfiles.find((p) => p.system);
    if (systemProfile) {
      http
        .post("/v1/device/assign", {
          id: device.id,
          profile: systemProfile.id,
        })
        .then(() => groupByProfiles());
    }
  };
  const addDevice = (device: DeviceDetails) => {
    if (activeProfile) {
      http
        .post("/v1/device/assign", {
          id: device.id,
          profile: activeProfile.id,
        })
        .then(() => groupByProfiles());
    }
  };
  const deviceTypes = useDeviceIcons();

  // NOTE: This will need to change once icons are added
  const getImage = (type: string) => {
    if (!deviceTypes) {
      return imagePlaceholder;
    } else {
      return (
        deviceTypes?.find(({ name }) => name === type)?.icon || imagePlaceholder
      );
    }
  };

  const { isAdapterOnline } = useSelector((state: RootState) => ({
    isAdapterOnline: state.adapter.adapter?.services.online.state,
  }));
  const { t } = useTranslation();
  const disabled = useMemo(() => !isAdapterOnline, [isAdapterOnline]);
  return (
    <PageLayout>
      {loading ? <IonLoading isOpen message="Loading ..." /> : null}
      <Title
        title={t("manageDevices:title")}
        className="mb-8"
        data-testid="manage-devices"
      />
      {activeProfile && otherProfiles.length && (
        <div>
          <div className="module-large">
            <Title
              title={t("manageDevices:currentProfile")}
              className="mb-4"
              subtitle={activeProfile.name}
            />
            <div className="module">
              <ul className="data-list">
                {activeProfile.devices.map((device) => (
                  <ListRow
                    key={`current-${device.id}`}
                    image={getImage(device.type)}
                    text={device.name || "Unnamed device"}
                  >
                    <button
                      type="button"
                      className="unstyled"
                      onClick={() => removeDevice(device)}
                      data-testid="remove"
                    >
                      <p className="red">{t("manageDevices:remove")}</p>
                    </button>
                  </ListRow>
                ))}
              </ul>
            </div>
          </div>

          <div className="module-large">
            <Title title={t("manageDevices:otherProfiles")} className="mb-4" />
            {otherProfiles.map((profile) => (
              <div key={profile.id}>
                {profile.devices.length > 0 && (
                  <div className="module">
                    <h3 className="truncate">{profile.name}</h3>
                    <ul className="data-list">
                      {profile.devices.map((device) => (
                        <ListRow
                          key={`profile-${profile.id}-${device.id}`}
                          image={getImage(device.type)}
                          text={device.name || "Unnamed device"}
                        >
                          <button
                            type="button"
                            data-testid={`move-to-${activeProfile.name}`}
                            title={
                              disabled
                                ? "router offline. Cannot move"
                                : `move to ${activeProfile.name}`
                            }
                            className="truncate w-36 md:w-60 unstyled p-2 transition no-underline text-orange hover:underline disabled:cursor-not-allowed disabled:no-underline disabled:text-gray-500 focus:outline-none focus:ring focus:ring-orange"
                            onClick={() => addDevice(device)}
                            disabled={disabled}
                          >
                            {`${t("manageDevices:moveTo")} ${
                              activeProfile.name
                            }`}
                          </button>
                        </ListRow>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </PageLayout>
  );
};

export default ManageDevicesComponent;
