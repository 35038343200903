import React, { useCallback } from "react";
import PageLayout from "../layouts/PageLayout";
import { useDispatch } from "react-redux";
import { Dispatch } from "../store";
import Button from "./Button";
import { useHistory } from "react-router";
import AppConfig from "../AppConfig";

interface Props {
  route?: string;
  error: string;
  helpRoute?: string;
  dismissError: () => void;
}

const ErrorComponent: React.FC<Props> = ({
  route = "/",
  error,
  helpRoute = AppConfig.SUPPORT_URL!,
  dismissError,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const history = useHistory();
  const actualDismissError = useCallback(
    (shouldLogout: boolean) => {
      if (shouldLogout) {
        dispatch.authentication.removeUserToken();
      }
      dispatch.ui.setError(undefined);
      dismissError();
    },
    [dismissError, dispatch.authentication, dispatch.ui]
  );
  return (
    <PageLayout testId="error-component">
      <div className="bg-white min-h-screen px-4 py-16 sm:px-6 sm:py-24 lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <p className="text-4xl font-extrabold text-orange sm:text-5xl">
              500
            </p>
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  Unexpected Error
                </h1>
                <p className="mt-1 text-base text-gray-500">{error}</p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                <Button
                  onClick={() => {
                    actualDismissError(route === "logout");
                    history.push(`${route === "logout" ? "/" : route}`);
                  }}
                >
                  Go back Home
                </Button>
                <Button
                  variant="white"
                  onClick={() => {
                    actualDismissError(false);
                    window.open(helpRoute, "_blank");
                  }}
                >
                  Help
                </Button>
              </div>
            </div>
          </main>
        </div>
      </div>
    </PageLayout>
  );
};

export default ErrorComponent;
