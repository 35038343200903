import React, { useState, useEffect, useMemo } from "react";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

interface Option {
  label: string;
  value: string;
}

interface Props {
  options: Option[];
  onSelect: (value: string) => void;
  value: string;
  title: string;
  vertical?: boolean;
}

export const RadioSelector: React.FC<Props> = ({
  options,
  onSelect,
  value,
  title,
  vertical,
}) => {
  return (
    <div style={defaultStyle.radioContainer}>
      <div style={defaultStyle.formText}>{title}</div>
      <div
        style={{
          ...defaultStyle.radioOptions,
          flexDirection: vertical ? "column" : "row",
        }}
      >
        {options.map((option, i) => {
          return (
            <div
              style={defaultStyle.radioOption}
              onClick={() => onSelect(option.value)}
            >
              <div style={defaultStyle.radioButtonOuter}>
                <div
                  style={
                    option.value === value
                      ? defaultStyle.radioInnerSelected
                      : defaultStyle.radioInnerDeselected
                  }
                ></div>
              </div>
              <div style={defaultStyle.labelText}>{option.label}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const defaultStyle = {
  radioContainer: {
    display: "flex",
    flexDirection: "column" as "column",
    gap: "10px",
    cursor: "pointer",
  },
  titleContainer: {
    borderBottom: "1px solid #d8d8d8",
    paddingBottom: "3px",
  },
  formText: {
    lineHeight: "20px",
  },
  radioOptions: {
    display: "flex",
    gap: "10px",
  },
  radioOption: {
    display: "flex",
    gap: "5px",
    alignItems: "center",
  },
  radioButtonOuter: {
    height: "16px",
    width: "16px",
    borderRadius: "8000px",
    border: "2px solid #FDA23F",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "inset 0 1px 2px rgba(0.4,0.4,0.4,0.2)",
  },
  radioInnerDeselected: {
    width: "0px",
    height: "0px",
    transition: "0.3s ease-in-out",
    backgroundColor: "#FDA23F",
    borderRadius: "8000px",
  },
  radioInnerSelected: {
    height: "16px",
    width: "16px",
    transition: "0.3s ease-in-out",
    backgroundColor: "#FDA23F",
    borderRadius: "4000px",
  },
  labelText: {
    fontSize: "14px",
  },
};

export default RadioSelector;
