import { IonSpinner } from "@ionic/react";

interface Props {
  loading: boolean;
  text?: string;
}
export default function Loading({ loading, text }: Props) {
  if (!loading) {
    return null;
  }
  return (
    <div className="flex items-center justify-center w-full p-2 mt-2">
      <IonSpinner name="lines" />
    </div>
  );
}
