import { IonIcon } from "@ionic/react";
import { informationCircleSharp } from "ionicons/icons";
import NavigationLink from "./NavigationLink";

interface Props {
  message: string;
  linkText: string;
  linkUrl: string;
  className?: string;
}

export default function EmptyMessage({
  message,
  linkText,
  linkUrl,
  className,
}: Props) {
  return (
    <div
      className={`bg-yellow-50 rounded-md border-yellow-400 p-4 ${className}`}
    >
      <div className="flex">
        <div className="flex-shrink-0">
          <IonIcon
            icon={informationCircleSharp}
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3 flex-1">
          <p className="text-sm text-yellow-700">{message}</p>
          <NavigationLink
            title={linkText}
            to={linkUrl}
            className="py-2 text-yellow-700 hover:text-yellow-600"
          />
        </div>
      </div>
    </div>
  );
}
