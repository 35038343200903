import { createModel } from "@rematch/core";
import { Notification } from "model";
import { RootModel } from "./models";
export type NotificationState = Notification[];

const initialState: NotificationState = [];

export const notifications = createModel<RootModel>()({
  state: initialState, // initial state
  reducers: {
    addNotification(state, notification: Notification) {
      return [...state, notification];
    },
    _removeNotification(state, notification: Notification) {
      return state.filter(
        (_notif) =>
          !(_notif.message === notification.message && notification.canDismiss)
      );
    },
    clearNotifications() {
      return [];
    },
  },
  effects: (dispatch) => ({
    // handle state changes with impure functions.
    // use async/await for async actions
    dismissNotification(notification: Notification) {
      if (notification.onPress) {
        notification.onPress();
      }
      dispatch.notifications._removeNotification(notification);
    },
  }),
});
