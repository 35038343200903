import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { ProfileDetails } from "../../../model";
import BedtimeDisplay from "./BedtimeDisplay";
import InternetPause from "../../../components/InternetPause";
import { getIcon } from "../../../utils";
import DelayBedtime from "../../../components/DelayBedtime";
import { IonIcon } from "@ionic/react";
import { trash } from "ionicons/icons";
import DeleteProfileModal from "./DeleteProfileModal";
import classNames from "classnames";

interface Props {
  link: string;
  profile: ProfileDetails;
  hideDelete?: boolean;
}

const ProfileOverviewComponent: React.FC<Props> = ({
  link,
  profile,
  hideDelete = false,
}) => {
  const { bedtime } = profile.settings;
  const [confirmDelete, setConfirmDelete] = useState(false);
  // if before 6 AM, display previous day / convert SUN-SAT to MON-SUN
  const day = new global.Date().getDay();
  const isSchoolNight = bedtime["school-nights"][day];
  const startTime = isSchoolNight ? bedtime["school"] : bedtime["other"];
  const history = useHistory();
  return (
    <div className="group details-header my-4">
      <div className="relative mt-0 mx-auto mb-1 w-16 h-16">
        <div className="transition image-container focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-500 hover:scale-110">
          <Link to={link} data-testid={`profile-${profile.name}`}>
            <img src={getIcon(profile.icon)} alt="" />
          </Link>
        </div>
        <InternetPause
          name={profile.name}
          type="profile"
          id={profile.id}
          pauseState={profile.settings["internet-pause"]}
        />
      </div>
      <div className="flex items-center justify-center w-full">
        <span
          className={classNames("font-semibold text-center truncate", {
            "mr-3": !profile.system && !hideDelete,
          })}
        >
          {profile.name}
        </span>
        <button
          type="button"
          style={
            profile.system || hideDelete
              ? { display: "none", cursor: "unset" }
              : {}
          }
          className="unstyled text-gr text-gray-800 focus:ring-orange focus:ring outline-none hover:scale-125 hover:text-red-700"
          onClick={() => setConfirmDelete(true)}
          title="delete profile"
          data-testid={`delete-profile-${profile.id}`}
        >
          <IonIcon style={{ fontSize: 16 }} icon={trash} />
        </button>
      </div>
      {startTime >= 0 && profile.settings.bedtime.enabled ? (
        <div>
          <p className="subinfo">
            <BedtimeDisplay profileId={profile.id} />
          </p>
          <div className="actions">
            <ul className="list-unstyled">
              <DelayBedtime profileId={profile.id} displayMoon large />
            </ul>
          </div>
        </div>
      ) : null}
      <DeleteProfileModal
        open={confirmDelete}
        profileId={profile.id}
        successCallback={() => history.push("/profile/list")}
        closeModal={() => setConfirmDelete(false)}
      />
    </div>
  );
};

export default ProfileOverviewComponent;
