import classNames from "classnames";
import { ButtonHTMLAttributes } from "react";

type Props = ButtonHTMLAttributes<Element> & {
  variant?: "white" | "danger" | "default";
  size?: "small" | "medium";
  makeSmallerOnMobile?: boolean;
};

export default function Button({
  className = "",
  children,
  variant = "default",
  size = "medium",
  makeSmallerOnMobile = false,
  ...otherProps
}: Props) {
  return (
    <div className={`${className}`}>
      <button
        type="button"
        className={classNames(
          {
            "px-3 py-2 leading-4 text-sm":
              size === "small" || makeSmallerOnMobile,
          },
          { "md:px-9 md:py-4 md:text-md": makeSmallerOnMobile },
          { "px-9 py-4 text-md": size === "medium" },
          "transition inline-flex justify-center rounded-md border border-transparent shadow-sm",
          "font-medium no-underline",
          {
            "text-white bg-rose-600 hover:bg-rose-700 disabled:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900":
              variant === "danger",
          },
          {
            "border-gray-300 text-gray-700 bg-white hover:bg-orange-200":
              variant === "white",
          },
          { "text-white bg-orange hover:bg-orange-400": variant === "default" },
          " focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 disabled:cursor-not-allowed disabled:bg-gray-400"
        )}
        {...otherProps}
      >
        {children}
      </button>
    </div>
  );
}
