import React from "react";

interface ContainerProps {
  value: number;
  timeOnly?: boolean;
  utc?: boolean;
  testId?: string;
}

// TODO: raplace with helper functions from Date library
const TimeComponent: React.FC<ContainerProps> = ({
  value,
  timeOnly,
  utc,
  testId,
}) => {
  const date = new Date(value * 1000);
  if (timeOnly && utc) {
    date.setTime(new Date().setUTCHours(0, 0, 0, 0) + value * 1000);
  } else if (timeOnly) {
    date.setTime(new Date().setHours(0, 0, 0, 0) + value * 1000);
  }

  let hours = date.getHours() % 12;
  const am = date.getHours() <= hours ? "AM" : "PM";
  if (!hours) hours = 12;
  const minutes = date.getMinutes();
  const zero = date.getMinutes() < 10 ? "0" : "";

  return <span data-testid={testId}>{`${hours}:${zero}${minutes} ${am}`}</span>;
};

export default TimeComponent;
