import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import * as firebase from "firebase/app";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import http from "axios";

import PageLayout from "../../layouts/PageLayout";
import { ProfileDetails, DeviceDetails } from "../../model";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "../../store";
import DeviceIconSelector from "../../components/DeviceIconSelector";
import { IonLoading } from "@ionic/react";
import Title from "components/Title";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import vest, { enforce, test } from "vest";
import { vestResolver } from "@hookform/resolvers/vest";
import { useForm } from "react-hook-form";
import Input from "components/Input";
import ErrorMessage from "components/ErrorMessage";

interface Inputs {
  name: string;
}

const validationSuite = vest.create((data: Inputs) => {
  test("name", i18next.t("editDevice:nameRequiredMessage"), () => {
    enforce(data.name).isNotEmpty();
  });
  test("name", i18next.t("editDevice:nameSizeLimitMessage"), () => {
    enforce(data.name.length).isBetween(1, 64);
  });
});

const IdentifyDeviceComponent: React.FC = () => {
  const { t } = useTranslation("editDevice");

  const { deviceId } = useParams<{ deviceId: string }>();
  const [icon, setIcon] = useState<string>("");
  const [loadingMessage, setLoadingMessage] = useState<string>("");
  const [error, setError] = useState<any>();
  const [initialIcon, setInitialIcon] = useState<string>("");
  const [initialName, setInitialName] = useState<string>("");
  const history = useHistory();
  const { deviceTypes } = useSelector((state: RootState) => ({
    deviceTypes: state.device.types,
  }));
  const dispatch = useDispatch<Dispatch>();
  const { register, formState, setValue, watch, trigger } = useForm<Inputs>({
    mode: "all",
    resolver: vestResolver(validationSuite),
    defaultValues: { name: "" },
  });
  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "device_name" });
    setLoadingMessage("Loading");
    http
      .get<DeviceDetails[]>(`/v1/device/list?id=${deviceId}`)
      .then(({ data }) => {
        const device = data[0]!;
        setValue("name", device.name);
        // this is a hack to make the form state update
        trigger("name");
        setIcon(device.type);
        setInitialName(device.name);
        setInitialIcon(device.type);
        setLoadingMessage("");
      })
      .catch((e) => {
        setLoadingMessage("");
        setError(e);
      }); // no device found
    if (!deviceTypes || deviceTypes.length === 0) {
      dispatch.device.getDeviceTypes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);

  const { isValid, errors } = formState;
  const name = watch().name;
  const save = () => {
    firebase.analytics().logEvent("DeviceName-SAVE", {});
    FirebaseAnalytics.logEvent("DeviceName-SAVE", {});
    const payload: { id: string; name?: string; type?: string } = {
      id: deviceId,
    };
    if (name !== initialName) {
      payload.name = name;
    }
    if (icon !== initialIcon && icon) {
      payload.type = icon;
    }
    setLoadingMessage("Updating Device");

    http
      .post<ProfileDetails>("/v1/device/name", payload)
      .then(() => {
        setLoadingMessage("");

        history.goBack();
        dispatch.ui.setToast({
          description: t("generic:success.deviceUpdatedMessage"),
          type: "success",
        });
      })
      .catch((error) => {
        setLoadingMessage("");
        setError(error);
      });
  };
  const [didMount, setDidMount] = useState(false);
  useEffect(() => {
    window.requestAnimationFrame(() => {
      setTimeout(() => {
        setDidMount(true);
      }, 100);
    });
  }, []);

  const didSomethingChange = name !== initialName || icon !== initialIcon;
  return (
    <PageLayout
      back={history.goBack}
      save={save}
      disabled={!isValid}
      didSomethingChange={didSomethingChange}
    >
      {loadingMessage ? <IonLoading message={loadingMessage} isOpen /> : null}
      <Title title={t("title")} className="mb-2" />
      <Input
        className="mb-4"
        type="text"
        id="name"
        placeholder={t("namePlaceholder")}
        label={t("nameLabel")}
        inputProps={{ ...register("name") }}
        error={errors.name?.message}
      />
      <Title title={t("deviceTypetitle")} level={2} />

      {didMount ? (
        <DeviceIconSelector select={setIcon} selected={icon} />
      ) : null}
      <ErrorMessage error={error} />
    </PageLayout>
  );
};

export default IdentifyDeviceComponent;
