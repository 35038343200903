import React, { useState, useEffect, useMemo } from "react";
import { IonIcon } from "@ionic/react";
import { closeOutline } from "ionicons/icons";

interface Props {
  isOpen: boolean;
  children: string | JSX.Element | JSX.Element[];
  onClose: () => void;
}

export const ModalContainer: React.FC<Props> = ({
  isOpen,
  children,
  onClose,
}) => {
  return (
    <div
      style={{
        ...defaultStyles.modalCurtain,
        display: isOpen ? ("flex" as "flex") : ("none" as "none"),
      }}
    >
      <div style={defaultStyles.modalBox}>
        <div
          style={defaultStyles.closeButtonContainer}
          onClick={() => {
            onClose();
          }}
        >
          <IonIcon
            icon={closeOutline}
            style={{ fontSize: "18px" }}
            aria-hidden="true"
          />
        </div>
        {children}
      </div>
    </div>
  );
};

const defaultStyles = {
  modalCurtain: {
    position: "fixed" as "fixed",
    top: "0px",
    bottom: "0px",
    left: "0px",
    right: "0px",
    backgroundColor: "rgba(0.4,0.4,0.4,0.4)",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 10000,
  },
  modalBox: {
    padding: "20px",
    borderRadius: "6px",
    minWidth: "100px",
    minHeight: "100px",
    backgroundColor: "#fff",
    boxShadow: "2px 2px 6px rgba(0.4,0.4,0.4,0.4)",
    position: "relative" as "relative",
  },
  closeButtonContainer: {
    position: "absolute" as "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
};

export default ModalContainer;
