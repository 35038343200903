import { getMonth, isBefore, isSameDay, setDate, setMonth } from "date-fns";

/**
 *
 * @param dayOfMonth a day between 1 and 31
 * @param today
 */
export const getPreviousDateByDay: (dayOfMonth: number, today: Date) => Date = (
  dayOfMonth,
  today = new Date()
) => {
  const givenDayThisMonth = setDate(today, dayOfMonth);
  if (
    isBefore(today, givenDayThisMonth) ||
    isSameDay(today, givenDayThisMonth)
  ) {
    return setMonth(givenDayThisMonth, getMonth(today) - 1);
  }
  return givenDayThisMonth;
};
