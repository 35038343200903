import React, { useEffect, useMemo } from "react";

import PageLayout from "../../layouts/PageLayout";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dispatch, RootState } from "../../store";
import { useForm } from "react-hook-form";
import api from "api";
import { usePrevious, useSetState } from "ahooks";
import { IonLoading } from "@ionic/react";
import { classNames, getDataCapDisplayValues, unitsMap } from "utils";
import vest, { enforce, test } from "vest";
import i18next from "i18next";
import { vestResolver } from "@hookform/resolvers/vest";
import Title from "components/Title";
import { useTranslation } from "react-i18next";
import Input from "components/Input";
import Select from "components/Select";
import { ListRow, Toggle } from "components";
import ErrorMessage from "components/ErrorMessage";

type Inputs = {
  cap: number;
  day: number;
  capOriginal: number;
};

const days = new Array(31)
  .fill(0)
  .map((_, i) => ({ value: i + 1, label: `${i + 1}` }));

const validationSuite = vest.create((data: Inputs) => {
  test("cap", i18next.t("dataCap:required"), () => {
    enforce(data.cap).isNotEmpty();
  });
  test("cap", i18next.t("dataCap:integer"), () => {
    enforce(data.cap).isNumeric();
    enforce(data.cap > 0).isTruthy();
    enforce(Math.round(+data.cap) === +data.cap).isTruthy();
  });
});

interface State {
  loading: boolean;
  networkError: string;
  dataCapDisabled: boolean;
}
const DataCap: React.FC<{}> = () => {
  // mb: 1, gb:1024, tb: 1.048.576
  const { dataCap, adapterId } = useSelector((state: RootState) => ({
    adapterId: state.adapter.adapter!.id,
    dataCap: state.adapter.adapter?.settings.datacap,
  }));

  const dispatch = useDispatch<Dispatch>();
  const [state, setState] = useSetState<State>({
    loading: false,
    networkError: "",
    dataCapDisabled: false,
  });
  useEffect(() => {
    if (dataCap?.cap === 0) {
      setState({ dataCapDisabled: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultValues = useMemo<Inputs>(
    () => getDataCapDisplayValues(dataCap),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const {
    register,
    handleSubmit,
    formState,
    reset,
    setValue,
    watch,
  } = useForm<Inputs>({
    mode: "all",
    resolver: vestResolver(validationSuite),
    defaultValues,
  });

  const { isValid, errors } = formState;

  const { goBack } = useHistory();
  const onSubmit = async ({ cap, day }: Inputs) => {
    const convertedCap = Math.round(cap * unitsMap.GB);
    setState({ loading: true });
    api.adapter
      .configureDataCap({ cap: convertedCap, day: +day, id: adapterId })
      .then(async () => {
        dispatch.ui.setToast({
          type: "success",
          description: i18next.t("generic:success.dataCapMessage"),
        });
        await dispatch.adapter.getAdapter(adapterId);
        goBack();
      })
      .catch((error) => {
        setState({ networkError: error.message });
      })
      .finally(() => {
        setState({ loading: false });
      });
  };

  const values = watch();
  const previousDataCapValue = usePrevious(values.cap);

  useEffect(() => {
    if (!state.dataCapDisabled) {
      if (defaultValues.cap === 0) {
        setValue("cap", 10, { shouldValidate: true });
      } else {
        reset({ cap: previousDataCapValue });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.dataCapDisabled]);

  const didSomethingChange = state.dataCapDisabled
    ? defaultValues.cap !== 0
    : `${defaultValues.cap}` !== `${values.cap}` ||
      `${defaultValues.day}` !== `${values.day}`;
  const { t } = useTranslation("dataCap");
  return (
    <PageLayout
      didSomethingChange={didSomethingChange}
      back={goBack}
      save={() => {
        if (state.dataCapDisabled) {
          onSubmit({ cap: 0, day: 1, capOriginal: 0 });
        } else {
          handleSubmit(onSubmit)();
        }
      }}
      disabled={(!!state.networkError || !isValid) && !state.dataCapDisabled}
    >
      {state.loading ? <IonLoading isOpen message="Saving Data cap" /> : null}
      <Title title={t("title")} />
      <ul className="data-list">
        <ListRow text={t("dataCap")} className="my-2">
          <Toggle
            id="disable-data-cap"
            checked={!state.dataCapDisabled}
            onChange={(dataCapDisabled) => {
              setState({ dataCapDisabled: !dataCapDisabled });
            }}
          />
        </ListRow>
      </ul>
      <div className={classNames(state.dataCapDisabled && "hidden")}>
        <Input
          id="cap"
          label={t("dataCap:capLabel")}
          className="disabled:text-gray-200 mb-2 mt-4 max-w-xs"
          type="number"
          disabled={state.dataCapDisabled}
          inputProps={register("cap")}
          placeholder="e.g. 100, 500"
          suffix="GB"
          error={errors.cap?.message}
        />
        <Select
          label={t("startDate")}
          inputProps={register("day")}
          id="day"
          className="max-w-xs"
          options={days}
        />
        <ErrorMessage error={state.networkError} />
      </div>
    </PageLayout>
  );
};

export default DataCap;
