import React from "react";
import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";

import { Subscription as SubscriptionType } from "model";
import Account from "./AccountContainer";
import Subscribe from "./Subscribe";
import ManageSub from "./ManageSubscription";
import Upgrade from "./Upgrade";
import Purchase from "./Purchase";
import PaymentMethod from "./PaymentMethod";
import EditAccount from "./EditAccount";

interface Props {
  subscription?: SubscriptionType;
}

const Routes: React.FC<Props> = ({ subscription }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/subscribe/:adapterId`} exact>
        <Subscribe />
      </Route>
      <Route path={`${path}/manage/:adapterId`} exact>
        <ManageSub />
      </Route>
      <Route path={`${path}/upgrade/:adapterId`} exact>
        <Upgrade reason="PHERO_VPN" />
      </Route>
      <Route path={`${path}/purchase`} exact>
        <Purchase />
      </Route>
      <Route path={`${path}/payment-method/:subscriptionId`} exact>
        <PaymentMethod />
      </Route>
      <Route path={`${path}/edit`} exact>
        <EditAccount />
      </Route>
      {!subscription && (
        <Route>
          <Account subscription={subscription} />
        </Route>
      )}

      <Route path={path} exact>
        <Account subscription={subscription} />
      </Route>
      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default Routes;
