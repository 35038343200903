import { BedtimeSetting, ProfileDetails, ProfileIcon } from "../model";
import http from "axios";
import { createMock } from "./utils";
import {
  GET_PROFILE_LIST_ROUTE,
  SET_PROFILE_SETTINGS_ROUTE,
  SET_BEDTIME_ROUTE,
  SET_BEDTIME_DELAY_ROUTE,
  DELETE_PROFILE_ROUTE,
  GET_PROFILE_ICONS_ROUTE,
} from "../constants";

export type RequireExactlyOne<
  ObjectType,
  KeysType extends keyof ObjectType = keyof ObjectType
> = {
  [Key in KeysType]: Required<Pick<ObjectType, Key>> &
    Partial<Record<Exclude<KeysType, Key>, never>>;
}[KeysType] &
  Omit<ObjectType, KeysType>;

export function deleteProfile(profileId: string) {
  return http.post<{ msg: string }>(DELETE_PROFILE_ROUTE, { id: profileId });
}
export const mockDeleteProfile = (profileId: string) =>
  createMock<{ msg: string }, undefined>(DELETE_PROFILE_ROUTE, "POST");
/**
 * Generates a list of specific profile, or all profiles associated with the clients account.
 * @param profileOrAdapterId Optional. The id of an adapter or profile. If an adapter id is provided,
 * all profiles belonging to that adapter will be reported. If a specific profile id is provided, that specific profile will be reported, otherwise all profiles on all adapters will be reported.
 */
export function getProfileDetails(profileOrAdapterId: string) {
  return http.get<ProfileDetails[]>(
    `${GET_PROFILE_LIST_ROUTE}?id=${profileOrAdapterId}`
  );
}
export const mockGetProfileDetails = (profileOrAdapterId: string) =>
  createMock<ProfileDetails[], undefined>(
    `${GET_PROFILE_LIST_ROUTE}?id=${profileOrAdapterId}`,
    "GET"
  );
/**
 * Get all profiles associated with the clients account.
 */
export function getAllProfiles() {
  return http.get<ProfileDetails[]>(GET_PROFILE_LIST_ROUTE);
}
export const mockGetAllProfiles = createMock<ProfileDetails[], undefined>(
  GET_PROFILE_LIST_ROUTE,
  "GET"
);

type ProfileConfigServices = keyof ProfileDetails["settings"];
type ConfigurableSettings = ProfileConfigServices &
  ("adult-block" | "youtube-restricted" | "safesearch");
export type SettingUpdate = RequireExactlyOne<
  Record<ConfigurableSettings, boolean>,
  ConfigurableSettings
>;
type SetProfileSettingRequestBody = { id: string } & (
  | SettingUpdate
  | Record<"internet-pause", number>
);
/**
 * Allows all devices, associated to a profile, access to the internet to be paused. Either for a timed period, or indefinitely,
 *  or for safesearch to be enforced, or youtube to be restricted, as required.

## Parameters
* id: The unique id of the profile.

* youtube-restricted: True, youtube is restricted. False, unrestricted.

* safesearch: True, search engine safe search enforced. False, unrestricted.

* internet-pause: The time when we "unpause" internet. If this is in the past, then by definition internet is enabled. To pause indefinitely, set to -1.

* adult-block: True, adult content is blocked. False, it is not blocked.
 */
export function setProfileSetting(
  profileId: string,
  setting: SettingUpdate | Record<"internet-pause", number>
) {
  const requestBody: SetProfileSettingRequestBody = {
    id: profileId,
    ...setting,
  };
  return http.post<ProfileDetails>(SET_PROFILE_SETTINGS_ROUTE, requestBody);
}
export const mockSetProfileSetting = createMock<
  ProfileDetails,
  SetProfileSettingRequestBody
>(SET_PROFILE_SETTINGS_ROUTE, "POST");

export type SetBedTimeBody = { id: string } & BedtimeSetting;
export function setBedTime(profileId: string, bedtime: BedtimeSetting) {
  const bedtimeBody: SetBedTimeBody = { id: profileId, ...bedtime };
  return http.post<ProfileDetails>(SET_BEDTIME_ROUTE, bedtimeBody);
}
export const mockSetBedTime = createMock<ProfileDetails, SetBedTimeBody>(
  SET_BEDTIME_ROUTE,
  "POST"
);

export type SetBedTimeDelayBody = { id: string; delay: number };
export function setBedTimeDelay(profileId: string, delay: number) {
  const bedtimeBody: SetBedTimeDelayBody = { id: profileId, delay };
  return http.post<ProfileDetails>(SET_BEDTIME_DELAY_ROUTE, bedtimeBody);
}
export const mockSetBedTimeDelay = createMock<
  ProfileDetails,
  SetBedTimeDelayBody
>(SET_BEDTIME_DELAY_ROUTE, "POST");

export function getProfileIcons() {
  return http.get<ProfileIcon[]>(GET_PROFILE_ICONS_ROUTE);
}
export const mockGetProfileIcons = createMock<ProfileIcon[], undefined>(
  GET_PROFILE_ICONS_ROUTE,
  "GET"
);
