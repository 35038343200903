import { useHistory } from "react-router";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

import handleDeepLinkSlug from "./handleDeepLinkSlug";
import { Dispatch } from "store";
import { App } from "@capacitor/app";

const AppUrlListener: React.FC<any> = () => {
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    if (process.env.NODE_ENV === "test") {
      document.addEventListener(
        "deep-link",
        function (e: any) {
          // console.log("event", e.detail);
          handleDeepLinkSlug(history, dispatch, e.detail);
        },
        false
      );
    }
    App.addListener("appUrlOpen", (data: any) => {
      // deep links are only registered on hosted websites
      // localhost won't work
      // We receive a url of the form 'host/passwordReset?newCustomer=true?exp=22&verify=token
      // If that's the case, we need to send the verify token to the server and get the auth token
      handleDeepLinkSlug(history, dispatch, data);
    });
  }, [history, dispatch]);

  return null;
};

export default AppUrlListener;
