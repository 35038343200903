
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "getConnected": {
    "title": "Get Connected",
    "description": "Connect your Privacy Hero router to your main router just like in the video below",
    "ok": "I'm Connected"
  },
  "activate": {
    "title": "Activate Your Router",
    "updateTitle": "Updating your Privacy Hero router to the latest firmware",
    "loadingMessage": "Hang tight, this may take a few moments",
    "updatingMessage": "There is a firmware update available. Please wait while we are updating your Privacy Hero router to the latest firmware. Important: Do not disconnect the power or the ethernet cable.",
    "needsReboot": "Your PrivacyHero router is updated to the latest firmware but needs to be restarted to continue the activation process. Please disconnect it from the power, wait for 5 seconds and reconnect again. Do not unplug the ethernet cable.",
    "manualRebootTitle": "Firmware updated. Please reboot the router.",
    "instructions": "Enter the code located at the back of your router",
    "codeLabel": "Activation Code",
    "codePlaceholder": "XXXXX",
    "codeHelpText": "5 Digit Code at The Back of Your Router"
  },
  "notFound": {
    "header": "Oops",
    "title": "Router not found",
    "details": "Try following the setup steps again, or contact support.",
    "goBack": "Try again",
    "help": "Help"
  },
  "createNetwork": {
    "title": "Create WIFI",
    "ok": "OK",
    "next": "Next",
    "description": "We will create 2 separate WIFI networks for you (5GHz and 2.4Ghz)"
  },
  "allSet": {
    "title": "You're all set",
    "description": "Your router is now connected and ready to use.",
    "nordCodeAvailable": "You have an included 'NordVPN 1 Year' code available to claim. If you choose to not claim the code now you may do so at any time from your Account page",
    "goHome": "Go to Dashboard",
    "claimNord": "Claim NordVPN 1 Year Code Now"
  },
  "steps": {
    "connect": "Connect",
    "activate": "Activate",
    "activateSubscription": "Subscription",
    "setupWifi": "WIFI",
    "allSet": "All Set"
  }
}