import {
  compareAsc,
  add,
  sub,
  isAfter,
  isSameDay,
  getDaysInMonth,
} from "date-fns";
import api from "api";
import { useEffect, useMemo, useState } from "react";
import { Storage } from "@capacitor/storage";
import { DeviceUsage } from "model";
import http from "axios";
import { useSetState } from "ahooks";
import { DataUsageParams } from "api/device";
import _ from "lodash";
import { getStat, StatType } from "components/Stat";
import { getPreviousDateByDay } from "./time";
import { useSelector } from "react-redux";
import { RootState } from "store";

/**
 * Hook used to load device icons from the API
 * and cache them for later use. Subsequent calls shouldn't call the API.
 * The cache should persist to local storage and should expire after 24 hours
 * @example
 * ```ts
 * const deviceIcons = useDeviceIcons();
 * ```
 */
export function useCachedRequest<T>(
  apiCall: () => Promise<T>,
  key: string,
  keepForHours: number = 24
) {
  const [data, setData] = useState<T>();
  useEffect(() => {
    async function load() {
      const jsonResult = await Storage.get({ key });
      const expirationKey = `${key}-lastTime`;
      const expirationTime = await Storage.get({ key: expirationKey });
      let cachedData: T;
      if (
        !jsonResult.value ||
        !expirationTime.value ||
        // now > lastTime + 1 day
        compareAsc(new Date(expirationTime.value), new Date()) === -1
      ) {
        cachedData = await apiCall();
        try {
          await Storage.set({ key, value: JSON.stringify(cachedData) });
          await Storage.set({
            key: expirationKey,
            value: add(new Date(), {
              hours: keepForHours,
            }).toString(),
          });
        } catch (e) {}
      } else {
        cachedData = JSON.parse(jsonResult.value) as T;
      }
      setData(cachedData);
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return data;
}

export function useDeviceIcons() {
  const apiCall = () => api.device.getDeviceTypes().then((r) => r.data);
  return useCachedRequest(apiCall, "deviceIcons");
}

export function useProfileIcons() {
  const apiCall = () => api.profile.getProfileIcons().then((r) => r.data);
  return useCachedRequest(apiCall, "profileIcons");
}
export interface UseDataUsageOptions {
  chartSpan: 1 | 7 | 30;
  id: string;
  type: "device" | "adapter" | "profile";
  today?: Date;
  startFromBillingDate?: boolean;
  canStartFromBillingDate?: boolean;
}

export interface DataUsagePoint {
  time: number;
  value: number;
  category: "Current usage" | "Previous usage";
  download: number;
  upload: number;
  timestamp: number;
}
export interface UseDataUsageData {
  data: DataUsagePoint[];
  loading: boolean;
  error?: any;
  stats: StatType[];
  currentPeriodTotalUsage: number;
}
/**
 * This hook is meant to be used for getting data usage data.
 * It is meant to encapsulate all configuration and display settings
 *
 * There are several options:
 * - chartSpan: display usage data in the UI: last (1, 7, 30) days
 * - type of usage data: device or adapter
 *
 * The result is comprised of the following:
 * - Data array to be used in the chart configuration
 * - Prefilled chart configuration to be passed to the chart directly ?
 * - Stats data to display number indicators above the chart
 * -
 *
 * New options
 * - Add possibility to compare devices when viewing adapter usage
 */
export function useDataUsage({
  chartSpan,
  id,
  type,
  today: td,
  startFromBillingDate,
  canStartFromBillingDate,
}: UseDataUsageOptions) {
  const { dataCap } = useSelector((state: RootState) => ({
    dataCap: state.adapter.adapter!.settings.datacap,
  }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const today = useMemo(() => td || new Date(), []);
  const [state, setState] = useSetState<UseDataUsageData>({
    data: [],
    loading: true,
    stats: [],
    currentPeriodTotalUsage: 0,
  });

  useEffect(() => {
    if (!id) return;
    const resolution = chartSpan === 1 ? "Hourly" : "Daily";
    const end = Math.round(today.getTime());
    const periodDifference = {
      days: chartSpan === 30 ? getDaysInMonth(today) : chartSpan === 1 ? 1 : 0,
      weeks: chartSpan === 7 ? 1 : 0,
    };
    // start date depends on today.
    const billingDateTime = getPreviousDateByDay(dataCap.day, today).getTime();

    let start =
      canStartFromBillingDate && startFromBillingDate
        ? billingDateTime
        : sub(end, periodDifference).getTime();
    const params: DataUsageParams = {
      id,
      resolution,
      // end,
      start: Math.round(start / 1000),
    };

    const requests = [http.get<DeviceUsage[]>(`/v1/${type}/usage`, { params })];

    const prevStart = sub(start, periodDifference).getTime();
    const previousParams: DataUsageParams = {
      id,
      resolution,
      end: Math.round(start / 1000),
      start: Math.round(prevStart / 1000),
    };
    requests.push(
      http.get<DeviceUsage[]>(`/v1/${type}/usage`, { params: previousParams })
    );

    setState({ loading: true });
    Promise.all(requests)
      .then((usageResponses) => {
        if (type !== "adapter") {
          const thisPeriodData = usageResponses[0].data.map<DataUsagePoint>(
            ({ rx, tx, timestamp }) => ({
              value: tx + rx,
              time: timestamp * 1000,
              category: "Current usage",
              upload: tx,
              download: rx,
              timestamp,
            })
          );
          const lastPeriodData =
            usageResponses.length > 1
              ? usageResponses[1].data
                  // is the data in the originally displayed range of the chart
                  .map<DataUsagePoint>(({ rx, tx, timestamp }) => {
                    const time = add(
                      new Date(timestamp * 1000),
                      chartSpan === 7 ? { days: 7 } : periodDifference
                    ).getTime();
                    // const originalTime = new Date(timestamp * 1000);
                    return {
                      value: tx + rx,
                      timestamp,
                      category: "Previous usage",
                      // formattedTime: format(time, "d-LLL HH:mm"),
                      // formattedOriginalTime: format(originalTime, "d-LLL HH:mm"),
                      time,
                      upload: tx,
                      download: rx,
                    };
                  })
              : [];

          const thisMonthTotalDownload = _.sumBy(thisPeriodData, "download");
          const thisMonthTotalUpload = _.sumBy(thisPeriodData, "upload");
          const lastMonthTotalDownload = _.sumBy(lastPeriodData, "download");
          const lastMonthTotalUpload = _.sumBy(lastPeriodData, "upload");

          const currentPeriodTotalUsage =
            thisMonthTotalDownload + thisMonthTotalUpload;
          const stats = [
            getStat({
              stat: thisMonthTotalDownload,
              previousStat: lastMonthTotalDownload,
              name: "Total downloaded",
            }),
            getStat({
              stat: thisMonthTotalUpload,
              previousStat: lastMonthTotalUpload,
              name: "Total uploaded",
            }),
          ];
          setState({
            data: thisPeriodData.concat(lastPeriodData),
            loading: false,
            stats,
            currentPeriodTotalUsage,
          });
        } else {
          const thisPeriod = usageResponses[0].data.reduce<{
            sum: { upload: number; download: number };
            afterBillingDateSum: { upload: number; download: number };
            data: DataUsagePoint[];
          }>(
            (acc, { rx, tx, timestamp }) => {
              // initial support from resetting the line at start
              // of billing date. The problem is continuity of the line.
              // We need to start from 0 instead of the last value !
              // To do that, we need to separate data passed to the chart
              // And that causes various problems
              const isAfterBillingDate =
                // false &&
                isAfter(timestamp * 1000, billingDateTime) ||
                isSameDay(timestamp * 1000, billingDateTime);
              const upload =
                tx +
                (isAfterBillingDate
                  ? acc.afterBillingDateSum.upload
                  : acc.sum.upload);
              const download =
                rx +
                (isAfterBillingDate
                  ? acc.afterBillingDateSum.download
                  : acc.sum.download);

              return {
                sum: {
                  upload,
                  download,
                },
                afterBillingDateSum: {
                  upload:
                    acc.afterBillingDateSum.upload +
                    (isAfterBillingDate ? tx : 0),
                  download:
                    acc.afterBillingDateSum.download +
                    (isAfterBillingDate ? rx : 0),
                },
                data: [
                  ...acc.data,
                  {
                    value: upload + download,
                    time: timestamp * 1000,
                    category: "Current usage",
                    upload,
                    download,
                    timestamp,
                  },
                ],
              };
            },
            {
              sum: { upload: 0, download: 0 },
              afterBillingDateSum: { upload: 0, download: 0 },
              data: [],
            }
          );
          const lastPeriod = usageResponses[1].data.reduce<{
            sum: { upload: number; download: number };
            data: DataUsagePoint[];
          }>(
            (acc, { rx, tx, timestamp }) => {
              const time = add(
                new Date(timestamp * 1000),
                chartSpan === 7 ? { days: 7 } : periodDifference
              ).getTime();
              return {
                sum: {
                  upload: acc.sum.upload + tx,
                  download: acc.sum.download + rx,
                },
                data: [
                  ...acc.data,
                  {
                    value: acc.sum.upload + acc.sum.download + tx + rx,
                    time,
                    category: "Previous usage",
                    upload: acc.sum.upload + tx,
                    download: acc.sum.download + rx,
                    timestamp,
                  },
                ],
              };
            },
            { sum: { upload: 0, download: 0 }, data: [] }
          );

          const currentPeriodTotalUsageBeforeBillingPeriod =
            thisPeriod.sum.download + thisPeriod.sum.upload;

          const currentPeriodTotalUsageAfterBillingPeriod =
            thisPeriod.afterBillingDateSum.download +
            thisPeriod.afterBillingDateSum.upload;

          const currentPeriodTotalUsage =
            currentPeriodTotalUsageAfterBillingPeriod +
            (startFromBillingDate
              ? 0
              : currentPeriodTotalUsageBeforeBillingPeriod);

          const stats = [
            getStat({
              stat: startFromBillingDate
                ? thisPeriod.afterBillingDateSum.download
                : thisPeriod.sum.download,
              previousStat: lastPeriod.sum.download,
              name: "Total downloaded",
            }),
            getStat({
              stat: startFromBillingDate
                ? thisPeriod.afterBillingDateSum.upload
                : thisPeriod.sum.upload,
              previousStat: lastPeriod.sum.upload,
              name: "Total uploaded",
            }),
          ];

          setState({
            data: thisPeriod.data.concat(lastPeriod.data),
            loading: false,
            stats,
            currentPeriodTotalUsage,
          });
        }
      })
      .catch((error) => {
        setState({ loading: false, error });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, type, chartSpan, startFromBillingDate]);

  return state;
}
