interface Props {
  label: string;
  options: { label: string; value: any }[];
  id: string;
  inputProps: any;
  className?: string;
}

export default function Select({
  label,
  options,
  inputProps,
  id,
  className = "",
}: Props) {
  return (
    <div className={className}>
      <label
        htmlFor="location"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <select
        id={id}
        data-testid={id}
        name={id}
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-orange-500 sm:text-sm rounded-md"
        {...inputProps}
      >
        {options.map(({ label, value }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
  );
}
