import App from "./App";
import ErrorComponent from "./components/ErrorComponent";
import React from "react";
import { connect } from "react-redux";
import { RootState } from "./store";
// import { logdna } from "utils";

interface OwnProps {}
interface State {
  error?: Error;
}

const mapState = (state: RootState) => ({
  error: state.ui.error,
});

type StateProps = ReturnType<typeof mapState>;
// type DispatchProps = ReturnType<typeof mapDispatch>;
type Props = OwnProps & StateProps;

class AppWithErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: undefined };
  }
  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { error };
  }
  // componentDidMount() {
  //   window.addEventListener("unhandledrejection", this.onUnhandledRejection);
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("unhandledrejection", this.onUnhandledRejection);
  // }

  // onUnhandledRejection = (event: PromiseRejectionEvent) => {
  //   event.promise.catch((error) => {
  //     this.setState(AppWithErrorBoundary.getDerivedStateFromError(error));
  //   });
  // };
  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // if (process.env.NODE_ENV !== "production") {
    console.log(
      "This is displayed only in dev and test environments.\n [ERROR] \n"
    );
    console.log(error, errorInfo);
    // } else {
    //   // logdna.log(error, errorInfo);
    // }
  }
  render() {
    if (this.state?.error) {
      // console.log("err", this.state?.error);
      // You can render any custom fallback UI
      return (
        <ErrorComponent
          error={this.state?.error.toString()}
          dismissError={() => this.setState({ error: undefined })}
        />
      );
    } else if (this.props.error?.error) {
      console.log(this.props.error);
      return (
        <ErrorComponent
          error={this.props.error.error.toString()}
          helpRoute={this.props.error.helpRoute}
          route={this.props.error.route}
          dismissError={() => this.setState({ error: undefined })}
        />
      );
    }

    return <App />;
  }
}

export default connect(mapState)(AppWithErrorBoundary);
