
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "tabs": {
    "usage": "Usage",
    "settings": "Settings",
    "devices": "Devices"
  },
  "usageTabs": {
    "today": "Today",
    "sevenDays": "7 days",
    "thirtyDays": "30 days"
  },
  "devices": {
    "emptyMessage": "You don't have any devices associated with this Profile yet.",
    "manageDevices": "Manage Devices",
    "title": "Devices"
  },
  "usage": {
    "startFromBillingDate": "Start from Billing data",
    "showPrevious": "Show previous",
    "vs": "vs",
    "yesterday": "yesterday",
    "lastBillingCycle": "last billing cycle",
    "previous30Days": "previous 30 days",
    "previous7Days": "previous 7 days",
    "increased": "Increased by",
    "decreased": "Decreased by"
  },
  "settings": {
    "safeSurf": "Safe Surf",
    "bedtime": "Bedtime",
    "bedtimeTitle": "Bedtime Settings",
    "schoolNights": "School Nights",
    "schoolNightsOffDescription": "Bedtime should be enabled to change school nights",
    "weekends": "Weekends",
    "weekendsOffDescription": "Bedtime should be enabled to change weekends",
    "noBedtime": "No Bedtime",
    "safeSearch": "Internet Safe Search",
    "youtubeSafeSearch": "Youtube Safe Search",
    "adultBlock": "Block Adult Content"
  },
  "bedtime": {
    "schoolNightConfigTitle": "School Night Bedtime",
    "weekendConfigTitle": "Weekend Bedtime",
    "schoolNightInfo": "Select the time that Bedtime will start. You may also customize which days are School Nights in your area.",
    "weekendInfo": "Select the time that Bedtime will start. You may also customize which days are School Nights in your area.",
    "bedtimeStart": "Bedtime Start",
    "autoOffNotice": "Bedtime will automatically turn off at 6:00 AM",
    "schoolNightsTitle": "School Nights",
    "schoolNightsSubtitle": "Which days are School Nights?",
    "schoolNightsRequired": "Please select at least one school night to proceed.",
    "schoolNightsRequiredTitle": "School nights invalid"
  }
}
