import React, { useEffect } from "react";
import { Dispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import PageLayout from "../../layouts/PageLayout";
import { useSetState } from "ahooks";
import { useForm } from "react-hook-form";
import { IonLoading } from "@ionic/react";
import api from "api";
import { useTranslation } from "react-i18next";
import Input from "components/Input";
import i18next from "i18next";
import vest, { enforce, test } from "vest";
import { vestResolver } from "@hookform/resolvers/vest";
import ErrorMessage from "components/ErrorMessage";
import Title from "components/Title";

type Inputs = {
  name: string;
};
interface State {
  loading: boolean;
  networkError: string;
}
const validationSuite = vest.create((data: Inputs) => {
  test("name", i18next.t("settings:routerNameScreen.patternError"), () => {
    enforce(data.name).matches(new RegExp("^[a-zA-Z0-9 _-]*$"));
  });

  test("name", i18next.t("settings:routerNameScreen.requiredError"), () => {
    enforce(data.name).isNotEmpty();
  });
});
const RouterSettingsContainer: React.FC<{}> = () => {
  const history = useHistory();
  const { adapter } = useSelector((state: RootState) => ({
    adapter: state.adapter.adapter!,
  }));
  const defaultValues = {
    name: adapter!.name,
  };
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<Inputs>({
    resolver: vestResolver(validationSuite),
    mode: "all",
    defaultValues: defaultValues,
  });
  const [state, setState] = useSetState<State>({
    loading: false,
    networkError: "",
  });

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "router_name" });
  }, []);
  const dispatch = useDispatch<Dispatch>();
  const onSubmit = ({ name }: Inputs) => {
    setState({ loading: true });
    api.adapter
      .configureName({ id: adapter.id, name })
      .then(async () => {
        await dispatch.adapter.getAdapters();
        setState({ loading: false });
        history.goBack();
      })
      .catch((error) => {
        setState({ loading: false, networkError: error });
      });
  };
  const didSomethingChange = watch().name !== defaultValues.name;
  const { t } = useTranslation("settings");
  return (
    <PageLayout
      back={history.goBack}
      didSomethingChange={didSomethingChange}
      save={handleSubmit(onSubmit)}
      disabled={!isValid || !didSomethingChange}
    >
      {state.loading ? <IonLoading isOpen message="Loading" /> : null}
      <Title title={t("routerNameScreen.title")} className="mb-4" />
      <form>
        <Input
          className="mb-4"
          id="name"
          data-testid="name"
          type="text"
          label={t("routerNameScreen.label")}
          placeholder={t("routerNameScreen.placeholder")}
          inputProps={register("name")}
          error={errors.name?.message}
        />

        <ErrorMessage
          error={state.networkError}
          fallback="Error editing account"
        />
      </form>
    </PageLayout>
  );
};

export default RouterSettingsContainer;
