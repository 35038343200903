import NewModal from "components/NewModal";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch, RootState } from "store";

interface Props {
  profileId: string;
  closeModal: () => void;
  successCallback: () => void;
  preDelete?: () => void;
  open: boolean;
}

export const DeleteProfileModal: React.FC<Props> = ({
  preDelete,
  profileId,
  successCallback,
  closeModal,
  open,
}) => {
  const dispatch = useDispatch<Dispatch>();
  const { hasDevices } = useSelector((state: RootState) => ({
    hasDevices: state.profile.profileDevicesMap[profileId]?.length > 0,
  }));
  const handler = useCallback(
    async (confirm?: boolean) => {
      if (!confirm) {
        return closeModal();
      }
      if (preDelete) {
        preDelete();
      }
      const isSuccess = await dispatch.profile.deleteProfile(profileId);
      closeModal();
      if (isSuccess) {
        successCallback();
      }
    },
    [closeModal, dispatch.profile, preDelete, profileId, successCallback]
  );
  const { t } = useTranslation();
  if (hasDevices) {
    return (
      <NewModal
        open={open}
        hideCancelButton
        onOk={() => handler()}
        onClose={() => handler()}
        title={t("generic:profileHasDevicesModal.title")}
        description={t("generic:profileHasDevicesModal.description")}
        okText={t("generic:profileHasDevicesModal.ok")}
        cancelText={t("generic:profileHasDevicesModal.cancel")}
        okTestId="delete-profile"
      />
    );
  }
  return (
    <NewModal
      open={open}
      onClose={() => handler()}
      onOk={() => {
        handler(true);
      }}
      title={t("generic:deleteProfileConfirmationModal.title")}
      description={t("generic:deleteProfileConfirmationModal.description")}
      okText={t("generic:deleteProfileConfirmationModal.ok")}
      cancelText={t("generic:deleteProfileConfirmationModal.cancel")}
      okTestId="delete-profile"
    />
  );
};

export default DeleteProfileModal;
