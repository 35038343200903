import React, { useCallback, useEffect, useMemo, useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import firebase from "firebase";
import { useHistory, useLocation } from "react-router";
import { UserToken } from "../../model";
import http from "axios";
import { useDispatch } from "react-redux";
import { Dispatch } from "../../store";
import { getSearchWithoutDecoding } from "../../utils";
import ErrorMessage from "components/ErrorMessage";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import Input, { PasswordInput } from "components/Input";
import Button from "components/Button";
import NewModal from "components/NewModal";

type Props = {};

const AccountDetails: React.FC<Props> = () => {
  const { t } = useTranslation("admin");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isConfirmationModalOpen, toggleConfirmationModal] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch<Dispatch>();

  const impersonateUser = useCallback(
    (user: UserToken) => dispatch.authentication.impersonateUser(user),
    [dispatch]
  );

  const email = useMemo(() => {
    return getSearchWithoutDecoding(location.search, "email");
  }, [location.search]);

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "admin_account_details" });
  }, []);

  const submit = useCallback(() => {
    firebase.analytics().logEvent("admin_check_user", {});
    FirebaseAnalytics.logEvent("admin_check_user", {});
    return http
      .post<UserToken>("/v1/auth/login", {
        id: email,
        pwd: password,
      })
      .then(({ data }) => {
        firebase.analytics().logEvent("login", { type: "login" });
        FirebaseAnalytics.logEvent("login", { type: "login" });
        impersonateUser({ ...data, email: email });
        history.push("/home");
        // navigating to the correct screen is handled in App component up in the tree
      })
      .catch((e) => {
        setError(e);
      });
  }, [email, history, impersonateUser, password]);

  const handleConfirmationAction = useCallback(
    async (accept?: boolean) => {
      if (accept) {
        await submit();
      }
      toggleConfirmationModal(false);
    },
    [submit]
  );
  return (
    <PageLayout testId="account-details">
      <Title title={t("accountDetails.title")} />
      <form
        onSubmit={(form) => {
          if (form) {
            form.preventDefault();
          }
          toggleConfirmationModal(true);
        }}
      >
        <Input
          className="mt-4 mb-2"
          label={t("accountDetails.emailLabel")}
          placeholder={t("accountDetails.emailPlaceholder")}
          id="email"
          type="email"
          inputProps={{
            defaultValue: email,
            readOnly: true,
          }}
        />
        <PasswordInput
          label={t("accountDetails.passwordLabel")}
          placeholder={t("accountDetails.passwordPlaceholder")}
          id="password"
          inputProps={{
            value: password,
            onChange: (e: any) => setPassword(e.target.value),
          }}
        />

        <ErrorMessage error={error} />

        <div className="button-group">
          <Button type="submit" disabled={!password} data-testid="login-as">
            {t("accountDetails.loginAs")}
          </Button>
        </div>
      </form>
      <NewModal
        type="warning"
        open={isConfirmationModalOpen}
        onClose={() => toggleConfirmationModal(false)}
        onOk={handleConfirmationAction}
        title={t("accountDetails.modalTitle")}
        description={t("accountDetails.impersonateExplanation")}
        okText={t("accountDetails.ok")}
        cancelText={t("accountDetails.cancel")}
        okTestId="begin"
      />
    </PageLayout>
  );
};
export default AccountDetails;
