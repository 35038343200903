
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Change Password",
  "guestTitle": "Edit Guest Network settings",
  "email": {
    "label": "Account Email",
    "placeholder": "mail@email.com"
  },
  "oldPassword": {
    "label": "Old Password",
    "placeholder": "",
    "required": "old password is required",
    "min": "old password should contain at least 8 characters"
  },
  "newPassword": {
    "label": "New Password",
    "placeholder": "",
    "required": "new password is required",
    "min": "Password must be at lease 8 characters long",
    "same": "New and old passwords should be different"
  },
  "confirmPassword": {
    "label": "Confirm Password",
    "placeholder": "",
    "match": "Passwords don't match"
  },
  "onboarding": {
    "title": "Create your primary wireless network",
    "paragraph": "lorem ipsum",
    "okButton": "Go"
  }
}
