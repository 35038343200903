// import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

interface Props {
  stage:
    | "connect"
    | "activate"
    | "setupWifi"
    | "activateSubscription"
    | "allSet";
}
const steps: Array<{ name: Props["stage"] }> = [
  { name: "connect" },
  { name: "activate" },
  // { name: "activateSubscription" },
  { name: "setupWifi" },
  { name: "allSet" },
];
export default function OnboardingSteps({ stage }: Props) {
  // const { t } = useTranslation("onboarding");

  const currentStepNumber = steps.findIndex(({ name }) => name === stage) + 1;
  return (
    <nav
      className="flex items-center justify-center mb-4 mt-10"
      aria-label="Progress"
    >
      <p className="text-sm font-medium">
        Step {currentStepNumber} of {steps.length}
      </p>
      <ol className="ml-8 flex items-center space-x-5">
        {steps.map((step, index) => {
          const status =
            index + 1 === currentStepNumber
              ? "current"
              : index + 1 < currentStepNumber
              ? "complete"
              : "upcoming";

          return (
            <li key={step.name}>
              {status === "complete" ? (
                <Link
                  to="#"
                  className="block w-2.5 h-2.5 bg-orange rounded-full hover:bg-orange-900"
                >
                  <span className="sr-only">{step.name}</span>
                </Link>
              ) : status === "current" ? (
                <Link
                  to="#"
                  className="relative flex items-center justify-center"
                  aria-current="step"
                >
                  <span
                    className="absolute w-5 h-5 p-px flex"
                    aria-hidden="true"
                  >
                    <span className="w-full h-full rounded-full bg-orange-200" />
                  </span>
                  <span
                    className="relative block w-2.5 h-2.5 bg-orange rounded-full"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </Link>
              ) : (
                <Link
                  to="#"
                  className="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400"
                >
                  <span className="sr-only">{step.name}</span>
                </Link>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
