import NavigationLink from "components/NavigationLink";
import React from "react";
import { useTranslation } from "react-i18next";

import PageLayout from "../../layouts/PageLayout";
import OnboardingSteps from "./components/OnboardingSteps";

const RouterNotFoundContainer: React.FC = () => {
  const { t } = useTranslation("onboarding");
  return (
    <PageLayout>
      <OnboardingSteps stage="activate" />

      <main className="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 my-auto py-16 sm:py-32">
          <p className="text-sm font-semibold text-orange uppercase tracking-wide">
            {t("notFound.header")}
            <span className="ml-2 font-extrabold text-gray-900 tracking-tight">
              {t("notFound.title")}
            </span>
          </p>
          <p className="mt-2 text-base text-gray-500">
            {t("notFound.details")}
          </p>
          <NavigationLink
            title={t("notFound.goBack")}
            to="/onboarding/get-connected"
            className="mt-6"
          />
          <NavigationLink title={t("notFound.help")} to="/onboarding/help" />
        </div>
      </main>
    </PageLayout>
  );
};
export default RouterNotFoundContainer;
