import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import PageLayout from "../../layouts/PageLayout";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Input from "components/Input";
import ErrorMessage from "components/ErrorMessage";
import Title from "components/Title";
import Button from "components/Button";
import { Subscription } from "model";
import api from "../../api";
import { tryCatch } from "../../utils";

interface Props {}

export const RedemptionContainer: React.FC<Props> = () => {
  const history = useHistory();
  const [redemptionCode, setRedemptionCode] = useState<string>("");
  const [redemptionError, setRedemptionError] = useState<string>("");
  const [alreadySubscribed, setAlreadySubscribed] = useState<boolean>(false);
  const { adapter, subscription } = useSelector((state: RootState) => {
    const { adapter, subscriptions } = state.adapter;
    const subscription = adapter
      ? subscriptions.find(
          (sub: Subscription) => sub.adapter_id && sub.adapter_id === adapter.id
        )
      : undefined;
    return {
      adapter,
      subscription,
    };
  });
  const { t } = useTranslation();

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "redemption" });
  }, []);

  useEffect(() => {
    if (
      subscription &&
      new Date(subscription.current_period_end * 1000).getTime() -
        new Date().getTime() >
        1000 * 3600 * 24 * 350
    ) {
      setAlreadySubscribed(true);
    } else {
      setAlreadySubscribed(false);
    }
  }, [subscription]);

  const handleSubmitCode = async () => {
    setRedemptionError("");

    const apiResult = await tryCatch(
      api.subscription.applyRedemptionCode(adapter!.id, redemptionCode)
    );

    setRedemptionCode("");

    if (apiResult.error) {
      setRedemptionError(apiResult.error.message);
    } else {
      if (apiResult.result.data.result === "Code applied") {
        window.location.href = "/account?reason=code_applied";
      } else {
        setRedemptionError(apiResult.result.data.result);
      }
    }
  };

  return (
    <PageLayout back={history.goBack}>
      <Title title={t("redemption:title")} className="mb-2" />
      <div
        style={{ padding: "20px", display: "flex", justifyContent: "center" }}
      >
        <p style={{ whiteSpace: "pre-line", width: "80%" }}>
          {t("redemption:text")}
        </p>
      </div>
      {alreadySubscribed && (
        <div
          style={{
            padding: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <p style={{ whiteSpace: "pre-line", width: "80%" }}>
            {t("redemption:alreadySubscribed")}
          </p>
        </div>
      )}
      {!alreadySubscribed && (
        <div>
          <div style={styles.formContainer}>
            <div className="mb-4">
              <Input
                id="promotionCode"
                data-testid="promotionCode"
                type="text"
                label=""
                placeholder={t("redemption:redemptionCode")}
                inputProps={{
                  autoComplete: "off",
                  value: redemptionCode,
                  onChange: (e: any) => setRedemptionCode(e.target.value),
                }}
              />
            </div>
            <div style={{ marginBottom: "20px" }}>
              <Button
                data-testid="submit"
                className="text-center mr-3 w-full"
                size="small"
                type="button"
                style={{ width: "100%" }}
                disabled={redemptionCode === ""}
                onClick={() => {
                  handleSubmitCode();
                }}
              >
                Submit
              </Button>
            </div>
            <ErrorMessage isText error={redemptionError} />
          </div>
        </div>
      )}
    </PageLayout>
  );
};

const styles = {
  formContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px",
    gap: "10px",
  },
};

export default RedemptionContainer;
