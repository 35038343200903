import { Dispatch } from "../store";
import { History } from "history";
import appConfig from "../AppConfig";
import { handleError } from "utils";
import api from "api";

export default async function handleDeepLinkSlug(
  history: History,
  dispatch: Dispatch,
  data: { url: string }
) {
  const slug = data.url.split(appConfig.DEEPLINK_HOST!).pop();

  if (!slug) {
    // console.log('No slug passed. Go to home page');
    history.push(`/home?fresh=none`);
  } else if (slug.indexOf("passwordReset") > -1) {
    // get the verify token
    const urlParams = new URLSearchParams(slug);
    const verifyToken = urlParams.get("verify");
    try {
      if (!verifyToken) {
        throw new Error("No verify token provided");
      }
      const result = await api.auth.confirmCustomer(verifyToken);
      dispatch.authentication.updateUserToken(result.data);
      history.push(`/home?fresh=good`);
    } catch (error) {
      // handle expiration error
      // handle other errors
      // redirect to welcome screen and show the error message
      const encodedError = encodeURIComponent(
        handleError({ error, fallback: "Error verifying token" }) ||
          "Error verifying token"
      );
      history.push(`/welcome?error=${encodedError}`);
    }
  } else {
    history.push(`/home?fresh=${slug}`);
  }
}
