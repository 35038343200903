
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Manage Devices",
  "currentProfile": "Current Profile",
  "otherProfiles": "Other Profiles",
  "moveTo": "Move to",
  "loading": "Loading devices ...",
  "remove": "Remove"
}
