import { IonToast } from "@ionic/react";
import React, { useState } from "react";
import { Dispatch } from "../../store";
import * as firebase from "firebase/app";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";

import http from "axios";
import { UserToken } from "../../model";
import { PageLayout } from "../../layouts";
import { useHistory } from "react-router-dom";
import { validateEmail } from "../../utils";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Input from "components/Input";
import Title from "components/Title";
import NavigationLink from "components/NavigationLink";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";

export const LoginComponent: React.FC<{}> = () => {
  const dispatch = useDispatch<Dispatch>();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<string>("");
  const [error, setError] = useState<string>("");
  const { t } = useTranslation();

  const history = useHistory();

  function login(form?: React.FormEvent<HTMLFormElement>) {
    if (form) form.preventDefault();
    if (validateEmail(email) && password) {
      http
        .post<UserToken>("/v1/auth/login", { id: email, pwd: password })
        .then((response) => {
          const data = response.data || {};
          firebase.analytics().logEvent("login", { type: "login" });
          FirebaseAnalytics.logEvent("login", { type: "login" });
          dispatch.authentication.updateUserToken({ ...data, password, email });
          // logdna.addContext({
          //   userEmail: email,
          // });
          // logdna.log("login");
          history.push("/");
        })
        .catch((e) => {
          setError(e);
        });
    }
  }

  return (
    <PageLayout testId="login-screen">
      <Title title={t("login:welcome")} className="mb-6" />
      <form
        data-testid="login-form"
        className="module-large"
        onSubmit={(f) => login(f)}
      >
        <div className="mb-4">
          <Input
            id="email"
            type="email"
            label={t("login:email.label")}
            placeholder={t("login:email.placeholder")}
            inputProps={{
              value: email,
              onChange: (e: any) => setEmail(e.target.value),
              autoComplete: "email",
            }}
          />
        </div>
        <div>
          <Input
            data-testid="password"
            id="password"
            label={t("login:password.label")}
            placeholder={t("login:password.placeholder")}
            type="password"
            inputProps={{
              value: password,
              onChange: (e: any) => setPassword(e.target.value),
              autoComplete: "current-password",
            }}
          />
        </div>
        <ErrorMessage error={error} fallback={t("login:invalidCredentials")} />
        <div className="mt-4">
          <Button
            data-testid="submit"
            disabled={!validateEmail(email) || !password}
            type="submit"
          >
            {t("login:pressable.login")}
          </Button>
        </div>
      </form>
      <NavigationLink
        to="/passwordRecovery?newCustomer=true"
        className="block"
        title={t("login:pressable.createAccount")}
      />
      <NavigationLink
        data-testid="reset-password"
        to="/passwordRecovery"
        className="block"
        title={t("login:pressable.resetPassword")}
      />
      <IonToast
        isOpen={!!toastMessage.length}
        onDidDismiss={() => setToastMessage("")}
        message={toastMessage}
      />
    </PageLayout>
  );
};

export default LoginComponent;
