import config from "AppConfig";
import http from "axios";
import { RESET_PASSWORD_ROUTE } from "../constants";
import { UserToken } from "../model";

import { createMock } from "./utils";

export const RENEW_TOKEN_ROUTE = `/v1/auth/renew?key=${config.API_KEY}`;
export const CONFIRM_CUSTOMER_ROUTE = `/v1/auth/confirmcustomer`;
export const CHANGE_PASSWORD_ROUTE = "/v1/auth/chgpwd";

interface ResetPasswordBody {
  verify: string;
  expchk?: boolean;
  pwd?: string;
}
export function resetPassword(verify: string, pwd?: string, expchk?: boolean) {
  const resetPasswordBody: ResetPasswordBody = {
    verify,
    expchk,
    pwd,
  };
  return http.post<UserToken>(RESET_PASSWORD_ROUTE, resetPasswordBody);
}
export const mockResetPassword = createMock<UserToken, ResetPasswordBody>(
  RESET_PASSWORD_ROUTE,
  "POST"
);

export function confirmCustomer(
  verify: string,
  pwd?: string,
  expchk?: boolean
) {
  const resetPasswordBody: ResetPasswordBody = {
    verify,
    expchk,
    pwd,
  };
  return http.post<UserToken>(CONFIRM_CUSTOMER_ROUTE, resetPasswordBody);
}
export const mockConfirmCustomer = createMock<UserToken, ResetPasswordBody>(
  CONFIRM_CUSTOMER_ROUTE,
  "POST"
);

export function changePassword(oldPassword: string, newPassword: string) {
  return http.post<{ bearer: string; exp: number; renew: number }>(
    "/v1/auth/chgpwd",
    {
      old: oldPassword,
      new: newPassword,
    }
  );
}

export function renewToken() {
  return http.get<UserToken>(RENEW_TOKEN_ROUTE);
}
