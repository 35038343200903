import React, { useEffect } from "react";
import ProfileOverview from "../../../pages/profile/components/Overview";
import { Dispatch, RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import NavigationLink from "components/NavigationLink";
import EmptyMessage from "components/EmptyMessage";
import classNames from "classnames";

interface Props {
  adapterId: string;
}

const ProfileHub: React.FC<Props> = ({ adapterId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<Dispatch>();
  useEffect(() => {
    dispatch.profile.getAdapterProfiles(adapterId).catch((err) => {
      console.log(err);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adapterId]);
  const profiles = useSelector((state: RootState) => {
    if (!state.profile.adapterProfileIds[adapterId]) {
      return [];
    }
    return state.profile.adapterProfileIds[adapterId]!.map(
      (profileId) => state.profile.profilesMap[profileId]!
    );
  });
  const createdProfiles = profiles.filter((profile) => !profile.system);

  return (
    <>
      <Title
        link="/profile/list"
        title={t("home:profiles")}
        subtitle={t("home:profilesSubtitle")}
      />

      {createdProfiles.length === 0 ? (
        <EmptyMessage
          message={t("home:noProfilesMessage")}
          linkUrl="/profile/create"
          linkText={t("home:pressable.createProfile")}
          className="mb-4"
        />
      ) : (
        <div className="">
          <ul className="grid grid-cols-2 gap-4 sm:grid-cols-4">
            {createdProfiles.map((profile, index) => (
              <li
                key={profile.name}
                className={classNames({ "hidden sm:block": index > 1 })}
              >
                <ProfileOverview
                  hideDelete
                  link={`profile/${profile.id}/details`}
                  profile={profile}
                />
              </li>
            ))}
          </ul>
          <p>
            <NavigationLink
              className="small has-icon"
              to="/profile/list"
              title={t("home:allProfiles")}
            />
          </p>
        </div>
      )}
    </>
  );
};

export default ProfileHub;
