
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "title": "Invoice History",
  "subtitle": "Past Invoice",
  "purchaseCode": "Purchase a NordVPN code",
  "invoice": "Invoice ID",
  "status": "Status",
  "created": "Created Date",
  "total": "Total",
  "download": "Download PDF",
  "showAll": "Show All",
  "hideAll": "Show Less",
  "showingRecent": "Showing your 5 most recent invoices",
  "showingAll": "Showing all invoices"
}
