import React from "react";
import { Route, useRouteMatch, Switch, Redirect } from "react-router-dom";

import Admin from "./AdminContainer";
import FindAccount from "./FindAccount";
import AccountDetails from "./AccountDetails";
import AddThirdPartyCodes from "./AddThirdPartyCodes";
import AddRedemptionCodes from "./AddRedemptionCodes";

const Routes: React.FC<{}> = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/find-account`} exact>
        <FindAccount />
      </Route>
      <Route path={`${path}/account-details`} exact>
        <AccountDetails />
      </Route>
      <Route path={`${path}/add-third-party-codes`} exact>
        <AddThirdPartyCodes />
      </Route>
      <Route path={`${path}/add-redemption-codes`} exact>
        <AddRedemptionCodes />
      </Route>
      <Route path={path} exact>
        <Admin />
      </Route>
      <Redirect to={`${path}`} />
    </Switch>
  );
};

export default Routes;
