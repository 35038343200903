import * as firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";
import http from "axios";
import { isPlatform } from "@ionic/react";

const anyWindow: Window = global.window;

const userAgent = anyWindow.navigator.userAgent.toLocaleLowerCase();
const isSafari = userAgent.includes("safari");

const isChrome = Boolean(
  // @ts-ignore
  userAgent.includes("edg") || userAgent.includes("chrome") || window.chrome
);

export const LOGDNA_INGESTION_KEY = process.env.REACT_APP_LOGDNA_INGESTION_KEY;

// export const LOGDNA_INGESTION_KEY = "8c332103f84b9fbd333dff4faea2de61";
// Initialize Firebase
let appId = process.env.REACT_APP_FIREBASE_WEB_APP_ID;
if (isPlatform("mobile") && !isPlatform("mobileweb")) {
  if (isPlatform("android")) {
    appId = process.env.REACT_APP_FIREBASE_ANDROID_APP_ID;
  } else if (isPlatform("ios")) {
    appId = process.env.REACT_APP_FIREBASE_IOS_APP_ID;
  }
}

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});
if (firebase.messaging.isSupported()) {
  const messaging = firebase.messaging();
  messaging.usePublicVapidKey(process.env.REACT_APP_FIREBASE_PUBLIC_VAPID_KEY!);
}
firebase.analytics.isSupported().then((isSupported) => {
  if (isSupported) {
    firebase.analytics();
  }
});

let env = process.env.REACT_APP_ENV as "dev" | "stage" | "prod"; //"dev"; //

if (["dev", "stage", "prod"].indexOf(env) === -1) {
  env = "prod";
}

// const API_URL_PREFIX = {
//   dev: "dev-api2.",
//   stage: "qa-api2.",
//   prod: "api2.",
// }[env];

// const DEEPLINK_PREFIX = {
//   dev: "dev.",
//   stage: "stage.",
//   prod: "",
// }[env];

function getApiKey() {
  // const t10 =
  //   "AOiI2NTY4YTRkNy05MGFjLTQzMjQtYjJlOC04YWVlYzRkNmIxNWMiLCJleHAiOjMwLCJyYmYiOjYwMCwic3ViIjoid2ViIn0.xQvYKB_nknLDfujn45FmDLwCGuXFVoc4fBb0peBKBXI";
  // const t1 =
  //   "AOiI2NTY4YTRkNy05MGFjLTQzMjQtYjJlOC04YWVlYzRkNmIxNWMiLCJleHAiOjMwLCJyYmYiOjYwLCJzdWIiOiJ3ZWIifQ.hc5ah4As9vmwTzzvFuha0gWeJzUv9xl8DExRxm_MD34";
  // if (env !== "prod") {
  if (isPlatform("ios") && !isPlatform("mobileweb")) {
    return process.env.REACT_APP_IOS_KEY;
  }
  if (isPlatform("android") && !isPlatform("mobileweb")) {
    return process.env.REACT_APP_ANDROID_KEY;
  }
  return process.env.REACT_APP_API_KEY;
  // } else {
  //   if (isPlatform("ios") && !isPlatform("mobileweb")) {
  //     return process.env.REACT_APP_PROD_IOS_KEY;
  //   }
  //   if (isPlatform("android") && !isPlatform("mobileweb")) {
  //     return process.env.REACT_APP_PROD_ANDROID_KEY;
  //   }
  //   return process.env.REACT_APP_PROD_API_KEY;
  // }
}
// https://capacitorjs.com/docs/guides/deep-links

const config = {
  env,
  API_URL: process.env.REACT_APP_API_URL_URL, //"http://127.0.0.1:10080/", //
  API_KEY: getApiKey(),
  DOMAIN: `${window.location.protocol}//${window.location.host}/`,
  SAFARI: !isChrome && isSafari,
  CHROME: isChrome,
  HS_AUTH: `Basic ${btoa(process.env.REACT_APP_HS_AUTH!)}`,
  HS_COL: process.env.REACT_APP_HS_COL,
  HS_URL: process.env.REACT_APP_HS_URL,
  DEEPLINK_HOST: process.env.REACT_APP_DEEPLINK_HOST,
  SUPPORT_URL: process.env.REACT_APP_SUPPORT_URL,
  GET_CONNECTED_YOUTUBE_URL: process.env.REACT_APP_GET_CONNECTED_YOUTUBE_URL,
};

http.defaults.baseURL = config.API_URL;

export default config;
