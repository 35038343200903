import React, { useState, useEffect } from "react";

import appConfig from "../../AppConfig";
import http from "axios";
import { PageLayout } from "../../layouts";
import { validateEmail } from "../../utils";
import { useHistory, useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import Input from "components/Input";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import { useLocalStorageState } from "ahooks";

type WelcomeState =
  | {
      error?: string;
    }
  | undefined;

const WelcomeComponent: React.FC = () => {
  const { t } = useTranslation("resetPassword");
  const [email, setEmail] = useLocalStorageState<string>("userEmail", "");
  const [error, setError] = useState<any>();

  const location = useLocation<WelcomeState>();

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const errorMessage = search.get("error");
    if (errorMessage) {
      setError(errorMessage);
    }
  }, [location]);
  const submit = (form?: React.FormEvent<HTMLFormElement>) => {
    if (form) {
      form.preventDefault();
    }

    http
      .post<{ msg: string }>("/v1/auth/newcustomer", {
        email,
        callback: `${appConfig.DEEPLINK_HOST}/passwordReset?newCustomer=true`,
      })
      .then(() => history.push(`/welcome-confirmation?email=${email}`))
      .catch((e) => {
        setError(e);
      });
  };
  const history = useHistory();

  return (
    <PageLayout>
      <div data-testid="welcome-screen">
        <Title title={t("welcome.title")} className="mb-4" />
        <p>{t("welcome.message")}</p>
        <form onSubmit={(e) => submit(e)}>
          <Input
            id="email"
            type="email"
            className="my-2"
            placeholder={t("welcome.emailPlaceholder")}
            label={t("welcome.emailLabel")}
            inputProps={{
              value: email,
              onChange: (e: any) => setEmail(e.target.value),
              autoComplete: "on",
            }}
          />
          <ErrorMessage error={error} />
          <Button
            data-testid="submit"
            type="submit"
            disabled={!validateEmail(email || "")}
          >
            Submit
          </Button>
        </form>
      </div>
    </PageLayout>
  );
};

export default WelcomeComponent;
