import React, { useState, useEffect, useMemo } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import { Dispatch, RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import * as firebase from "firebase/app";
import { FirebaseAnalytics } from "@ionic-native/firebase-analytics";
import imagePlaceholder from "./../../assets/images/icons/placeholder_64.png";

import {
  DeviceDetails,
  DeviceServiceUpdate,
  ProfileDetails,
  UIServiceState,
} from "./../../model";
import PageLayout from "./../../layouts/PageLayout";
import NerdStuffTab from "./components/NerdStuffTab";
import SettingsTab from "./components/SettingsTab";
import {
  classNames,
  createPollAndRefresh,
  getIcon,
  tryCatch,
} from "./../../utils";
import api from "./../../api";
import InternetPause from "./../../components/InternetPause";
import { useDeviceIcons } from "utils/hooks";
import UsageChart from "components/UsageChart";
import ErrorMessage from "components/ErrorMessage";
type ServiceKey = "vpn" | "adblocking" | "device-protection" | "internet-pause";

interface Update {
  id: string;
  service: DeviceServiceUpdate;
  setting: number | boolean;
}

const tabs = [{ name: "usage" }, { name: "settings" }, { name: "nerd" }];
export type ServiceStatus = {
  "ad-blocking": UIServiceState | undefined;
  "device-protection": UIServiceState | undefined;
  vpn: UIServiceState | undefined;
};
const DeviceDetailComponent: React.FC<{}> = () => {
  const { deviceId } = useParams<{ deviceId: string }>();
  const dispatch = useDispatch<Dispatch>();

  const { adapter, device } = useSelector((state: RootState) => ({
    adapter: state.adapter.adapter!,
    device: state.device.devices[deviceId],
  }));
  const search = useLocation().search;
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tab, setTab] = useState<string>("settings");
  const [error, setError] = useState<string>("");
  const [profile, setProfile] = useState<ProfileDetails>();
  const [adBlockingStatus, setAdBlockingStatus] = useState<UIServiceState>();
  const [
    deviceProtectionStatus,
    setDeviceProtectionStatus,
  ] = useState<UIServiceState>();
  const [vpnStatus, setVpnStatus] = useState<UIServiceState>();

  const loadDevice = async () => {
    try {
      const [device] = await dispatch.device.getDevice(deviceId);
      return device;
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    if (query.has("tab")) {
      const activeTab = query.get("tab");
      if (
        activeTab &&
        ["usage", "settings", "nerd"].indexOf(activeTab) !== -1
      ) {
        setTab(activeTab);
      }
    }
  }, [query]);

  useEffect(() => {
    FirebaseAnalytics.logEvent("init_page", { page: "device_details", tab });
  }, [tab]);

  useEffect(() => {
    loadDevice()
      .then((deviceDetails) => {
        if (deviceDetails) {
          dispatch.profile
            .getProfileDetails(deviceDetails.profile)
            .then((data) => {
              setProfile(data[0]);
              setLoading(false);
            })
            .catch(() => {
              // loading the profile isn't a problem
              setLoading(false);
            });
        } else {
          setLoading(false);
          setError("Error Loading device");
        }
      })
      .catch((e) => {
        // history.push("/device/list");
        setLoading(false);
        setError(e);
      });
  }, [deviceId]); // eslint-disable-line

  const updateStatus = (key: ServiceKey, state?: UIServiceState) => {
    if (key === "vpn") {
      setVpnStatus(state);
    } else if (key === "adblocking") {
      setAdBlockingStatus(state);
    } else if (key === "device-protection") {
      setDeviceProtectionStatus(state);
    }
  };

  const poll = (key: ServiceKey, state: Update, retryCount: number) =>
    createPollAndRefresh<DeviceDetails | undefined>(
      loadDevice,
      (deviceDetails) => deviceDetails?.cfg![key]!.state === state.setting,
      (uiState) => {
        updateStatus(key, uiState);
        if (uiState === "Failed!") {
          firebase.analytics().logEvent("DeviceDetails_toggle", {
            service: state.service,
            setting: state.setting,
            status: "ERROR",
          });
          FirebaseAnalytics.logEvent("DeviceDetails_toggle", {
            service: state.service,
            setting: state.setting,
            status: "ERROR",
          });
          dispatch.notifications.addNotification({
            canDismiss: true,
            message: "Failed to Update Device",
            level: "ERROR",
          });
        } else if (uiState === "Done!") {
          firebase.analytics().logEvent("DeviceDetails_toggle", {
            service: state.service,
            setting: state.setting,
            status: "SUCCESS",
          });
          FirebaseAnalytics.logEvent("DeviceDetails_toggle", {
            service: state.service,
            setting: state.setting,
            status: "SUCCESS",
          });
        }
      },
      retryCount
    );
  const changeStateAndPoll = async (
    key: ServiceKey,
    state: Update,
    retryCount: number
  ) => {
    updateStatus(key, "Updating");
    const deviceSettingResult = await tryCatch(
      api.device.setDeviceSettings(state)
    );
    if (deviceSettingResult.error) {
      updateStatus(key, "Failed!");
    } else {
      poll(key, state, retryCount);
    }
  };

  const deviceTypes = useDeviceIcons();
  const deviceType = useMemo(() => {
    if (!deviceTypes) {
      return undefined;
    } else {
      return deviceTypes.find(({ name }) => device?.type === name)?.icon;
    }
  }, [deviceTypes, device]);
  if (error) {
    return (
      <ErrorMessage
        testId="device-details-screen-error"
        error={error}
        fallback="error loading device"
        // route="/device/list"
      />
    );
  } else if (loading) {
    return (
      <PageLayout
        testId="device-details-screen"
        loading={loading}
        loadingMessage="Loading device ..."
      >
        {" "}
      </PageLayout>
    );
  } else {
    const deviceName = device?.name || "Unnamed device";
    return (
      <PageLayout
        testId="device-details-screen"
        loading={loading}
        loadingMessage="Loading device ..."
      >
        <div>
          <div className="group details-header mb-4 mt-12">
            <div className="relative mt-0 mx-auto mb-1 w-16 h-16">
              <div className="image-container transition focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-orange-500 hover:scale-110">
                <Link to={`/device/identify/${device?.id}`} className="">
                  <img src={deviceType || imagePlaceholder} alt="" />
                </Link>
              </div>
              <InternetPause
                id={device?.id}
                name={deviceName}
                type="device"
                pauseState={device?.cfg?.["internet-pause"]?.state}
              />
            </div>
            <h3 className="mb-0">{deviceName}</h3>
          </div>
          {profile ? (
            <Link
              to={`/profile/${profile.id}/details`}
              className="group hover:cursor-pointer"
            >
              <div className="flex items-center flex-col ">
                <img
                  className="rounded-full w-9 group-focus:ring-2 group-focus:ring-offset-2 group-focus:ring-orange-500"
                  src={getIcon(profile.icon)}
                  alt={profile.name}
                  title={profile.name}
                />
                <div className="flex items-center text-xs text-gray group-focus:text-orange group-hover:text-orange">
                  <span className="truncate capitalize mt-1 mb-4">
                    {profile.name} Profile
                  </span>
                </div>
              </div>
            </Link>
          ) : null}
          <div className="tabs">
            <div className="block mb-4">
              <nav
                className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200"
                aria-label="Tabs"
              >
                {tabs.map(({ name }, tabIdx) => (
                  <Link
                    key={name}
                    to={`?tab=${name}`}
                    onClick={(e) => {
                      // e.preventDefault();
                      setTab(name);
                    }}
                    className={classNames(
                      name === tab
                        ? "text-gray-900"
                        : "text-gray-500 hover:text-gray-700",
                      tabIdx === 0 ? "rounded-l-lg" : "",
                      tabIdx === tabs.length - 1 ? "rounded-r-lg" : "",
                      "no-underline group relative min-w-0 flex-1 overflow-hidden",
                      "bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10 focus:ring ring-orange"
                    )}
                    aria-current={name === tab ? "page" : undefined}
                  >
                    <span className="capitalize">{name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        name === tab ? "bg-orange" : "bg-transparent",
                        "absolute inset-x-0 bottom-0 h-0.5"
                      )}
                    />
                  </Link>
                ))}
              </nav>
            </div>
            <div className="">
              {tab === "usage" && (
                <UsageChart id={deviceId} type="device" chartType="bar" />
              )}
              {tab === "settings" && (
                <SettingsTab
                  device={device}
                  status={{
                    "ad-blocking": adBlockingStatus,
                    vpn: vpnStatus,
                    "device-protection": deviceProtectionStatus,
                  }}
                  update={(key, value) =>
                    changeStateAndPoll(key, { ...value, id: deviceId }, 10)
                  }
                  isAdapterOnline={adapter!.services.online.state}
                  adapterStatus={adapter!.services}
                  isVPNAdapterConnected={
                    adapter!.ip.vpn.ip4 != null && adapter!.services.vpn.state
                  }
                />
              )}
              {tab === "nerd" && <NerdStuffTab device={device} />}
            </div>
          </div>
        </div>
      </PageLayout>
    );
  }
};

export default DeviceDetailComponent;
