import { IonIcon } from "@ionic/react";
import {
  // closeOutline,
  cloudOfflineOutline,
} from "ionicons/icons";

export default function Banner() {
  return (
    <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5 ">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="p-2 rounded-lg bg-orange shadow-lg sm:p-3 max-w-4xl m-auto">
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-0 flex-1 flex items-center">
              <span className="flex p-2 rounded-lg bg-orange-800">
                <IonIcon
                  icon={cloudOfflineOutline}
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </span>
              <p className="ml-3 font-medium text-white truncate">
                <span>The router is offline! No configuration possible</span>
              </p>
            </div>
            {/* <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
              <button
                type="button"
                className="-mr-1 flex p-2 rounded-md hover:bg-orange focus:outline-none focus:ring-2 focus:ring-white"
              >
                <span className="sr-only">Dismiss</span>
                <IonIcon
                  icon={closeOutline}
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
