import { createModel } from "@rematch/core";
import { RootModel } from "./models";

interface UIState {
  toast?: {
    type: "success" | "error" | "warning" | "info";
    description: string;
    duration?: "short" | "medium" | "long";
    title: string;
    isDisplayed?: boolean;
    notificationTitle?: string;
  };
  error?: {
    error: string;
    helpRoute?: string;
    route?: string;
  };
  isMenuDisplayed: boolean;
  isModalOpen: boolean;
}

const initialState: UIState = {
  toast: undefined,
  error: undefined,
  isMenuDisplayed: false,
  isModalOpen: false,
};

export const ui = createModel<RootModel>()({
  state: initialState, // initial state
  reducers: {
    _setToast(state, toast: UIState["toast"]) {
      if (toast) {
        return { ...state, toast: { ...toast, isDisplayed: true } };
      }
      return { ...state, toast: undefined };
    },
    _hideToast(state) {
      return { ...state, toast: undefined };
    },
    setError(state, error: UIState["error"]) {
      return { ...state, error };
    },
    _toggleMenu(state, isMenuDisplayed: boolean) {
      return { ...state, isMenuDisplayed };
    },
    setIsModalOpen(state, isModalOpen: boolean) {
      return { ...state, isModalOpen };
    },
    clearAll(state) {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    toggleMenu(isMenuDisplayed: boolean) {
      dispatch.ui._toggleMenu(isMenuDisplayed);
    },
    setToast(
      toast:
        | {
            type: "success" | "error" | "warning" | "info";
            notificationTitle?: string;
            description: string;
          }
        | undefined
    ) {
      // we need to rmeove the toast from the DOM before changing the message.
      dispatch.ui._hideToast();
      if (toast) {
        const title =
          toast.notificationTitle ||
          {
            success: "Success",
            error: "Error",
            warning: "Warning",
            info: "Info",
          }[toast.type];

        setTimeout(() => {
          dispatch.ui._setToast({ ...toast, isDisplayed: true, title });
        }, 100);
      }
    },
  }),
});
