import React, { useMemo } from "react";
import { ReactComponent as WifiIcon } from "../assets/images/svg-icons/wifi.svg";
import { ReactComponent as VpnIcon } from "../assets/images/svg-icons/vpn.svg";
import { ReactComponent as DataIcon } from "../assets/images/svg-icons/data.svg";
import { ReactComponent as DevicesIcon } from "../assets/images/svg-icons/devices.svg";
import { ReactComponent as ProfilesIcon } from "../assets/images/svg-icons/profiles.svg";
import { ReactComponent as StreamingIcon } from "../assets/images/svg-icons/streaming.svg";
import { ReactComponent as HomeIcon } from "../assets/images/svg-icons/home.svg";
import { RootState } from "../store";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { StatusLink } from "components/NavigationLink";
import { Subscription } from "model";

export const StatusContainer: React.FC<{}> = () => {
  const { t } = useTranslation();
  const {
    adapter,
    devicesCount,
    user,
    onboardingStage,
    adaptersList,
    subscription,
  } = useSelector((state: RootState) => {
    const adapter = state.adapter.adapter!;
    const { list, onboardingStage, subscriptions } = state.adapter;
    const subscription = adapter
      ? subscriptions.find(
          (sub: Subscription) => sub.adapter_id && sub.adapter_id === adapter.id
        )
      : undefined;
    return {
      adapter,
      adaptersList: list,
      onboardingStage,
      user: state.authentication.user,
      devicesCount: state.device.deviceIds.filter(
        (id) => state.device.devices[id].adapter === adapter.id
      ).length,
      subscription,
    };
  });
  const isFirstTimeOnboarding = useMemo<boolean>(
    () =>
      Boolean(
        window.location.pathname.includes("onboarding") &&
          (adaptersList.length === 0 ||
            (adaptersList.length === 1 && onboardingStage === "after-claim"))
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [adaptersList.length, window.location.pathname, onboardingStage]
  );
  const isMenuItemDisabled = isFirstTimeOnboarding || user?.admin;

  return (
    <div className="relative flex justify-center bg-white max-w-5xl mx-auto pt-8 px-8 md:px-0 lg:px-0">
      <ul className="flex justify-center gap-10 flex-wrap">
        <StatusLink
          to="/home"
          icon={<HomeIcon className="w-10" />}
          title={t("home:home")}
          disabled={isMenuItemDisabled}
          id={"nav-link-home"}
        />
        <StatusLink
          to="/settings/primary-network"
          icon={<WifiIcon className="w-10" />}
          title={t("home:wifi")}
          disabled={isMenuItemDisabled}
          id={"nav-link-wifi"}
        />
        <StatusLink
          to="/security"
          icon={<VpnIcon className="w-10" />}
          title={t("home:vpn")}
          status={adapter?.status?.vpn}
          locked={
            !(
              subscription?.product_permissions["VPN"]?.toLowerCase() === "true"
            )
          }
          disabled={isMenuItemDisabled}
          id={"nav-link-vpn"}
        />
        <StatusLink
          to="/streaming"
          icon={<StreamingIcon className="w-10" />}
          title={t("home:streaming")}
          locked={
            !(
              subscription?.product_permissions[
                "STREAMING_RELOCATION"
              ]?.toLowerCase() === "true"
            )
          }
          disabled={isMenuItemDisabled}
          id={"nav-link-streaming"}
        />
        <StatusLink
          to="/internet"
          icon={<DataIcon className="w-8" />}
          title={t("home:data")}
          locked={
            !(
              subscription?.product_permissions["DATA"]?.toLowerCase() ===
              "true"
            )
          }
          disabled={isMenuItemDisabled}
          id={"nav-link-data"}
        />
        <StatusLink
          to="/device/list"
          icon={
            <div className="relative flex items-center justify-center">
              <div className="absolute text-xs top-[0.45rem]">
                {devicesCount > 0 ? devicesCount : ""}
              </div>
              <DevicesIcon className="w-10" />
            </div>
          }
          title={t("home:devices")}
          locked={
            !(
              subscription?.product_permissions["DEVICES"]?.toLowerCase() ===
              "true"
            )
          }
          disabled={isMenuItemDisabled}
          id={"nav-link-devices"}
        />
        <StatusLink
          to="/profile/list"
          icon={<ProfilesIcon className="w-10 mt-1" />}
          title={t("home:profiles")}
          locked={
            !(
              subscription?.product_permissions["PROFILES"]?.toLowerCase() ===
              "true"
            )
          }
          disabled={isMenuItemDisabled}
          id={"nav-link-profiles"}
        />
      </ul>
    </div>
  );
};

export default StatusContainer;
