import React from "react";
import { Route, useRouteMatch, Switch } from "react-router-dom";

import { AdapterListItem } from "../../model";
import {
  ProfileList,
  ProfileCreate,
  ProfileDetails,
  ManageDevices,
  BedtimeConfig,
} from "../../pages";

interface Props {
  adapter: AdapterListItem;
}

const Routes: React.FC<Props> = ({ adapter }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/create`} exact>
        <ProfileCreate adapterId={adapter.id} />
      </Route>
      <Route path={`${path}/list`} exact>
        <ProfileList adapterId={adapter.id} />
      </Route>
      <Route path={`${path}/:profileId/details`} exact>
        <ProfileDetails />
      </Route>
      <Route path={`${path}/:profileId/edit`} exact>
        <ProfileCreate adapterId={adapter.id} />
      </Route>
      <Route path={`${path}/:profileId/manage`} exact>
        <ManageDevices adapterId={adapter.id} />
      </Route>
      <Route path={`${path}/:profileId/school-nights`} exact>
        <BedtimeConfig school />
      </Route>
      <Route path={`${path}/:profileId/weekends`} exact>
        <BedtimeConfig />
      </Route>
    </Switch>
  );
};

export default Routes;
