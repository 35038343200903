import { IonIcon } from "@ionic/react";
import { closeCircleSharp } from "ionicons/icons";
import { useMemo } from "react";
import { handleError } from "utils";

interface Props {
  error: any;
  fallback?: string;
  isText?: boolean;
  testId?: string;
}
export default function ErrorMessage({
  error,
  fallback,
  isText = false,
  testId,
}: Props) {
  const errorObject = useMemo<{
    code: string;
    message: string;
    fallback?: string;
  } | null>(() => {
    if (error) {
      if (isText) {
        return { message: error, code: "" };
      }
      return handleError({ error, fallback, asObject: true }) as any;
    }
    return null;
  }, [error, fallback, isText]);
  if (errorObject) {
    return (
      <div className="rounded-md bg-red-50 p-4 my-4" data-testid={testId}>
        <div className="flex">
          <div className="flex-shrink-0">
            <IonIcon
              icon={closeCircleSharp}
              className="h-5 w-5 text-red-400"
              aria-hidden="true"
            />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800 first-letter:uppercase">
              {`Error ${errorObject.code}`}
            </h3>
            <div className="mt-2 text-sm text-red-700">
              <p data-testid="error-message" className="first-letter:uppercase">
                {errorObject.message || fallback || errorObject.fallback}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return null;
}
