import { createModel } from "@rematch/core";
import { RootModel } from "./models";
import api from "../api";
import {
  AdapterListItem,
  StreamingConfig,
  StreamingServices,
  UIServiceState,
} from "../model";
import { createPollAndRefresh, tryCatch, getCountryName } from "../utils";
import { StreamingConfigBody } from "../api/streaming";

export interface StreamingState {
  adapterConfigMap: Record<string, StreamingConfig[]>;
  adapterServicesMap: Record<string, StreamingServices[]>;
}

const initialState: StreamingState = {
  adapterConfigMap: {},
  adapterServicesMap: {},
};

export const streaming = createModel<RootModel>()({
  state: initialState, // initial state
  reducers: {
    _setStreamingConfig(
      state,
      { adapterId, config }: { adapterId: string; config: StreamingConfig[] }
    ) {
      return {
        ...state,
        adapterConfigMap: { ...state.adapterConfigMap, [adapterId]: config },
      };
    },
    _setStreamingServices(
      state,
      {
        adapterId,
        services,
      }: { adapterId: string; services: StreamingServices[] }
    ) {
      return {
        ...state,
        adapterServicesMap: {
          ...state.adapterServicesMap,
          [adapterId]: services,
        },
      };
    },
    clearAll(state) {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async getStreamingConfig(_, rootState) {
      const adapterId = rootState.adapter.adapter!.id;
      const res = await api.streaming
        .getStreamingConfig(adapterId)
        .then((d) => d.data);

      dispatch.streaming._setStreamingConfig({
        adapterId,
        config: res,
      });
      return res;
    },
    async getStreamingServices(_, rootState) {
      const adapterId = rootState.adapter.adapter!.id;
      const res = await api.streaming.getStreamingServices(adapterId);
      dispatch.streaming._setStreamingServices({
        adapterId,
        services: res.data,
      });
    },
    async toggleRelocation(
      {
        updateUi,
        wantedState,
      }: {
        updateUi: (state: UIServiceState) => any;
        wantedState: boolean;
      },
      rootState
    ) {
      updateUi("Updating");
      const adapterId = rootState.adapter.adapter!.id;

      const res = await tryCatch(
        api.adapter.changeConfigurationService({
          id: adapterId,
          service: "streamrelocation",
          state: wantedState,
        })
      );
      const errorMessage = `Error ${
        wantedState ? "enabling" : "disabling"
      } streaming relocation`;
      const successMessage = `Streaming relocation ${
        wantedState ? "enabled" : "disabled"
      } successfully`;
      function displayErrorMessage() {
        dispatch.ui.setToast({
          description: errorMessage,
          type: "error",
        });
      }
      if (res.error) {
        displayErrorMessage();
        return updateUi("Failed!");
      }

      const getAdapterDetails = () =>
        api.adapter.getAdapter(adapterId).then((res) => res.data[0]!);

      createPollAndRefresh<AdapterListItem>(
        getAdapterDetails,
        (adapter) => {
          return adapter.services.streamrelocation?.state === wantedState;
        },
        (state, adapter) => {
          if (state !== "Updating") {
            updateUi(state);
          }
          if (state === "Done!") {
            dispatch.adapter._setAdapter(adapter!);
            dispatch.ui.setToast({
              description: successMessage,
              type: "success",
            });
          } else if (state === "Failed!") {
            dispatch.ui.setToast({
              description: errorMessage,
              type: "error",
            });
          }
        },
        10
      );
    },
    async configureStreamingService({
      config,
      updateUi,
      serviceName,
      isCityCode,
      locCaption,
    }: {
      config: StreamingConfigBody;
      updateUi: (state: UIServiceState) => any;
      serviceName: string;
      isCityCode: boolean;
      locCaption: string;
    }) {
      const countryName =
        config.country === "" ? "" : getCountryName(config.country);
      updateUi("Updating");
      const result = await tryCatch(
        api.streaming.configureStreamingService(config)
      );
      const errorMessage = !countryName
        ? `Error disabling streaming relocation for ${serviceName}`
        : `Error configuring ${serviceName} in ${countryName}`;
      if (result.error) {
        dispatch.ui.setToast({
          description: errorMessage,
          type: "error",
        });
        updateUi("Failed!");
      } else {
        const getStreamingConfig = dispatch.streaming.getStreamingConfig;

        createPollAndRefresh<StreamingConfig[]>(
          getStreamingConfig,
          (streamingConfigurations) => {
            const service = streamingConfigurations.find(
              ({ id }) => id === config.id
            );
            if (!service && !config.country) {
              // disabling relocation would remove the service from the list?
              return true;
            }
            return service?.country === config.country;
          },
          (state) => {
            if (state !== "Updating") {
              updateUi(state);
            }
            if (state === "Done!") {
              const message =
                !countryName && !isCityCode
                  ? `Streaming relocation for ${serviceName} has been disabled`
                  : locCaption !== ""
                  ? `Streaming service ${serviceName} was set to ${locCaption} successfully`
                  : `Streaming service ${serviceName} was set to ${countryName} successfully`;
              dispatch.ui.setToast({
                description: message,
                type: "success",
              });
            } else if (state === "Failed!") {
              dispatch.ui.setToast({
                description: errorMessage,
                type: "error",
              });
            }
          },
          10
        );
      }
    },
  }),
});
