import React, { useMemo } from "react";
import PageLayout from "../../layouts/PageLayout";
import SpeedTest from "./components/SpeedTest";
import UsageChart from "../../components/UsageChart";
import DevicesDisplay from "./components/DevicesDisplay";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { getDataCapDisplayValues } from "utils";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import Title from "components/Title";
import NavigationLink from "components/NavigationLink";

interface Props {
  adapterId: string;
}

const Overview: React.FC<Props> = ({ adapterId }) => {
  const { datacap } = useSelector((state: RootState) => ({
    datacap: state.adapter.adapter!.settings.datacap,
  }));
  const formattedDataCap = useMemo(() => {
    const cap = getDataCapDisplayValues(datacap);
    if (cap.cap === 0) {
      return "disabled";
    } else {
      return `${_.round(cap.cap, 1)} ${cap.unit}`;
    }
  }, [datacap]);
  const { t } = useTranslation();
  return (
    <PageLayout>
      <div className="module-large">
        <Title
          title={t("internet:usageTitle")}
          subtitle={t("internet:usageSubtitle")}
        />
        <p>
          <NavigationLink
            to="/internet/datacap"
            title={`${t("internet:dataCapLink")} (${formattedDataCap}). ${t(
              "internet:dataCapLinkEdit"
            )}`}
          />
        </p>
        <UsageChart type="adapter" id={adapterId} />
      </div>
      <br />
      <DevicesDisplay />
    </PageLayout>
  );
};

export default Overview;
