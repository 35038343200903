import AppConfig from "AppConfig";
import Button from "components/Button";
import InformationMessage from "components/InformationMessage";
import Title from "components/Title";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import PageLayout from "../../layouts/PageLayout";
import OnboardingSteps from "./components/OnboardingSteps";

const GetConnectedContainer: React.FC = () => {
  const { t } = useTranslation("onboarding");
  const history = useHistory();
  return (
    <PageLayout>
      <OnboardingSteps stage="connect" />
      <Title
        title={t("getConnected.title")}
        data-testid="get-connected"
        className="mb-2"
      />
      <InformationMessage text={t("getConnected.description")} />
      <div className="video-container">
        <iframe
          width="560"
          height="315"
          src={AppConfig.GET_CONNECTED_YOUTUBE_URL}
          title="How to setup privacy hero router?"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
      <Button
        className="mt-2"
        onClick={() => history.push("/onboarding/activate")}
      >
        {t("getConnected.ok")}
      </Button>
    </PageLayout>
  );
};
export default GetConnectedContainer;
