
/**
 * Loader API Version: 2
 * Is this in "webpack mode": true
 */
export default {
  "tabs": {
    "usage": "Usage",
    "settings": "Settings",
    "devices": "Devices"
  },
  "settings": {
    "internetPaused": "Device internet is paused, so its settings can't be changed",
    "vpn": "VPN",
    "adapterVPNOff": "Adapter VPN is off, so VPN can't be used by individual devices",
    "vpnDown": "Our VPN tunnel is down, VPN is off, but you still can disable VPN for this device",
    "adBlocking": "Ad Blocking",
    "adapterAdBlockingOff": "Adapter Ad Blocking is off, so it can't be used by individual devices",
    "malware": "Device Protection"
  },
  "nerd": {
    "ip": "IP Address",
    "mac": "MAC Address",
    "brand": "Brand",
    "online": "Online",
    "lastSeen": "No, last seen online"
  }
}
