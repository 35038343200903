import React from "react";
import { Route, useRouteMatch, Switch } from "react-router-dom";

import { AdapterListItem } from "../../model";
import InternetOverview from "./Overview";
import DataCap from "./DataCap";

interface Props {
  adapter: AdapterListItem;
}

const Routes: React.FC<Props> = ({ adapter }) => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact>
        <InternetOverview adapterId={adapter.id} />
      </Route>
      <Route path={`${path}/datacap`} exact>
        <DataCap />
      </Route>
    </Switch>
  );
};

export default Routes;
