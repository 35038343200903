import MockAdapter from "axios-mock-adapter";
import { getUtils } from "../uvu_tests/setup/utils";
import { StatusCodes } from "./StatusCodes";

type ErrorType = { error: string } | { message: string | null };
export function createMock<ResponseType, RequestBody>(
  url: string,
  method: "POST" | "GET"
) {
  return function (
    mock: MockAdapter | InstanceType<typeof getUtils>["mock"],
    status: StatusCodes,
    response: ResponseType | ErrorType,
    body?: RequestBody,
    params?: string
  ) {
    let urlWithParams = `${url}${params || ""}`;
    if (method === "GET") {
      return mock.onGet(urlWithParams).reply(status, response);
    } else if (method === "POST") {
      if (body) {
        return mock.onPost(urlWithParams, body).reply(status, response);
      } else {
        return mock.onPost(urlWithParams).reply(status, response);
      }
    }
  };
}
