import { Fragment, useCallback, useMemo } from "react";
import { Transition } from "@headlessui/react";
import { IonIcon } from "@ionic/react";
import classNames from "classnames";
import {
  alertCircle,
  checkmarkCircle,
  close,
  informationCircle,
} from "ionicons/icons";
import { isPlatform } from "@ionic/core";

interface Props {
  type?: "success" | "error" | "warning" | "info";
  title?: string;
  description?: string;
  isDisplayed: boolean;
  hide: () => void;
  duration?: "short" | "medium" | "long";
}
export default function Notification({
  type,
  title,
  description = "",
  isDisplayed,
  hide,
}: Props) {
  const icon = useMemo(() => {
    switch (type) {
      case "success":
        return checkmarkCircle;
      case "error":
      case "warning":
        return alertCircle;
      case "info":
        return informationCircle;
    }
  }, [type]);
  const handleClick = useCallback(() => {
    if (isPlatform("mobile")) {
      hide();
    }
  }, [hide]);
  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="z-20 fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
      >
        <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={isDisplayed && !!description}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              onClick={handleClick}
              className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
            >
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <IonIcon
                      icon={icon}
                      className={classNames("h-6 w-6", {
                        "text-green-400": type === "success",
                        "text-red-400": type === "error",
                        "text-orange": type === "warning",
                        "text-blue-400": type === "info",
                      })}
                      aria-hidden="true"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{title}</p>
                    <p className="mt-1 text-sm text-gray-500">{description}</p>
                  </div>
                  <div className="ml-4 flex-shrink-0 flex">
                    <button
                      className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      onClick={() => {
                        hide();
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <IonIcon
                        icon={close}
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
}
